import React from 'react';
import { RiskProfilesGrayIconMap, RiskProfilesIconMap } from 'utils/RiskProfiles';

function RiskProfileIcon({ riskProfile, grayscale, className, alt }) {
  const icon = grayscale ? RiskProfilesGrayIconMap[riskProfile] : RiskProfilesIconMap[riskProfile];

  return icon !== undefined && <img src={icon} className={className} alt={alt} />;
}

export default RiskProfileIcon;
