import React from 'react';
import { useSelector } from 'react-redux';
import { status } from 'utils/utilities';
import gameFinishedImg from 'assets/images/game-finished.png';
import { getPlayer } from 'redux/selectors/user.selector';

const CashOut = () => {
  const isCompleted = useSelector((state) => state.game.stats.isCompleted);
  const userStatus = useSelector((state) => state.user.status);
  const { isWinner } = useSelector(getPlayer);

  if (!isCompleted || userStatus !== status.registered) {
    return null;
  }

  return (
    <>
      <div className="container cash-out p-0">
        <h1 className="pt-24 text-start">The game is finished!</h1>
        {isWinner && <img src={gameFinishedImg} alt="game-finished" />}
      </div>
    </>
  );
};

export default CashOut;
