import { STRATEGY_PROVIDER } from 'utils/constants';

const useGasEstimate = () => ({
  [STRATEGY_PROVIDER.AaveV2]: {
    approve: 80000,
    joinGame: 500000,
    joinWhiteList: 600000,
    deposit: 450000,
    withdraw: 150000,
    earlyWithdraw: 450000,
    redeem: 600000,
  },
  [STRATEGY_PROVIDER.AaveV3]: {
    approve: 80000,
    joinGame: 500000,
    joinWhiteList: 600000,
    deposit: 450000,
    withdraw: 150000,
    earlyWithdraw: 450000,
    redeem: 600000,
  },
  [STRATEGY_PROVIDER.Moola]: {
    approve: 80000,
    joinGame: 500000,
    joinWhiteList: 600000,
    deposit: 450000,
    withdraw: 150000,
    earlyWithdraw: 450000,
    redeem: 600000,
  },
  [STRATEGY_PROVIDER.CurveAave]: {
    approve: 80000,
    joinGame: 750000,
    joinWhiteList: 850000,
    deposit: 700000,
    withdraw: 150000,
    earlyWithdraw: 700000,
    redeem: 1000000,
  },
  [STRATEGY_PROVIDER.CurveAtricrypto]: {
    approve: 80000,
    joinGame: 1250000,
    joinWhiteList: 1300000,
    deposit: 1250000,
    withdraw: 150000,
    earlyWithdraw: 1250000,
    redeem: 1600000,
  },
});

export default useGasEstimate;
