/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sdkJsClient } from 'service/sdk';
import { setUsersAddress } from 'redux/reducers/user';
import { LEADERBOARD_TYPES } from 'utils/constants';

import { FetchActionStatus } from 'redux/reduxConstants';
import { setLoaderDashboardProfile } from './feedbacks';

export const fetchPlayerGames = createAsyncThunk(
  'dashboard/fetchPlayerGames',
  async (usersAddress) => {
    const games = await sdkJsClient.getPlayerGames(usersAddress);

    return games;
  },
  {
    condition: (_, { getState }) => {
      const { loadingPlayerGames } = getState().dashboard;
      return loadingPlayerGames !== FetchActionStatus.Pending;
    },
  }
);

export const fetchPlayerGGScore = createAsyncThunk('dashboard/fetchPlayerGGScore', async (playerAddress) => {
  const response = await sdkJsClient.getPlayerGGScore(playerAddress, LEADERBOARD_TYPES.AllTime);
  return response;
});

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    playerGames: {},
    loadingPlayerGames: FetchActionStatus.Idle,

    playerGGScore: {},
    loadingPlayerGGScore: FetchActionStatus.Idle,
    isDashboardWithdrawing: false,
    externalLink: {},
    identityNames: {},
    showPreviousPools: false,
  },
  reducers: {
    setIsDashboardWithdrawing: (state, { payload }) => {
      state.isDashboardWithdrawing = payload;
    },
    setExternalLink: (state, { payload }) => {
      state.externalLink = payload;
    },
    setIdentityName: (state, { payload }) => {
      state.identityNames[payload.userAddress.toLowerCase()] = payload.userName;
    },
    setShowPreviousPools: (state, { payload }) => {
      state.showPreviousPools = payload;
    },
    resetDashboard: (state) => {
      state.playerGames = {};
      state.loadingPlayerGames = FetchActionStatus.Idle;
      state.playerGGScore = {};
      state.loadingPlayerGGScore = FetchActionStatus.Idle;
      state.isDashboardWithdrawing = false;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchPlayerGames.pending, (state) => {
        if (state.loadingPlayerGames === FetchActionStatus.Idle) {
          state.loadingPlayerGames = FetchActionStatus.Pending;
        }
      })
      .addCase(fetchPlayerGames.fulfilled, (state, action) => {
        const sortedGames = action.payload.sort((firstGame, secondGame) =>
          firstGame.gameStartsAt < secondGame.gameStartsAt ? 1 : -1
        );

        const allPlayerGames = {};
        allPlayerGames[action.meta.arg] = sortedGames;

        state.playerGames = { ...allPlayerGames };
        state.loadingPlayerGames = FetchActionStatus.Fulfilled;
      })

      .addCase(fetchPlayerGGScore.pending, (state) => {
        state.loadingPlayerGGScore = FetchActionStatus.Pending;
      })
      .addCase(fetchPlayerGGScore.fulfilled, (state, action) => {
        state.playerGGScore = action.payload;
        state.loadingPlayerGGScore = FetchActionStatus.Fulfilled;
      })
      .addCase(setUsersAddress, (state) => {
        state.loadingPlayerGames = FetchActionStatus.Idle;
        state.loadingPlayerGGScore = FetchActionStatus.Idle;
      })
      .addCase(setLoaderDashboardProfile, (state) => {
        state.loadingPlayerGames = FetchActionStatus.Idle;
        state.loadingPlayerGGScore = FetchActionStatus.Idle;
      }),
});

export default dashboardSlice.reducer;

export const { setIsDashboardWithdrawing, setExternalLink, setIdentityName, setShowPreviousPools, resetDashboard } =
  dashboardSlice.actions;
