import { useContext } from 'react';
import { getConfiguredSlippage } from 'redux/selectors/user.selector';
import { useSelector } from 'react-redux';
import { ABIVersions } from 'utils/constants';

import { HashContext } from 'providers/HashProvider';
import { ContractContext } from './ContractProvider';

const PoolRedeemSlippage = 0.5;

function useWriteContract() {
  const ContractClient = useContext(ContractContext);

  const { withdrawGameStats, withdrawPlayerInfo } = useContext(HashContext);

  const usersAddress = useSelector((state) => state.user.address) ?? withdrawPlayerInfo.address;
  const slippagePercentage = useSelector(getConfiguredSlippage);
  const paidAmount = useSelector((state) => state.user.player.paidAmount);
  const netPaidAmount = useSelector((state) => state.user.player.netPaidAmount);
  const playerInterestAmount = useSelector((state) => state.user.player.interestAmount);
  const underlyingPlayerInterestAmount = useSelector((state) => state.user.player.underlyingInterestAmount);
  const isPlayerWinner = useSelector((state) => state.user.player.isWinner);

  const paymentAmount = useSelector((state) => state.game.info.paymentAmount);
  const performanceFee = useSelector((state) => state.game.info.performanceFee);
  const earlyWithdrawFee = useSelector((state) => state.pool.info.earlyWithdrawFee);
  const abiVersion = useSelector((state) => state.pool.info.abiVersion);

  const impermanentLossShare = useSelector((state) => state.game.stats.impermanentLossShare);
  const poolPrincipal = useSelector((state) => state.game.stats.principal) ?? withdrawGameStats.principal;
  const poolInterest = useSelector((state) => state.game.stats.interest) ?? withdrawGameStats.interest;
  const totalPoolFunds = parseFloat(poolPrincipal) + parseFloat(poolInterest);

  // V2 variables
  const isV2 = ABIVersions.v20x.includes(abiVersion);
  const hasImpermanentLoss = parseFloat(impermanentLossShare) > 0 || parseFloat(underlyingPlayerInterestAmount) < 0;
  const finalImpermanentLossShare = hasImpermanentLoss ? parseFloat(impermanentLossShare) / 100 : 1;

  return {
    earlyWithdraw: () => {
      if (isV2) {
        const playerWithdrawAmount =
          parseFloat(netPaidAmount) * parseFloat(finalImpermanentLossShare) * (1 - parseFloat(earlyWithdrawFee) / 100);

        return ContractClient.earlyWithdraw(usersAddress, playerWithdrawAmount, slippagePercentage);
      }

      const playerImpermanentLoss = parseFloat(playerInterestAmount) < 0 ? parseFloat(playerInterestAmount) : 0;
      const earlyWithdrawAmount =
        parseFloat(paidAmount) * (1 - parseFloat(earlyWithdrawFee) / 100) + playerImpermanentLoss;
      return ContractClient.earlyWithdraw(usersAddress, earlyWithdrawAmount, slippagePercentage);
    },
    joinGame: () => {
      return ContractClient.joinGame(usersAddress, paymentAmount, slippagePercentage);
    },
    joinWhitelistedGame: (proofs) => {
      return ContractClient.joinWhitelistedGame(usersAddress, proofs, paymentAmount, slippagePercentage);
    },
    makeDeposit: () => {
      return ContractClient.makeDeposit(usersAddress, paymentAmount, slippagePercentage);
    },
    withdraw: () => {
      if (isV2) {
        const playerInterest =
          isPlayerWinner && !hasImpermanentLoss
            ? parseFloat(underlyingPlayerInterestAmount) * (1 - parseFloat(performanceFee) / 100)
            : 0;

        const playerWithdrawAmount = parseFloat(netPaidAmount) * parseFloat(finalImpermanentLossShare) + playerInterest;

        return ContractClient.withdraw(usersAddress, playerWithdrawAmount, slippagePercentage);
      }

      return ContractClient.withdraw(usersAddress, totalPoolFunds, PoolRedeemSlippage);
    },
    redeem: () => {
      return ContractClient.redeem(usersAddress, totalPoolFunds, PoolRedeemSlippage);
    },
  };
}

export default useWriteContract;
