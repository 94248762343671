import React from 'react';

export default function Sponsor({ sponsors }) {
  return (
    <>
      {sponsors.map((sponsor) => {
        const { logo, description, url, extraDescription, extraUrl } = { ...sponsor };
        return (
          <section className="sponsor-item">
            <a href={url} target="_blank" rel="noreferrer">
              <img src={logo} alt="logo" />
            </a>
            <p className="mb-0">{description}</p>
            {extraDescription && extraUrl && (
              <p>
                Check their{' '}
                <a href={extraUrl} target="_blank" rel="noreferrer">
                  {extraDescription}
                </a>{' '}
                for more details.
              </p>
            )}
          </section>
        );
      })}
    </>
  );
}
