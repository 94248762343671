import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Widget } from '@typeform/embed-react';
import { setShowUserSurveyModal, resetErrors } from 'redux/reducers/feedbacks';
import { GasContext } from 'providers/GasProvider';
import Modal from 'components/elements/Modal';
import ModalHeader from 'components/elements/ModalHeader';

export default (props) => {
  const dispatch = useDispatch();
  const { reset } = useContext(GasContext);
  const showUserSurveyModal = useSelector((state) => state.feedbacks.showUserSurveyModal);

  const closeUserSurveyModal = () => {
    dispatch(setShowUserSurveyModal(false));
    dispatch(resetErrors());
    reset();
  };

  return (
    <div className="user-survey-modal">
      <Modal handleClose={closeUserSurveyModal} show={showUserSurveyModal}>
        <ModalHeader header="Tell us about yourself" onClose={closeUserSurveyModal} />
        <Widget id={props.id} style={{ width: '100%', height: '60vh' }} className={`my-form${props.classNames}`} />
      </Modal>
    </div>
  );
};
