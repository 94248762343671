import { isPayableSegmentsDone } from './game';

export const isWinner = (player, gameInfo) => {
  if (!isPayableSegmentsDone(gameInfo)) {
    return false;
  }

  if (player.isWinner !== undefined) {
    return player.isWinner;
  }

  return parseInt(player.mostRecentSegmentPaid) === parseInt(gameInfo.lastPayableSegment);
};

export const hasPaidThisSegment = (player, gameInfo) =>
  (parseInt(player.mostRecentSegmentPaid) === parseInt(gameInfo.currentSegment) && !player.withdrawn) ||
  isWinner(player, gameInfo);



// move presentation to another file
export const statusColors = {
  winner: 'has-paid-this-segment',
  waiting: 'still-to-pay-this-segment',
  ghost: 'ghosts',
  dropout: 'withdrawn-players',
};

export const statusEmoji = {
  winner: '🏆',
  waiting: '⏳',
  ghost: '👻',
  dropout: '🤦‍♀️',
};
