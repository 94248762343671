import React from 'react';
import { OverlayTrigger, Popover as PopoverBootstrap } from 'react-bootstrap';

export function DefaultPopover({ children, bsPrefix, content, placement = 'right', id, onHide, onShow }) {
  return (
    <OverlayTrigger
      trigger="click"
      key={placement}
      rootClose
      onToggle={(show) => {
        if (show) {
          onShow();
        } else {
          onHide();
        }
      }}
      placement={placement}
      overlay={
        <PopoverBootstrap bsPrefix={bsPrefix} id={id}>
          {content}
        </PopoverBootstrap>
      }
    >
      {children}
    </OverlayTrigger>
  );
}

export function GasPopover({ children, bsPrefix, content, id, handleToggle, show }) {
  return (
    <OverlayTrigger
      trigger="click"
      key="gas-popover"
      rootClose
      show={show}
      onToggle={handleToggle}
      placement="top"
      overlay={
        <PopoverBootstrap bsPrefix={bsPrefix} id={id}>
          <PopoverBootstrap.Body>{content}</PopoverBootstrap.Body>
        </PopoverBootstrap>
      }
    >
      {children}
    </OverlayTrigger>
  );
}
