import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import Image from 'components/elements/Image';
import { getPoolInfo } from 'redux/selectors/pool.selector';
import Badge from 'components/elements/Badge';
import { getGameStats } from 'redux/selectors/game.selector';
import { truncateAndformat } from 'utils/numberFormat';
import { getNetworkIcon, titleCase } from '../../../../utils/utilities';
import { TAG_TOOL_TIP, POOL_TYPE_LINK } from '../../../../utils/constants';

function NetworkIcon({ network }) {
  const networkIconUri = getNetworkIcon(`${network}IconAndName`);
  return (
    <div className="network-icon d-flex pr-10 pl-6 mb-12">
      <p className="mb-0">{titleCase(network)}</p>
      <Image src={networkIconUri} alt="Network Type" width={15} height={15} />
    </div>
  );
}

const Header = () => {
  const poolInfo = useSelector(getPoolInfo);
  const { gameNameShort, blogPostUri, gameName, tags, description, name: networkName } = { ...poolInfo };

  const networkIconUri = getNetworkIcon(`${networkName}IconAndName`);
  const gameStats = useSelector(getGameStats);
  return (
    <Container className="welcome">
      <div className="headline">
        <h1>{gameNameShort ?? gameName}</h1>
        <div className="d-flex widgets-wrapper flex-column flex-md-row align-items-center align-items-md-start">
          {!!gameName && (
            <p className="blog-link meta mb-0 mt-0 ml-0">
              Id:<span className="unique-name">{` ${gameName}`}</span>
            </p>
          )}
        </div>
      </div>
      {(!!description || !!blogPostUri) && (
        <p className="challenge-description">
          {!!description && <span>{description}</span>}{' '}
          {!!blogPostUri && (
            <span>
              Learn more in the{' '}
              <a href={blogPostUri} target="_blank" rel="noopener noreferrer nofollow">
                <b>blog post</b>!
              </a>
            </span>
          )}
        </p>
      )}
      <div className="welcome-content pt-10">
        <div className="badge-container mb-6">
          {tags &&
            tags.map((tag) => {
              return (
                <Badge
                  label={tag === 'flexible' ? 'custom' : tag}
                  type="primary"
                  tooltip={tag === 'flexible' ? TAG_TOOL_TIP.custom || '' : TAG_TOOL_TIP[tag] || ''}
                  link={tag === 'flexible' ? POOL_TYPE_LINK.custom : POOL_TYPE_LINK[tag]}
                  target="_blank"
                />
              );
            })}
        </div>
        <div className="second-group">
          <div className="APY">{`${truncateAndformat(gameStats.apy, 2)}% APY`}</div>
          {networkIconUri && <NetworkIcon network={networkName} />}
        </div>
      </div>
    </Container>
  );
};

export default Header;
