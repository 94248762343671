import React from 'react';

import Button from 'components/elements/Button';
import ButtonGroup from 'components/elements/ButtonGroup';
import Input from 'components/elements/Input';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';

import { DefaultSlippageValues } from './SlippageConfiguration';

function SlippageSelector({ form }) {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const isUsingCustomValue = watch('isUsingCustomValue');

  return (
    <ButtonGroup className="slippage-button-group">
      <Controller
        name="slippageDefaultInput"
        control={control}
        render={({ field: { onChange, value } }) => {
          return DefaultSlippageValues.map((defaulValue) => (
            <Button
              className={defaulValue === value && !isUsingCustomValue ? 'selected' : ''}
              key={`slippage-selector-${defaulValue}`}
              id={`slippage-selector-${defaulValue}`}
              color="light"
              onClick={() => {
                onChange(defaulValue);
                setValue('isUsingCustomValue', false);
              }}
            >
              {defaulValue}%
            </Button>
          ));
        }}
      />

      <Controller
        name="slippageInput"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            onChange={(e) => {
              const { value } = e.target;

              const isNumber = !Number.isNaN(Number(value));
              const hasTwoOrLessDecimalPlaces = value.toString().includes('.')
                ? value.toString().split('.')[1].length <= 2
                : true;
              const isBetweenBoundaries = Number(value) >= 0 && Number(value) <= 100;

              if (isNumber && hasTwoOrLessDecimalPlaces && isBetweenBoundaries) {
                field.onChange(e);
              }
            }}
            onFocus={() => setValue('isUsingCustomValue', true)}
            className={classNames('slippage-input', isUsingCustomValue && 'selected', errors.slippageInput && 'error')}
            size="sm"
            type="text"
            placeholder="1.1"
          />
        )}
      />
      <span>%</span>
    </ButtonGroup>
  );
}

export default SlippageSelector;
