import React from 'react';
import classNames from 'classnames';

import { gameNetworkIcons } from 'utils/networks';
import { trimWords } from 'utils/utilities';
import FutureGameIcon from 'assets/images/coming-soon-icon.png';
import NewGameIcon from 'assets/images/new-icon.png';
import Tooltip from './Tooltip';

const PoolCardType = {
  Halofi: 'halofi',
  Community: 'community',
  Completed: 'completed',
  Dashboard: 'dashboard',
};

export default function PoolCard({
  heading,
  subHeading,
  poolNumber,
  network,
  poolName,
  subDescription,
  onClick,
  className,
  isfutureGame,
  challengeType,
  nftImage,
  extraDescription,
}) {
  const classes = classNames(className);
  const imgClass = classNames(
    nftImage || isfutureGame || challengeType === PoolCardType.Halofi || challengeType === PoolCardType.Dashboard
      ? 'rounded-circle'
      : 'network-challenge-img'
  );
  const nftOrNetworkImage = !nftImage ? gameNetworkIcons[network] : nftImage;
  const displayImage =
    challengeType === PoolCardType.Halofi || challengeType === PoolCardType.Dashboard
      ? isfutureGame
        ? FutureGameIcon
        : NewGameIcon
      : nftOrNetworkImage;
  const poolHeading = trimWords(heading, 11, 14);
  const thePoolName = trimWords(poolName ?? '', 17, 20);

  return (
    <div className={classes} onClick={onClick} key={poolNumber}>
      <div className="pool-box">
        <div className="image-box">
          <img className={imgClass} src={displayImage} alt="network/nft" />
        </div>
        <div className="heading-container">
          <div className="heading-title">
            <span className="header-link">{poolHeading.text}</span>
            <Tooltip content={heading} placement="bottom" displayContent={() => <i className="bi bi-info" />} />
          </div>
          <span>{subHeading}</span>
          {poolName && (
            <div className="description-container">
              <Tooltip content={poolName} placement="bottom" displayContent={() => <p>{thePoolName.text}</p>} />
              <span>{subDescription}</span>
            </div>
          )}
        </div>
      </div>
      {challengeType === PoolCardType.Dashboard && (
        <div className="additonal-description">
          <p>{extraDescription}</p>
        </div>
      )}
    </div>
  );
}
