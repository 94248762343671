import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContractProvider from 'providers/ContractProvider/ContractProvider';
import NftProvider from 'providers/NftProvider';

import { getGamesInfo } from 'redux/selectors/game.selector';
import CenterLoading from 'components/elements/CenterLoading';
import { isEmpty } from 'lodash';
import Button from 'components/elements/Button';
import useScrollToTop from 'Hooks/useScrollToTop';
import useGetGame from './hooks/useGetGame';
import useCheckUserBalance from './hooks/useCheckUserBalance';
import useGetPlayer from './hooks/useGetPlayer';
import useGetGameAlreadyStarted from './hooks/useGetGameAlreadyStarted';
import useGetGames from './hooks/useGetGames';

// components
import LeftColumn from './components/LeftColumn';
import Header from './components/Header';
import ConfirmModal from './components/Modals/ConfirmModal/ConfirmModal';
import EarlyWithdrawModal from './components/Modals/EarlyWithdrawModal/EarlyWithdrawaModal';
import DepositModal from './components/Modals/DepositModal/DepositModal';
import WithdrawModal from './components/Modals/WithdrawModal/WithdrawModal';
import CountDown from './components/CountDown/CountDown';
import Tabs from './components/Tabs/Tabs';
import LaunchingSoon from './LaunchingSoon';
import CashOut from './components/CashOut/CashOut';
import { Alerts } from './components/common/Alerts';
import Levels from './components/Levels/Levels';

function NewGame({ info }) {
  // get game info and stats
  useGetGame(info);

  // check the user balance
  useCheckUserBalance();

  // get the player info
  useGetPlayer();

  // get if the game is already started and the user outside of it
  useGetGameAlreadyStarted();

  return (
    <div className="section center-content">
      {info.futureGame ? (
        <>
          <Header />
          <LaunchingSoon />
        </>
      ) : (
        <>
          {/** alerts */}
          <Alerts />
          <div className="container two-column-left">
            <div className="spacer" />
            <div className="left-game position-absolute">
              <LeftColumn mobileHide />
              <Levels positionClass="position-absolute bottom-40" />
            </div>
            <div className="right-game">
              {/** modals */}
              <ConfirmModal />
              <EarlyWithdrawModal />
              <DepositModal />
              <WithdrawModal />
              {/* sections */}
              <Header />
              <CountDown />
              <CashOut />
              {/** tabs */}
              <Tabs />
              {/** @todo unregisteredplayer */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default function WrappedNewGame() {
  // get the games configs
  useGetGames();
  // 👇️ scroll to top on page load
  useScrollToTop();
  const history = useHistory();

  const { id } = useParams();
  const gamesInfo = useSelector(getGamesInfo);

  if (isEmpty(gamesInfo)) {
    return <CenterLoading text="Waiting for pool" />;
  }

  const gameInfo = gamesInfo[id];

  if (!gameInfo) {
    return (
      <div className="page-not-found container">
        <p>
          This challenge does not exist! Please go back to the Challenges page to check for other live or upcoming
          challenges
        </p>
        <Button
          className="button button-primary"
          onClick={() => history.push('/challenges')}
          id="move-to-challenges-for-page-not-found"
        >
          Challenges Page
        </Button>
      </div>
    );
  }

  return (
    <ContractProvider info={gameInfo}>
      <NftProvider>
        <NewGame info={gameInfo} />
      </NftProvider>
    </ContractProvider>
  );
}
