import React from 'react';
import GenericSection from '../components/sections/GenericSection';
import { brandDomain, contactEmail, brandCaps } from '../utils/brand';

function Terms() {
  return (
    <GenericSection topDivider className="illustration-section-07">
      <div className="container-xs">
        <h3 className="mt-0 text-center">Terms of Service</h3>
        <p>
          <i>Last updated: 14 September 2023</i>
        </p>
      </div>
      <div className="container-xs">
        <p>
          Welcome to {` ${brandDomain} `}, a website of Binary Node Limited ( {` ${brandCaps} `} “we”, “our”, or “us”).
          This page explains the terms by which you may use our website (the “Site”), including the {` ${brandCaps} `}
          front-end and all the products, services, tools and information made available on the Site. By accessing or
          using the Site, you signify that you have read, understood, and agree to be bound by this Terms of Service
          Agreement (“Agreement”), whether or not you are a registered user of our Site. This Agreement applies to all
          visitors, users, and others who access the Site (“Users”).{' '}
          <b>If you do not want to be bound by the Agreement, you should not access the Site.</b>
        </p>
        <p>
          {` ${brandCaps} `} reserves the right to make unilateral modifications to this Agreement and will provide
          notice of these changes. You are advised to check the Agreement periodically to familiarise yourself with any
          changes to the terms and conditions of this Agreement. If you have any questions or need clarification on these terms, please contact us at  <a href="mailto:info@halofi.me">info@halofi.me</a>.
        </p>
        <p>
          Please read this Agreement carefully to ensure that you understand each provision. This agreement contains a
          mandatory individual arbitration and class action/jury trial waiver provision that requires the use of
          arbitration on an individual basis to resolve disputes, rather than jury trials or class actions.
        </p>
      </div>
      <div className="container-xs font-size-14">
        <h4 className="mt-0">Summary</h4>
        <p>
         By using the {` ${brandCaps} `} Site, you agree to be bound by these terms, which may be updated at any time. You must be at least 18 years old to use the site, which is operated from the British Virgin Islands and is not available to residents of the U.S., China, or the U.K. The site's content is owned by HaloFi, and your personal information is stored in accordance with BVI laws. While security measures are in place, complete security is not guaranteed. The site may include third-party links, and you agree to indemnify HaloFi against certain liabilities. All disputes must be resolved through individual arbitration, not class actions or jury trials.
        </p>     
        <h4 className="mt-0">{` ${brandCaps} `} Site</h4>
        <p>
          As part of the Site, {` ${brandCaps} `} provides access to an experimental and decentralized goal-based reward
          application (“Platform”) on the Polygon PoS blockchain, the Celo blockchain and the Base blockchain, that
          allows participants to use Polygon, Base and/or Celo assets (“Assets”) to transact using smart contracts (“Smart
          Contracts”).
        </p>
        <p>
          Using the Platform may require that you pay a fee, such as gas fees on the Polygon, Celo and/or Base networks
          to perform a transaction. You acknowledge and agree that {` ${brandCaps} `} has no control over any
          transactions, the method of payment of any transactions, or any actual payments of transactions. You must
          ensure that you have a sufficient balance of the applicable Assets stored at your {` ${brandCaps} `}{' '}
          Platform-compatible wallet address to complete any transaction on the {` ${brandCaps} `} Platform before
          initiating such a transaction.
        </p>
        <h4>Use of our Site</h4>
        <h5>A. Eligibility</h5>
        <p>
          This is a contract between you and {` ${brandCaps} `} . You must read and agree to these terms before using
          the Site. If you do not agree, you may not use the Site. You may use the Site only if you can form a binding
          contract with {` ${brandCaps} `} , and only in compliance with this Agreement and all applicable local, state,
          national, and international laws, rules and regulations. Any use or access to the Site by anyone under
          eighteen (18) years of age is strictly prohibited and in violation of this Agreement. The Site is not
          available to any Users previously removed from the Site by {` ${brandCaps} `} .
        </p>
        <h5>B. Access and Use of the Site and Smart Contracts</h5>
        <p>
          Subject to the terms and conditions of this Agreement, you are hereby granted a non-exclusive, limited,
          non-transferable, freely revocable license to use the Site as permitted by the features of the Site.
          {` ${brandCaps} `} reserves all rights not expressly granted herein in the Site and the {` ${brandCaps} `}{' '}
          Content (as defined below). {` ${brandCaps} `} may terminate this license, in whole or in part, at any time
          for any reason or no reason.
        </p>
        <h5>C. Site Rules</h5>
        <p>
          You agree not to engage in any of the following prohibited activities:
          <ul>
            <li>
              (i) copying, distributing, or disclosing any part of the Site in any medium, including without limitation
              by any automated or non-automated “scraping”;
            </li>
            <li>
              (ii) using any automated system, including without limitation “robots,” “spiders,” “offline readers,”
              etc., to access the Site in a manner that sends more request messages to the {` ${brandCaps} `} servers
              than a human can reasonably produce in the same period of time by using a conventional on-line web browser
              (except that {` ${brandCaps} `} grants the operators of public search engines revocable permission to use
              spiders to copy publically available materials from {brandDomain} for the sole purpose of and solely to
              the extent necessary for creating publicly available searchable indices of the materials, but not caches
              or archives of such materials);
            </li>
            <li>
              (iii) transmitting spam, chain letters, or other unsolicited email; (iv) attempting to interfere with,
              compromise the system integrity or security or decipher any transmissions to or from the servers running
              the Site; (v) taking any action that imposes, or may impose at our sole discretion an unreasonable or
              disproportionately large load on our infrastructure; (vi) uploading invalid data, viruses, worms, or other
              software agents through the Site;
            </li>
            <li>
              (vii) collecting or harvesting any personally identifiable information, including account names, from the
              Site;
            </li>
            <li>(viii) using the Site for any commercial solicitation purposes;</li>
            <li>
              (ix) impersonating another person or otherwise misrepresenting your affiliation with a person or entity,
              conducting fraud, hiding or attempting to hide your identity;
            </li>
            <li>(x) interfering with the proper working of the Site;</li>
            <li>
              (xi) accessing any content on the Site through any technology or means other than those provided or
              authorized by the Site; or
            </li>
            <li>
              (xii) bypassing the measures we may use to prevent or restrict access to the Site, including without
              limitation features that prevent or restrict use or copying of any content or enforce limitations on use
              of the Site or the content therein.
            </li>
          </ul>
          We may, without prior notice, change the Site; stop providing the Site or features of the Site, to you or to
          Users generally; or create usage limits for the Site. We may permanently or temporarily terminate or suspend
          your access to the Site without notice and liability for any reason, including if in our sole determination
          you violate any provision of this Agreement, or for no reason. Upon termination for any reason or no reason,
          you continue to be bound by this Agreement.
        </p>
        <p>
          You are solely responsible for your interactions with other {` ${brandCaps} `} Users and Platform Users. We
          reserve the right, but have no obligation, to monitor disputes between you and other Users. {` ${brandCaps} `}{' '}
          shall have no liability for your interactions with other Users, or for any User’s action or inaction.
        </p>
        <h5>D. Site Location and Jurisdiction</h5>
        <p>
          The Site is controlled and operated from the British Virgin Islands (“BVI”). HaloFi makes
          no representations that the Site is appropriate or available for use in any other locations. Those who access
          or use the Site from any jurisdictions do so at their own volition and are entirely responsible for compliance
          with all applicable BVI and local laws and regulations, including but not limited to export and import
          regulations. You may not use the Site if you are an individual or entity subject to sanctions or embargoes enforced
          by the British Virgin Islands (BVI), or if you are a resident of the United States of America, the People's Republic of China, or the United Kingdom.
          These restrictions are in accordance with various regulatory requirements, including Know Your Customer (KYC) procedures, compliance laws, and other
          regulations set forth by the BVI or foreign governments. Unless otherwise explicitly stated, all
          materials found on the Site are solely directed to individuals, companies, or other entities located in the BVI.
        </p>

        <h4>Proprietary Rights</h4>
        <p>
          The Site and all materials therein or transferred thereby, including, without limitation, software, images,
          text, graphics, illustrations, logos, patents, trademarks, service marks, copyrights, photographs, audio,
          videos, music, and User Content belonging to other Users (the “{` ${brandCaps} `} Content”), and all
          Intellectual Property Rights related thereto, are the exclusive property of {` ${brandCaps} `} and its
          licensors. Except as explicitly provided herein, nothing in this Agreement shall be deemed to create a license
          in or under any such Intellectual Property Rights, and you agree not to sell, license, rent, modify,
          distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create
          derivative works from any
          {` ${brandCaps} `} Content. Use of the {` ${brandCaps} `} Content for any purpose not expressly permitted by
          this Agreement is strictly prohibited.
        </p>
        <p>
          You may choose to or we may invite you to submit comments or ideas about the Site, including without
          limitation about how to improve the Site or our products (“Ideas”). By submitting any Idea, you agree that
          your disclosure is gratuitous, unsolicited and without restriction and will not place {` ${brandCaps} `} under
          any fiduciary or other obligation, and that we are free to use the Idea without any additional compensation to
          you, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. You further acknowledge
          that, by acceptance of your submission, {` ${brandCaps} `} does not waive any rights to use similar or related
          ideas previously known to {` ${brandCaps} `} , or developed by its employees, or obtained from sources other
          than you.
        </p>

        <h4>No Professional Advice</h4>
        <p>
          If the Site provides professional information (for example, medical, legal, or financial), such information is
          for informational purposes only and should not be construed as professional advice. No action should be taken
          based upon any information contained in the Site. You should seek independent professional advice from a
          person who is licensed and/or qualified in the applicable area. In particular, the content and materials
          available on the Site do not constitute any form of advice or recommendation by us, should not be regarded as
          an offer, solicitation, invitation or recommendation to buy or sell investments, securities or any other
          financial services and is not intended to be relied upon by you in making any specific investment or other
          decision.
        </p>

        <h4>No Banking Service</h4>
        <p>
          The {` ${brandCaps} `} Platform does not offer any kind of investments, banking or money services. The
          Platform may offer access to blockchain technology to interact with various virtual tokens that are
          transmitted via digital ledger technology - sometimes referred to as “virtual currencies” - although these
          tokens are not in fact considered “currency” or “money” of any sort under the present BVI financial services
          regime. Hence, any references to “money”, “currency” or “saving” on our Site or Platform refer strictly to
          virtual currencies, and not to fiat currency. Furthermore, the Platform does not intermediate transmissions or
          exchanges of fiat currency.
        </p>

        <h4>Privacy Policy</h4>
        <p>
          We are committed to protecting and respecting your privacy. Nonetheless, when you use the Site, we may collect
          information about your computer and your interaction with the Site. You understand that by using the Site you
          consent to the collection, use and disclosure of personally identifiable information and aggregate data, and
          to have your information collected, used, transferred to and processed in the BVI.
        </p>
        <p>
          If you wish to opt-out of any data collection or have concerns about your privacy, please contact us at 
          <a href="mailto:info@halofi.me">info@halofi.me</a>. We respect your choices and will work to address any concerns you may have.
        </p>

        <h4>Security</h4>
        <p>
          {` ${brandCaps} `} uses commercially reasonable physical, managerial, and technical safeguards to preserve the
          integrity and security of your personal information and implement your privacy settings. However, we cannot
          guarantee that unauthorized third parties will never be able to defeat our security measures or use your
          personal information for improper purposes. You acknowledge that you provide your personal information at your
          own risk.
        </p>

        <h4>Third-Party Links and Information</h4>
        <p>
          The Site may embed or contain links or references to third-party materials that are not owned or controlled by
          {` ${brandCaps} `} . {` ${brandCaps} `} does not endorse or assume any responsibility for any such third-party
          sites, information, materials, products, or services. If you access a third-party website or service from the
          Site on or through any third-party website or service, you do so at your own risk, and you understand that
          this Agreement and {` ${brandCaps} `} Privacy Policy do not apply to your use of such sites. You expressly
          relieve
          {` ${brandCaps} `} from any and all liability arising from your use of any third-party website, service, or
          content. Additionally, your dealings with or participation in promotions of advertisers found on the Site,
          including payment and delivery of goods, and any other terms (such as warranties) are solely between you and
          such advertisers. You agree that {` ${brandCaps} `} shall not be responsible for any loss or damage of any
          sort relating to your dealings with such advertisers.
        </p>
        <p>
          Your use of any third-party services, including but not limited to those listed below, is governed by the respective terms and conditions of those services. We strongly recommend that you review those terms before using the services. We are not responsible for any issues arising from the use of third-party services. Examples of such third-party services include:
          <ul>
          <li><a href="https://ramp.network/" target="_blank">Ramp Network</a> (Ramp Swaps Ltd and Ramp Swaps LLC): Allows the exchange of fiat currency for Digital Assets (i.e., On-Ramp and Off-Ramp services).</li>
          <li><a href="https://www.getfront.com/" target="_blank">Front</a> (Front Financial Inc.): Enables the transfer of Digital Assets.</li>
          <li><a href="https://www.squidrouter.com/" target="_blank">Squid Router</a> and <a href="https://axelar.network/" target="_blank">Axelar Network</a> (Axelar Inc.): Provides cross-chain swap and liquidity routing of Digital Assets.</li>
          <li><a href="https://www.mava.app/" target="_blank">Mava</a> (Koia Global Limited): Platform to collect user feedback and support tickets.</li>
          <li><a href="https://mouseflow.com/" target="_blank">Mouseflow</a> (Mouseflow Inc): Platform to perform user behavior analytics and collect user feedback.</li>
          <li><a href="https://hotjar.com/" target="_blank">Hotjar</a> (Hotjar Ltd.): Platform to perform user behavior analytics and collect user feedback.</li>
          <li><a href="https://valha.xyz/" target="_blank">Valha</a> (1.608 LABS): An API platform for DeFi integrations.</li>
          <li><a href="https://www.typeform.com/" target="_blank">Typeform</a> (Typeform SL): Platform to collect user feedback.</li>
          </ul>
        </p>
        <h4>Indemnity</h4>
        <p>
          You agree to defend, indemnify and hold harmless {` ${brandCaps} `} and its subsidiaries, agents, licensors,
          managers, and other affiliated companies, and their employees, contractors, agents, officers and directors,
          from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney’s fees) arising from:
          <ul>
            <li>
              (i) your use of and access to the Site, including any data or content transmitted or received by you;
            </li>
            <li>
              (ii) your violation of any term of this Agreement, including without limitation your breach of any of the
              representations and warranties above;
            </li>
            <li>
              (iii) your violation of any third-party right, including without limitation any right of privacy or
              Intellectual Property Rights;
            </li>
            <li>(iv) your violation of any applicable law, rule or regulation;</li>
            <li>
              (v) any content that you submit to the Site including without limitation misleading, false, or inaccurate
              information;
            </li>
            <li>(vi) your willful misconduct; or </li>
            <li>
              {' '}
              (vii) any other party’s access and use of the Site with your unique username, password or other
              appropriate security code.
            </li>
          </ul>
        </p>

        <h4>Assumption of Risk & Disclaimer</h4>
        <p>
          You acknowledge that the Site (including without limitation the Platform and the Smart Contracts) and your use
          of the Site contain certain risks, including without limitation the following risks:
          <ul>
            <li>
              That any Smart Contracts you interact with are entirely your own responsibility / liability, and
              {` ${brandCaps} `} is not a party to the Smart Contracts;
            </li>
            <li>
              At any time, your access to your Assets may be suspended or terminated or there may be a delay in your
              access or use of your Assets which may result in the Assets diminishing in value or you being unable to
              complete a Smart Contract and the Platform may be suspended or terminated for any or no reason, which may
              limit your access to your Assets.
            </li>
          </ul>
        </p>
        <p>
          Accordingly, you expressly agree that:
          <ul>
            <li>
              (A) you assume all risk in connection with your access and use of the Site, the Platform and the Smart
              Contracts; and
            </li>
            <li>
              (B) that you expressly waive and release {` ${brandCaps} `} from any and all liability, claims, causes of
              action, or damages arising from or in any way related to your use of the Site, the Platform or the Smart
              Contracts.
            </li>
          </ul>
        </p>

        <h4>No Warranty</h4>
        <p>
          <b>The Site is provided on an “as is” and “as available” basis. Use of the Site is at your own risk.</b> To
          the maximum extent permitted by applicable law, the Site is provided without warranties or representations of
          any kind, whether express or implied, including, but not limited to, implied warranties of merchantability,
          fitness for a particular purpose, or non-infringement. No advice or information, whether oral or written,
          obtained by you from {` ${brandCaps} `} or through the Site will create any warranty not expressly stated
          herein. Without limiting the foregoing, {` ${brandCaps} `} , its subsidiaries, its affiliates, and its
          licensors do not warrant that the content is accurate, reliable or correct; that the Site will meet your
          requirements; that the Site will be available at any particular time or location, uninterrupted or secure;
          that any defects or errors will be corrected; or that the Site is free of viruses or other harmful components.
          Any content downloaded or otherwise obtained through the use of the Site is downloaded at your own risk and
          you will be solely responsible for any damage to your computer system or mobile device or loss of data that
          results from such download or your use of the Site. Any use of the Platform is done at your own risk to the
          fullest extent permissible pursuant to applicable law any and all liability as well as all warranties,
          including any fitness for a particular purpose with respect to {` ${brandCaps} `} and/or the underlying
          software and the use thereof are disclaimed.
        </p>
        <p>
          {` ${brandCaps} `} does not warrant, endorse, guarantee, or assume responsibility for any product or site
          advertised or offered by a third party through the Site or any hyperlinked website or site, and{' '}
          {` ${brandCaps} `} will not be a party to or in any way monitor any transaction between you and third-party
          providers of products or services.
        </p>

        <h4>Limitation of Liability</h4>
        <p>
          To the maximum extent permitted by applicable law, in no event shall {` ${brandCaps} `} , its affiliates,
          agents, directors, employees, suppliers or licensors be liable for any indirect, punitive, incidental,
          special, consequential or exemplary damages, including without limitation damages for loss of profits,
          goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use,
          this Site. Under no circumstances will {` ${brandCaps} `} be responsible for any damage, loss or injury
          resulting from hacking, tampering or other unauthorized access or use of the Site or the information contained
          therein.To the maximum extent permitted by applicable law, {` ${brandCaps} `} assumes no liability or
          responsibility for any (i) errors, mistakes, or inaccuracies of content; (ii) personal injury or property
          damage, of any nature whatsoever, resulting from your access to or use of our site; (iii) any unauthorized
          access to or use of our secure servers and/or any and all personal information stored therein; (iv) any
          interruption or cessation of transmission to or from the Site; (v) any bugs, viruses, trojan horses, or the
          like that may be transmitted to or through our site by any third party; (vi) any errors or omissions in any
          content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted,
          or otherwise made available through the Site; and/or (vii) User Content or the defamatory, offensive, or
          illegal conduct of any third party. In no event shall {` ${brandCaps} `} , its affiliates, agents, directors,
          employees, suppliers, or licensors be liable to you for any claims, proceedings, liabilities, obligations,
          damages, losses or costs in an amount exceeding the amount you paid to {` ${brandCaps} `} hereunder or
          $100.00, whichever is greater.
        </p>
        <p>
          This limitation of liability section applies whether the alleged liability is based on contract, tort,
          negligence, strict liability, or any other basis, even if {` ${brandCaps} `} has been advised of the
          possibility of such damage. The foregoing limitation of liability shall apply to the fullest extent permitted
          by law in the applicable jurisdiction.
        </p>

        <h4>Governing Law, Arbitration and Class Action Waiver</h4>
        <p>
          Read this section carefully because it requires the parties to arbitrate their disputes and limits the manner
          in which you can seek relief from {` ${brandCaps} `} .
        </p>

        <h5>Initial dispute resolution</h5>
        <p>
          For any dispute with {` ${brandCaps} `} , you agree to first contact us at {` ${contactEmail} `} and attempt
          to resolve the dispute with us informally. The parties shall use their best efforts to engage directly to
          settle any dispute, claim, question, or disagreement and engage in good faith negotiations which shall be a
          condition to either party initiating an arbitration or lawsuit.
        </p>

        <h5>Arbitration</h5>
        <p>
          In the unlikely event that {` ${brandCaps} `} has not been able to resolve a dispute it has with you after
          sixty (60) days, we each agree to resolve any claim, dispute, or controversy (excluding any claims for
          injunctive or other equitable relief as provided below) arising out of or in connection with or relating to
          this Agreement, or the breach or alleged breach thereof (collectively, “Claims”), by binding arbitration.
        </p>
        <p>
          These Terms are governed by and will be construed under the laws of the British Virgin Islands (“BVI”),
          without regard to the conflicts of laws provisions thereof. Any dispute arising from or relating to the
          subject matter of these Terms shall be finally settled in the BVI, in English, in accordance with the
          Arbitration Rules of the British Virgin Islands International Arbitration Centre (the "Rules") for the time
          being in force, which rules are deemed to be incorporated by reference in this Section, by one commercial
          arbitrator with substantial experience in resolving intellectual property and commercial contract disputes,
          who shall be selected from the appropriate list of arbitrators in accordance with such Rules. The seat of the
          arbitration shall be Singapore, unless you and {` ${brandCaps} `} agree otherwise.
        </p>
        <p>
          If you are using the Site for commercial purposes, each party will be responsible for paying any British
          Virgin Islands International Arbitration Centre (“BVI IAC”) filing, administrative and arbitrator fees in
          accordance with the Rules, and the award rendered by the arbitrator shall include costs of arbitration,
          reasonable attorneys’ fees and reasonable costs for expert and other witnesses. If you are an individual using
          the Site for non-commercial purposes: (i) BVI IAC may require you to pay a fee for the initiation of your
          case, unless you apply for and successfully obtain a fee waiver from BVI IAC; (ii) the award rendered by the
          arbitrator may include your costs of arbitration, your reasonable attorney’s fees, and your reasonable costs
          for expert and other witnesses; and (iii) you may sue in a small claims court of competent jurisdiction
          without first engaging in arbitration, but this does not absolve you of your commitment to engage in the
          informal dispute resolution process. Any judgment on the award rendered by the arbitrator may be entered in
          any court of competent jurisdiction. Nothing in this Section shall be deemed as preventing {` ${brandCaps} `}{' '}
          from seeking injunctive or other equitable relief from the courts as necessary to prevent the actual or
          threatened infringement, misappropriation, or violation of our data security, Intellectual Property Rights or
          other proprietary rights.
        </p>

        <h5>Class Action Trial Waiver</h5>
        <p>
          With respect to all persons and entities, regardless of whether they have obtained or used the site for
          personal, commercial or other purposes, all Claims must be brought in the parties’ individual capacity, and
          not as a plaintiff or class member in any purported class action, collective action, private attorney general
          action or other representative proceeding. This waiver applies to class arbitration, and, unless we agree
          otherwise, the arbitrator may not consolidate more than one person’s claims.{' '}
          <b>
            You agree that, by entering into this agreement, you and {` ${brandCaps} `} are each waiving the right to a
            trial by jury or to participate in a class action, collective action, private attorney general action, or
            other representative proceeding of any kind.
          </b>
        </p>
      </div>
    </GenericSection>
  );
}

export default Terms;
