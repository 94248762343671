import React, { cloneElement, useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  nextFunction: PropTypes.func.isRequired,
  shouldScroll: PropTypes.bool,
  children: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadingText: PropTypes.string,
};

const defaultProps = {
  shouldScroll: true,
  loadingText: 'Loading More...',
};

function InfiniteScroll({ nextFunction, shouldScroll, hasMore, children, loadingText }) {
  const elementLoader = useRef({});
  const [next, setNext] = useState(0);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setNext((prev) => prev + 1);
    }
  }, []);

  const handleNextFunction = useCallback(() => {
    if (hasMore) {
      nextFunction(next);
    }
    // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  }, [next]);

  useEffect(() => {
    handleNextFunction();
  }, [handleNextFunction]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };

    if (shouldScroll) {
      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(elementLoader.current);
    }
  }, [handleObserver, shouldScroll]);

  return (
    <>
      {cloneElement(children)}
      <div className={hasMore && shouldScroll && 'infinite-scroll'} ref={elementLoader}>
        {hasMore && shouldScroll && <span>{loadingText}</span>}
      </div>
    </>
  );
}

InfiniteScroll.propTypes = propTypes;
InfiniteScroll.defaultProps = defaultProps;
export default InfiniteScroll;
