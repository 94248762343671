import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { persistStore } from 'reduxjs-toolkit-persist';
import store from 'redux/store';
import Web3Provider from 'providers/Web3Provider';
import GasProvider from 'providers/GasProvider';
import EnsProvider from 'providers/EnsProvider';
import IdentityProvider from 'providers/IdentityProvider';
import HashProvider from 'providers/HashProvider';
import UnstoppableDomainProvider from 'providers/UnstoppableDomainProvider';
import AddressResolverProvider from 'providers/AddressResolverProvider';
import ArcxAnalyticsProvider from 'providers/AnalyticsProvider';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/scss/main.scss';
import '@rainbow-me/rainbowkit/dist/index.css';
import { RainbowKitProvider, connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  metaMaskWallet,
  coinbaseWallet,
  braveWallet,
  walletConnectWallet,
  injectedWallet,
  omniWallet,
  // ledgerWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { polygon, base /* , baseGoerli */ } from 'wagmi/chains';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { Celo as CeloMainnet } from '@celo/rainbowkit-celo/chains';
import { Valora } from '@celo/rainbowkit-celo/wallets';

import { networkData } from 'utils/networks';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { UnstoppableDomains } from './rainbowkit/UDWallet';
// import { Venly } from './rainbowkit/VenlyWallet';

const celoMainnetConfig = { ...CeloMainnet, name: 'Celo' };

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;

// TODO: replace baseGoerli by base
const { chains, publicClient } = configureChains(
  // Swap these lines to enable baseGoerli in the frontend for testing.
  // [celoMainnetConfig, polygon, base, baseGoerli],
  [celoMainnetConfig, polygon, base],
  [
    jsonRpcProvider({
      rpc: (chain) => ({ http: networkData[chain.id].rpcURL }),
      static: false,
    }),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: 'Celo',
    wallets: [
      Valora({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      coinbaseWallet({ projectId, chains }),
      braveWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      //  CeloWallet({ projectId, chains }),
      omniWallet({ projectId, chains }),
      // ledgerWallet({ projectId, chains }),
      injectedWallet({ chains }),
    ],
  },
  {
    groupName: 'Polygon',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      braveWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      coinbaseWallet({ projectId, chains }),
      UnstoppableDomains({ projectId, chains }),
      injectedWallet({ chains }),
      // ledgerWallet({ projectId, chains }),
      // Venly({ chains }),
    ],
  },
  {
    groupName: 'Base',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      braveWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      coinbaseWallet({ projectId, chains }),
      injectedWallet({ projectId, chains }),
      // ledgerWallet({ projectId, chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: false,
  connectors,
  publicClient,
});

const history = createBrowserHistory();
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <ArcxAnalyticsProvider>
          <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains}>
              <Web3Provider>
                <EnsProvider>
                  <UnstoppableDomainProvider>
                    <AddressResolverProvider>
                      <IdentityProvider>
                        <HashProvider>
                          <GasProvider>
                            <App />
                          </GasProvider>
                        </HashProvider>
                      </IdentityProvider>
                    </AddressResolverProvider>
                  </UnstoppableDomainProvider>
                </EnsProvider>
              </Web3Provider>
            </RainbowKitProvider>
          </WagmiConfig>
        </ArcxAnalyticsProvider>
      </Router>
    </PersistGate>
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
