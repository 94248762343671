/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from 'react';
import { sdkJsClient } from 'service/sdk';
import { IMAGE_RESOLUTION } from 'utils/constants';

export default function useGetPlayersGGScore(leaderboard, shouldNotFetch = false) {
  const [loading, setLoading] = useState(false);
  const [playerScores, setPlayerScores] = useState([]);
  const [totalScores, setTotalScores] = useState(1);
  const [prevPage, setPage] = useState(-1);

  const getPlayersImage = useCallback((players) => {
    const playersAddress = players.map((player) => player.playerAddress);
    return sdkJsClient.getProfileImage({
      playersAddress,
      resolution: IMAGE_RESOLUTION.Low,
    });
  }, []);

  const getPlayerScores = async function fetch(page) {
    if (shouldNotFetch || loading) {
      return;
    }
    setLoading(true);
    try {
      if (prevPage === page) {
        return;
      }
      setPage(page);
      const offset = playerScores.length;
      const playerScoresRequest = await sdkJsClient.getPlayersGGScore({ offset, limit: 100, leaderboard });
      const playerScoresData = playerScoresRequest.data;
      const profileImages = await getPlayersImage(playerScoresData);

      const playerScoresWithProfileImage = playerScoresData.map((_, i) => {
        return { ...playerScoresData[i], profileImage: profileImages?.playersProfileImage[i] };
      });
      setPlayerScores([...playerScores, ...playerScoresWithProfileImage]);
      setTotalScores(playerScoresRequest.total);
    } catch (err) {
      //
    } finally {
      setLoading(false);
    }
  };

  return [playerScores, getPlayerScores, totalScores];
}
