import React, { useContext } from 'react';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { discordLink } from 'utils/utilities';
import celebrate from 'assets/animations/celebrate.gif';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { resetErrors, setShowConfirmModal, setSuccessJoinGame } from 'redux/reducers/feedbacks';
import {
  getErrorNotWhitelisted,
  getSuccessJoinGame,
  getShowConfirmModal,
  getLoaderJoinGame,
} from 'redux/selectors/feedbacks.selector';
import { getNextSegmentEnd } from 'redux/selectors/game.selector';
import { getPoolInfo } from 'redux/selectors/pool.selector';
import ExplorerRoute from 'utils/ExplorerRoute';
import { GasContext } from 'providers/GasProvider';
import Modal from 'components/elements/Modal';
import ModalHeader from 'components/elements/ModalHeader';
import LinkNewTab from 'components/elements/LinkNewTab';
import { PaymentCrypto } from './PaymentCrypto';
import useViewableStats from '../../GameStats/hooks/useViewableStats';
import useJoinGame from '../hooks/useJoinGame';
import { brandDomain, twitterHandle, appSubdomain, hashtag } from '../../../../../utils/brand';
import { JOIN_TWEETS } from '../../../../../utils/twitterTexts';
import { pickRandomItem } from '../../../../../utils/utilities';

dayjs.extend(relativeTime);

export default () => {
  const dispatch = useDispatch();

  const info = useSelector(getPoolInfo);
  const errorNotWhitelisted = useSelector(getErrorNotWhitelisted);
  const nextSegmentEnd = useSelector(getNextSegmentEnd);
  const successJoinGameMap = useSelector(getSuccessJoinGame);
  const { show, backButton } = useSelector(getShowConfirmModal);
  const loaderJoinGame = useSelector(getLoaderJoinGame);
  const [join] = useJoinGame();
  const { reset } = useContext(GasContext);
  const successJoinGame = successJoinGameMap[info.id];

  // eslint-disable-next-line no-unused-vars
  const [deposit, amount, , strategy] = useViewableStats();

  const date = dayjs(nextSegmentEnd);

  const closeConfirmModal = () => {
    dispatch(setShowConfirmModal(false));
    dispatch(resetErrors());
    dispatch(setSuccessJoinGame({ success: false, id: info.id }));
    reset();
  };

  const handleGoBack = () => {
    dispatch(setShowConfirmModal(false));
    dispatch(resetErrors());
    dispatch(setSuccessJoinGame({ success: false, id: info.id }));
  };

  return (
    <Modal show={show} handleClose={closeConfirmModal} className="confirm-modal">
      {!successJoinGame && !errorNotWhitelisted && (
        <ModalHeader
          header="Join this challenge"
          backButtonLabel={backButton}
          handleGoBack={handleGoBack}
          onClose={closeConfirmModal}
        />
      )}
      {successJoinGame && (
        <div>
          <ModalHeader onClose={closeConfirmModal} />
          <img src={celebrate} alt="Celebrate animation" />
          <h4>
            Congrats you have joined the challenge!{' '}
            <span role="img" aria-label="swimmer">
              🥳
            </span>{' '}
          </h4>
          {deposit.data !== '1' && (
            <p className="font-size-16 mb-12">Come back {date.fromNow()} to make your next deposit.</p>
          )}
          <TwitterShareButton
            title={`${pickRandomItem(JOIN_TWEETS)}.\nI just joined one of ${twitterHandle} saving challenges.\n\n`}
            url={`https://${appSubdomain}.${brandDomain}/#/challenges/${info.displayId}?utm_source=twitter&utm_medium=social&utm_campaign=share_join_challenge&utm_content=join_challenge\n\n`}
            hashtags={[hashtag, 'newdefichallenge']}
            related={[twitterHandle]}
            className="mb-1"
            id="share-join-from-modal"
          >
            <p className="font-size-16">
              You are part of the future of savings!
              <br />
              Share with others.
            </p>
            <TwitterIcon size={48} round />
          </TwitterShareButton>
          <div>
          <ExplorerRoute />
          </div>
        </div>
      )}
      {errorNotWhitelisted ? (
        <>
          <h2>Sorry you are not whitelisted for this game</h2>
          <p>
            Keep an eye on our <LinkNewTab link={discordLink}> discord </LinkNewTab> for upcoming games
          </p>
        </>
      ) : null}
      {!successJoinGame && !errorNotWhitelisted && (
        <>
          <p className="p-three text-start">You need to execute the three steps below to join the game</p>
          <PaymentCrypto payActionTitle="Join Game" action={join} loaderAction={loaderJoinGame} />
        </>
      )}
    </Modal>
  );
};
