import axios from 'axios';
import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/selectors/user.selector';
import { polygonID } from 'utils/networks';
import { FRONT_CATALOG_URL, FRONT_NETWORK_URL, FRONT_URL } from 'utils/constants';
import Button from 'components/elements/Button';
import LoadingButton from 'views/NewGame/components/common/LoadingButton';
import FrontComponent from './Front';

const FrontPage = () => {
  const userInfo = useSelector(getUser);

  const [iframeLink, setIframeLink] = useState(null);
  const [pending, setPending] = useState(false);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-client-id': process.env.REACT_APP_FRONT_CLIENT_ID,
      'x-client-secret': process.env.REACT_APP_FRONT_API_KEY,
    },
    params: {
      userId: process.env.REACT_APP_FRONT_CLIENT_ID,
      enableTransfers: true,
    },
  };

  const networkConfig = {
    headers: {
      'Content-Type': 'application/json',
      'x-client-id': process.env.REACT_APP_FRONT_CLIENT_ID,
      'x-client-secret': process.env.REACT_APP_FRONT_API_KEY,
    },
  };

  const makeTransfer = useCallback(async () => {
    setIframeLink(null);
    setPending(true);
    try {
      const networkData = await axios.get(FRONT_NETWORK_URL, networkConfig);
      const polygonNetwork = networkData.data.content.networks.filter(
        (network) => network.chainId === polygonID.toString()
      );
      const { address } = userInfo;
      let toAddresses = [];

      polygonNetwork.forEach((item) => {
        const { id, supportedTokens } = item;
        const addresses = supportedTokens.map((token) => ({
          address,
          networkId: id,
          symbol: token,
        }));
        toAddresses = [...toAddresses, ...addresses];
      });
      const dataInfo = {
        toAddresses,
      };
      const response = await axios.post(FRONT_CATALOG_URL, dataInfo, config);
      const { data } = response;
      if (response.status !== 200 || !data?.content) {
        const error = (data && data.message) || response.statusText;
        console.error('Error!', error);
      } else if (!data.content.iFrameUrl) {
        // eslint-disable-next-line no-unused-expressions
        console.error('Iframe url is empty');
      } else {
        // eslint-disable-next-line no-unused-expressions
        setIframeLink(data.content.iFrameUrl);
      }
    } catch (err) {
      console.error('make transfer error:', err);
    } finally {
      setPending(false);
    }
  }, [userInfo]);
  return (
    <>
      <>
        <h3 className="mt-0">Transfer from an exchange</h3>
        <p className="mb-10">
          Easily transfer your crypto from exchanges like Coinbase, Binance, Kraken and Robinhood into your own wallet.
          Our{' '}
          <a href={FRONT_URL} target="_blank" className="button-link" rel="noreferrer">
            Front
          </a>{' '}
          integration, makes it simple to get started with HaloFi.
        </p>

        {userInfo.networkId === polygonID ? (
          <LoadingButton
            onClick={makeTransfer}
            loading={pending}
            text="Start Transfer"
            disabled={pending}
            className="button-primary-outline mt-15"
            buttonId="transer-crypto"
          />
        ) : (
          <>
            <Button
              type="button"
              onClick={makeTransfer}
              disabled={userInfo.networkId !== polygonID}
              className="mb-15 mt-10 disabled"
            >
              Transfer Crypto
            </Button>
            <div className="mt-0 font-size-12 red-text">Connect your wallet to Polygon to unlock this feature.</div>
          </>
        )}
      </>

      <FrontComponent
        iframeLink={iframeLink}
        onSuccess={(authData) => {
          // eslint-disable-next-line no-console
          console.log('auth data', authData);
          setIframeLink(null);
        }}
        onExit={(err) => {
          // eslint-disable-next-line no-unused-expressions
          console.error(err);
        }}
      />
    </>
  );
};

export default FrontPage;
