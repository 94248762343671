import SlippagePopover from 'views/NewGame/components/Slippage/SlippagePopover';

import { getConfiguredSlippage } from 'redux/selectors/user.selector';
import { isSlippageStrategy } from 'redux/selectors/pool.selector';

import React from 'react';
import { useSelector } from 'react-redux';
import SlippageError from './SlippageError';

function SlippageSection() {
  const slippage = useSelector(getConfiguredSlippage);

  return (
    <>
      <section className="slippage-section pb-10">
        <div className="content-column-left">
          <p className="text-start mb-0">Slippage % </p>
        </div>
        <div className="content-column-right">
          <p className="mb-0 text-start">
            <span className="slippage-section-percentage">{slippage}%</span>
            <SlippagePopover />
          </p>
        </div>
      </section>
      <SlippageError />
    </>
  );
}

function SlippageSectionV3Only(props) {
  const shouldDisplaySlippage = useSelector(isSlippageStrategy);

  if (shouldDisplaySlippage) {
    return <SlippageSection {...props} />;
  }
  return null;
}

export default SlippageSectionV3Only;
