/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGame } from 'redux/reducers/game';

import { sdkJsClient } from 'service/sdk';
import { networkData } from 'utils/networks';
import { MECHANISM_TYPE, UNIX_MINUTES_EPOCH_CONVERSION } from 'utils/constants';

function getGameStartAndEndDate({ gameStartsAt, gameClosesAt, gameEndsAt }) {
  const startsAt = gameStartsAt ? new Date(parseInt(gameStartsAt) * UNIX_MINUTES_EPOCH_CONVERSION) : undefined;
  const closesAt = gameClosesAt ? new Date(parseInt(gameClosesAt) * UNIX_MINUTES_EPOCH_CONVERSION) : undefined;
  const endsAt = gameEndsAt ? new Date(parseInt(gameEndsAt) * UNIX_MINUTES_EPOCH_CONVERSION) : undefined;

  return { startsAt, closesAt, endsAt };
}

export default function useGetGames() {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);

  const getGames = async () => {
    if (fetching) {
      return;
    }

    setFetching(true);

    try {
      const [games, parentGames, futureGames] = await Promise.all([
        sdkJsClient.getGames(),
        sdkJsClient.getParentGames(),
        sdkJsClient.getFutureGames(),
      ]);

      const gamesInfo = Object.assign(games, parentGames, futureGames);
      const gameContractAddresses = Object.keys(gamesInfo);
      const newGamesObject = {};

      for (let i = 0; i < gameContractAddresses.length; i += 1) {
        const gameContractAddress = gameContractAddresses[i];

        const {
          networkId,
          sponsors,
          id,
          isCapped,
          isParent,
          contractVersion,
          subgraphVersion,
          displayId,
          depositToken,
          liquidityTokenAddress,
          rewardToken,
          incentiveToken,
          incentiveTokenAddress,
          depositTokenAddress,
          isWhitelisted,
          calenderUrl,
          earlyWithdrawalFee,
          gameStartsAt,
          gameEndsAt,
          gameClosesAt,
          rewardTokenAddress,
          secondaryRewardToken,
          subgraphId,
          mechanismType,
          depositType,
        } = gamesInfo[gameContractAddress];
        const gameSponsors = [];
        let isCappedGame = false;

        if (sponsors && sponsors.length > 0) {
          for (let j = 0; j < sponsors.length; j += 1) {
            const sponsor = sponsors[j];
            gameSponsors.push({
              logo: sponsor.imageUrl,
              description: sponsor.description,
              url: sponsor.sponsorUrl,
              extraDescription: sponsor.description2,
              extraUrl: sponsor.sponsorUrl2,
            });
          }
        }
        if (isCapped) {
          isCappedGame = isCapped;
        }

        const { startsAt, closesAt, endsAt } = getGameStartAndEndDate({ gameStartsAt, gameClosesAt, gameEndsAt });
        newGamesObject[displayId] = {
          ...networkData[networkId],
          ...gamesInfo[gameContractAddress],
          sponsors: gameSponsors,
          contract: id,
          isCappedGame,
          isParent: isParent || false,
          abiVersion: contractVersion,
          graphVersion: subgraphVersion,
          gameNumber: displayId,
          stableToken: depositToken,
          daiAddress: depositTokenAddress,
          aDaiAddress: liquidityTokenAddress,
          rewardsToken: rewardToken,
          rewardToken2: incentiveToken,
          rewardsTokenAddress2: incentiveTokenAddress,
          hasWhitelist: isWhitelisted,
          addToCalendar: calenderUrl,
          displayOrder: displayId,
          earlyWithdrawFee: earlyWithdrawalFee,
          starts: startsAt,
          closes: closesAt,
          ends: endsAt,
          rewardsTokenAddress: rewardTokenAddress,
          secondaryRewardToken,
          subgraphName: subgraphId,
          mechanismType: mechanismType || MECHANISM_TYPE.Saving,
          depositType,
        };
      }

      dispatch(setGame(newGamesObject));
    } catch (err) {
      //
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getGames();
    // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  }, []);
}
