import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useOnClickOutside from 'Hooks/clickOutside';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  menuTitle: PropTypes.string,
  handleSelectedValue: PropTypes.func,
  shouldReset: PropTypes.bool,
};

const defaultProps = {
  className: '',
  label: '',
  options: [],
  menuTitle: '',
  handleSelectedValue: () => null,
  shouldReset: false,
};

const dropDownType = {
  Header: 'dropdown',
  Button: 'dropdown-button',
};

function Dropdown({ className, label, options, handleSelectedValue, shouldReset, menuTitle, ...props }) {
  const classes = classNames(className);
  const isHeader = className === dropDownType.Header;
  const [show, setShow] = useState(isHeader);
  const [selected, setSelected] = useState(null);
  const dropDownRef = useRef();
  useOnClickOutside(dropDownRef, () => {
    if (!isHeader) {
      setShow(false);
    }
  });

  useEffect(() => {
    if (shouldReset === true) {
      //
      setSelected(null);
    }
  }, [shouldReset]);

  const showMenuItems = () => {
    if (isHeader) {
      return;
    }
    if (!show) {
      setShow(true);
      return;
    }
    setShow(false);
  };

  const onSelectedItem = (option) => {
    const { action, value } = option;
    if (!action || !value) {
      return;
    }
    handleSelectedValue(value);
    setSelected(value);
    // instantiate action method
    action();
  };

  const getSelectedItem = (value) => {
    return value === selected && !isHeader ? 'selected' : '';
  };

  return (
    <div className={classes} {...props} onClick={showMenuItems} ref={dropDownRef}>
      <div className="dropbtn">
        {label} <span /> <div className="arrow-down" />
      </div>
      {show && (
        <div className="dropdown-content">
          {menuTitle && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className="menu-title">
              <span>{menuTitle}</span>
            </a>
          )}
          {options.map((option) => {
            const selectedItem = getSelectedItem(option.value);
            return (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a onClick={() => onSelectedItem(option)} value={selected} key={option.value} className={selectedItem}>
                {option.label}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
}

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
