/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';

class Image extends React.Component {
  image = React.createRef();

  componentDidMount() {
    const placeholderImage = document.createElement('img');
    this.handlePlaceholder(this.image.current, placeholderImage);
  }

  placeholderSrc = (w, h) => {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`;
  };

  handlePlaceholder = (img, placeholder) => {
    img.style.display = 'none';
    img.before(placeholder);
    placeholder.src = this.placeholderSrc(img.getAttribute('width') || 0, img.getAttribute('height') || 0);
    placeholder.width = img.getAttribute('width');
    placeholder.height = img.getAttribute('height');
    placeholder.style.opacity = '0';
    if (img.className) {
      placeholder.classList.add(img.className);
    }

    img.addEventListener('load', () => {
      placeholder.remove();
      img.style.display = '';
    });
  };

  render() {
    const { className, src, width, height, alt, key, ...props } = this.props;

    return (
      <img
        {...props}
        ref={this.image}
        key={key}
        className={className}
        src={src}
        width={width}
        height={height}
        alt={alt}
      />
    );
  }
}

Image.propTypes = {
  key: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
};
Image.defaultProps = {
  width: undefined,
  height: undefined,
  alt: undefined,
  className: undefined,
};

export default Image;
