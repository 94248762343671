import React from 'react';
import { Col } from 'react-bootstrap';
import Tabs from 'components/elements/Tabs';
import TabContent from 'components/elements/TabContent';
import { useSelector } from 'react-redux';
import GameStats from '../GameStats/GameStats';
import Players from '../Players/Players';
import Admin from '../Admin/Admin';
import Timeline from '../Timeline/Timeline';

export default function GameTabs() {
  const playersCount = useSelector((state) => state.game.stats.playersCount);

  return (
    <Col sm={12}>
      <Tabs>
        <TabContent header="Game Stats">
          <GameStats />
        </TabContent>
        <TabContent header="Timeline">
          <Timeline />
        </TabContent>
        <TabContent header={`Players (${~~playersCount})`}>
          <Players />
        </TabContent>
        <TabContent header="Info">
          <Admin />
        </TabContent>
      </Tabs>
    </Col>
  );
}
