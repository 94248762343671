import React, { useContext, useEffect, useState } from 'react';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { useSelector, useDispatch } from 'react-redux';
import { setShowWithdrawModal, resetErrors, setSuccessWithdraw } from 'redux/reducers/feedbacks';
import {
  getErrorTransaction,
  getErrorRefusedSignature,
  getShowWithdrawModal,
  getSuccessWithdraw,
  getLoaderWithdraw,
  getLoaderGasPrices,
  getErrorTimeout,
} from 'redux/selectors/feedbacks.selector';
import { getPoolInfo } from 'redux/selectors/pool.selector';
import { getPlayer } from 'redux/selectors/user.selector';
import { getGameInfo } from 'redux/selectors/game.selector';
import { HashContext } from 'providers/HashProvider';
import { GasContext } from 'providers/GasProvider';
import ExplorerRoute from 'utils/ExplorerRoute';
import celebrate from 'assets/animations/celebrate.gif';
import winnerImg from 'assets/images/withdraw-winner.png';
import loserImg from 'assets/images/withdraw-loser.png';
import GasPicker from 'components/sections/GasPicker';
import useWithdraw from 'views/NewGame/hooks/useWithdraw';
import Modal from 'components/elements/Modal';
import ModalHeader from 'components/elements/ModalHeader';
import ModalFooter from 'components/elements/ModalFooter';
import { WINNING_TEXT, WITHDRAW_TEXT_LOSER, WITHDRAW_TEXT_WINNER } from 'utils/twitterTexts';
import { pickRandomItem } from 'utils/utilities';
import { TransactionRefusedError, TransactionError, TimeoutError } from '../../common/Errors';
import ProfitAndLossSummary from '../../LeftColumn/components/ProfitAndLossSummary';
import SlippageSection from '../../Slippage/SlippageSection';
import LoadingButton from '../../common/LoadingButton';
import { brandDomain, twitterHandle, appSubdomain, hashtag } from '../../../../../utils/brand';

export default () => {
  const dispatch = useDispatch();

  const { withdrawPlayerInfo, withdrawGameInfo } = useContext(HashContext);
  const { reset } = useContext(GasContext);

  const [withdrawMsg, setWithdrawMsg] = useState('');

  const errorTransaction = useSelector(getErrorTransaction);
  const errorRefusedSignature = useSelector(getErrorRefusedSignature);
  const timeoutError = useSelector(getErrorTimeout);
  const showWithdrawModal = useSelector(getShowWithdrawModal);
  const successWithdrawMap = useSelector(getSuccessWithdraw);
  const playerInfo = useSelector(getPlayer);
  const loaderWithdraw = useSelector(getLoaderWithdraw);
  const loaderGasPrices = useSelector(getLoaderGasPrices);
  const poolInfo = useSelector(getPoolInfo);
  const game = useSelector(getGameInfo);
  const gameInfo = game.displayId ? game : withdrawGameInfo;
  const [withdraw] = useWithdraw(gameInfo);
  const successWithdraw = successWithdrawMap[poolInfo.id];

  const closeWithdrawModal = () => {
    dispatch(setShowWithdrawModal(false));
    dispatch(setSuccessWithdraw({ success: false, id: poolInfo.id }));
    dispatch(resetErrors());
    reset();
  };

  const winner = playerInfo.isWinner ? playerInfo.isWinner : withdrawPlayerInfo.isWinner;

  useEffect(() => {
    setWithdrawMsg(winner ? pickRandomItem(WITHDRAW_TEXT_WINNER) : pickRandomItem(WITHDRAW_TEXT_LOSER));
  }, [winner]);

  return (
    <Modal show={showWithdrawModal} handleClose={closeWithdrawModal}>
      {successWithdraw && (
        <div className="withdraw-container">
          <ModalHeader onClose={closeWithdrawModal} />
          {winner && <img src={celebrate} alt="Celebrate animation" className="w-20" />}
          {winner ? (
            <h4>Congrats, you withdrew your savings.</h4>
          ) : (
            <h4>You withdrew your savings. Try to make all the deposits next time!</h4>
          )}
          <div className="user-info w-75 mx-auto mb-4">
            <ProfitAndLossSummary />
          </div>
          <p className="font-size-16 twitter-social-description">
            You are being your best financial self!
            <br />
            Share with others.
          </p>
          <TwitterShareButton
            title={`${pickRandomItem(WINNING_TEXT)}.\nI completed another challenge at ${twitterHandle}`}
            url={`\n\nCheck it out at https://${appSubdomain}.${brandDomain}?utm_source=twitter&utm_medium=social&utm_campaign=winning&utm_content=winning\n`}
            hashtags={[hashtag, 'winningathalofi']}
            related={[twitterHandle]}
            className="mb-3"
            id="share-winner-withdrawal-from-modal"
          >
            <TwitterIcon size={48} round />
          </TwitterShareButton>
          <div>
            <ExplorerRoute />
          </div>
        </div>
      )}
      {!successWithdraw && (
        <>
          <ModalHeader header="It's withdrawal o'clock" onClose={closeWithdrawModal} />
          <div className="pl-24">
            <img src={winner ? winnerImg : loserImg} alt="withdraw img" className="withdraw-img" />
            <p className="withdraw-msg">{withdrawMsg}</p>
            <GasPicker />
            <SlippageSection />
          </div>
          {errorTransaction && <TransactionError />}
          {errorRefusedSignature && <TransactionRefusedError />}
          {timeoutError && <TimeoutError />}
          <ModalFooter>
            <LoadingButton
              onClick={withdraw}
              loading={loaderWithdraw}
              text="Get my funds"
              className="button-primary"
              disabled={loaderWithdraw || loaderGasPrices}
            />
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
