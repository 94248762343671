import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3']),
  className: PropTypes.string,
  headerClass: PropTypes.string,
  subHeaderClass: PropTypes.string,
};

const defaultProps = {
  children: null,
  tag: 'h2',
  className: '',
  headerClass: '',
  subHeaderClass: '',
};

function SectionHeader(props) {
  const { className, data, children, tag, headerClass, subHeaderClass } = props;

  const classes = classNames('section-header', className);

  const Component = tag;

  return (
    <>
      {(data.title || data.paragraph) && (
        <div {...props} className={classes}>
          <div>
            {children}
            {data.title && (
              <Component className={classNames('mt-0', headerClass, data.paragraph ? 'mb-16' : 'mb-0')}>
                {data.title}
              </Component>
            )}
            {data.paragraph && <p className={classNames('m-0', subHeaderClass)}>{data.paragraph}</p>}
          </div>
        </div>
      )}
    </>
  );
}

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
