import { twitterHandle } from './brand';

export const JOIN_TWEETS = [
  'LFG!! 🚀',
  'Time to level up financially 🤑',
  'Excited to join the saving squad! 🙌',
  'New saving challenge, who dis? 😎',
  'Saving like a boss 💪',
  "It's time to save some serious 💸",
  "Let's start stacking those coins 💰",
  'Saving challenge accepted 👊',
  'Ready, set, save! 🚀',
  'My wallet is ready 💼',
  "Let's do this saving thing! 🙏",
  'On my way to financial freedom 💸',
  'Saving just got fun 😜',
  "Who said saving can't be exciting? 🤑",
  'Count me in for the saving challenge 💪',
  "Let's make our money work for us 💰",
  'Ready for a financial glow-up 💫',
  "Let's get this bread 🍞",
  'Time to hustle and save 💪',
  "Let's build our financial fortress 💪",
  'Money-saving mode: ON 🚀',
  'Bring on the saving challenge 💰',
  'Saving is the new cool 😎',
  "I'm ready to make some saving moves 🤑",
  "Let's crush this saving challenge 💪",
  'Saving game on point 🎮',
  "It's time to save and slay 🔥",
  "Can't wait to see those savings grow 🌱",
  "Let's do some financial fitness 🏋️‍♀️",
  'Saving up for a bright future 🌟',
  'Time to get my money right 💰',
  'Saving goals: unlocked 🔓',
  "Let's get that financial glow-up 💫",
  'On a mission to save money 💪',
  'Saving is the new sexy 🤑',
  'Saving challenge, here I come! 🏃‍♀️',
  "Let's make our money moves 🤑",
  'Saving for a rainy day ☔',
  "Let's get financially fit 💪",
  'Time to get serious about saving 💰',
  "Saving challenge, let's go! 🚀",
  "Let's build our wealth 💰",
  'Saving is the new black 🖤',
  "Let's make saving a habit 🙏",
  'Time to get financially empowered 👑',
  "Let's save like there's no tomorrow 💸",
  'Money-saving gang, unite! 🤝',
  'Saving is the new trend 🤑',
  'Time to make some money moves 💰',
  'Saving is the new power move 💪',
  "Let's get financially savvy 🤓",
  'On a mission to save, save, save 💸',
  "Saving like it's hot 🔥",
  'Time to level up financially 📈',
  'Saving for a better future 💫',
  "Saving challenge, let's do this! 💪",
  "Let's make our savings dreams a reality 💭",
  'Saving is the new cool kid on the block 😎',
  'Time to get our money right 💰',
  'Saving to live my best life 💯',
  'Saving and thriving 💸',
  'Saving challenge, game on! 🎮',
  "Let's get that money, honey 🍯",
  'Saving is the new rich 💵',
  'Time to save like a boss 🤑',
];

export const SCORE_TWEETS = (score) => {
  return [
    `The crypto game? I'm winning - GG Score of ${score} 🤩`,
    `Not to sound too cocky, but I'm killin' it in crypto - GG Score ${score} 🤑`,
    `I'm on 🔥 - GG Score ${score}, no big deal 🤓`,
    `Web3 pro status - GG Score ${score} 🤩`,
    `It's all going my way - GG Score ${score} 🤟`,
    `Crypto? No sweat - GG Score ${score} 🤙`,
    `Crypto game? Aced it - GG Score ${score} 🤗`,
    `DeFi guru status 💯 - GG Score ${score} 🤓`,
    `Crypto domination - GG Score ${score} 🤩`,
    `DeFi? No problem - GG Score ${score} 🤙`,
    `I don't mean to brag, but I'm crushing it in crypto - GG Score of ${score} 💪`,
    `Boom, my GG Score is ${score} - lit AF🔥`,
  ];
};

export const DEPOSIT_INTROS = [
  'Sprinkling some cash 🌟',
  'Tossing in some dough 💰',
  'Making it rain savings ☔️',
  'Planting the seeds of wealth 🌱',
  'Saving up for a rainy day 🌧',
  'Growing my money tree 🌳',
  'Stacking up the cash 💸',
  'Building my piggy bank 🐷',
  'Contributing to my fortune 💰',
  'One more step towards financial bliss 🌈',
  'Adding some sparkle to my savings 💎',
  'Growing my treasure trove 🏆',
  'Making my wallet proud 🤑',
  'Taking my game to the next level 🎮',
  'Sowing the seeds of financial freedom 🌱',
  'Saving up for something special 🎁',
  'Adding some magic to my money 💫',
  'Making my investments sparkle ✨',
  'Building my financial castle 🏰',
  'Contributing to my future riches 💰',
  'One more drop in my money bucket 🪣',
  'Adding some flavor to my finances 🌶️',
  'Growing my stash of cash 💰',
  'Squirreling away some savings 🐿️',
  'Adding some zest to my financial life 🍋',
  'Making my money pot boil 🍲',
  'Building my financial muscle 💪',
  'Contributing to my monetary growth 💰',
  'One more rung on the financial ladder 🪜',
  'Adding some sparkle to my coin purse 💰',
  'Growing my wealth garden 🌼',
  'Making my money work for me 🏋️‍♂️',
  'Storing away some financial sunshine ☀️',
  'Making my financial dreams come true 💭',
  'Building my money fortress 🏰',
  'Contributing to my financial fun fund 💰',
  'One more step towards financial awesomeness 🤩',
  'Adding some richness to my financial soup 🥣',
  'Growing my money garden 🌱',
  'Making my financial plan pop 💥',
  'Taking my money game to the next level 🎲',
  'Adding some zest to my wealth jar 🍊',
];

export const WINNING_TEXT = [
  'Nailed it 💯',
  'Killed it 🤘',
  'Smashed it 🚀',
  'Crushed the game 🎮',
  'Rocked it 🎸',
  'Owned it 👑',
  'Dominating 🔝',
  'Conquered it 🏆',
  'Mastery achieved 🥇',
  'Ace it 🎾',
  'Completed like a boss 💼',
  'Executed flawlessly 💪',
  'Accomplished the mission 🚀',
  'Got the job done 💼',
  'Achievement unlocked 🎉',
  'Won the challenge 🏆',
  'Slayed it 🗡️',
  'Destroyed it 💥',
  'Took it to the next level 🆙',
  'Performed like a pro 🎭',
  'Totally nailed it 🎯',
  'Beat the game 🎮',
  'Conquered the challenge 👊',
  'Completed with ease 💪',
  'Kicked butt 🥋',
  'Mastered the task 🎓',
  'Smashed the competition 🏋️‍♀️',
  'Succeeded with flying colors 🌈',
  'Achieved greatness 🌟',
  'Excelled in the game 🏅',
  'Completed like a champion 🏆',
  'Nailed the objective 🎯',
  'Knocked it out of the park ⚾',
  'Outdid myself 🌟',
  'Overcame the challenge 🥊',
  'Proved myself 👌',
  'Scored big 🏀',
  'Surpassed my goal 🚀',
  'Topped the charts 📈',
  'Victory achieved 🏆',
  'Completed with style 💃',
  'Finished strong 🏁',
  'Aced the test ✍️',
  'Smashed the target 🎯',
  'Succeeded in the game 🎮',
];

export const BADGES_TEXT = [
  `Nailed it! Just got this ${twitterHandle} badge 🎉`,
  `Got it! Proud owner of this ${twitterHandle} badge 🏅`,
  `Achievement unlocked! This ${twitterHandle} badge earned 🚀`,
  `Scored big time! Got this ${twitterHandle} badge 🤩`,
  `Woohoo! Just received this ${twitterHandle} badge 🎖️`,
  `Yes! I earned this ${twitterHandle} badge 💪`,
  `Mission accomplished! This ${twitterHandle} badge acquired 🏆`,
  `Awesome! Got this ${twitterHandle} badge 🔥`,
  `Pat on the back! Earned this ${twitterHandle} badge 🤗`,
  `On fire! Just earned this ${twitterHandle} badge 🔥`,
  `Just unlocked this ${twitterHandle} badge 🎊`,
  `New badge alert! This ${twitterHandle} badge earned 🚨`,
  `Hurray! I earned this ${twitterHandle} badge 🙌`,
  `Kudos to me! Just got this ${twitterHandle} badge 👍`,
  `Sweet! Just unlocked this ${twitterHandle} badge 🍭`,
  `Shining bright with this ${twitterHandle} badge ✨`,
  `Over the moon! Just got this ${twitterHandle} badge 🌕`,
  `Got it done! This ${twitterHandle} badge acquired 🎯`,
  `Level up! Just earned this ${twitterHandle} badge 🎮`,
  `Cheers! Just received this ${twitterHandle} badge 🍻`,
  `Feeling accomplished with this ${twitterHandle} badge 🤓`,
  `Yay! Earned this ${twitterHandle} badge 🥳`,
  `Great news! Just unlocked this ${twitterHandle} badge 🎉`,
  `Pumped! Got this ${twitterHandle} badge 💪`,
  `Ecstatic! Earned this ${twitterHandle} badge 😃`,
  `Rocking it with this ${twitterHandle} badge 🤘`,
  `Happy days! Just received this ${twitterHandle} badge 😊`,
  `Hyped! Just got this ${twitterHandle} badge 🤩`,
  `High five! Got this ${twitterHandle} badge 🖐️`,
  `Spectacular! Just unlocked this ${twitterHandle} badge 🌟`,
  `Upgraded! Earned this ${twitterHandle} badge 🆙`,
  `Stoked! Just received this ${twitterHandle} badge 🤙`,
  `Superb! Got this ${twitterHandle} badge 👌`,
  `Chuffed! Just unlocked this ${twitterHandle} badge 😊`,
  `Thrilled! Earned this ${twitterHandle} badge 😁`,
  `Excellent! Just got this ${twitterHandle} badge 👍`,
  `Elated! Received this ${twitterHandle} badge 🤗`,
  `Celebrating! Earned this ${twitterHandle} badge 🎉`,
  `Smashing it with this ${twitterHandle} badge 💥`,
  `Jubilant! Just unlocked this ${twitterHandle} badge 🎊`,
  `Accomplished! Got this ${twitterHandle} badge 🌟`,
  `Feeling good with this ${twitterHandle} badge 👌`,
  `Brilliant! Just earned this ${twitterHandle} badge 🌟`,
  `Super! Just got this ${twitterHandle} badge 🌟`,
  `Proud moment! Got this ${twitterHandle} badge 😎`,
];

export const WITHDRAW_TEXT_WINNER = [
  "You're a crypto champion! Confirm withdrawal now",
  'Congratulations on the win! Confirm to withdraw your cryptos',
  'You crushed it! Time to withdraw those earnings',
  'Great job on your crypto hustle! Confirm withdrawal and reap the rewards',
  "You're killing it in the crypto game! Confirm to withdraw and celebrate your success",
];

export const WITHDRAW_TEXT_LOSER = [
  'So close yet so far! Withdraw now and get ready for the next challenge',
  'You gave it your all! Confirm withdrawal and come back stronger next time',
  "It wasn't your time this time, but it will be! Withdraw now and keep pushing",
  "Don't give up now! Confirm to withdraw and keep grinding towards that challenge",
  "You're a crypto contender! Confirm withdrawal and get your cryptos back for the next round",
];
