import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPaymentAmount, getTotalSegments, getDepositToken } from 'redux/selectors/game.selector';
import { getNetworkId } from 'redux/selectors/pool.selector';
import { getConnectedNetworkId, getUserAddress } from 'redux/selectors/user.selector';
import { appSubdomain, brandCaps, brandDomain } from 'utils/brand';
import { bigNumberFrom } from 'utils/bignumber';
import { ethToWeiEthersBN } from 'providers/ethersBN';

import { celoID, polygonID } from 'utils/networks';

// TODO: BASE - add assets for on/off ramp on base
export const assetMapping = {
  eth: 'MATIC_ETH',
  weth: 'MATIC_ETH',
  wmatic: 'MATIC_MATIC',
  dai: 'MATIC_DAI',
  usdc: 'MATIC_USDC',
  cusd: 'CELO_CUSD',
  ceur: 'CELO_CEUR',
  creal: 'CELO_CREAL',
  celo: 'CELO_CELO',
};

const fallbackAsset = {
  [polygonID]: 'MATIC_DAI',
  [celoID]: 'CELO_CUSD',
};

export const sharedRampOpts = {
  hostAppName: brandCaps,
  hostLogoUrl: `${window.location.origin}/halofi-logo.svg`,
  variant: 'embedded-mobile',
  hostApiKey: process.env.REACT_APP_RAMP_API_KEY,
  environmentUrl: process.env.REACT_APP_RAMP_ENVIRONMENT_URL,
  enabledFlows: ['ONRAMP', 'OFFRAMP'],
  finalUrl: `https://${appSubdomain}.${brandDomain}`,
  swapAsset: 'MATIC_DAI,MATIC_USDC,MATIC_MATIC,MATIC_ETH,CELO_*',
};

export const defaultAsset = 'MATIC_DAI';

export default function useRampConf(token = '') {
  const usersAddress = useSelector(getUserAddress);
  const userConnectedNetworkId = useSelector(getConnectedNetworkId);
  const gameConnectedNetworkId = useSelector(getNetworkId);
  const depositToken = useSelector(getDepositToken);
  const paymentAmount = useSelector(getPaymentAmount);
  const totalSegments = useSelector(getTotalSegments);

  const asset = useMemo(() => {
    const mappedToken = assetMapping?.[token || depositToken];
    if (mappedToken) {
      return mappedToken;
    }

    if (gameConnectedNetworkId) {
      return fallbackAsset?.[parseInt(gameConnectedNetworkId)] || '';
    }
    if (userConnectedNetworkId) {
      return fallbackAsset?.[userConnectedNetworkId] || '';
    }

    return defaultAsset;
  }, [userConnectedNetworkId, gameConnectedNetworkId, depositToken]);

  const alertMessage = asset
    ? ''
    : 'The network you are connected is different from the asset provided. Please note that you are purchasing DAI on Polygon.';

  const swapAmount = useMemo(() => {
    if (!paymentAmount || !totalSegments) {
      return null;
    }
    const amount = bigNumberFrom(paymentAmount)
      .times(parseInt(totalSegments) - 1)
      .toFixed();

    // Subtract 1 segment from totalSegment, the last segment it does not require a deposit (it is just a waiting round)
    return ethToWeiEthersBN(amount);
  }, [paymentAmount, totalSegments]);

  return { usersAddress, swapAmount, asset, userConnectedNetworkId, alertMessage };
}
