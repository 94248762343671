import React, { Component } from 'react';
import classNames from 'classnames';

function Tab({ activeTab, value, label, onClick, tabListItemClassName }) {
  const handleOnClick = () => {
    onClick(label);
  };

  let className = 'tab-list-item';

  if (activeTab === label) {
    className += ' tab-list-active';
  }

  const classes = classNames(className, tabListItemClassName);
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li className={classes} onClick={handleOnClick} value={value}>
      {label}
    </li>
  );
}

export default class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
      activeTabValue: this.props.children[0].props.value,
    };
  }

  componentDidMount() {
    const { activeTab, activeTabValue } = this.state;
    this.props.children[0].props.onSelectedTab({ label: activeTab, value: activeTabValue });
  }

  onClickTabItem = (tab, value, action, onSelectedTab) => {
    this.setState({ activeTab: tab, activeTabValue: value });
    onSelectedTab({ label: tab, value });
    action();
  };

  render() {
    const { children } = this.props;
    const { activeTab, activeTabValue } = this.state;
    const { tabsClassName, tabListClassName, tabContent, tabListItemClassName } = this.props;
    const tabsClasses = classNames('tabs', tabsClassName);
    const tabListClasess = classNames('tab-list', tabListClassName);
    const tabContentClassName = classNames('tab-content', tabContent);

    return (
      <div className={tabsClasses}>
        <ol className={tabListClasess}>
          {children.map((child) => {
            const { label, onClick, onSelectedTab, value } = child.props;
            return (
              <Tab
                activeTab={activeTab}
                value={activeTabValue}
                key={label}
                label={label}
                onClick={(tab) => this.onClickTabItem(tab, value, onClick, onSelectedTab)}
                tabListItemClassName={tabListItemClassName}
              />
            );
          })}
        </ol>
        <div className={tabContentClassName}>
          {children.map((child) => {
            if (child.props.label !== activeTab) {
              return undefined;
            }
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}
