import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GasPopover } from 'components/elements/Popover';
import Loading from 'components/elements/Loading';
import { GasContext } from 'providers/GasProvider';
import { HashContext } from 'providers/HashProvider';

import { setLoaderGasPrices } from 'redux/reducers/feedbacks';
import { getCustomInput, getLoaderGasPrices } from 'redux/selectors/feedbacks.selector';

import GasComponent from './GasComponent';

export default function GasPicker() {
  const {
    gasPrices,
    userChoice,
    setUserChoice,
    defaultChoice,
    isTransacting,
    priceRange: { floor, ceiling },
    btnName,
    show,
    toggle,

    setPrevBtn,
    handlePopover,
    modifier,
  } = useContext(GasContext);

  const { darkTheme } = useContext(HashContext);

  const customInput = useSelector(getCustomInput);
  const loaderGasPrices = useSelector(getLoaderGasPrices);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!show && !toggle) {
      setPrevBtn(btnName);
    }

    if (!userChoice && !!defaultChoice) {
      setUserChoice(defaultChoice);
    }

    if (!!userChoice && loaderGasPrices) {
      dispatch(setLoaderGasPrices(false));
    }

    if (!!userChoice && userChoice !== gasPrices[btnName] && !toggle && !customInput && !isTransacting) {
      setUserChoice(gasPrices[btnName]);
    }
  }, [userChoice, gasPrices[btnName], show, toggle]);

  const WarningMsg = () => {
    return (
      <section className="d-flex">
        {modifier(userChoice) < floor && !loaderGasPrices && (
          <p className="slippage-error text-center w-75">⚠️ Transaction could fail because the gas is too low. ⚠️</p>
        )}
        {modifier(userChoice) > ceiling && !loaderGasPrices && (
          <p className="slippage-error">
            ⚠️ A higher value for transaction speed results in higher transaction cost (gas price). Are you sure? ⚠️
          </p>
        )}
      </section>
    );
  };

  return (
    <>
      <section className="gas-container">
        <div className="content-column-left">
          <p className="mb-0">Transaction Speed</p>
        </div>
        <div className="content-column-right">
          {loaderGasPrices ? (
            <Loading classes="m-0" width="20px" height="20px" />
          ) : (
            <p className="m-0 fw-bold">
              {userChoice && modifier(userChoice)} Gwei{' '}
              <GasPopover
                bsPrefix={`popover gas-popover ${darkTheme ? 'theme--dark' : 'theme--default'}`}
                placement="bottom"
                content={<GasComponent />}
                handleToggle={handlePopover}
                show={show}
              >
                <button type="button" className="slippage-config-button">
                  ⚙️
                </button>
              </GasPopover>
            </p>
          )}
        </div>
      </section>
      <WarningMsg />
    </>
  );
}
