import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import twitterImg from 'assets/images/twitter.svg';
import discordImg from 'assets/images/discord.svg';
import Image from 'components/elements/Image';
import Dropdown from 'components/elements/Dropdown';
import { docsURL, twitterURL, snapshotURL, blogURL, saveAppURL /* brandAnnouncement */ } from 'utils/brand';
import Alert from 'components/elements/Alert';
import Logo from './partials/Logo';
import { WalletButton } from './partials/WalletButton';

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
  closeHighlightHeader: PropTypes.func,
  className: PropTypes.string,
  history: PropTypes.func,
  setShowTopBanner: PropTypes.func,
  showBanner: PropTypes.bool,
  showTopBanner: PropTypes.bool,
  showEmergencyHeader: PropTypes.bool,
  showPoolSpecificHeader: PropTypes.bool,
};

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
  closeHighlightHeader: undefined,
  className: '',
  history: () => null,
  setShowTopBanner: () => null,
  showBanner: false,
  showTopBanner: false,
  showEmergencyHeader: false,
  showPoolSpecificHeader: false,
};

class Header extends React.Component {
  nav = React.createRef();

  hamburger = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  componentDidMount() {
    if (this.props.active) {
      this.openMenu();
    }
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    this.nav.current.style.maxHeight = `${this.nav.current.scrollHeight}px`;
    this.setState({ isActive: true });
  };

  closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    if (this.nav.current) {
      this.nav.current.style.maxHeight = null;
    }
    this.setState({ isActive: false });
  };

  keyPress = (e) => {
    if (this.state.isActive && e.keyCode === 27) {
      this.closeMenu();
    }
  };

  clickOutside = (e) => {
    if (!this.nav.current) {
      return;
    }
    if (!this.state.isActive || this.nav.current.contains(e.target) || e.target === this.hamburger.current) {
      return;
    }
    this.closeMenu();
  };

  render() {
    // add showBanner in the props if needed.
    const {
      className,
      navPosition,
      hideNav,
      bottomOuterDivider,
      bottomDivider,
      showEmergencyHeader,
      showPoolSpecificHeader,
      // eslint-disable-next-line no-unused-vars
      showTopBanner,
      ...props
    } = this.props;

    const classes = classNames('site-header', bottomOuterDivider && 'has-bottom-divider', className);

    return (
      <div>
        <header {...props} className={classes}>
          <Alert
            show={showTopBanner}
            type="rebrand-banner"
            close={() => {
              this.props.setShowTopBanner(false);
            }}
            text={
              <>
                <p className="mb-0">
                  Connections using Ledger are currently disabled due to a{' '}
                  <a target="_blank" href="https://twitter.com/Ledger/status/1735291427100455293" rel="noreferrer">
                    security incident involving Ledger.
                  </a>{' '}
                </p>
              </>
            }
          />
          {/* Re-enable if you want to show an informative banner on top */}
          {/*
          {!showEmergencyHeader && (
            <Alert
              show={showTopBanner}
              type="banner-text"
              close={this.props.closeHighlightHeader}
              text={
                <>
                  <p className="mb-0 light-text-banner">
                    HaloFi is live on Base. Join our <Link to="/challenges/296">Ether</Link> Hold challenge today!🥳
                  </p>
                </>
              }
            />
          )} */}
          {showEmergencyHeader && (
            <Alert
              show
              type="emergency-banner-text"
              close={() => {}}
              text={
                <>
                  <p className="mb-0 light-text-banner">
                    Deposits | Withdrawals for USDC, DAI challenges on Polygon are unavailable. WETH not impacted.{' '}
                    <a
                      target="_blank"
                      href="https://discord.com/channels/703609699429515295/851816944712548423"
                      rel="noreferrer"
                    >
                      See Discord Announcement for details.
                    </a>{' '}
                  </p>
                </>
              }
            />
          )}
          {showPoolSpecificHeader && (
            <Alert
              show
              type="banner-text"
              close={() => {}}
              text={
                <>
                  <p className="mb-0 light-text-banner">
                    Please update your GoodDollar wallet to the latest version to ensure a smooth connection.
                  </p>
                </>
              }
            />
          )}
          <div className={classNames('site-header-inner', bottomDivider && 'has-bottom-divider')}>
            <Logo />
            {!hideNav && (
              <>
                <button
                  ref={this.hamburger}
                  className="header-nav-toggle"
                  id="header-nav-toggle"
                  onClick={this.state.isActive ? this.closeMenu : this.openMenu}
                  type="button"
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner" />
                  </span>
                </button>
                <nav ref={this.nav} className={classNames('header-nav', this.state.isActive && 'is-active')}>
                  <div className="header-nav-inner">
                    <ul className={classNames('list-reset text-xxs', navPosition && `header-nav-${navPosition}`)}>
                      <li>
                        <Link to="/challenges" onClick={this.closeMenu}>
                          Challenges
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={this.closeMenu}>
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link to="/leaderboard" onClick={this.closeMenu}>
                          Leaderboard
                        </Link>
                      </li>
                      <li>
                        <Link to="/buy" onClick={this.closeMenu}>
                          Get Crypto
                        </Link>
                      </li>
                      <li>
                        <a href={saveAppURL} target="_blank" onClick={this.closeMenu} rel="noreferrer">
                          Save
                        </a>
                      </li>
                      <li>
                        <Dropdown
                          className="dropdown"
                          label="More"
                          options={[
                            {
                              label: 'Faq',
                              value: 'Faq',
                              action: () => window.open(`${docsURL}/halofi-challenges/faq`),
                            },
                            {
                              label: 'Docs',
                              value: 'Docs',
                              action: () => window.open(docsURL),
                            },
                            {
                              label: 'Vote',
                              value: 'Vote',
                              action: () => window.open(snapshotURL),
                            },
                            {
                              label: 'Blog',
                              value: 'Blog',
                              action: () => window.open(blogURL),
                            },
                          ]}
                        />
                      </li>
                      <li>
                        <a href="https://discord.gg/Y6bPpwbpK3" target="_blank" rel="noopener noreferrer">
                          <Image className="image" src={discordImg} alt="Discord" width={18} height={18} />
                        </a>
                      </li>
                      <li>
                        <a href={twitterURL} target="_blank" onClick={this.closeMenu} rel="noreferrer">
                          <Image className="image" src={twitterImg} alt="Twitter" width={18} height={18} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </>
            )}
            <div className="login-container">
              <WalletButton />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
