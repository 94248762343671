import { useDispatch, useSelector } from 'react-redux';
import {
  setLoaderMakeDeposit,
  setSuccessMakeDeposit,
  setErrorRefusedSignature,
  setErrorTransactionMessage,
  setErrorTransaction,
} from 'redux/reducers/feedbacks';
import { updatePlayerInfo } from 'redux/reducers/user';
import { getUserAddress } from 'redux/selectors/user.selector';
import useWriteContract from 'providers/ContractProvider/useWriteContract';

import { useSendEvent } from './useSendEvent';

export default function useMakeDeposit() {
  const dispatch = useDispatch();
  const gameInfo = useSelector((state) => state.game.info);
  const playerInfo = useSelector((state) => state.user.player);
  const usersAddress = useSelector(getUserAddress);

  const WriteContract = useWriteContract();

  const logEvent = useSendEvent();

  const makeDeposit = async () => {
    dispatch(setLoaderMakeDeposit(true));

    try {
      await WriteContract.makeDeposit();
      const newPlayerInfo = {
        ...playerInfo,
        mostRecentSegmentPaid: parseInt(playerInfo.mostRecentSegmentPaid) + 1,
        paidAmount: parseFloat(playerInfo.paidAmount) + parseFloat(gameInfo.paymentAmount),
      };

      dispatch(updatePlayerInfo(newPlayerInfo));
      dispatch(setSuccessMakeDeposit({ success: true, id: gameInfo.id }));
    } catch (err) {
      console.error(err);
      if (err?.message?.includes('User denied')) {
        dispatch(setErrorRefusedSignature(true));
      } else {
        dispatch(setErrorTransaction(true));
        if (err?.message.includes('max fee per gas less than block base fee')) {
          dispatch(
            setErrorTransactionMessage(
              'Minimum Network fee is higher than the Transaction Speed set. Increase the Transaction Speed and try again'
            )
          );
        } else {
          dispatch(setErrorTransactionMessage(err?.message));
        }
        logEvent('DEPOSIT_TRANSACTION_ERROR', playerInfo.networkId, {
          errMsg: err?.message,
          usersAddress,
          navigator: window.navigator.userAgent,
        });
      }
    } finally {
      dispatch(setLoaderMakeDeposit(false));
    }
  };

  return [makeDeposit];
}
