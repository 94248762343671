import React from 'react';
import { Tab } from 'react-tabs';

export const ModalTab = ({ ...props }) => <Tab className="modal-tab" {...props} />;

ModalTab.tabsRole = 'Tab';

export const TabHeading = ({ num, title }) => (
  <>
    <div className="d-flex justify-content-center align-items-center mb-15">
      <div className="tab-avatar mr-6">{num}</div>
      <p className="mb-0">{title}</p>
    </div>
    <div className="tab-line" />
  </>
);
