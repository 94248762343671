import { useEffect, useContext } from 'react';
import { ContractContext } from 'providers/ContractProvider/ContractProvider';
import { useSelector, useDispatch } from 'react-redux';
import { setHasBalance, setUserBalance } from 'redux/reducers/user';
import { getUserAddress, isUserOnCorrectNetwork, getUserBalance } from 'redux/selectors/user.selector';
import { getGameNumber } from 'redux/selectors/pool.selector';
import { getPaymentAmount, getDepositTokenAddress } from 'redux/selectors/game.selector';

function useCheckUserBalance() {
  const dispatch = useDispatch();
  const ContractClient = useContext(ContractContext);
  const usersAddress = useSelector(getUserAddress);
  const depositTokenAddress = useSelector(getDepositTokenAddress);
  const poolNumber = useSelector(getGameNumber);
  const paymentAmount = useSelector(getPaymentAmount);
  const isOnCorrectNetwork = useSelector(isUserOnCorrectNetwork);
  const userBalance = useSelector(getUserBalance);

  useEffect(() => {
    (async () => {
      if (usersAddress.length && depositTokenAddress && isOnCorrectNetwork) {
        const fetchedUserBalance = await ContractClient.getDepositTokenBalance(usersAddress);
        dispatch(setUserBalance(fetchedUserBalance));
      }
    })();
  }, [usersAddress, depositTokenAddress, poolNumber, isOnCorrectNetwork]);

  useEffect(() => {
    if (usersAddress.length && parseFloat(paymentAmount)) {
      const hasBalance = Number(userBalance) >= Number(paymentAmount);

      dispatch(setHasBalance(hasBalance));
    }
  }, [usersAddress, userBalance, paymentAmount]);
}

export default useCheckUserBalance;
