import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Container, Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Button from 'components/elements/Button';
import { getPoolInfo } from 'redux/selectors/pool.selector';
import { getErrorTransactionMessage } from 'redux/selectors/feedbacks.selector';
import ExplorerRoute from 'utils/ExplorerRoute';

export const WrongNetwork = () => {
  const info = useSelector(getPoolInfo);
  const network = info.display;
  return (
    <Container className="text-start p-0">
      <h3>This game runs on {network}</h3>
    </Container>
  );
};

const UsingErrorStyle = ({ children }) => {
  return <div className="sans_serif transaction-error">{children}</div>;
};

export const TransactionError = () => {
  const [open, setOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const errorTransactionMessage = useSelector(getErrorTransactionMessage);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <>
      <UsingErrorStyle>
        <p className="mb-0">
          Woah, a funky little bug! 🐞 Check our{' '}
          <a
            href="https://docs.halofi.me/hub/halofi-challenges/troubleshooting"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            troubleshooting tips
          </a>{' '}
          to solve the most common issues, or click on "View/Copy details" to take a peek for the deets.
        </p>
      </UsingErrorStyle>
      <Collapse in={open}>
        <div className="has-top-divider pt-10 mt-10">
          <p className="text-start mb-0 font-size-15">
            If you're still scratching your head, copy those error details and drop them in our Discord{' '}
            <a
              href="https://discord.com/channels/703609699429515295/851816944712548423"
              target="_blank"
              rel="noreferrer"
            >
              "#tech-support" channel
            </a>{' '}
            . Our crew will be stoked to help you out! 🛠️✌️
          </p>
          {open && (
            <>
              <CopyToClipboard text={errorTransactionMessage} onCopy={handleCopy}>
                <Button className="button-light mb-12">
                  Copy to clipboard <span className="bi bi-files ml-custom-4" />
                </Button>
              </CopyToClipboard>
            </>
          )}
          {isCopied && <p className="mb-0 text-success font-size-14">Copied to Clipboard!</p>}
          <UsingErrorStyle>
            <p className="mb-0">{errorTransactionMessage}</p>
          </UsingErrorStyle>
        </div>
      </Collapse>
      <Button className="button-error p-0" onClick={() => setOpen(!open)}>
        {open ? 'Show less' : 'View/Copy details'}
      </Button>
    </>
  );
};

export const TimeoutError = () => (
  <UsingErrorStyle>
  <p className="timeout-error">
  Your transaction is processing slowly. Refer to our 
  {' '}<a
  href="https://docs.halofi.me/hub/halofi-challenges/troubleshooting"
  target="_blank"
  rel="noopener noreferrer nofollow"
>
  troubleshooting guide
</a>{' '} or monitor the <ExplorerRoute label="transaction" />.
  </p>
  </UsingErrorStyle>
)

export const CustomTransactionError = ({ errorMessage }) => (
  <UsingErrorStyle>
    <p className="mb-0">
      Something went wrong{' '}
      <span role="img" aria-label="sad">
        😢
      </span>
    </p>
    <p className="mb-0">{errorMessage}</p>
  </UsingErrorStyle>
);

export const TransactionRefusedError = () => (
  <CustomTransactionError error="MetaMask Tx Signature: User denied transaction signature" />
);
