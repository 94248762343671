import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowDepsitModal, setLoaderGasPrices } from 'redux/reducers/feedbacks';
import { setPaymentAmount } from 'redux/reducers/game';
import { getPlayer } from 'redux/selectors/user.selector';
import { getGameInfo } from 'redux/selectors/game.selector';
import { getPoolInfo } from 'redux/selectors/pool.selector';

import { DEPOSIT_TYPE } from 'utils/constants';
import Button from 'components/elements/Button';

const MakeDepositButton = () => {
  const dispatch = useDispatch();
  const isWaitingSegment = useSelector((state) => state.game.stats.isWaitingSegment);
  const isCompleted = useSelector((state) => state.game.stats.isCompleted);
  const playerInfo = useSelector(getPlayer);
  const gameInfo = useSelector(getGameInfo);
  const poolInfo = useSelector(getPoolInfo);
  const mostRecentSegmentPaid = useSelector((state) => state.user.player.mostRecentSegmentPaid);

  const { currentSegment } = gameInfo;
  const { depositType } = poolInfo;
  const { isAlive, withdrawn, isWinner, depositAmount } = playerInfo;
  const hasWithdrawn = withdrawn && !isWinner;
  const hasPaid = parseInt(mostRecentSegmentPaid) === parseInt(currentSegment);

  if (!isAlive || hasPaid || isWaitingSegment || hasWithdrawn || isCompleted) {
    return null;
  }

  const onDepositModal = () => {
    if (depositType === DEPOSIT_TYPE.Flexible) {
      dispatch(setPaymentAmount(depositAmount));
      dispatch(setShowDepsitModal(true));
      return;
    }

    dispatch(setShowDepsitModal(true));
  };

  return (
    <>
      <div className="pt-16 pb-16">
        <Button
          id="follow-up-deposit"
          onClick={() => {
            onDepositModal();
            dispatch(setLoaderGasPrices(true));
          }}
          color="primary"
          className="button button-primary"
        >
          Make your deposit
        </Button>
      </div>
    </>
  );
};

export default MakeDepositButton;
