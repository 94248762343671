import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { setPaymentAmount } from 'redux/reducers/game';
import { getPoolInfo, hasRiskProfile } from 'redux/selectors/pool.selector';
import LinkNewTab from 'components/elements/LinkNewTab';
import { getPrecision, validateAmount } from 'utils/utilities';
import RiskProfileIcon from 'components/elements/RiskProfileIcon';
import { RiskProfilesLinks, RiskProfilesLabels } from 'utils/RiskProfiles';
import { docsURL } from 'utils/brand';
import { bigNumberFrom } from 'utils/bignumber';
import InputGroup from 'components/elements/InputGroup';
import Button from 'components/elements/Button';
import { getUserBalance } from 'redux/selectors/user.selector';
import { getGameInfo } from 'redux/selectors/game.selector';
import { getMaxPrecision, getMinAmountForCoin, truncateAndFormatCoin } from 'utils/numberFormat';
import useViewableStats from '../../GameStats/hooks/useViewableStats';
import Checkbox from '../../common/Checkbox';
import BuyCryptoMsg from './BuyCryptoMsg';

export default function FlexibleDepositTab({ updateUserAgreement, userAgreement, updateReview }) {
  const dispatch = useDispatch();
  const userBalance = useSelector(getUserBalance);
  const info = useSelector(getPoolInfo);
  const gameInfo = useSelector(getGameInfo);
  const history = useHistory();
  const [depositAmount, setDepositAmount] = useState(gameInfo.paymentAmount);
  const [validationError, setValidationError] = useState(null);
  const { stableToken, maxDepositAmount } = info;
  const depositToken = stableToken && stableToken.toUpperCase();
  const { currentSegment } = gameInfo;

  const shouldDisplayRisk = useSelector(hasRiskProfile);

  // eslint-disable-next-line no-unused-vars
  const [deposit, amount, , strategy] = useViewableStats();

  const depositGreaterThanBalance = parseFloat(depositAmount) > parseFloat(userBalance);

  const nextBtnClass = classNames(
    'w-25 mt-20',
    depositGreaterThanBalance && userAgreement ? 'button-primary-outline' : 'button-primary'
  );
  const buyBtnClass = classNames('mb-3 mr-10', depositGreaterThanBalance ? 'button-primary' : 'button-light');

  const onChange = (e) => {
    const { value } = e.target;
    const maxDecimals = getMaxPrecision(depositToken);
    const maxDecimalPlaces = value.toString().includes('.')
      ? value.toString().split('.')[1].length <= maxDecimals
      : true;

    if (maxDecimalPlaces) {
      setDepositAmount(value);
    }

    if (validationError) {
      setValidationError(null);
    }
  };

  const onNext = async () => {
    if (info.depositType === 'fixed') {
      updateReview(false);
      return;
    }

    const isValidDepositAmount = validateAmount(depositAmount);

    if (!depositAmount) {
      setValidationError('Please enter a valid deposit amount');
      return;
    }

    if (isValidDepositAmount === false) {
      setValidationError('Please enter a valid deposit amount');
      return;
    }

    const depositAmountBN = bigNumberFrom(depositAmount);

    if (depositAmountBN.isGreaterThan(bigNumberFrom(maxDepositAmount))) {
      setValidationError(`You cannot deposit more than ${maxDepositAmount} ${depositToken}`);
      return;
    }

    const maxDecimals = getMaxPrecision(depositToken);
    const inputDecimals = getPrecision(depositAmount);
    const minDeposit = getMinAmountForCoin(depositToken);
    if (depositAmountBN.lt(minDeposit) || inputDecimals > maxDecimals) {
      setValidationError(`You cannot deposit less than ${minDeposit.toString()} ${depositToken}`);
      return;
    }

    const decimalsCanRrepsentNumber = depositAmountBN
      .multipliedBy(bigNumberFrom(10).exponentiatedBy(maxDecimals))
      .isGreaterThanOrEqualTo(1);

    if (!decimalsCanRrepsentNumber) {
      setValidationError(`Your deposit is too small`);
      return;
    }

    dispatch(setPaymentAmount(depositAmount));
    updateReview(false);
    setValidationError(null);
  };

  return (
    <>
      <p className="text-start mb-6 font-size-14">
        Your balance:{' '}
        <span className="fw-600">
          {truncateAndFormatCoin(userBalance, depositToken)} {depositToken}
        </span>
      </p>
      <div className="flexible-deposit-input">
        <InputGroup
          label={depositToken}
          type="number"
          placeholder="Enter your desired deposit amount"
          onChange={onChange}
          value={depositAmount}
          disabled={info.depositType === 'fixed' || currentSegment > 0}
          isInvalid={!!validationError}
          invalidMsg={validationError}
          className="mb-0"
        />
        <p className="text-start mb-custom-5 font-size-14"> </p>
        <p className="text-start font-size-14">
          Ensure you have enough{' '}
          <LinkNewTab link={`${docsURL}/hub/getting-started/getting-cryptocurrency-tokens`}>{String(info.baseToken).toUpperCase()}</LinkNewTab>{' '}
          for transaction fees.
        </p>
        {depositGreaterThanBalance && <BuyCryptoMsg depositAmount={depositAmount} />}
        <section className="rules mb-10">
          <p className="font-size-16 mb-10">Summary</p>
          <div className="d-flex justify-content-between">
            <div className="align-self-center">
              <span>{deposit.confirmLabel}:</span>{' '}
              <span className="code">{deposit.data === '1' ? 'Only one' : `Every ${deposit.frequency}`}</span>
            </div>
            <div>
              <span>
                <RiskProfileIcon
                  className="risk-profile-icon pr-6"
                  riskProfile={info.riskProfile}
                  alt="risk-profile-icon"
                />
                <a
                  href={RiskProfilesLinks[info.riskProfile]}
                  rel="noreferrer"
                  target="_blank"
                  className="text-decoration-none fw-600"
                >
                  {RiskProfilesLabels[info.riskProfile]}
                </a>
              </span>
            </div>
          </div>
          <div className="mt-10 mb-10">
            <span>Total amount:</span>{' '}
            <span className="code">
              {truncateAndFormatCoin(
                bigNumberFrom(~~info.paymentCount).times(bigNumberFrom(depositAmount)),
                info.stableToken
              ) || 0}{' '}
              {String(info.stableToken).toUpperCase()}{' '}
            </span>
          </div>
          <div>
            <span>{amount.confirmLabel}:</span>{' '}
            <span className="code">{`${depositAmount || 0} ${String(info.stableToken).toUpperCase()}`}</span>
          </div>
        </section>
        <div className="text-start">
          <Checkbox
            name="join-user-agreement"
            checked={userAgreement || parseFloat(currentSegment) > 0}
            disabled={currentSegment > 0}
            onChange={() => updateUserAgreement(!userAgreement)}
          >
            <p className="term mb-2">
              I agree to the{' '}
              <Link to="/terms" target="_blank">
                Terms of Service
              </Link>{' '}
              {shouldDisplayRisk && (
                <>
                  and{' '}
                  <a href={`${docsURL}/audits#risk-disclaimers`} target="_blank" rel="noreferrer">
                    Risk Disclaimers
                  </a>
                </>
              )}
            </p>
          </Checkbox>
        </div>
        <div>
          <Button
            onClick={() => history.push(`/buy${depositToken ? `?token=${depositToken}` : ''}`)}
            className={buyBtnClass}
            id="buy-crypto-button-review-tab"
          >
            Get {String(depositToken)}
          </Button>
          <Button
            disabled={!userAgreement && parseFloat(currentSegment) === 0}
            onClick={onNext}
            className={nextBtnClass}
            id="flexible-deposit-next"
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
}
