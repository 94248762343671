import React from 'react';
import VideoExplainerModal from '../Modals/VideoExplainerModal/VideoExplainerModal';
import VideoButton from './VideoButton';

const VideoExplainer = () => (
  <>
    <VideoButton />
    <VideoExplainerModal />
  </>
);

export default VideoExplainer;
