import { useContext, useCallback } from 'react';
import { Web3Context } from 'providers/Web3Provider';
import { bigNumberFrom } from 'utils/bignumber';
import { SLIPPAGE_STRATEGIES } from 'utils/constants';
import GoodGhostingStrategyABI from 'ABIs/GoodGhosting-Strategy';
import { ethToWeiEthersBN } from 'providers/ethersBN';
import { useStrategySlippage } from './useStrategySlippage';

function useGetSlippage({ info }) {
  const Web3Client = useContext(Web3Context);

  const strategyProvider = info?.strategyProvider;
  const goodGhostingStrategyAddress = info?.strategyController;

  const provider = useStrategySlippage();

  const calculateSlippage = useCallback(
    async (paymentAmount, slippagePercentage, strategyContract) => {
      const poolFeePercentage = await provider.getPoolFee({ strategyContract });

      const effectiveMaxSlippage = (slippagePercentage + poolFeePercentage) / 100;
      const minValue = bigNumberFrom(paymentAmount.toString()).multipliedBy(1 - effectiveMaxSlippage);
      return minValue.toFixed(0, 3);
    },
    [provider]
  );

  const getDepositSlippage = useCallback(
    async (slippageInfo) => {
      const { slippagePercentage, strategyContract } = slippageInfo;

      const paymentInLiquidityToken = await provider.convertToLiquidityToken(slippageInfo);

      return calculateSlippage(paymentInLiquidityToken, slippagePercentage, strategyContract);
    },
    [provider, calculateSlippage]
  );

  const getWithdrawSlippage = useCallback(
    async ({ paymentAmount, slippagePercentage, strategyContract, decimals }) => {
      const strategyRemainingBalance = await provider.getRemainingBalance({ strategyContract });
      const remainingBalance = bigNumberFrom(strategyRemainingBalance.toString());
      const playerWithdrawAmountWei = String(ethToWeiEthersBN(paymentAmount, decimals));

      const minimalAmountRequiredForSlippage = bigNumberFrom(playerWithdrawAmountWei).multipliedBy(
        slippagePercentage / 100
      );

      if (remainingBalance.isLessThan(minimalAmountRequiredForSlippage)) {
        return String(0);
      }

      if (remainingBalance.isLessThan(playerWithdrawAmountWei)) {
        return calculateSlippage(remainingBalance, slippagePercentage, strategyContract);
      }

      return calculateSlippage(playerWithdrawAmountWei, slippagePercentage, strategyContract);
    },
    [provider, calculateSlippage]
  );

  const getSlippage = useCallback(
    async ({ slippagePercentage, paymentAmount, decimals = 18, isDeposit = true }) => {
      if (!SLIPPAGE_STRATEGIES.includes(strategyProvider)) {
        return String(0);
      }

      const strategyContract = Web3Client.createContract({
        address: goodGhostingStrategyAddress,
        abi: GoodGhostingStrategyABI,
      });

      if (isDeposit) {
        return getDepositSlippage({ paymentAmount, slippagePercentage, strategyContract, decimals });
      }

      return getWithdrawSlippage({ paymentAmount, slippagePercentage, strategyContract, decimals });
    },
    [Web3Client, strategyProvider, goodGhostingStrategyAddress, getDepositSlippage, getWithdrawSlippage]
  );

  return [getSlippage];
}

export default useGetSlippage;
