import { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sdkJsClient } from 'service/sdk';

import { Web3Context } from 'providers/Web3Provider';
import { HashContext } from 'providers/HashProvider';
import { setPool } from 'redux/reducers/pool';

import { getIsDashboardWithdrawing } from 'redux/selectors/dashboard.selector';

import { setShowWithdrawModal, setLoaderGasPrices } from 'redux/reducers/feedbacks';
import { setIsDashboardWithdrawing } from 'redux/reducers/dashboard';
import { getUserAddress } from 'redux/selectors/user.selector';

import { setGameInfo, setGameStats } from 'redux/reducers/game';
import { setPlayerInfo } from 'redux/reducers/user';

export default function useWithdrawDashboard(info) {
  const dispatch = useDispatch();
  const Web3Client = useContext(Web3Context);
  const { setWithdrawPlayerInfo, setWithdrawPoolInfo, setWithdrawGameInfo, setWithdrawGameStats } =
    useContext(HashContext);

  const userNetworkId = useSelector((state) => state.user.networkId);
  const usersAddress = useSelector(getUserAddress);
  const isDashboardWithdrawing = useSelector(getIsDashboardWithdrawing);

  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [hasWithdrawn, setHasWithdrawn] = useState(false);

  const dashboardWithdraw = async () => {
    try {
      dispatch(setIsDashboardWithdrawing(true));
      setIsWithdrawing(true);

      if (~~userNetworkId !== ~~info.networkId) {
        await Web3Client.changeNetwork({
          networkId: info.networkId,
          forceLogOut: true,
        });
      }

      const [currentPlayer, currentGame, currentStats] = await Promise.all([
        sdkJsClient.getPlayerGameStatus({
          playerAddress: usersAddress.toLowerCase(),
          contractAddress: info.contract,
        }),
        sdkJsClient.getGames(info.contract),
        sdkJsClient.getStats(info.contract),
      ]);

      dispatch(setPool(info));

      setWithdrawPlayerInfo(currentPlayer);
      dispatch(setPlayerInfo(currentPlayer));

      setWithdrawGameInfo(currentGame);
      dispatch(setGameInfo(currentGame));

      setWithdrawGameStats(currentStats);
      dispatch(setGameStats(currentStats));

      setWithdrawPoolInfo(info);

      dispatch(setLoaderGasPrices(true));
      dispatch(setShowWithdrawModal(true));
    } catch (err) {
      setHasWithdrawn(false);
    } finally {
      setIsWithdrawing(false);
      dispatch(setIsDashboardWithdrawing(false));
    }
  };

  return [dashboardWithdraw, isWithdrawing, hasWithdrawn, isDashboardWithdrawing];
}
