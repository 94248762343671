import React from 'react';
import classNames from 'classnames';
import Image from '../../elements/Image';
import { brandCaps, brandDomain } from '../../../utils/brand';

const Logo = ({ className, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <a href={`https://${brandDomain}`}>
          <Image src={require('../../../assets/images/logo-default.svg')} alt={brandCaps} width={100} height={32} />
        </a>
      </h1>
    </div>
  );
};

export default Logo;
