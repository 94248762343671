import React, { useEffect, useRef, useState } from 'react';
import Image from 'components/elements/Image';
import classNames from 'classnames';
import Button from 'components/elements/Button';
import { useHistory } from 'react-router-dom';
import useGetProfileImage from 'Hooks/useGetProfileImage';

import { parseBoolEnvVar } from 'utils/utilities';
import RampModal from 'views/Buy/components/RampModal';
import BetaSwitch from './BetaSwitch';

const shouldDisplayBetaSwitch = parseBoolEnvVar(process.env.REACT_APP_ENABLE_BETA_MODE);

function useClickOutside(ref, open, func) {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        e.preventDefault();
        e.stopPropagation();

        func();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, open]);
}

export function WalletDropdown({ userAddress, open, logOut, close }) {
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, open, () => close());
  const [showModal, setShowModal] = useState(false);
  const [playerProfileImage] = useGetProfileImage(userAddress);
  const history = useHistory();

  return (
    <>
      <div ref={dropdownRef} className={classNames('wallet-dropdown', open && 'show')}>
        <div className="dropdown-title">
          <p className="m-0 pt-11">Account</p>
          <div className="dropdown-logout-button">
            <Button className="button-link" onClick={logOut} id="wallet-dropdown-log-out">
              Logout
            </Button>
          </div>
        </div>
        <div className="dropdown-address">
          <Image
            onClick={() => history.push(`/dashboard/${userAddress}`)}
            className="dropdown-avatar"
            src={playerProfileImage}
          />
          <div onClick={() => history.push(`/dashboard/${userAddress}`)}>{userAddress}</div>
        </div>
        <div className="w-100 d-flex justify-content-end">
          <Button
            className="button-primary-outline"
            onClick={() => {
              close();
              history.push('/dashboard');
            }}
            id="wallet-dropdown-dashboard"
          >
            dashboard
          </Button>
        </div>
        {shouldDisplayBetaSwitch && <BetaSwitch />}
      </div>
      <RampModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
