/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const statesSlice = createSlice({
  name: 'gamestates',
  initialState: {
    errors: {},
    hasRequestedPlayers: false,
    hasSetFinalRewards: false,
    loadingState: {},
    loadingGameStatus: false,
    showConfirmModal: false,
    showEarlyWithdrawModal: false,
    showDepositModal: false,
    showVideoModal: false,
    showUserSurveyModal: true,
    success: {},
  },
  reducers: {
    setSuccessState: (state, { payload }) => {
      state.success = payload;
    },
    setErrors: (state, { payload }) => {
      state.errors = payload;
    },
    setLoadingState: (state, { payload }) => {
      state.loadingState = payload;
    },
    setLoadingGameStatus: (state, { payload }) => {
      state.loadingGameStatus = payload;
    },
    setHasRequestedPlayers: (state, { payload }) => {
      state.setHasRequestedPlayers = payload;
    },
    setHasSetFinalRewards: (state, { payload }) => {
      state.hasSetFinalRewards = payload;
    },
    setShowConfirmModal: (state, { payload }) => {
      state.showConfirmModal = payload;
    },
    setShowEarlyWithdrawModal: (state, { payload }) => {
      state.showEarlyWithdrawModal = payload;
    },
    setShowDepsitModal: (state, { payload }) => {
      state.showDepositModal = payload;
    },
    setShowVideoModal: (state, { payload }) => {
      state.showVideoModal = payload;
    },
    setShowUserSurveyModal: (state, { payload }) => {
      state.setShowUserSurveyModal = payload;
    },
    resetGameStates: (state) => {
      state.errors = {};
      state.hasRequestedPlayers = false;
      state.hasSetFinalRewards = false;
      state.loadingState = {};
      state.loadingGameStatus = false;
      state.showConfirmModal = false;
      state.showEarlyWithdrawModal = false;
      state.showDepositModal = false;
      state.showVideoModal = false;
      state.success = {};
    },
  },
});

export default statesSlice.reducer;
export const {
  setSuccessState,
  setLoadingState,
  setErrors,
  setLoadingGameStatus,
  setHasRequestedPlayers,
  setHasSetFinalRewards,
  setShowConfirmModal,
  setShowEarlyWithdrawModal,
  setShowDepsitModal,
  setShowVideoModal,
  setShowUserSurveyModal,
  resetGameStates,
} = statesSlice.actions;
