import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { HashContext } from 'providers/HashProvider';
import { getExplorerURL } from 'redux/selectors/pool.selector';

 function ExplorerRoute({label}) {
  const { hash, withdrawPoolInfo } = useContext(HashContext);
  const explorerUrl = useSelector(getExplorerURL) || withdrawPoolInfo.baseExplorerURL;
  return (
    <>
      {Boolean(explorerUrl) && (
          <a href={`${explorerUrl}tx/${hash}`} target="_blank" rel="noopener noreferrer">
            {label}
          </a>
      )}
    </>
  );
}

ExplorerRoute.defaultProps = {
  label: "View on Explorer",
}

export default ExplorerRoute;
