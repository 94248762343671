function importAll(r) {
  const cache = {};
  r.keys().forEach((key) => {
    const [image] = key.replace('./', '').split('.');
    cache[image] = r(key);
  });

  return cache;
}

const blank = importAll(require.context('assets/badges/unaccomplished', false, /\.(png|jpe?g|svg)$/));
export const unaccomplishedBadges = Object.values(blank);
