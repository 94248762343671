import React from 'react';

import Modal from 'components/elements/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setIsValoraModalOpen } from 'redux/reducers/wallet';
import Loading from 'components/elements/Loading';
import ValoraIcon from 'assets/logos/valora.svg';

export function ValoraModal() {
  const isValoraModalOpen = useSelector((state) => state.wallet.isValoraModalOpen);
  const dispatch = useDispatch();
  return (
    <Modal
      show={isValoraModalOpen}
      handleClose={() => {
        dispatch(setIsValoraModalOpen(false));
      }}
      className="wallet-modal-valora"
    >
      <img src={ValoraIcon} alt="Valora Icon" />
      <div>
        <Loading classes="m-30" type="BallTriangle" />

        <span>Waiting for transaction</span>
      </div>
      <h5>Please confirm the transaction in the Valora App on your mobile device</h5>
    </Modal>
  );
}
