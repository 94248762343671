import { useDispatch, useSelector } from 'react-redux';
import { setErrorTransaction, setErrorRefusedSignature } from 'redux/reducers/feedbacks';
import { getErrorTransaction, getErrorRefusedSignature } from 'redux/selectors/feedbacks.selector';

const useCloseErrorMsg = () => {
  const dispatch = useDispatch();
  const errorTransaction = useSelector(getErrorTransaction);
  const errorRefusedSignature = useSelector(getErrorRefusedSignature);

  const closeErrorMsg = () => {
    if (errorTransaction) {
      dispatch(setErrorTransaction(false));
    }

    if (errorRefusedSignature) {
      dispatch(setErrorRefusedSignature(false));
    }
  };

  return closeErrorMsg;
};

export default useCloseErrorMsg;
