/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { useDispatch, useSelector } from 'react-redux';
import { status } from 'utils/utilities';
import { setGameAlreadyStarted } from 'redux/reducers/game';
import { getPaymentCount, getCurrentSegment, getNetworkId } from 'redux/selectors/game.selector';
import { getUserStatus, getMostRecentSegmentPaid } from 'redux/selectors/user.selector';

export default function useGetGameAlreadyStarted() {
  const dispatch = useDispatch();
  const currentSegment = useSelector(getCurrentSegment);
  const paymentCount = useSelector(getPaymentCount);
  const networkId = useSelector(getNetworkId);
  const isLastPayableSegment = useSelector((state) => state.game.stats.isLastPayableSegment);
  const mostRecentSegmentPaid = useSelector(getMostRecentSegmentPaid);
  const userStatus = useSelector(getUserStatus);

  const roundPaidAndNoPaymentNextRound =
    isLastPayableSegment && mostRecentSegmentPaid && mostRecentSegmentPaid === ~~currentSegment && ~~paymentCount;

  const gameAlreadyStarted = !!(
    networkId?.length &&
    currentSegment !== '0' &&
    userStatus === status.unregistered &&
    !roundPaidAndNoPaymentNextRound
  );

  dispatch(setGameAlreadyStarted(gameAlreadyStarted));
}
