import React from 'react';
import classNames from 'classnames';

export default function ModalHeader(props) {
  const { header = '', backButtonLabel = '', handleGoBack, onClose, className, onClick, link } = props;
  const classes = classNames('modal-header-gg', className);
  const headerClass = classNames('mt-0 mb-6', link);
  return (
    <>
      <div className={classes}>
        {backButtonLabel && (
          <div className="modal-action">
            <button className="button-link mt-custom-2 ps-0" onClick={handleGoBack} type="button">
              <i className="bi bi-arrow-left-short" />
              Back
            </button>
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <h3 onClick={onClick} className={headerClass}>
          {header}
        </h3>
        <button type="button" className="btn-close" aria-label="Close" onClick={onClose} />
      </div>
      {Boolean(header) && <div className="has-bottom-divider mb-20" />}
    </>
  );
}
