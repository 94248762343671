import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { getNetworkId } from 'redux/selectors/game.selector';
import { setCustomInput } from 'redux/reducers/feedbacks';
import { getNetworkName } from 'redux/selectors/pool.selector';
import {
  getShowWithdrawModal,
  getShowDepositModal,
  getShowConfirmModal,
  getShowEarlyWithdrawModal,
  getLoaderWithdraw,
  getLoaderEarlyWithdraw,
  getLoaderJoinGame,
  getLoaderMakeDeposit,
  getLoaderApproveDeposit,
  getCustomInput,
} from 'redux/selectors/feedbacks.selector';
import { celoID, polygonID, baseID, baseGoerliID } from 'utils/networks';
import { HashContext } from 'providers/HashProvider';
import { truncateAndformat } from 'utils/numberFormat';

export const GasContext = createContext();

export default function GasProvider({ children }) {
  const [gasPrices, setGasPrices] = useState({});
  const [defaultChoice, setDefaultChoice] = useState(0);
  const [userChoice, setUserChoice] = useState(false);
  const [customFee, setCustomFee] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [btnName, setBtnName] = useState('low');
  const [prevBtn, setPrevBtn] = useState('');
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const { withdrawGameInfo, withdrawPoolInfo } = useContext(HashContext);
  const networkId = useSelector(getNetworkId) ?? withdrawGameInfo.networkId;
  const name = useSelector(getNetworkName) ?? withdrawPoolInfo.name;
  const showDepositModal = useSelector(getShowDepositModal);
  const showWithdrawModal = useSelector(getShowWithdrawModal);
  const showEarlyWithdrawModal = useSelector(getShowEarlyWithdrawModal);
  const showConfirmModal = useSelector(getShowConfirmModal);
  const loaderWithdraw = useSelector(getLoaderWithdraw);
  const loaderEarlyWithdraw = useSelector(getLoaderEarlyWithdraw);
  const loaderJoinGame = useSelector(getLoaderJoinGame);
  const loaderMakeDeposit = useSelector(getLoaderMakeDeposit);
  const loaderApproveDeposit = useSelector(getLoaderApproveDeposit);
  const customInput = useSelector(getCustomInput);

  const dispatch = useDispatch();

  const prevGasRef = useRef();
  prevGasRef.current = gasPrices;

  const reset = () => {
    setDefaultChoice(0);
    setGasPrices({});
    setUserChoice(false);
    setCustomFee(0);
    setBtnName('low');
    setPrevBtn('low');
    if (show) {
      setShow(false);
    }
  };

  const fallbackGasPrices = {
    polygonID: {
      maxFeePerGas: 270,
      maxPriorityFeePerGas: 33,
    },
    celoID: {
      maxFeePerGas: 5,
      maxPriorityFeePerGas: 5,
    },
    baseID: {
      maxFeePerGas: 0.1,
      maxPriorityFeePerGas: 0.1,
    },
    baseGoerliID: {
      maxFeePerGas: 0.1,
      maxPriorityFeePerGas: 0.1,
    },
  };

  const gasPriceRange = {
    polygon: {
      floor: 30,
      ceiling: 800,
    },
    celo: {
      floor: 5,
      ceiling: 25,
    },
    base: {
      floor: 0.1,
      ceiling: 2,
    },
    baseGoerli: {
      floor: 0.1,
      ceiling: 2,
    },
    default: {
      floor: 10,
      ceiling: 500,
    },
  };

  const priceRange = name !== undefined ? gasPriceRange[name] : gasPriceRange.default;
  const isTransacting =
    loaderEarlyWithdraw || loaderWithdraw || loaderJoinGame || loaderMakeDeposit || loaderApproveDeposit;

  useEffect(() => {
    if (parseInt(networkId) === celoID) {
      setDefaultChoice(gasPriceRange.celo.floor);
      setGasPrices({
        ...fallbackGasPrices.celoID,
        low: gasPriceRange.celo.floor,
        standard: 8,
        instant: gasPriceRange.celo.ceiling,
      });
    } else if (parseInt(networkId) === baseID) {
      setDefaultChoice(gasPriceRange.base.floor);
      setGasPrices({
        ...fallbackGasPrices.baseID,
        low: gasPriceRange.base.floor,
        standard: gasPriceRange.base.floor * 2,
        instant: gasPriceRange.base.ceiling,
      });
    } else if (parseInt(networkId) === baseGoerliID) {
      setDefaultChoice(gasPriceRange.base.floor);
      setGasPrices({
        ...fallbackGasPrices.baseGoerliID,
        low: gasPriceRange.baseGoerli.floor,
        standard: gasPriceRange.baseGoerli.floor * 2,
        instant: gasPriceRange.baseGoerli.ceiling,
      });
    }
  }, [showDepositModal, showWithdrawModal, showEarlyWithdrawModal, showConfirmModal]);

  useEffect(() => {
    const fetchGasFees = async () => {
      try {
        const payload = await axios.get('https://gasstation.polygon.technology/v2');
        const { safeLow, standard, fast, estimatedBaseFee } = payload.data;
        const {
          polygonID: { maxPriorityFeePerGas: defaultPriorityFee },
        } = fallbackGasPrices;
        const customPriorityFee = customFee - estimatedBaseFee;
        setDefaultChoice(safeLow.maxFee);
        setGasPrices((prevState) => ({
          ...prevState,
          low: safeLow.maxFee,
          standard: standard.maxFee,
          instant: fast.maxFee,
          maxPriorityFeePerGas: {
            low: safeLow.maxPriorityFee ?? defaultPriorityFee,
            standard: standard.maxPriorityFee ?? defaultPriorityFee,
            instant: fast.maxPriorityFee ?? defaultPriorityFee,
            custom: customPriorityFee < 30 || customPriorityFee === undefined ? defaultPriorityFee : customPriorityFee,
          },
        }));
      } catch (error) {
        console.error('Error fetching gas prices: ', error);
      }
    };

    let intervalId;
    const openModal = showDepositModal || showWithdrawModal || showEarlyWithdrawModal || showConfirmModal;

    if (parseInt(networkId) === polygonID && openModal && !isTransacting) {
      fetchGasFees();
      intervalId = setInterval(fetchGasFees, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [showDepositModal, showWithdrawModal, showEarlyWithdrawModal, showConfirmModal, isTransacting, customFee]);

  const { standard, low, instant } = gasPrices;
  const { floor } = priceRange;

  const radios = [
    { name: 'Low', value: low },
    { name: 'Standard', value: standard },
    { name: 'Instant', value: instant },
  ];

  const modifier = (choice) => {
    if (Boolean(choice) && choice < floor) {
      return truncateAndformat(floor, 1);
    }

    return truncateAndformat(choice, 1) ?? choice;
  };

  const handlePopover = () => {
    setShow((prev) => !prev);
    if (toggle) {
      setToggle(false);
    }
    if (!customInput && prevBtn === 'custom' && customFee > floor) {
      dispatch(setCustomInput(true));
      setUserChoice(customFee);
      setBtnName('custom');
      return;
    }
    if (prevBtn === 'custom' && customFee < floor) {
      setBtnName('low');
      setUserChoice(gasPrices.low);
      return;
    }
    if (customInput && btnName !== 'custom') {
      dispatch(setCustomInput(false));
    }
    if (customInput && prevBtn === 'custom' && customFee > floor) {
      setUserChoice(customFee);
      return;
    }

    if (btnName !== 'custom' && show) {
      setUserChoice(gasPrices[prevBtn]);
    }

    if (!userChoice) {
      setBtnName('low');
    }
    // The setState below should always be at the bottom.
    setBtnName(prevBtn);
  };

  const handleConfirm = () => {
    if (customInput && customFee < floor) {
      setErrorMsg(`Minimum gas fee is ${floor}`);
      setTimeout(() => {
        setErrorMsg(false);
      }, 4000);
      return;
    }
    if (customInput) {
      setUserChoice(customFee);
      setBtnName('custom');
    } else {
      setUserChoice(gasPrices[btnName]);
    }
    setShow(false);
    setToggle(false);
  };

  const handleClick = () => {
    dispatch(setCustomInput(true));
    setBtnName('custom');
  };

  const handleToggleChange = (btn) => {
    if (customInput) {
      dispatch(setCustomInput(false));
    }
    if (customFee === '') {
      setCustomFee(0);
    }
    setToggle(true);
    setBtnName(btn.toLowerCase());
  };

  const handleCustomChange = (e, field) => {
    const { value } = e.target;
    const hasTwoOrLessDecimalPlaces = value.toString().includes('.')
      ? value.toString().split('.')[1].length <= 2
      : true;
    if (hasTwoOrLessDecimalPlaces) {
      field.onChange(e);
      setCustomFee(value);
    }
  };
  return (
    <GasContext.Provider
      value={{
        gasPrices,
        defaultChoice,
        userChoice,
        priceRange,
        isTransacting,
        radios,
        prevBtn,
        customFee,
        btnName,
        show,
        toggle,
        errorMsg,

        setDefaultChoice,
        reset,
        modifier,
        setUserChoice,
        setPrevBtn,
        setBtnName,
        setCustomFee,
        handlePopover,
        handleConfirm,
        handleClick,
        handleToggleChange,
        handleCustomChange,
      }}
    >
      {children}
    </GasContext.Provider>
  );
}
