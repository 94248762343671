import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Tooltip from 'components/elements/Tooltip';
import classNames from 'classnames';
import { getGameInfo } from 'redux/selectors/game.selector';
import { getPlayer } from 'redux/selectors/user.selector';
import { HashContext } from 'providers/HashProvider';
import { truncateAndformat, truncateAndFormatCoin } from 'utils/numberFormat';
import { getGameVersionType } from 'utils/utilities';

export default function ProfitAndLossSummary() {
  const { withdrawPlayerInfo, withdrawGameInfo } = useContext(HashContext);
  const game = useSelector(getGameInfo);
  const player = useSelector(getPlayer);
  const gameInfo = game.displayId ? game : withdrawGameInfo;
  const playerInfo = player.id ? player : withdrawPlayerInfo;
  const hasWithdrawn = playerInfo.withdrawn && !playerInfo.isWinner;
  const hasPositiveGains = parseFloat(playerInfo.earningsConverted) >= 0;
  const isDropoutAndNegativeGains = hasWithdrawn && !hasPositiveGains;
  const isV2Game = getGameVersionType(gameInfo);
  const playerStatus = hasWithdrawn ? 'Withdrawn' : playerInfo.isAlive ? 'Winning' : 'Lost';

  const profitOrLoss = hasPositiveGains ? 'Profit' : 'Loss';
  const profitAndLossSummaryTitleMap = {
    Winning: {
      Profit: 'Player Profit',
      Loss: 'Player Loss',
    },
    Lost: {
      Profit: 'Missed Player Profit',
      Loss: 'Player Loss',
    },
    Withdrawn: {
      Profit: 'Missed Player Profit',
    },
  };
  const isGhostAndHasLoss = playerStatus === 'Lost' && !hasPositiveGains;

  return isV2Game ? (
    <ProfitAndLossSummaryV2Container
      isDropoutAndNegativeGains={isDropoutAndNegativeGains}
      isGhostAndHasLoss={isGhostAndHasLoss}
      profitAndLossSummaryTitleMap={profitAndLossSummaryTitleMap}
      playerStatus={playerStatus}
      profitOrLoss={profitOrLoss}
      gameInfo={gameInfo}
      playerInfo={playerInfo}
    />
  ) : (
    <ProfitAndLossSummaryContainer
      isDropoutAndNegativeGains={isDropoutAndNegativeGains}
      isGhostAndHasLoss={isGhostAndHasLoss}
      profitAndLossSummaryTitleMap={profitAndLossSummaryTitleMap}
      playerStatus={playerStatus}
      profitOrLoss={profitOrLoss}
      gameInfo={gameInfo}
      playerInfo={playerInfo}
    />
  );
}

const ProfitAndLossSummaryContainer = ({
  isGhostAndHasLoss,
  isDropoutAndNegativeGains,
  profitAndLossSummaryTitleMap,
  playerStatus,
  profitOrLoss,
  gameInfo,
  playerInfo,
}) => {
  const displayContent = () => (
    <Row>
      <Col xs={5}>
        <strong className="pl-8 fw-400">{`≈ Total ${playerInfo.conversionCurrency}`}</strong>
      </Col>
      <Col xs={7}>
        <ProfitAndLossSummaryItemValue
          value={!isGhostAndHasLoss ? playerInfo.earningsConverted : playerInfo.interestConverted}
          precision={2}
        />
      </Col>
    </Row>
  );
  return (
    <>
      {!isDropoutAndNegativeGains && (
        <>
          <Row>
            <Col xs={7} className="text-nowrap fw-400">
              <strong className="fw-400">{profitAndLossSummaryTitleMap?.[playerStatus]?.[profitOrLoss]}</strong>
            </Col>
          </Row>
          <ProfitAndLossSummaryItem
            token={gameInfo.depositToken}
            value={playerInfo.interestAmount}
            valueConverted={playerInfo.interestConverted}
            precision={gameInfo.depositToken}
            conversionCurrency={playerInfo.conversionCurrency}
            displayWhenZeroOrNegative
          />
          <ProfitAndLossSummaryItem
            token={gameInfo.rewardToken}
            value={playerInfo.rewardAmount}
            valueConverted={playerInfo.rewardsConverted}
            precision={gameInfo.rewardToken}
            conversionCurrency={playerInfo.conversionCurrency}
            displayWhenZeroOrNegative={isGhostAndHasLoss}
            overrideValueAndUseZero={isGhostAndHasLoss}
          />
          <ProfitAndLossSummaryItem
            token={gameInfo.secondaryRewardToken}
            value={playerInfo.secondaryRewardAmount}
            valueConverted={playerInfo.secondaryRewardsConverted}
            precision={gameInfo.secondaryRewardToken}
            conversionCurrency={playerInfo.conversionCurrency}
            displayWhenZeroOrNegative={isGhostAndHasLoss}
            overrideValueAndUseZero={isGhostAndHasLoss}
          />
          <ProfitAndLossSummaryItem
            token={gameInfo.incentiveToken}
            value={playerInfo.incentiveAmount}
            valueConverted={playerInfo.incentivesConverted}
            precision={gameInfo.incentiveToken}
            conversionCurrency={playerInfo.conversionCurrency}
            displayWhenZeroOrNegative={isGhostAndHasLoss}
            overrideValueAndUseZero={isGhostAndHasLoss}
          />
          <Tooltip
            placement="bottom"
            content="Token amount(s) converted to {playerInfo.conversionCurrency} at the current market rate."
            displayContent={displayContent}
          />
        </>
      )}
    </>
  );
};

const ProfitAndLossSummaryV2Container = ({
  isGhostAndHasLoss,
  isDropoutAndNegativeGains,
  profitAndLossSummaryTitleMap,
  playerStatus,
  profitOrLoss,
  gameInfo,
  playerInfo,
}) => {
  const { rewards } = playerInfo;

  const displayContent = () => (
    <Row>
      <Col xs={5}>
        <strong className="pl-8 fw-400">{`≈ Total ${playerInfo.conversionCurrency}`}</strong>
      </Col>
      <Col xs={7}>
        <ProfitAndLossSummaryItemValue
          value={!isGhostAndHasLoss ? playerInfo.earningsConverted : playerInfo.interestConverted}
          precision={2}
        />
      </Col>
    </Row>
  );

  return (
    <>
      {!isDropoutAndNegativeGains && (
        <>
          <Row>
            <Col xs={5} className="text-nowrap">
              <strong className="fw-400">{profitAndLossSummaryTitleMap?.[playerStatus]?.[profitOrLoss]}</strong>
            </Col>
          </Row>

          <ProfitAndLossSummaryItem
            token={gameInfo.depositToken}
            value={playerInfo.interestAmount}
            valueConverted={playerInfo.interestConverted}
            precision={gameInfo.depositToken}
            conversionCurrency={playerInfo.conversionCurrency}
            displayWhenZeroOrNegative
          />

          {rewards.map((reward) => (
            <ProfitAndLossSummaryItem
              token={reward.tokenId}
              value={reward.balance}
              valueConverted={reward.convertedBalance}
              precision={reward.tokenId}
              conversionCurrency={playerInfo.conversionCurrency}
              displayWhenZeroOrNegative={isGhostAndHasLoss}
              overrideValueAndUseZero={isGhostAndHasLoss}
            />
          ))}
          <Tooltip
            placement="bottom"
            content={`Token amount(s) converted to ${playerInfo.conversionCurrency} at the current market rate`}
            displayContent={displayContent}
          />
        </>
      )}
    </>
  );
};

const ProfitAndLossSummaryItem = ({
  token,
  conversionCurrency,
  value,
  valueConverted,
  precision,
  displayWhenZeroOrNegative = false,
  overrideValueAndUseZero = false,
}) => {
  if (!token) {
    return null;
  }

  if (parseFloat(value) <= 0 && !displayWhenZeroOrNegative) {
    return null;
  }

  const finalValue = overrideValueAndUseZero ? 0 : value;
  const finalValueConverted = overrideValueAndUseZero ? 0 : valueConverted;
  const finalPrecision = overrideValueAndUseZero ? 2 : precision;

  const displayContent = () => (
    <Row>
      <Col xs={5}>
        <strong className="pl-8 fw-400">{`≈ ${String(token).toUpperCase()}`}</strong>
      </Col>
      <Col xs={7}>
        <ProfitAndLossSummaryItemValue value={finalValue} precision={finalPrecision} />
      </Col>
    </Row>
  );

  return (
    <Tooltip
      placement="bottom"
      content={`${String(token).toUpperCase()} gains: ≈${truncateAndformat(
        finalValueConverted,
        2
      )} ${conversionCurrency} at the current market rate`}
      displayContent={displayContent}
    />
  );
};

const ProfitAndLossSummaryItemValue = ({ value, precision }) => {
  const parsedNumber = parseFloat(value);
  const isPositive = parsedNumber >= 0;

  const classes = classNames(!isPositive && 'red-text', 'fw-400');

  const formattedValue =
    typeof precision === 'number'
      ? truncateAndformat(Math.abs(parsedNumber), precision)
      : truncateAndFormatCoin(Math.abs(parsedNumber), precision);

  return (
    <>
      <span className={classes}>{`${isPositive ? '+' : '-'} ${formattedValue}`}</span> <i className="bi bi-info" />
    </>
  );
};
