import React, { useContext } from 'react';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { useSelector, useDispatch } from 'react-redux';
import celebrate from 'assets/animations/celebrate.gif';
import { setShowDepsitModal, resetErrors } from 'redux/reducers/feedbacks';
import ExplorerRoute from 'utils/ExplorerRoute';
import { GasContext } from 'providers/GasProvider';
import Modal from 'components/elements/Modal';
import ModalHeader from 'components/elements/ModalHeader';
import { brandDomain, twitterHandle, appSubdomain, hashtag } from 'utils/brand';
import { pickRandomItem } from 'utils/utilities';
import { DEPOSIT_INTROS } from 'utils/twitterTexts';
import { PaymentCrypto } from '../ConfirmModal/PaymentCrypto';
import useMakeDeposit from '../hooks/useMakeDeposit';

export default () => {
  const dispatch = useDispatch();
  const { reset } = useContext(GasContext);
  const info = useSelector((state) => state.pool.info);
  const gameInfo = useSelector((state) => state.game.info);
  const loaderMakeDeposit = useSelector((state) => state.feedbacks.loaderMakeDeposit);
  const showDepositModal = useSelector((state) => state.feedbacks.showDepositModal);
  const successMakeDepositMap = useSelector((state) => state.feedbacks.successMakeDeposit);
  const [makeDeposit] = useMakeDeposit();
  const successMakeDeposit = successMakeDepositMap[info.id];

  const closeDepositModal = () => {
    dispatch(setShowDepsitModal(false));
    dispatch(resetErrors());
    reset();
  };

  return (
    <Modal handleClose={closeDepositModal} show={showDepositModal}>
      {!successMakeDeposit && <ModalHeader header="Deposit this round" onClose={closeDepositModal} />}
      {successMakeDeposit && (
        <div>
          <ModalHeader onClose={closeDepositModal} />
          <img src={celebrate} alt="Celebrate animation" className="w-20" />
          <h4>
            Congrats your deposit is complete!{' '}
            <span role="img" aria-label="swimmer">
              🥳
            </span>{' '}
          </h4>
          <TwitterShareButton
            title={`${pickRandomItem(DEPOSIT_INTROS)}\nI just made another deposit to ${twitterHandle}'s  ${
              info.gameNameShort ?? info.gameName
            } DeFi saving challenge\n\n`}
            url={`https://${appSubdomain}.${brandDomain}?utm_source=twitter&utm_medium=social&utm_campaign=deposit&utm_content=deposit`}
            hashtags={[hashtag, 'stepbystepsaving']}
            related={[twitterHandle]}
            className="mb-1"
            id="share-deposit-from-modal"
          >
            <p>
              <b>
                You are part of the future of savings!
                <br />
                Share with others.
              </b>
            </p>
            <TwitterIcon size={48} round />
          </TwitterShareButton>
          <div>
          <ExplorerRoute />
          </div>
        </div>
      )}
      {!successMakeDeposit && gameInfo.gameStartsAt && (
        <>
          <PaymentCrypto action={makeDeposit} loaderAction={loaderMakeDeposit} payActionTitle="Make your deposit" />
        </>
      )}
    </Modal>
  );
};
