import React from 'react';

import SlippageSelector from './SlippageSelector';

function SlippagePopoverContent({ form }) {
  return (
    <section className="slippage-popover-content">
      <SlippageSelector form={form} />
    </section>
  );
}

export default SlippagePopoverContent;
