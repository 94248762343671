import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { ToggleButton, Button } from 'react-bootstrap';
import classNames from 'classnames';
import Input from 'components/elements/Input';

import { GasContext } from 'providers/GasProvider';
import { getCustomInput } from 'redux/selectors/feedbacks.selector';

export default function GasComponent() {
  const {
    radios,
    priceRange: { floor },
    customFee,
    btnName,
    errorMsg,

    handleConfirm,
    handlePopover,
    handleClick,
    handleToggleChange,
    handleCustomChange,
    modifier,
  } = useContext(GasContext);

  const customInput = useSelector(getCustomInput);

  const { control } = useForm();

  return (
    <>
      <section className="gas-fee-btns">
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={idx}
            type="radio"
            variant="outline-toggle"
            value={radio.value}
            checked={btnName.toLowerCase() === radio.name.toLowerCase() && !customInput}
            onChange={() => handleToggleChange(radio.name)}
          >
            <p>{radio.name === 'Low' ? 'Slow' : radio.name}</p>
            <p>({modifier(radio.value)} Gwei)</p>
          </ToggleButton>
        ))}
        <div onClick={handleClick} className={`gas-form rounded ${customInput ? 'button-gray' : 'gas-btn-outline'}`}>
          <p className="text-center">Custom</p>
          <Controller
            name="customFee"
            control={control}
            render={({ field }) => (
              <div className="d-flex">
                <section>
                  <Input
                    {...field}
                    size="sm"
                    onChange={(e) => handleCustomChange(e, field)}
                    value={customFee !== 0 ? customFee : ''}
                    type="number"
                    autoFocus={!!customInput}
                    className={classNames('gas-input')}
                  />
                  <p className="text-secondary m-0">Minimum: {floor}</p>
                  {errorMsg && <p className="red-text">Minimum gas fee is {floor}</p>}
                </section>
                <p className="ms-1">Gwei</p>
              </div>
            )}
          />
        </div>
      </section>
      <section className="action">
        <Button className="me-1 button-primary-outline" onClick={handlePopover} id="gas-input-cancel">
          Cancel
        </Button>
        <Button className="button button-sm button-primary" onClick={handleConfirm} id="gas-input-confirm">
          Confirm
        </Button>
      </section>
    </>
  );
}
