/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect, useCallback } from 'react';
import { NftContext } from 'providers/NftProvider';
import { useSelector, useDispatch } from 'react-redux';
import { setPlayers, setNft, setOffset } from 'redux/reducers/players';
import { statusColors } from 'Hooks/players';
import { sdkJsClient } from 'service/sdk';
import { IMAGE_RESOLUTION } from 'utils/constants';

export default function useGetPlayers() {
  const dispatch = useDispatch();
  const NftClient = useContext(NftContext);
  const [fetching, setFetching] = useState(false);
  const contract = useSelector((state) => state.pool.info.contract);
  const nftProvider = useSelector((state) => state.pool.info.nftProvider);
  const players = useSelector((state) => state.players.list);
  const offset = useSelector((state) => state.players.offset);

  const getNFT = async (player) => {
    if (nftProvider === 'aavegotchi') {
      const res = await NftClient.getAavegotchiNFTs(player);
      return res;
    }
    return [];
  };

  const getPlayersImage = useCallback((playersInfo) => {
    const playersAddress = playersInfo.map((player) => player.address);
    return sdkJsClient.getProfileImage({
      playersAddress,
      resolution: IMAGE_RESOLUTION.Low,
    });
  }, []);

  const getPlayers = async () => {
    if (fetching) {
      return;
    }

    setFetching(true);
    try {
      const result = await sdkJsClient.getPlayers({ contractAddress: contract, limit: 100, offset });

      const enhancedPlayers = result.map((player) => {
        const color = statusColors[player.playerStatus];

        return {
          ...player,
          color,
        };
      });

      const playersImage = await getPlayersImage(enhancedPlayers);
      const enhancedPlayersWithProfileImage = enhancedPlayers.map((_, i) => {
        return { ...enhancedPlayers[i], profileImage: playersImage?.playersProfileImage[i] };
      });
      dispatch(setPlayers([...players, ...enhancedPlayersWithProfileImage]));
      dispatch(setOffset([...players, ...enhancedPlayersWithProfileImage].length));
      const aavegotchiData = await getNFT(enhancedPlayersWithProfileImage.map((player) => player.address));
      dispatch(setNft(aavegotchiData));
    } catch (err) {
      //
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getPlayers();
  }, []);

  return [getPlayers];
}
