import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from './Tooltip';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  tooltip: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.string,
  custom: PropTypes.boolean,
};

const defaultProps = {
  className: '',
  label: '',
  color: '',
  tooltip: '',
  type: '',
  link: '',
  target: '_self',
  custom: false,
};

const Badge = ({ className, label, type, custom, tooltip, link, target, ...props }) => {
  const classes = classNames('form-badge', type && `form-badge-${type}`, className);
  const customBadgeClass = classNames('form-badge-status', custom && `form-badge-status-${custom}`);

  return (
    <div className={classes} {...props}>
      {custom && <div className={customBadgeClass} />}
      <a className="form-badge-label" href={link} target={target} rel="noreferrer">
        {label}
      </a>
      {tooltip && <Tooltip content={tooltip} placement="bottom" displayContent={() => <i className="bi bi-info" />} />}
    </div>
  );
};

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;

export default Badge;
