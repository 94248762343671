import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { docsURL, blogURL, brandCaps, snapshotURL, contactEmail } from 'utils/brand';
import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
  className: '',
};

function Footer(props) {
  const { className, topOuterDivider, topDivider } = props;

  const classes = classNames('site-footer center-content-mobile', topOuterDivider && 'has-top-divider', className);

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div className={classNames(topDivider && 'has-top-divider')}>
          <div className="footer-top space-between mb-30">
            {/*  <Logo />
            <FooterSocial /> */}
            <div className="footer-links footer-nav">
              <h5>{`${brandCaps}`}</h5>
              <a href={`mailto:${contactEmail}`}>Contact</a>
              <Link to="/terms">Terms of Service</Link>
            </div>
            <div className="footer-links footer-nav">
              <h5>Application</h5>
              <Link to="/challenges">Challenges</Link>
              <Link to="/">Dashboard</Link>
              <Link to="/leaderboard">Leaderboard</Link>
            </div>

            <div className="footer-links footer-nav">
              <h5>Community</h5>
              <a href={blogURL} target="_blank" rel="noreferrer">
                Blog
              </a>
              <a href={snapshotURL} target="_blank" rel="noreferrer">
                Vote
              </a>
              <a href="https://discord.gg/Y6bPpwbpK3" target="_blank" rel="noreferrer">
                Discord
              </a>
            </div>

            <div className="footer-links footer-nav">
              <h5>Resources</h5>
              <a href={`${docsURL}/hub/getting-started`} target="_blank" rel="noreferrer">
                Guides
              </a>
              <a href={`${docsURL}`} target="_blank" rel="noreferrer">
                Documentation
              </a>
              <a href={`${docsURL}/halofi-challenges/faq`} target="_blank" rel="noreferrer">
                FAQ
              </a>
              <a href={`${docsURL}/audits`} target="_blank" rel="noreferrer">
                Audits
              </a>
            </div>
          </div>
        </div>
        <div className="has-bottom-divider mb-60" />
        <div className="footer-social">
          <p>© {new Date().getFullYear()} Binary Node Ltd</p>
          <FooterSocial />
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
