import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Tooltip from 'components/elements/Tooltip';

function RenderStat({ tooltip, title, value }) {
  const displayContent = () => (
    <Container>
      <Row>
        <Col xs={12}>
          <h5 className="game-state-title pb-10">{title}</h5>
          <span className="game-state-value">{value}</span>
        </Col>
      </Row>
    </Container>
  );
  return (
    <div className="game-stat">
      <Tooltip placement="bottom" content={tooltip} displayContent={displayContent} />
    </div>
  );
}

export default React.memo(RenderStat);
