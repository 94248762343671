import React, { useEffect, useState } from 'react';
import { createFrontConnection } from '@front-finance/link';

const FrontComponent = ({ iframeLink, onSuccess, onExit }) => {
  const [frontConnection, setFrontConnection] = useState(null);

  useEffect(() => {
    setFrontConnection(
      createFrontConnection({
        clientId: process.env.REACT_APP_FRONT_CLIENT_ID,
        onBrokerConnected: (authData) => {
          onSuccess(authData);
          return null;
        },
        onExit: (error) => {
          if (error) {
            console.error(`[FRONT ERROR] ${error}`);
          }
          // eslint-disable-next-line no-unused-expressions
          onExit && onExit();
        },
      })
    );
  }, []);

  useEffect(() => {
    if (iframeLink) {
      // eslint-disable-next-line no-unused-expressions
      frontConnection?.openPopup(iframeLink);
    }
  }, [frontConnection, iframeLink]);

  return <></>;
};

export default FrontComponent;
