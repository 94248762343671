import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { gameNetworkIcons } from 'utils/networks';
import { trimWords } from 'utils/utilities';
import FutureGameIcon from 'assets/images/coming-soon-icon.png';
import Tooltip from './Tooltip';
import Button from './Button';
import ProgressBar from './ProgessBar';

const propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  poolNumber: PropTypes.number,
  descriptionItems: PropTypes.array,
  network: PropTypes.string,
  buttonText: PropTypes.string,
  buttonId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool,
  isfutureGame: PropTypes.bool,
  nftImage: PropTypes.string,
  loading: PropTypes.bool,
  showGameProgress: PropTypes.bool,
  progressBarValue: PropTypes.number,
  playerNextRoundActionText: PropTypes.string,
  playerNextRoundActionValue: PropTypes.string,
};

const defaultProps = {
  className: '',
  heading: '',
  loading: false,
  subHeading: '',
  description: '',
  status: '',
  poolNumber: -1,
  descriptionItems: [],
  network: '',
  buttonText: '',
  buttonId: '',
  isButtonDisabled: false,
  isfutureGame: false,
  nftImage: '',
  showGameProgress: false,
  progressBarValue: 0,
  playerNextRoundActionText: '',
  playerNextRoundActionValue: '',
};

function CommunityPoolCard({
  heading,
  loading,
  subHeading,
  description,
  status,
  poolNumber,
  descriptionItems,
  network,
  buttonText,
  buttonId,
  onClick,
  className,
  isButtonDisabled,
  isfutureGame,
  nftImage,
  showGameProgress,
  progressBarValue,
  playerNextRoundActionText,
  playerNextRoundActionValue,
}) {
  const imgClass = classNames(nftImage || isfutureGame ? 'rounded-circle' : 'network-challenge-img');
  const nftOrNetworkImage = !nftImage ? gameNetworkIcons[network] : nftImage;
  const displayImage = isfutureGame ? FutureGameIcon : nftOrNetworkImage;
  const poolHeading = trimWords(heading, 10, 13);
  const poolDescription = trimWords(description, 57, 60);
  return (
    <div className="pool-box-container-community" onClick={onClick} key={poolNumber}>
      <div className={`pool-box ${!showGameProgress && 'has-bottom-divider'}`}>
        <div className="image-box">
          <img className={imgClass} src={displayImage} alt="network/nft" />
        </div>
        <div className="heading-container">
          <div className="heading-title">
            {poolHeading.text}
            <Tooltip content={heading} placement="bottom" displayContent={() => <i className="bi bi-info" />} />
          </div>
          <span>{subHeading}</span>
        </div>
      </div>
      {showGameProgress && (
        <div className="game-progress-container">
          <ProgressBar
            currentProgressInSegment={progressBarValue}
            className="game-progress-bar"
            propertyClassName="progress-bar"
          />
          {playerNextRoundActionText && (
            <div className="next-deposti-container">
              <p>{playerNextRoundActionText}</p>
              <div className="next-deposit">
                <span>{playerNextRoundActionValue}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {!showGameProgress && (
        <div className="description-container">
          {status && <h5>{status}</h5>}
          <p>{poolDescription.text}</p>
          {descriptionItems && (
            <ul>
              {descriptionItems.map((item) => {
                return <li>{item}</li>;
              })}
            </ul>
          )}
        </div>
      )}
      {buttonText && (
        <div className="button-box">
          <Button className={className} id={buttonId} disabled={isButtonDisabled} loading={loading}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
}

CommunityPoolCard.propTypes = propTypes;
CommunityPoolCard.defaultProps = defaultProps;

export default CommunityPoolCard;
