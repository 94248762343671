import React, { cloneElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getShowInfoModal } from 'redux/selectors/feedbacks.selector';
import { setShowInfoModal } from 'redux/reducers/feedbacks';
import PropTypes from 'prop-types';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import Button from './Button';

const propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  descriptionItems: PropTypes.array,
  buttonType: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isAnchorHeader: PropTypes.bool,
  href: PropTypes.func,
  children: PropTypes.array,
  subDescription: PropTypes.string,
};

const defaultProps = {
  subTitle: '',
  descriptionItems: [],
  isAnchorHeader: false,
  href: () => null,
  children: [],
  subDescription: '',
};

function sanitizeDescriptionElements(elements) {
  return elements.filter((element) => !!element);
}

function InfoModal({
  title,
  subTitle,
  description,
  subDescription,
  descriptionItems,
  buttonType,
  buttonText,
  buttonId,
  onClick,
  isAnchorHeader,
  href,
  children,
}) {
  const dispatch = useDispatch();
  const infoModal = useSelector(getShowInfoModal);
  const descriptionElements = sanitizeDescriptionElements(children);

  const setCloseModal = () => {
    dispatch(setShowInfoModal({ show: false }));
  };

  const isAnchor = isAnchorHeader ? 'link' : '';
  const descriptionImage = descriptionElements[0];

  return (
    <Modal show={infoModal.show} handleClose={setCloseModal}>
      <ModalHeader
        header={title}
        onClose={setCloseModal}
        className="info-modal-header"
        link={isAnchor}
        onClick={href}
      />
      <div className="info-content">
        {subTitle && <h4 className="subtitle">{subTitle}</h4>}
        {descriptionImage && cloneElement(descriptionImage)}
        <div className="description">
          {description && <p>{description}</p>}
          {subDescription && <p>{subDescription}</p>}
          {descriptionItems?.length > 0 && (
            <ul>
              {descriptionItems.map((item) => {
                return <li>{item}</li>;
              })}
            </ul>
          )}

          {[...descriptionElements.slice(1)].map((element) => {
            return cloneElement(element);
          })}
        </div>
      </div>
      <ModalFooter>
        <Button onClick={onClick} className={buttonType} id={buttonId}>
          {buttonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

InfoModal.propTypes = propTypes;
InfoModal.defaultProps = defaultProps;

export default InfoModal;
