/* eslint-disable no-unused-expressions */
import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Web3Context } from 'providers/Web3Provider';
import { HashContext } from 'providers/HashProvider';
import GoodGhostingWhitelistedV002 from 'ABIs/GoodGhostingWhitelisted-v0.0.2';
import GoodGhostingWhitelistedV001 from 'ABIs/GoodGhostingWhitelisted-v0.0.1';
import GoodGhostingV003 from 'ABIs/GoodGhosting-v0.0.3';
import GoodGhostingV200 from 'ABIs/GoodGhosting-v2.0.0';
import DaiABI from 'ABIs/ABI-dai';
import { sleep } from 'utils/utilities';
import { ethToWeiEthersBN, gweiToWeiEthersBN, weiToERC20 } from 'providers/ethersBN';
import { format } from 'utils/numberFormat';
import { ABI, ABIVersions, SLIPPAGE_STRATEGIES, TRANSACTION_TYPE, MAX_GAS_PRICE } from 'utils/constants';
import { AnalyticsProviderContext } from 'providers/AnalyticsProvider';
import { GasContext } from 'providers/GasProvider';
import { celoID, polygonID } from 'utils/networks';
import { setErrorTimeout } from 'redux/reducers/feedbacks';
import useGasEstimate from './useGasEstimate';

import { useFetchDecimals } from './useFetchDecimals';

import useGetSlippage from './slippage/useGetSlippage';

export const ContractContext = React.createContext();

export default function ContractProvider({ children, info }) {
  const Web3Client = useContext(Web3Context);
  const dispatch = useDispatch();

  const { sendTransaction: sendTransactionToAnalytics } = useContext(AnalyticsProviderContext);
  const {
    defaultChoice,
    userChoice,
    gasPrices,
    btnName,
    priceRange: { floor },
  } = useContext(GasContext);
  const { setHash } = useContext(HashContext);
  const [getSlippage] = useGetSlippage({ info });
  const gasEstimate = useGasEstimate();
  let userMaxFeePerGas;

  let gameABI;

  if (info.abiVersion === ABI.v001) {
    gameABI = GoodGhostingWhitelistedV001;
  } else if (info.abiVersion === ABI.v002) {
    gameABI = GoodGhostingWhitelistedV002;
  } else if (info.abiVersion === ABI.v003) {
    gameABI = GoodGhostingV003;
  } else if (ABIVersions.v20x.includes(info.abiVersion)) {
    gameABI = GoodGhostingV200;
  } else if (!info.abiVersion && info.name) {
    throw new Error('Unable to determine the correct contract ABI version. Please review game property "abiVersion"');
  }

  const { createContract, provider } = Web3Client;

  const timeout = 2 * 60 * 1000;

  const getDepositTokenContract = useCallback(() => {
    return createContract({ address: info.daiAddress, abi: DaiABI });
  }, [info.daiAddress, createContract]);

  const getGoodGhostingContract = useCallback(() => {
    return createContract({ address: info.contract, abi: gameABI });
  }, [info.contract, gameABI, createContract]);

  const getDepositTokenDecimals = useFetchDecimals({ getDepositTokenContract });

  if (userChoice > floor) {
    userMaxFeePerGas = userChoice;
  } else if (defaultChoice > floor && !userChoice) {
    userMaxFeePerGas = defaultChoice;
  } else {
    userMaxFeePerGas = floor;
  }

  const isErrorCodeIsInvalidParam = (code) => {
    return code && Math.abs(Number(code)) === 32602;
  };

  const getGasConfigForTransaction = async ({
    transaction,
    contract,
    parameters = [],
    userAddress,
    forceLegacyTransaction = false,
  }) => {
    const strategy = info?.strategyProvider?.toLowerCase();
    const txGasLimit = strategy in gasEstimate ? gasEstimate[strategy] : gasEstimate.aaveV2;
    const gasLimit = txGasLimit[transaction] ?? 0;

    const estimatedGasRaw = (await contract.estimateGas[transaction](...parameters, { from: userAddress })).toNumber();
    const estimatedGas = Math.floor(estimatedGasRaw * 1.2);
    const minEstimatedGas = Math.min(estimatedGas, MAX_GAS_PRICE);
    const maxGas = Math.max(gasLimit, minEstimatedGas);

    const maxFeePerGas = gweiToWeiEthersBN(format(userMaxFeePerGas)).toNumber();
    const maxPriorityFeePerGas = gweiToWeiEthersBN(
      format(gasPrices.maxPriorityFeePerGas[btnName]) ?? gasPrices.maxPriorityFeePerGas
    ).toNumber();
    if (forceLegacyTransaction) {
      return { gasLimit: maxGas, gasPrice: maxFeePerGas };
    }

    if (Number(info.networkId) === Number(celoID)) {
      return { gasLimit: maxGas, gasPrice: maxFeePerGas };
    }

    return { gasLimit: maxGas, maxPriorityFeePerGas, maxFeePerGas };
  };

  const sendGoodGhostingTransaction = async ({
    transaction,
    amount,
    slippagePercentage,
    isDeposit,
    parameters = [],
    userAddress,
  }) => {
    const goodGhostingContract = getGoodGhostingContract();
    const { contract, gameName, depositToken, riskProfile, networkId } = info;
    const txTimestamp = Math.floor(Date.now() / 1000);
    const metadata = { txType: transaction, contract, gameName, depositToken, riskProfile, amount, txTimestamp };

    const getTransactionConfig = async (transactionParam = [], forceLegacyTransaction = false) => {
      const gasConfig = await getGasConfigForTransaction({
        transaction,
        contract: goodGhostingContract,
        parameters: transactionParam,
        userAddress,
        forceLegacyTransaction,
      });

      const transactionConfig = { from: userAddress, ...gasConfig };
      return transactionConfig;
    };

    const getTransactionConfigForcedLegacy = async (transactionParams) => getTransactionConfig(transactionParams, true);

    const emitTransaction = async (transactionParams, transactionConfig, isRetry = false) => {
      try {
        const result = await goodGhostingContract[transaction](...transactionParams, transactionConfig);

        setHash(result.hash);

        // start a timer for the timeout
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Transaction timed out')), timeout);
        });

        // wait for either the transaction to be mined or the timeout to expire
        try {
          await Promise.race([provider.waitForTransaction(result.hash), timeoutPromise]);
        } catch (error) {
          console.error(error.message);
          if (error.message === 'Transaction timed out') {
            dispatch(setErrorTimeout(true));
          }
        }

        const receipt = await result.wait();
        return receipt;
      } catch (e) {
        if (!isRetry && isErrorCodeIsInvalidParam(e.code) && Number(info.networkId) === Number(polygonID)) {
          console.warn('Transaction failed with error, re-trying with legacy transaction', e);
          const legacyTransactionConfig = await getTransactionConfigForcedLegacy(transactionParams);
          return emitTransaction(transactionParams, legacyTransactionConfig, true);
        }

        throw e;
      }
    };

    if (info.abiVersion === ABI.v003) {
      // Curve V1 games have ended so they don't need slippage anymore - New curve games will be V2
      const slippage = 0; // await getSlippage({ slippagePercentage, paymentAmount: amount, isDeposit });
      const transactionParams = [...parameters, slippage];
      const transactionConfig = await getTransactionConfig(transactionParams);
      const transactionInfo = await emitTransaction(transactionParams, transactionConfig);
      sendTransactionToAnalytics(networkId, transactionInfo.transactionHash, metadata, transactionConfig);
      return transactionInfo;
    }

    if (ABIVersions.v20x.includes(info.abiVersion)) {
      let slippage = 0;
      const decimals = await getDepositTokenDecimals();

      if (SLIPPAGE_STRATEGIES.includes(info.strategyProvider)) {
        slippage = await getSlippage({ slippagePercentage, paymentAmount: amount, isDeposit, decimals });
      }

      if (transaction === TRANSACTION_TYPE.JoinGame || transaction === TRANSACTION_TYPE.MakeDeposit) {
        const depositAmount = ethToWeiEthersBN(amount, decimals);
        const transactionParams = [...parameters, slippage, depositAmount];
        const transactionConfig = await getTransactionConfig(transactionParams);
        const transactionInfo = await emitTransaction(transactionParams, transactionConfig);
        sendTransactionToAnalytics(networkId, transactionInfo.transactionHash, metadata, transactionConfig);
        return transactionInfo;
      }

      const transactionParams = [...parameters, slippage];
      const transactionConfig = await getTransactionConfig(transactionParams);
      const transactionInfo = await emitTransaction(transactionParams, transactionConfig);
      sendTransactionToAnalytics(networkId, transactionInfo.transactionHash, metadata, transactionConfig);
      return transactionInfo;
    }

    const transactionParams = [...parameters];
    const transactionConfig = await getTransactionConfig(transactionParams);
    const transactionInfo = await emitTransaction(transactionParams, transactionConfig);
    sendTransactionToAnalytics(networkId, transactionInfo.transactionHash, metadata, transactionConfig);
    return transactionInfo;
  };

  const approveToSpendToken = async (userAddress, amount) => {
    try {
      const depositTokenContract = getDepositTokenContract();
      const decimals = await getDepositTokenDecimals();
      const amountWei = ethToWeiEthersBN(String(amount), decimals);

      const gasConfigParameters = {
        transaction: TRANSACTION_TYPE.Approve,
        contract: depositTokenContract,
        parameters: [info.contract, amountWei.toString()],
        userAddress,
      };

      const sendApproveTransactionUsingConfig = async (transactionGasConfig) => {
        const transaction = await depositTokenContract.approve(info.contract, amountWei.toString(), {
          from: userAddress,
          ...transactionGasConfig,
        });

        setHash(transaction.hash);

        // start a timer for the timeout
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Transaction timed out')), timeout);
        });

        // wait for either the transaction to be mined or the timeout to expire
        try {
          await Promise.race([provider.waitForTransaction(transaction.hash), timeoutPromise]);
        } catch (error) {
          console.error(error.message);
          if (error.message === 'Transaction timed out') {
            dispatch(setErrorTimeout(true));
          }
        }

        const tx = await transaction.wait();
        const receipt = tx?.events?.[0]?.data ?? 0;

        const allowance = weiToERC20(receipt, decimals);
        return allowance;
      };

      try {
        const gasConfig = await getGasConfigForTransaction(gasConfigParameters);

        const result = await sendApproveTransactionUsingConfig(gasConfig);
        return result;
      } catch (e) {
        if (isErrorCodeIsInvalidParam(e.code)) {
          console.warn('Approve transaction failed with error, re-trying with legacy transaction', e);
          const forcedLegacyGasConfig = await getGasConfigForTransaction({
            ...gasConfigParameters,
            forceLegacyTransaction: true,
          });
          return sendApproveTransactionUsingConfig(forcedLegacyGasConfig);
        }

        throw e;
      }
    } catch (err) {
      console.error('error', err);
      throw err;
    }
  };

  const earlyWithdraw = async (userAddress, amount, slippagePercentage) => {
    // used sleep(500) as a quick workaround to fix exception being throw on
    // Celo contract kit saying user already withdraw(probably due to gas estimate above)
    sleep(500);

    const tx = await sendGoodGhostingTransaction({
      transaction: TRANSACTION_TYPE.EarlyWithdraw,
      amount,
      slippagePercentage,
      isDeposit: false,
      userAddress,
    });
    setHash(tx.transactionHash);
    return tx;
  };

  const joinGame = async (userAddress, amount, slippagePercentage) => {
    try {
      const tx = await sendGoodGhostingTransaction({
        transaction: TRANSACTION_TYPE.JoinGame,
        amount,
        slippagePercentage,
        isDeposit: true,
        userAddress,
      });
      setHash(tx.transactionHash);
      return tx;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const joinWhitelistedGame = async (userAddress, data, amount, slippagePercentage) => {
    try {
      return sendGoodGhostingTransaction({
        transaction: TRANSACTION_TYPE.JoinWhitelistedGame,
        amount,
        slippagePercentage,
        isDeposit: true,
        parameters: [data.index, data.proofs],
        userAddress,
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const makeDeposit = async (userAddress, amount, slippagePercentage) => {
    const tx = await sendGoodGhostingTransaction({
      transaction: TRANSACTION_TYPE.MakeDeposit,
      amount,
      slippagePercentage,
      isDeposit: true,
      userAddress,
    });
    setHash(tx.transactionHash);
    return tx;
  };

  const redeem = async (userAddress, amount, slippagePercentage) => {
    return sendGoodGhostingTransaction({
      transaction: TRANSACTION_TYPE.RedeemFromExternalPool,
      amount,
      slippagePercentage,
      isDeposit: false,
      userAddress,
    });
  };

  const withdraw = async (userAddress, amount, slippagePercentage) => {
    const tx = await sendGoodGhostingTransaction({
      transaction: TRANSACTION_TYPE.Withdraw,
      amount,
      slippagePercentage,
      isDeposit: false,
      userAddress,
    });
    setHash(tx.transactionHash);
    return tx;
  };

  const getDepositTokenBalance = async (usersAddress) => {
    const depositTokenContract = getDepositTokenContract();

    const [balance, decimals] = await Promise.all([
      depositTokenContract.balanceOf(usersAddress),
      getDepositTokenDecimals(),
    ]);

    return weiToERC20(balance, decimals);
  };

  const getAvailableAllowance = async (userAddress) => {
    const depositTokenContract = getDepositTokenContract();

    if (!depositTokenContract) {
      return 0;
    }

    try {
      const [allowance, decimals] = await Promise.all([
        depositTokenContract.allowance(userAddress, info.contract),
        getDepositTokenDecimals(),
      ]);

      return parseFloat(weiToERC20(allowance, decimals).toString());
    } catch (err) {
      console.error('getAvailableAllowance error:', err);
      return 0;
    }
  };

  return (
    <ContractContext.Provider
      value={{
        approveToSpendToken,
        earlyWithdraw,
        joinGame,
        joinWhitelistedGame,
        makeDeposit,
        withdraw,
        getDepositTokenBalance,
        getAvailableAllowance,
        getDepositTokenDecimals,
        redeem,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
}
