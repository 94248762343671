import React from 'react';
import PropTypes from 'prop-types';
import { brandCaps } from '../../utils/brand';

const propTypes = {
  handleHalofiChallenge: PropTypes.func.isRequired,
  handleCommunityChallenge: PropTypes.func.isRequired,
  handleCompletedChallenge: PropTypes.func.isRequired,
};

function ChallengeBar({ handleHalofiChallenge, handleCommunityChallenge, handleCompletedChallenge }) {
  return (
    <div className="challenges-bar">
      <div className="challenges" onClick={handleHalofiChallenge}>
        <p>{brandCaps} Challenges</p>
      </div>
      <div onClick={handleCommunityChallenge} className="challenges">
        <p>Unique Challenges</p>
      </div>
      <div onClick={handleCompletedChallenge} className="challenges">
        <p>Past & Ongoing</p>
      </div>
    </div>
  );
}

ChallengeBar.propTypes = propTypes;
export default ChallengeBar;
