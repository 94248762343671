import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIdentityName } from 'redux/reducers/dashboard';
import { setLoaderDashboardProfile } from 'redux/reducers/feedbacks';
import { AddressResolverProviderContext } from 'providers/AddressResolverProvider';
import SearchBar from 'components/elements/SearchBar';

export default function PlayerSearch() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const errorMessageRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const addressResolverProvider = useContext(AddressResolverProviderContext);
  const history = useHistory();

  const onChange = (e) => {
    setSearch(e.target.value);
    setErrorMessage('');
  };

  const setUserIdentityName = ({ userAddress, userName }) => {
    dispatch(setIdentityName({ userAddress, userName }));
  };

  const searchPlayer = async () => {
    if (!search) {
      setErrorMessage('Enter an address, ENS, nomspace or unstoppable domain name');
      return;
    }
    const resolvedAddress = await addressResolverProvider.resolveAddress(search);
    if (resolvedAddress instanceof Error) {
      setErrorMessage('Could not resolve player address');
      setSearch('');
      return;
    }
    const { userAddress, userName } = resolvedAddress;

    setUserIdentityName({ userAddress, userName });
    dispatch(setLoaderDashboardProfile(true));
    history.push(`/dashboard/${userAddress}`);
  };

  return (
    <>
      <SearchBar
        errorMessageRef={errorMessageRef}
        placeholder="Search for a player"
        onChange={onChange}
        search={search}
        onReset={() => 'Do nothing'}
        errorMessage={errorMessage}
        onSearch={searchPlayer}
        type="search-bar-withIcon"
        className="search-bar-withIcon search-container"
        searchButtonID="share-player-profile"
      />
    </>
  );
}
