import React, { useContext } from 'react';

import { DefaultPopover } from 'components/elements/Popover';
import { HashContext } from 'providers/HashProvider';
import SlippagePopoverContent from './SlippagePopoverContent';
import useSlippageForm from './hooks/useSlippageForm';

function SlippagePopover() {
  const [form, submit, start] = useSlippageForm();
  const { darkTheme } = useContext(HashContext);

  return (
    <DefaultPopover
      onHide={submit}
      onShow={start}
      bsPrefix={`popover ${darkTheme ? 'theme--dark' : 'theme--default'}`}
      placement="top-end"
      content={<SlippagePopoverContent form={form} />}
    >
      <button type="button" className="slippage-config-button">
        ⚙️
      </button>
    </DefaultPopover>
  );
}

export default SlippagePopover;
