/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gameInfoDTO } from 'redux/service/gameInfoDTO';
import { setLoaderInitGame, setLoaderUpdatingGame } from 'redux/reducers/feedbacks';
import {
  setGameInfo,
  setGameStats,
  setIsFirstSegment,
  setLastSegment,
  setFirstSegmentStart,
  setFirstSegmentEnd,
  setNextSegmentEnd,
  setLastSegmentEnd,
  setAllSegmentTimes,
} from 'redux/reducers/game';
import { setPlayersStats } from 'redux/reducers/players';
import { setPool, setIsCelo } from 'redux/reducers/pool';
import { isCeloNetwork } from 'utils/utilities';
import { sdkJsClient } from 'service/sdk';

export default function useGetGameInfo(info) {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const loaderUpdatingGame = useSelector((state) => state.feedbacks.loaderUpdatingGame);

  useEffect(() => {
    if ((!info?.contract && !loaderUpdatingGame) || fetching) {
      return;
    }
    setFetching(true);
    // eslint-disable-next-line no-unused-vars

    (async function () {
      try {
        dispatch(setPool(info));
        dispatch(setIsCelo(isCeloNetwork(info.name)));

        const [gameInfo, gameStats] = await Promise.all([
          sdkJsClient.getGames(info.contract),
          sdkJsClient.getStats(info.contract),
        ]);

        dispatch(setGameStats(gameStats));
        dispatch(setGameInfo(gameInfo));

        const gameInfoParsed = gameInfoDTO.fromApiToState(gameInfo);
        dispatch(setIsFirstSegment(gameInfoParsed.isFirstSegment));
        dispatch(setLastSegment(gameInfoParsed.lastSegment));
        dispatch(setFirstSegmentStart(gameInfoParsed.firstSegmentStart));
        dispatch(setFirstSegmentEnd(gameInfoParsed.firstSegmentEnd));
        dispatch(setNextSegmentEnd(gameInfoParsed.nextSegmentEnd));
        dispatch(setLastSegmentEnd(gameInfoParsed.lastSegmentEnd));
        dispatch(setAllSegmentTimes(gameInfoParsed.allSegmentTimes));
        dispatch(setLoaderUpdatingGame(false));
        dispatch(setLoaderInitGame(true));

        sdkJsClient.getPlayersStats(info.contract).then((res) => {
          dispatch(setPlayersStats(res));
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setFetching(false);
      }
    })();
  }, [info, loaderUpdatingGame]);
}
