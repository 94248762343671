import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setConfiguredSlippage } from 'redux/reducers/user';
import { getNetworkId } from 'redux/selectors/pool.selector';
import { celoID } from 'utils/networks';

import { DefaultSlippage, DefaultSlippageCelo } from '../SlippageConfiguration';

function useSlippageForm() {
  const dispatch = useDispatch();
  const networkId = useSelector(getNetworkId);

  const defaultSlippage = Number(networkId) === celoID ? DefaultSlippageCelo : DefaultSlippage;

  const form = useForm({
    defaultValues: {
      slippageInput: defaultSlippage,
      slippageDefaultInput: defaultSlippage,
      isUsingCustomValue: false,
    },
    mode: 'all',
  });

  const submit = form.handleSubmit((state) => {
    if (state.isUsingCustomValue) {
      dispatch(setConfiguredSlippage(state.slippageInput));
    } else {
      dispatch(setConfiguredSlippage(state.slippageDefaultInput));
    }
  });

  const start = () => {
    form.reset();
  };

  return [form, submit, start];
}

export default useSlippageForm;
