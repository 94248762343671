import dayjs from 'dayjs';
import { truncate, displaySegment, titleCase } from 'utils/utilities';
import { bigNumberFrom } from 'utils/bignumber';

import { ROUND_MEASURE } from './constants';

export const MaxDecimalPrecision = {
  matic: 3,
  wmatic: 3,
  eth: 6,
  weth: 6,
  dai: 3,
  crv: 3,
  cusd: 2,
  celo: 3,
  flipp3ning: 2,
  gd: 5,
  wptg: 2,
  must: 2,
  bank: 2,
  percentage: 2,
  wbtc: 8,
  ghst: 3,
  usdt: 6,
  usd: 2,
  gdai: 5,
  usdc: 3,
  usdbc: 3,
  usdchalofi: 3,
  cmt: 3,
  link: 3,
};

export const MinDecimalPrecision = {
  matic: 1,
  wmatic: 1,
  eth: 2,
  usdt: 0,
  weth: 2,
  dai: 0,
  wbtc: 2,
  crv: 1,
  gd: 0,
  cusd: 0,
  celo: 0,
  flipp3ning: 1,
  wptg: 0,
  must: 2,
  bank: 0,
  percentage: 2,
  ghst: 1,
  usd: 2,
  gdai: 0,
  usdc: 0,
  usdbc: 0,
  usdchalofi: 0,
  cmt: 0,
  link: 2,
};

const DefaultPrecision = 1;

export function format(val, coin, options = {}) {
  const coinName = coin && coin.toLowerCase();

  const customFormatting = options[coinName] ?? {};

  const minimumFractionDigits =
    customFormatting.minimumFractionDigits ?? MinDecimalPrecision[coinName] ?? DefaultPrecision;

  const maximumFractionDigits =
    customFormatting.maximumFractionDigits ?? MaxDecimalPrecision[coinName] ?? DefaultPrecision;

  return formatToMaxAndMinDigits(val, minimumFractionDigits, maximumFractionDigits);
}

export function truncateAndformat(val, precision) {
  const truncatedVal = truncate(val, precision);

  return formatToMaxAndMinDigits(truncatedVal, precision, precision);
}

export function truncateAndFormatCoin(val, coin, options = {}) {
  const coinName = coin && coin.toLowerCase();

  const customFormatting = options[coinName] ?? {};

  const minimumFractionDigits =
    customFormatting.minimumFractionDigits ?? MinDecimalPrecision[coinName] ?? DefaultPrecision;

  const maximumFractionDigits =
    customFormatting.maximumFractionDigits ?? MaxDecimalPrecision[coinName] ?? DefaultPrecision;

  const truncatedVal = truncate(val, maximumFractionDigits);

  return formatToMaxAndMinDigits(truncatedVal, minimumFractionDigits, maximumFractionDigits);
}

export function formatToMaxAndMinDigits(val, minimumFractionDigits, maximumFractionDigits) {
  const numberStr = String(val);
  if (!numberStr.includes('.')) {
    return val;
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  })
    .format(Number(val))
    .replaceAll(',', '');
}

export function getMaxPrecision(coin) {
  return Number(MaxDecimalPrecision[String(coin)?.toLowerCase()] || DefaultPrecision);
}

export function getMinAmountForCoin(coin) {
  const precision = getMaxPrecision(coin);
  return bigNumberFrom(10).exponentiatedBy(bigNumberFrom(precision).negated());
}

export function getDepositRoundMeasure(gameInfo) {
  const { roundMeasure, paymentCount, segmentLength } = gameInfo;
  const roundLength = dayjs.duration(segmentLength, 'seconds');
  let roundLengthMeasure = null;

  if (roundMeasure === ROUND_MEASURE.Hours) {
    roundLengthMeasure = roundLength.asHours();
  }

  if (roundMeasure === ROUND_MEASURE.Days) {
    roundLengthMeasure = roundLength.asDays();
  }

  if (roundMeasure === ROUND_MEASURE.Weeks) {
    roundLengthMeasure = roundLength.asWeeks();
  }

  if (roundMeasure === ROUND_MEASURE.Months) {
    roundLengthMeasure = roundLength.asMonths();
  }

  return {
    depositAndFrequency: `${displaySegment(~~paymentCount - 1)} /  ${truncateAndformat(
      roundLengthMeasure || 0,
      2
    )} ${titleCase(roundMeasure)}`,
    frequency: `${truncateAndformat(roundLengthMeasure || 0, 2)} ${titleCase(roundMeasure)}`,
  };
}
