import { useDispatch, useSelector } from 'react-redux';
import { updateStats } from 'redux/reducers/game';
import {
  setLoaderJoinGame,
  setSuccessJoinGame,
  setErrorNotWhitelisted,
  setErrorRefusedSignature,
  setErrorTransaction,
  setErrorTransactionMessage,
} from 'redux/reducers/feedbacks';
import { setRefetch, setStatus } from 'redux/reducers/user';
import { updatePlayersStats } from 'redux/reducers/players';
import { status } from 'utils/utilities';
import { sdkJsClient } from 'service/sdk';
import useWriteContract from 'providers/ContractProvider/useWriteContract';
import { getNetworkId } from 'redux/selectors/pool.selector';

import { useSendEvent } from './useSendEvent';

export default function useJoinGame() {
  const dispatch = useDispatch();

  const contract = useSelector((state) => state.pool.info.contract);
  const hasWhitelist = useSelector((state) => state.pool.info.hasWhitelist);
  const winners = useSelector((state) => state.players.stats.winners);
  const dropouts = useSelector((state) => state.players.stats.dropouts);
  const hasWithdrawn = useSelector((state) => state.user.player.withdrawn);
  const playersCount = useSelector((state) => state.game.stats.playersCount);
  const usersAddress = useSelector((state) => state.user.address);
  const networkId = useSelector(getNetworkId);
  const writeContract = useWriteContract();

  const logEvent = useSendEvent();

  const joinGame = async () => {
    dispatch(setLoaderJoinGame(true));

    try {
      if (hasWhitelist) {
        const proofs = await sdkJsClient.getProofs(contract, usersAddress);
        await writeContract.joinWhitelistedGame(proofs);
      } else {
        await writeContract.joinGame();
      }
      dispatch(setSuccessJoinGame({ success: true, id: contract }));
      dispatch(setStatus(status.registered));
      dispatch(updatePlayersStats({ key: 'winners', value: ~~winners + 1 }));
      if (!hasWithdrawn) {
        dispatch(updateStats({ playersCount: `${~~playersCount + 1}` }));
      }
      if (hasWithdrawn) {
        dispatch(updatePlayersStats({ key: 'dropouts', value: ~~dropouts - 1 }));
      }
      dispatch(setRefetch(true));
    } catch (err) {
      console.error(err);
      if (err?.message === 'Address is not on whitelist') {
        dispatch(setErrorNotWhitelisted(true));
      } else if (err?.message?.includes('User denied')) {
        dispatch(setErrorRefusedSignature(true));
      } else {
        dispatch(setErrorTransaction(true));
        if (err?.message.includes('max fee per gas less than block base fee')) {
          dispatch(
            setErrorTransactionMessage(
              'Minimum Network fee is higher than the Transaction Speed set. Increase the Transaction Speed and try again'
            )
          );
        } else {
          dispatch(setErrorTransactionMessage(err?.message));
        }
        logEvent('JOIN_TRANSACTION_ERROR', networkId, {
          errMsg: err?.message,
          usersAddress,
          navigator: window.navigator.userAgent,
        });
      }
    } finally {
      dispatch(setLoaderJoinGame(false));
    }
  };

  return [joinGame];
}
