import React, { useContext, useEffect } from 'react';
import { HashContext } from 'providers/HashProvider';
import { Overlay } from 'react-bootstrap';
import Button from 'components/elements/Button';
import Input from 'components/elements/Input';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  errorMessage: PropTypes.string,
  errorMessageRef: PropTypes.object,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  searchButtonText: PropTypes.string,
  searchButtonID: PropTypes.string,
  searchButtonType: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  search: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  onReset: () => null,
  placeholder: '',
  className: '',
  errorMessage: '',
  errorMessageRef: {},
  searchButtonText: '',
  searchButtonID: '',
  searchButtonType: '',
  label: '',
  search: '',
  type: '',
};

const searchBarTypes = {
  default: 'search-bar-default',
  large: 'search-bar-large',
  withIcon: 'search-bar-withIcon',
};

function ErrorMessage({ show, message, errorMessageRef }) {
  const { darkTheme } = useContext(HashContext);
  return (
    <Overlay target={errorMessageRef.current} show={show} placement="bottom">
      {({ ...props }) => (
        <div className={darkTheme ? 'theme--dark' : 'theme--default'} {...props}>
          <p className="search-error-message">{message}</p>
        </div>
      )}
    </Overlay>
  );
}

function SearchBar({
  onSearch,
  placeholder,
  label,
  errorMessage,
  errorMessageRef,
  searchButtonText,
  searchButtonID,
  type,
  search,
  onReset,
  className,
  ...props
}) {
  const classes = classNames(className);
  const isWithIcon = type === searchBarTypes.withIcon;

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        onSearch();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [search, onSearch]);

  return (
    <div className={classes}>
      <ErrorMessage show={!!errorMessage} message={errorMessage} errorMessageRef={errorMessageRef} />
      {isWithIcon && (
        <div className="search-input-wrapper">
          <div className="search-input">
            <Input
              type="text"
              wrappedClassName="input-group"
              value={search}
              innerRef={errorMessageRef}
              placeholder={placeholder}
              {...props}
            />
          </div>
          <div className={search ? 'input-wrapped-icon-active' : 'input-wrapped-icon'} onClick={onReset} />
        </div>
      )}
      {label && <span>{label}</span>}
      {!isWithIcon && (
        <Input
          wrappedClassName="input-group"
          innerRef={errorMessageRef}
          value={search}
          type="text"
          placeholder={placeholder}
          {...props}
        />
      )}
      {!isWithIcon && (
        <Button
          onClick={onSearch}
          className="button button-primary button-primary-outline button-sm"
          type="button"
          id={searchButtonID}
        >
          {searchButtonText}
        </Button>
      )}
    </div>
  );
}

SearchBar.propTypes = propTypes;
SearchBar.defaultProps = defaultProps;

export default SearchBar;
