import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlayerGames, fetchPlayerGGScore, resetDashboard } from 'redux/reducers/dashboard';
import CenterLoading from 'components/elements/CenterLoading';
import { sdkJsClient } from 'service/sdk';
import {
  getPreviousGames,
  getPendingWithdrawnGames,
  getActiveGames,
  getLoadingPlayerGames,
  getIsDashboardWithdrawing,
  getIdentityName,
  getPendingWithdrawnLostGames,
  getPendingWithdrawnWonGames,
} from 'redux/selectors/dashboard.selector';
import { getUserAddress } from 'redux/selectors/user.selector';
import { getGamesInfo } from 'redux/selectors/game.selector';
import { mappedToObject, displayEnsNameOrAddress } from 'utils/utilities';
import WithdrawModal from 'views/NewGame/components/Modals/WithdrawModal/WithdrawModal';
import { HashContext } from 'providers/HashProvider';
import ContractProvider from 'providers/ContractProvider/ContractProvider';
import useScrollToTop from 'Hooks/useScrollToTop';
import useGetGames from '../NewGame/hooks/useGetGames';

import PlayerSearch from './components/PlayerSearch/PlayerSearch';
import PlayerDashboardProfile from './components/PlayerDashboardProfile/PlayerDashboardProfile';

function getAllActiveGames(games) {
  return games
    .filter((game) => {
      const notEnded = game.ends ? new Date(game.ends).getTime() > Date.now() : true;
      return notEnded && !game.isChild && !game.isHidden && !game.futureGame && ~~game.currentSegment === 0;
    })
    .sort((a, b) => (Date.now() - new Date(b.closes) || -1) - (Date.now() - new Date(a.closes) || -1));
}

function getGameStats(games) {
  return games.map((game) => {
    return sdkJsClient.getStats(game.contract);
  });
}

async function getLatestGamePlayerNotJoined(playerActiveGames, games, lastGameIndex, isWalletConnected) {
  const activeGames = mappedToObject(playerActiveGames, 'gameId');
  const allGames = getAllActiveGames(games);

  const playerActiveOrNoGame = allGames.filter((game) => {
    return activeGames[game.contract] === undefined;
  });

  const playerActiveOrGames = isWalletConnected
    ? playerActiveOrNoGame
    : allGames.filter((game) => game.contract !== undefined);

  const gameStats = await Promise.all(getGameStats([...playerActiveOrGames].slice(0, lastGameIndex)));
  return playerActiveOrGames.map((game, i) => {
    return { ...game, ...gameStats[i] };
  });
}

function getLastGameIndex(isWalletConnected, isNewJoiner) {
  const maxLastIndex = 4;
  const minLastIndex = 1;

  if (!isWalletConnected) {
    return maxLastIndex;
  }
  if (isNewJoiner) {
    return maxLastIndex;
  }
  return minLastIndex;
}

function PlayerDashboard() {
  useGetGames();
  // 👇️ scroll to top on page load
  useScrollToTop();
  const dispatch = useDispatch();
  const [gameNotLoading, setGameNotLoading] = useState(false);
  const [activeGames, setActiveGames] = useState([]);
  const [playerBadges, setPlayerBadges] = useState([]);
  const [liveGames, setLiveGames] = useState([]);
  const { withdrawPoolInfo } = useContext(HashContext);

  const playerActiveGames = useSelector(getActiveGames);
  const previousGames = useSelector(getPreviousGames);
  const pendingWithdrawnGames = useSelector(getPendingWithdrawnGames);
  const isLoadingGames = useSelector(getLoadingPlayerGames);
  const identityName = useSelector(getIdentityName);
  const pendingWithdrawnWonGames = useSelector(getPendingWithdrawnWonGames);
  const pendingWithdrawnLostGames = useSelector(getPendingWithdrawnLostGames);

  const isDashboardWithdrawing = useSelector(getIsDashboardWithdrawing);
  const games = useSelector(getGamesInfo);
  const userAddress = useSelector(getUserAddress);
  const gameAlreadyLoaded = !gameNotLoading && activeGames.length === 0;

  const isGamesLoaded = !isLoadingGames && !gameAlreadyLoaded && Object.values(games).length > 0;
  const isWalletConnected = !!userAddress;

  let { playerAddress } = useParams();

  if (!playerAddress && !!userAddress) {
    playerAddress = userAddress;
  }

  const identityNameToDisplay = !identityName[playerAddress?.toLowerCase()]
    ? playerAddress
    : identityName[playerAddress?.toLowerCase()];
  const isProfileOwner = playerAddress?.toLowerCase() === userAddress.toLowerCase();
  const showDisplayLoading = isLoadingGames && !isDashboardWithdrawing;

  const isNewJoiner = isProfileOwner && ![...activeGames, ...previousGames, ...pendingWithdrawnGames].length > 0;

  const gameLoadingTimeout = setTimeout(() => {
    if (isLoadingGames && isWalletConnected) {
      setGameNotLoading(isLoadingGames);
    } else {
      setGameNotLoading(true);
    }
  }, 4000);

  const getLatestActiveGame = useCallback(async () => {
    const lastGameIndex = getLastGameIndex(isWalletConnected, isNewJoiner);

    const playerGames = await getLatestGamePlayerNotJoined(
      activeGames,
      Object.values(games) ?? [],
      lastGameIndex,
      isWalletConnected
    );

    setLiveGames(playerGames);
    // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  }, [JSON.stringify(activeGames), JSON.stringify(Object.keys(games))]);

  useEffect(() => {
    getLatestActiveGame();
  }, [getLatestActiveGame]);

  useEffect(() => {
    async function init() {
      if (!playerAddress) {
        return;
      }
      const playerGameBadges = await sdkJsClient.getPlayerBadges(playerAddress);
      setPlayerBadges(playerGameBadges);
    }
    init();
  }, [playerAddress]);

  useEffect(() => {
    const activeGameOrNoGame = playerActiveGames[playerAddress] ?? [];
    setActiveGames(activeGameOrNoGame);
    // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  }, [JSON.stringify(Object.keys(playerActiveGames))]);

  useEffect(() => {
    if (playerAddress) {
      if (!playerActiveGames[playerAddress]) {
        dispatch(fetchPlayerGames(playerAddress));
      }
      dispatch(fetchPlayerGGScore(playerAddress));
    }

    return () => {
      dispatch(resetDashboard());
      clearTimeout(gameLoadingTimeout);
      setGameNotLoading(false);
    };
    // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  }, [playerAddress, dispatch]);

  if (!isGamesLoaded) {
    return <CenterLoading />;
  }

  const openJoinableGames = getAllActiveGames(Object.values(games) ?? []);
  const playerOpenGames = getAllActiveGames(activeGames ?? []);
  const profileTitle = isProfileOwner ? 'Welcome back' : displayEnsNameOrAddress(identityNameToDisplay);

  return (
    <div className="player-dashboard-container container">
      <div className="header">
        <h4 className="m-0">{profileTitle}</h4>
        <PlayerSearch />
      </div>

      <PlayerDashboardProfile
        openJoinableGames={openJoinableGames}
        playerOpenGames={playerOpenGames}
        liveGames={liveGames}
        isNewJoiner={isNewJoiner}
        playerActiveGames={[...activeGames, ...pendingWithdrawnLostGames, ...pendingWithdrawnWonGames]}
        games={games}
        playerAddress={playerAddress}
        previousGames={previousGames}
        playerBadges={playerBadges}
        isLoadingGames={isLoadingGames}
        showDisplayLoading={showDisplayLoading}
        isProfileOwner={isProfileOwner}
        isWalletConnected={isWalletConnected}
      />
      {Object.keys(withdrawPoolInfo).length > 0 && (
        <ContractProvider info={withdrawPoolInfo}>
          <WithdrawModal />
        </ContractProvider>
      )}
    </div>
  );
}

export default React.memo(PlayerDashboard);
