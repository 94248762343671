import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import Tooltip from 'components/elements/Tooltip';
import { getDropOutsCount, getGhostCount, getWaitingCount, getWinnersCount } from 'redux/selectors/players.selector';
import RenderPlayers from './RenderPlayer';
import useGetPlayers from './hooks/useGetPlayers';

const Players = () => {
  const isCompleted = useSelector((state) => state.game.stats.isCompleted);
  const playersFetched = useSelector((state) => state.players.list.length);
  const winnersCount = useSelector(getWinnersCount);
  const waitingCount = useSelector(getWaitingCount);
  const dropOutsCount = useSelector(getDropOutsCount);
  const ghostCount = useSelector(getGhostCount);
  const [getPlayers] = useGetPlayers();

  if (!playersFetched) {
    return <h4 className="text-center">Loading players...</h4>;
  }

  return (
    <>
      <Row>
        <CircleData
          label={isCompleted ? `Winners` : `Winning`}
          data={winnersCount}
          tooltipText="All players who have made the latest deposit."
        />

        <CircleData
          label="Waiting"
          data={waitingCount}
          tooltipText="Players that are live, but still need to deposit this round."
        />
        <CircleData
          label={`Ghosts `}
          data={ghostCount}
          tooltipText="Players who have missed a deposit and are no longer live in the game."
        />
        <CircleData
          label="Drop-outs"
          data={dropOutsCount}
          tooltipText="Players who decided to withdraw their funds early."
        />
      </Row>
      <Row className="pt-15">
        <RenderPlayers getPlayers={getPlayers} />
      </Row>
    </>
  );
};

const CircleData = (props) => {
  const textClass = classNames(props.data?.length > 3 ? 'small-font-size' : 'large-font-size', 'mt-15 mb-0');
  const displayContent = () => (
    <div className="players-stats-circle m-auto text-center">
      <p className={textClass}>{props.data}</p>
    </div>
  );
  return (
    <Col sm={6} md={3}>
      <h5 className="text-center">{props.label}</h5>
      <Tooltip placement="bottom" content={props.tooltipText} displayContent={displayContent} />
    </Col>
  );
};

export default Players;
