import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../assets/scss/react-tabs.css';

const TabsGroup = (props, i) => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="container" key={i}>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          {props.children.map((children, index) => (
            <>
              <Tab key={`header${index}`}>
                {children.props.header}{' '}
                {children.props.header === 'Admin' && children.props.adminAlert && <span className="admin-warn" />}
              </Tab>
            </>
          ))}
        </TabList>

        {props.children.map((Children, index) => (
          <TabPanel key={`tab${index}`}>{Children}</TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default TabsGroup;
