import imgCeloHeader from 'assets/images/game/celo-label.svg';
import imgPolygonHeader from 'assets/images/game/polygon-label.svg';
import imgBaseHeader from 'assets/images/game/base.svg';

import { docsURL } from './brand';

export const networksIcon = {
  celo: imgCeloHeader,
  polygon: imgPolygonHeader,
  base: imgBaseHeader,
  baseGoerli: imgBaseHeader,
  celoIconAndName: imgCeloHeader,
  polygonIconAndName: imgPolygonHeader,
  baseIconAndName: imgBaseHeader,
  baseGoerliIconAndName: imgBaseHeader,
};

export const gameNetworkIcons = {
  polygon: imgPolygonHeader,
  celo: imgCeloHeader,
  base: imgBaseHeader,
  baseGoerli: imgBaseHeader,
};

export const polygonID = 137;
export const celoID = 42220;
export const alfajoresID = 44787;
export const ethereumID = 1;
export const baseID = 8453;
export const baseGoerliID = 84531;

export const networkData = {
  [ethereumID]: {
    name: 'ethereum',
    rpcURL: process.env.REACT_APP_MAINNET_RPC,
  },
  // 80001: {
  //   name: "mumbai",
  //   nameWeb3: "mumbai",
  //   display: "Mumbai (testnet)",
  //   baseExplorerURL: `https://explorer-mumbai.maticvigil.com/`,
  //   ethLink: "https://faucet.matic.network/",
  //   stableToken: "DAI",
  //   baseToken: "MATIC",
  //   networkId: 80001,
  //   addNetworkToWallet: false,
  //   daiLink: "https://app.dev.pods.finance/utils",
  //   rpcURL: "https://rpc-mumbai.maticvigil.com/v1/0fce9f98acaea94baab3ce74df45a4edc1852a7c",
  // },
  [polygonID]: {
    name: 'polygon',
    nameWeb3: 'matic',
    networkId: polygonID,
    display: 'Polygon Mainnet',
    rpcURL: process.env.REACT_APP_POLYGON_RPC,
    baseExplorerURL: `https://polygonscan.com/`,
    docsLink: `${docsURL}/hub/getting-started/connect-on-polygon`,
    ethLink: `${docsURL}/hub/getting-started/getting-cryptocurrency-tokens/obtaining-matic-usdc-dai-weth-and-other-polygon-tokens#how-to-obtain-matic-on-polygon`,
    daiLink: `${docsURL}/hub/getting-started/getting-cryptocurrency-tokens/obtaining-matic-usdc-dai-weth-and-other-polygon-tokens#how-to-obtain-dai-on-polygon`,
    baseToken: 'MATIC',
  },
  [alfajoresID]: {
    name: 'alfajores',
    nameWeb3: 'alfajores',
    networkId: alfajoresID,
    display: 'Alfajores (Testnet)',
    rpcURL: 'https://alfajores-forno.celo-testnet.org',
    baseExplorerURL: `https://alfajores-blockscout.celo-testnet.org/`,
    docsLink: `${docsURL}/hub/getting-started`,
    ethLink: 'https://celo.org/developers/faucet',
    daiLink: 'https://celo.org/developers/faucet',
    baseToken: 'CELO',
  },
  [celoID]: {
    name: 'celo',
    nameWeb3: 'celo',
    networkId: celoID,
    display: 'Celo',
    rpcURL: process.env.REACT_APP_CELO_RPC,
    baseExplorerURL: `https://celoscan.io/`,
    docsLink: `${docsURL}/hub/getting-started/connect-on-celo`,
    ethLink: `${docsURL}/hub/getting-started/getting-cryptocurrency-tokens/obtaining-celo-cusd-ceur-and-other-celo-tokens#how-to-obtain-celo`,
    daiLink: `${docsURL}/hub/getting-started/getting-cryptocurrency-tokens/obtaining-celo-cusd-ceur-and-other-celo-tokens#how-to-obtain-cusd-ceur-creal-on-celo`,
    baseToken: 'CELO',
  },
  [baseID]: {
    name: 'base',
    nameWeb3: 'base',
    networkId: baseID,
    display: 'Base',
    rpcURL: process.env.REACT_APP_BASE_RPC,
    baseExplorerURL: `https://basescan.org/`,
    docsLink: `${docsURL}/hub/getting-started/connect-on-base`,
    ethLink: `${docsURL}/hub/getting-started/getting-cryptocurrency-tokens/obtaining-celo-cusd-ceur-and-other-celo-tokens#how-to-obtain-celo`,
    daiLink: `${docsURL}/hub/getting-started/getting-cryptocurrency-tokens/obtaining-celo-cusd-ceur-and-other-celo-tokens#how-to-obtain-cusd-ceur-creal-on-celo`,
    baseToken: 'ETH',
  },
  [baseGoerliID]: {
    name: 'baseGoerli',
    nameWeb3: 'baseGoerli',
    networkId: baseGoerliID,
    display: 'BaseGoerli',
    rpcURL: process.env.REACT_APP_BASE_GOERLI_RPC,
    baseExplorerURL: `https://goerli.basescan.org/`,
    docsLink: `${docsURL}/hub/getting-started/connect-on-base`,
    ethLink: `${docsURL}/hub/getting-started/getting-cryptocurrency-tokens/obtaining-celo-cusd-ceur-and-other-celo-tokens#how-to-obtain-celo`,
    daiLink: `${docsURL}/hub/getting-started/getting-cryptocurrency-tokens/obtaining-celo-cusd-ceur-and-other-celo-tokens#how-to-obtain-cusd-ceur-creal-on-celo`,
    baseToken: 'ETH',
  },
};

export const networkIdNames = {
  [ethereumID]: 'Ethereum',
  [polygonID]: 'Polygon',
  [alfajoresID]: 'Alfajores',
  [celoID]: 'Celo',
  [baseID]: 'Base',
  [baseGoerliID]: 'BaseGoerli',
};

export const hasWaitingRound = true;

/*
**** Notes ***

- rewardToken is the tokens recieved from the lending protocol itself
- rewardToken2 is an addition token added to the pool manually
- poolInterest is interest generated in the "stableToken" - note that we will start using volatile tokens as well

*/
