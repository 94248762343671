import React, { useState, useContext } from 'react';
import { Web3Context } from 'providers/Web3Provider';
import { useSelector } from 'react-redux';
import { displayEnsNameOrAddress } from 'utils/utilities';
import { networkIdNames, polygonID, celoID, baseID, baseGoerliID } from 'utils/networks';
import ButtonGroup from 'components/elements/ButtonGroup';
import Button from 'components/elements/Button';
import celo from 'assets/images/game/celo-label.svg';
import polygon from 'assets/images/game/polygon-label.svg';
import base from 'assets/images/game/base.svg';
import { IdentityContext } from 'providers/IdentityProvider';
import { useChainModal } from '@rainbow-me/rainbowkit';
import { WalletDropdown } from './WalletDropdown';

const networkSvgMap = {
  [polygonID]: polygon,
  [celoID]: celo,
  [baseID]: base,
  [baseGoerliID]: base,
};

export function WalletButton() {
  const Web3Client = useContext(Web3Context);
  const IdentityClient = useContext(IdentityContext);
  const usersAddress = useSelector((state) => state.user.address);
  const netId = useSelector((state) => state.user.networkId);

  const [open, setOpen] = useState(false);

  const { openChainModal } = useChainModal();

  const connect = async () => {
    try {
      await Web3Client.init();
    } catch (err) {
      console.error(err);
    }
  };

  if (usersAddress) {
    return (
      <>
        <ButtonGroup className="wallet-group-button">
          {networkIdNames[netId] && (
            <Button size="sm" className="network-button" onClick={openChainModal} id="network-button">
              {networkSvgMap[netId] ? <img src={networkSvgMap[netId]} alt="Polyon" /> : networkIdNames[netId]}
            </Button>
          )}

          <Button className="address-button" onClick={() => setOpen(!open)} id="address-button">
            <div className="light" />
            {displayEnsNameOrAddress(IdentityClient.identityName)}
            <div className="arrow-down" />
          </Button>
          <WalletDropdown
            userAddress={usersAddress}
            open={open}
            logOut={() => {
              Web3Client.logOut();
              setOpen(false);
            }}
            close={() => setOpen(false)}
          />
        </ButtonGroup>
      </>
    );
  }

  return (
    <Button className="button button-primary" onClick={connect} id="header-connect-wallet">
      Connect Wallet
    </Button>
  );
}
