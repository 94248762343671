import React from 'react';

import { useSelector } from 'react-redux';
import { isHighSlippageError, isLowSlippageError, isZeroSlippageError } from 'redux/selectors/user.selector';

function SlippageError() {
  const lowSlippage = useSelector(isLowSlippageError);
  const highSlippage = useSelector(isHighSlippageError);
  const zeroSlippage = useSelector(isZeroSlippageError);
  return (
    <>
      {highSlippage && (
        <span className="slippage-error">
          ⚠️ High slippage means you may lose part of your deposit. Are you sure? ⚠️
        </span>
      )}
      {lowSlippage && (
        <span className="slippage-error">⚠️ Low slippage means your transaction might fail. Are you sure? ⚠️</span>
      )}
      {zeroSlippage && (
        <span className="slippage-error">❌ The slippage configuration is required to be more than zero</span>
      )}
    </>
  );
}

export default SlippageError;
