import React, { useContext } from 'react';
import ContractProvider from 'providers/ContractProvider/ContractProvider';

import { HashContext } from 'providers/HashProvider';
import { titleCase, getStatusLabel } from 'utils/utilities';
import CommunityPoolCard from 'components/elements/CommunityPoolCard';
import useWithdrawDashboard from './hooks/useWithdrawDashboard';

function GameWithdraw({ gameInfo, isProfileOwner, progressBarValue }) {
  const [dashboardWithdraw, isWithdrawing, isDashboardWithdrawing] = useWithdrawDashboard(gameInfo);
  const { withdrawPlayerInfo, withdrawGameInfo, withdrawnGames } = useContext(HashContext);

  const { gameName, gameNameShort, gameNumber, gameImage, paymentAmount, depositToken, mechanismType, name } = gameInfo;
  const { displayId } = withdrawGameInfo;
  const { withdrawn } = withdrawPlayerInfo;

  const alreadyWithdrawn = (gameNumber === displayId && withdrawn) || withdrawnGames.includes(gameNumber);

  return (
    <CommunityPoolCard
      heading={gameNameShort ?? gameName}
      nftImage={gameImage}
      subHeading={`${paymentAmount} ${depositToken && depositToken.toUpperCase()} | ${titleCase(
        mechanismType
      )} |  ${titleCase(name)}`}
      poolNumber={gameNumber}
      network={name}
      buttonText={isProfileOwner && !alreadyWithdrawn && 'Withdraw'}
      buttonId="deposit-now-pool-card"
      status={getStatusLabel(gameInfo)}
      onClick={dashboardWithdraw}
      isButtonDisabled={!isWithdrawing && isDashboardWithdrawing}
      className="button-tertiary-outline"
      loading={isWithdrawing}
      showGameProgress
      progressBarValue={progressBarValue}
    />
  );
}

function WrappedGameWithdraw({ gameInfo, isProfileOwner, progressBarValue }) {
  return (
    <ContractProvider info={gameInfo}>
      <GameWithdraw gameInfo={gameInfo} isProfileOwner={isProfileOwner} progressBarValue={progressBarValue} />
    </ContractProvider>
  );
}

export default WrappedGameWithdraw;
