import dayjs from 'dayjs';
import { calendarInvites } from 'Hooks/game';
import { hasWaitingRound } from 'utils/networks';
import { displaySegment, getGameVersionType } from 'utils/utilities';

export function getAllSegmentDateData(currentIndex, parsedGameInfo) {
  const currentRound = currentIndex + 1;

  const { segmentLength, waitingRoundLength, totalSegmentCount, firstSegmentEnd } = parsedGameInfo;
  const lastIndexDelimiter = 1;

  const totalRounds = ~~totalSegmentCount + 1;

  if (currentRound === totalRounds) {
    const lastIndexDelimiterBeforeWaitingRound = 2;

    const roundDuration = (currentIndex - lastIndexDelimiterBeforeWaitingRound) * segmentLength;
    const poolWaitingRoundLength = parseInt(waitingRoundLength) || 0;

    return firstSegmentEnd.add(poolWaitingRoundLength + roundDuration, 's');
  }

  return firstSegmentEnd.add((currentIndex - lastIndexDelimiter) * segmentLength, 's');
}

function getLastOrNextSegmentEnd(parsedGameInfo) {
  const { waitingRoundLength, firstSegmentStart, segmentLength, currentSegment, totalSegmentCount, paymentCount } =
    parsedGameInfo;
  const isV2Game = getGameVersionType(parsedGameInfo);

  const currentRound = displaySegment(currentSegment);
  const totalRounds = isV2Game ? parseInt(paymentCount) : parseInt(totalSegmentCount);
  const poolSegmentLength = parseInt(segmentLength);
  const poolWaitingRoundLength = parseInt(waitingRoundLength) || 0;
  const poolTotalSegmentDuration = poolSegmentLength * totalRounds;

  let nextSegmentEnd = 0;
  const lastSegmentEnd = dayjs.unix(firstSegmentStart).add(poolTotalSegmentDuration + poolWaitingRoundLength, 's');

  if (currentRound === ~~totalSegmentCount) {
    const lastIndexDelimiterBeforeWaitingRound = 1;
    const roundDuration = isV2Game
      ? poolSegmentLength * (currentRound - lastIndexDelimiterBeforeWaitingRound)
      : poolSegmentLength * currentRound;
    nextSegmentEnd = dayjs.unix(firstSegmentStart).add(poolWaitingRoundLength + roundDuration, 's');

    return { nextSegmentEnd, lastSegmentEnd };
  }

  nextSegmentEnd = dayjs.unix(firstSegmentStart).add(poolSegmentLength * currentRound, 's');

  return { nextSegmentEnd, lastSegmentEnd };
}

export function getSegmentGameInfo(gameInfo) {
  const parsedGameInfo = { ...gameInfo };

  parsedGameInfo.contractId = gameInfo.id;
  parsedGameInfo.isFirstSegment = ~~parsedGameInfo.currentSegment === 0;
  parsedGameInfo.lastSegment = ~~parsedGameInfo.totalSegmentCount - 1;
  parsedGameInfo.firstSegmentStart = parsedGameInfo.gameStartsAt;
  const { nextSegmentEnd, lastSegmentEnd } = getLastOrNextSegmentEnd(parsedGameInfo);

  parsedGameInfo.firstSegmentEnd = dayjs.unix(parsedGameInfo.firstSegmentStart).add(parsedGameInfo.segmentLength, 's');
  parsedGameInfo.nextSegmentEnd = nextSegmentEnd;
  parsedGameInfo.lastSegmentEnd = lastSegmentEnd;
  return parsedGameInfo;
}

export const gameInfoDTO = {
  fromApiToState: (gameInfo) => {
    const parsedGameInfo = getSegmentGameInfo(gameInfo);

    const segmentSize = Array(parseInt(parsedGameInfo.lastSegment) + (hasWaitingRound ? 2 : 1));
    parsedGameInfo.allSegmentTimes = segmentSize.fill().map((x, index) => {
      return {
        round: index + 1,
        dateData: getAllSegmentDateData(index, parsedGameInfo),
        calInvite: index === 0 ? null : calendarInvites[index - 1],
      };
    });

    return parsedGameInfo;
  },
};
