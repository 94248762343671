/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

export default (props) => {
  const [visibility, setVisibility] = useState(false);

  const classes = classNames(
    'alert-container sans_serif',
    props.type ? props.type : 'success-msg',
    visibility && 'alert-visible',
    !visibility && 'alert-invisible'
  );

  useEffect(() => {
    setVisibility(props.show);
  }, [props.show]);

  return (
    <div className={classes}>
      <span>
        {props.text}
        {!!props.actionText && (
          <button
            onClick={() => {
              props.action();
              setVisibility(false);
            }}
            className="alert-action-text"
            type="button"
          >
            {props.actionText}
          </button>
        )}
        {props.children}
      </span>
      <button
        type="button"
        className="alert-close"
        onClick={() => {
          !!props.close && props.close();
          setVisibility(false);
        }}
      >
        X
      </button>
    </div>
  );
};
