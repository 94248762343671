import { createSelector } from '@reduxjs/toolkit';

const getPlayers = (state) => state.players;

export const getPlayersList = createSelector(getPlayers, (players) => players.list);
export const getPlayersStats = createSelector(getPlayers, (players) => players.stats);

export const getWinnersCount = createSelector(getPlayersStats, (stats) => stats.winners);
export const getWaitingCount = createSelector(getPlayersStats, (stats) => stats.waitings);
export const getGhostCount = createSelector(getPlayersStats, (stats) => stats.ghosts);
export const getDropOutsCount = createSelector(getPlayersStats, (stats) => stats.dropouts);
