import React from 'react';
import Button from 'components/elements/Button';
import { gameNetworkIcons } from 'utils/networks';
import Image from 'components/elements/Image';
import Tooltip from 'components/elements/Tooltip';
import CenterLoading from 'components/elements/CenterLoading';

function ChallengeCard({
  gameName,
  gameShortName,
  frequencyOfDeposit,
  network,
  apy,
  gameEndDate,
  gameImage,
  gameDescription,
  buttonLabel,
  onClick,
}) {
  const gameOrNetworkImage = !gameImage ? gameNetworkIcons[network] : gameImage;
  const isLoading = !gameName && !gameShortName;

  if (isLoading) {
    return (
      <div className="challenge-card-container">
        <CenterLoading />
      </div>
    );
  }
  return (
    <div className="challenge-card-section">
      <div className="challenge-card">
        <div className="left-panel">
          <div className="header">
            <h2>
              {gameShortName}{' '}
              <Tooltip content={gameName} placement="bottom" displayContent={() => <i className="bi bi-info" />} />
            </h2>
            <p>{gameDescription}</p>
          </div>
          <div className="deposit-frequency">
            <span>Deposit each {frequencyOfDeposit}</span>
            <span>Ends on {gameEndDate}</span>
          </div>
          {/* <div className="apy">
            <p>{apy} % APY</p>
          </div> */}
        </div>
        <div className="right-panel">
          <p className="sponsored-challenge">Closing soon!</p>
          <div className="game-image">
            <Image className="image" src={gameOrNetworkImage} alt="How-it-works" width={60} height={60} />
          </div>
          {/* <div className="join-button">
            <Button className="button button-primary" onClick={onClick}>
              {buttonLabel}
            </Button>
          </div> */}
        </div>
      </div>
      <div className="apy-action-button">
        <div className="apy">
          <p>{apy} % APY</p>
        </div>
        <div className="join-button">
          <Button className="button button-primary" onClick={onClick}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ChallengeCard;
