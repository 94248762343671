import imgChooseChallenge from 'assets/how-it-works/choose-challenge.svg';
import imgMakeDeposit from 'assets/how-it-works/make-deposit.svg';
import imgAimForVictory from 'assets/how-it-works/aim-for-victory.svg';
import imgWithdraw from 'assets/how-it-works/withdraw.svg';
import imgEarnReward from 'assets/how-it-works/earn-reward.svg';
import { docsURL } from './brand';

export const PLAYERS_PER_PAGE = 100;
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const ERRORS = {
  RECORD_NOT_FOUND: 'RECORD_NOT_FOUND',
};

export const HOW_IT_WORKS = [
  {
    title: 'Pick a Challenge',
    description:
      'Explore our varied challenges, each with their own rules and rewards. Pick based on your preferred deposit token and duration.',
    imageUri: imgChooseChallenge,
  },
  {
    title: 'Make a Deposit',
    description: 'Link your wallet, chose how much you want to save, and make your first deposit.',
    imageUri: imgMakeDeposit,
  },
  {
    title: 'Hit the Mark',
    description: 'Each challenge has its rules - some need many deposits, others just one held over time. Stick to the deposit schedule to bag your rewards!',
    imageUri: imgAimForVictory,
  },
  {
    title: 'Withdraw Whenever',
    description:
      'Need to exit early? You can withdraw your funds at any point. Beware this may incur fees. To sidestep fees, stay put until the challenge concludes.',
    imageUri: imgWithdraw,
  },
  {
    title: 'Claim Your Rewards',
    description:
      'Great job! After completing all deposits and reaching the end of the challenge, you can withdraw your funds and your well-earned rewards!',
    imageUri: imgEarnReward,
  },
];

export const ROUND_MEASURE = {
  Hours: 'hours',
  Days: 'days',
  Weeks: 'weeks',
  Months: 'months',
};

export const LEADERBOARD_TYPES = {
  LastFiveChallenges: 'lastFiveChallenges',
  GovernanceV2: 'governanceV2',
  AllTime: 'allTime',
};

export const MECHANISM_TYPE = {
  Saving: 'saving',
  Hodl: 'hold',
};

export const DEPOSIT_TYPE = {
  Fixed: 'fixed',
  Flexible: 'flexible',
};

export const TOKEN_TYPE = {
  Stable: 'stable',
  Volatile: 'volatile',
};

export const NETWORK_TYPE = {
  Celo: 'celo',
  Polygon: 'polygon',
  Base: 'base',
  BaseGoerli: 'baseGoerli',
};

export const TAG_TOOL_TIP = {
  hold: `Make one deposit and do not withdraw it before the end date, to complete this challenge. Get motivated to "Hold On for Dear Life" (HODL).`,
  saving: 'Savings challenges require multiple deposits. They help you build up the good habit of saving regularly.',
  fixed: `Deposit amounts are the same for all players in this pool (fixed deposit amount).`,
  custom: `Deposit amounts are chosen by each player when joining this challenge (custom deposit amounts).`,
  stable: `This savings challenge only has exposure to stablecoins (pegged to a fiat currency).`,
  volatile: `This savings challenge has price exposure to one or more volatile assets, and is susceptible to market volatility.`,
};

export const POOL_TYPE_LINK = {
  hold: `${docsURL}/halofi-challenges/technical-documentation/challenge-types`,
  saving: `${docsURL}/halofi-challenges/technical-documentation/challenge-types`,
  fixed: `${docsURL}/halofi-challenges/technical-documentation/challenge-types`,
  custom: `${docsURL}/halofi-challenges/technical-documentation/challenge-types`,
  stable: `${docsURL}/halofi-challenges/technical-documentation/savings-challenge-mechanics#deposit-tokens`,
  volatile: `${docsURL}/halofi-challenges/technical-documentation/savings-challenge-mechanics#deposit-tokens`,
};

export const ABI = {
  v001: '0.0.1',
  v002: '0.0.2',
  v003: '0.0.3',
  v200: '2.0.0',
  v201: '2.0.1',
  v202: '2.0.2',
  v203: '2.0.3',
  v204: '2.0.4',
  v205: '2.0.5',
};

export const ABIVersions = {
  v20x: [ABI.v200, ABI.v201, ABI.v202, ABI.v203, ABI.v204, ABI.v205],
};

// TODO: add BASE network specific strategies
export const STRATEGY_PROVIDER = {
  AaveV2: 'aaveV2',
  AaveV3: 'aaveV3',
  Moola: 'moola',
  Curve: 'curve', // used by V2 contracts
  CurveAave: 'polygon-curve-aave', // used by V1 contracts
  CurveAtricrypto: 'polygon-curve-atricrypto', // used by V1 contracts
  CurveMatic: 'curve-matic-stmatic', // used by V1 contracts
  CurveCeloTripool: 'celo-curve-tripool',
  Mobius: 'mobius',
  Open: 'open',
  'Mobius-cUSD-USDC': 'mobius-cUSD-USDC',
  'Mobius-cUSD-DAI': 'mobius-cUSD-DAI',
};

export const TRANSACTION_TYPE = {
  JoinGame: 'joinGame',
  Approve: 'approve',
  ExternalPoolDeposit: 'depositIntoExternalPool',
  EarlyWithdraw: 'earlyWithdraw',
  Join: 'join',
  JoinWhitelistedGame: 'joinWhitelistedGame',
  JoinWhiteList: 'joinWhiteList',
  MakeDeposit: 'makeDeposit',
  Deposit: 'deposit',
  RedeemFromExternalPool: 'redeemFromExternalPool',
  Redeem: 'redeem',
  Withdraw: 'withdraw',
};

// TODO: add BASE network specific strategies
export const SLIPPAGE_STRATEGIES = [
  STRATEGY_PROVIDER.CurveAave,
  STRATEGY_PROVIDER.CurveAtricrypto,
  STRATEGY_PROVIDER.CurveMatic,
  STRATEGY_PROVIDER.CurveCeloTripool,
  STRATEGY_PROVIDER.Curve,
  STRATEGY_PROVIDER.Mobius,
  STRATEGY_PROVIDER['Mobius-cUSD-USDC'],
  STRATEGY_PROVIDER['Mobius-cUSD-DAI'],
];

export const REGISTRY = {
  unstoppableDomain: 'uns',
  ensDomain: 'ens',
};

export const ENS_REGISTRY = {
  eth: REGISTRY.ensDomain,
  nom: REGISTRY.ensDomain,
};

export const UNSTOPPABLE_DOMAIN_REGISTRY = {
  crypto: REGISTRY.unstoppableDomain,
  wallet: REGISTRY.unstoppableDomain,
  blockchain: REGISTRY.unstoppableDomain,
  dao: REGISTRY.unstoppableDomain,
  nft: REGISTRY.unstoppableDomain,
};

export const IMAGE_RESOLUTION = {
  High: 'high',
  Medium: 'medium',
  Low: 'low',
};

export const GAME_PROPOSER = {
  Self: 'self',
  Community: 'community',
};

export const UNIX_MINUTES_EPOCH_CONVERSION = 1000;
export const MAX_GAS_PRICE = 10000000;
export const ENS_ADDRESS = '0x3DE51c3960400A0F752d3492652Ae4A0b2A36FB3';
export const FRONT_URL = 'https://www.getfront.com/';
export const FRONT_CATALOG_URL = 'https://integration-api.getfront.com/api/v1/cataloglink';
export const FRONT_NETWORK_URL = 'https://integration-api.getfront.com/api/v1/transfers/managed/networks';
