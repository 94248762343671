import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import CenterLoading from 'components/elements/CenterLoading';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone';

import { Web3Context } from 'providers/Web3Provider';
import { getGamesInfo } from 'redux/selectors/game.selector';
import { setShowPreviousPools, fetchPlayerGames } from 'redux/reducers/dashboard';
import { setShowParentChallengeModal, setShowInfoModal, setShowUserSurveyModal } from 'redux/reducers/feedbacks';
import { getUserAddress } from 'redux/selectors/user.selector';
import {
  getPlayerGames,
  getShowPreviousPools,
  getLoadingPlayerGames,
  getActiveGames,
} from 'redux/selectors/dashboard.selector';
import { getShowParentChallengeModal, getShowInfoModal } from 'redux/selectors/feedbacks.selector';

import ChallengesBar from 'components/elements/ChallengesBar';
import Dropdown from 'components/elements/Dropdown';
import Button from 'components/elements/Button';
import SearchBar from 'components/elements/SearchBar';
import InfoModal from 'components/elements/InfoModal';
import ToggleSwitch from 'components/elements/ToggleSwitch';
import Modal from 'components/elements/Modal';
import ModalHeader from 'components/elements/ModalHeader';
import ModalFooter from 'components/elements/ModalFooter';
import PoolCard from 'components/elements/PoolCard';
import CommunityPoolCard from 'components/elements/CommunityPoolCard';
import EthIllustration from 'assets/images/eth-weights.svg';
import BtcIllustration from 'assets/images/btc-weights.svg';
import ComingSoonGIF from 'assets/images/coming-soon.gif';
import useScrollToTop from 'Hooks/useScrollToTop';
import { sdkJsClient } from 'service/sdk';
import useGetGames from './NewGame/hooks/useGetGames';
import SectionHeader from '../components/sections/partials/SectionHeader';
import UserSurveyModal from './NewGame/components/Modals/UserSurveyModal/UserSurveyModal';
import { titleCase, getDateDiff, mappedToObject } from '../utils/utilities';
import {
  MECHANISM_TYPE,
  DEPOSIT_TYPE,
  NETWORK_TYPE,
  TOKEN_TYPE,
  GAME_PROPOSER,
  UNIX_MINUTES_EPOCH_CONVERSION,
} from '../utils/constants';
import { docsURL, snapshotURL, brandCaps } from '../utils/brand';

dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const gameFilterTypes = {
  ChallengeType: 'challenge-type',
  Duration: 'duration',
  Network: 'network',
  Token: 'token',
  TokenSymbol: 'token-symbol',
  All: 'all',
};

const typeOfChallenge = {
  Halofi: 'halofi',
  Community: 'community',
  Completed: 'completed',
};

function SaveOnSession(object) {
  Object.keys(object).map((key) => sessionStorage.setItem(key, JSON.stringify(object[key])));
}

function getFromSession(key) {
  return JSON.parse(sessionStorage.getItem(key));
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  const convertedDate = new Date(date);
  return [
    padTo2Digits(convertedDate.getDate()),
    padTo2Digits(convertedDate.getMonth() + 1),
    convertedDate.getFullYear(),
  ].join('/');
}

function getNumberOfGamesToDisplay(games, numOfGamesToDisplay, showMore) {
  const shouldShowMore = games.length > numOfGamesToDisplay;
  const toDisplayGames = showMore ? games : games.slice(0, numOfGamesToDisplay);
  return [shouldShowMore, toDisplayGames];
}

function getLowToUpperBound(bounds) {
  const [lowerBound, UpperBound] = bounds;
  const elements = {};
  for (let i = lowerBound; i <= UpperBound; i += 1) {
    elements[i] = i;
  }
  return elements;
}

function getPlayerPreviousGames(playerPreviousGames, games) {
  const previousGames = mappedToObject(playerPreviousGames, 'gameId');

  return Object.values(games).filter((game) => {
    return previousGames[game.contract] !== undefined;
  });
}

function handleGameDurationFilter(thresholds, games) {
  const gameThresholds = getLowToUpperBound(thresholds);

  return games.filter((game) => {
    const { starts, ends } = game;
    const durationOfGameInMonths = getDateDiff(ends, starts, 'months');
    return gameThresholds[durationOfGameInMonths] !== undefined;
  });
}

function handleGameNetworkFilter(network, games) {
  if (network === gameFilterTypes.All) {
    return games;
  }
  return games.filter((game) => game.name === network);
}

function handleGameTokenFilter(token, games) {
  if (token === gameFilterTypes.All) {
    return games;
  }

  return games.filter((game) => game.tags && game.tags.includes(token));
}

function handleGameTokenSymbolFilter(token, games) {
  if (token === gameFilterTypes.All) {
    return games;
  }

  return games.filter((game) => game.depositToken && game.depositToken === token);
}

function handleChallengeTypeFilter(challengeType, games) {
  if (challengeType === gameFilterTypes.All) {
    return games;
  }
  return games.filter((game) => game.mechanismType === challengeType);
}

function handleGameFilterTypeProvider(filterType) {
  switch (filterType) {
    case gameFilterTypes.Network:
      return handleGameNetworkFilter;
    case gameFilterTypes.Duration:
      return handleGameDurationFilter;
    case gameFilterTypes.ChallengeType:
      return handleChallengeTypeFilter;
    case gameFilterTypes.Token:
      return handleGameTokenFilter;
    case gameFilterTypes.TokenSymbol:
      return handleGameTokenSymbolFilter;
    default:
      return new Error('Not supported');
  }
}

function handleGameFilterTypeExecution(filterTypeSequence, games) {
  const filterType = Object.keys(filterTypeSequence);
  let next = 0;

  const traverseExecutionSequence = (selectedGames) => {
    if (next === filterType.length) {
      return selectedGames;
    }
    const handleGameFilter = handleGameFilterTypeProvider(filterType[next]);
    const argument = filterTypeSequence[filterType[next]];
    const filteredGames = handleGameFilter(argument, selectedGames);
    next += 1;
    return traverseExecutionSequence(filteredGames);
  };
  return traverseExecutionSequence(games);
}

function ParentChallenge({ challengeType, childPools, headerTitle, headerDescription }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const showModal = useSelector(getShowParentChallengeModal);

  const closeParentChallengeModal = () => {
    dispatch(setShowParentChallengeModal(false));
  };

  return (
    <Modal show={showModal} handleClose={closeParentChallengeModal}>
      <ModalHeader header={headerTitle} onClose={closeParentChallengeModal} />
      <div className="challenges-container parent-challenge">
        {childPools.map((game) => {
          const {
            gameNameShort,
            paymentAmount,
            depositType,
            depositToken,
            mechanismType,
            name,
            displayId,
            description,
            descriptionItems,
            gameName,
            starts,
            ends,
            gameImage,
          } = game;

          const payableAmount =
            depositType === DEPOSIT_TYPE.Fixed
              ? `${paymentAmount} ${depositToken?.toUpperCase()}`
              : `${depositToken?.toUpperCase()}`;
          const subHeading = `${payableAmount} | ${titleCase(mechanismType)} |  ${titleCase(name)}`;

          if (challengeType === typeOfChallenge.Halofi) {
            return (
              <PoolCard
                nftImage={gameImage}
                heading={gameNameShort}
                subHeading={subHeading}
                poolNumber={displayId}
                network={name}
                challengeType={typeOfChallenge.Halofi}
                className="pool-box-container-halofi"
                onClick={() => history.push(`/challenges/${displayId}`)}
              />
            );
          }
          if (challengeType === typeOfChallenge.Community) {
            return (
              <CommunityPoolCard
                nftImage={gameImage}
                heading={gameNameShort}
                subHeading={subHeading}
                poolNumber={displayId}
                network={name}
                descriptionItems={descriptionItems ?? []}
                description={description}
                challengeType={typeOfChallenge.Community}
                onClick={() => history.push(`/challenges/${displayId}`)}
                className="button-primary button-xs w-24"
              />
            );
          }
          return (
            <PoolCard
              nftImage={gameImage}
              heading={gameNameShort ?? gameName}
              subHeading={subHeading}
              poolNumber={displayId}
              network={name}
              poolName={gameName}
              subDescription={`${formatDate(starts)} -  ${formatDate(ends)}`}
              challengeType={typeOfChallenge.Completed}
              className="pool-box-container-completed"
              onClick={() => history.push(`/challenges/${displayId}`)}
            />
          );
        })}
        <p>{headerDescription}</p>
      </div>
      <ModalFooter>
        <Button
          onClick={closeParentChallengeModal}
          className="button-primary-outline"
          id="close-parent-challenge-modal"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default React.memo(function WrappedGames({ location }) {
  // get the games configs
  useGetGames();
  // 👇️ scroll to top on page load
  useScrollToTop();
  const games = useSelector(getGamesInfo);
  const playerGames = useSelector(getPlayerGames);
  const activeGames = useSelector(getActiveGames);
  const showPreviousPools = useSelector(getShowPreviousPools);
  const isLoadingGames = useSelector(getLoadingPlayerGames);
  const userAddress = useSelector(getUserAddress);
  const infoModal = useSelector(getShowInfoModal);
  const Web3Client = useContext(Web3Context);
  const history = useHistory();
  const dispatch = useDispatch();
  const [supportedToken, setSupportedTokens] = useState([]);

  const params = new URLSearchParams(location.search);
  const network = params.get('network')?.toLowerCase();
  const tokenSymbol = params.get('tokensymbol')?.toLowerCase();

  useEffect(() => {
    if (userAddress) {
      dispatch(fetchPlayerGames(userAddress));
    }
  }, [userAddress, dispatch]);

  useEffect(() => {
    const getSupportedTokens = async () => {
      const tokens = await sdkJsClient.getTokensList();
      tokens.sort((a, b) => a.symbol.localeCompare(b.symbol));
      setSupportedTokens(tokens);
    };
    getSupportedTokens();
  }, []);

  if (isEmpty(games) || isEmpty(supportedToken)) {
    return <CenterLoading text="Waiting for pools" />;
  }

  let allGames = games;

  if (network) {
    allGames = handleGameNetworkFilter(network, Object.values(games));
  }

  if (tokenSymbol) {
    allGames = handleGameTokenSymbolFilter(tokenSymbol, Object.values(games));
  }

  const onSetShowPreviousPools = (payload) => {
    dispatch(setShowPreviousPools(payload));
  };

  const onSetShowParentChallengeModal = (payload) => {
    dispatch(setShowParentChallengeModal(payload));
  };

  const onSetShowFutureChallengeModal = (payload) => {
    dispatch(setShowInfoModal(payload));
  };

  const goToPage = (page) => {
    history.push(page);
  };

  const handleUserSurveyModalClick = () => {
    return dispatch(setShowUserSurveyModal({ show: true, backButton: false }));
  };

  const connectWallet = () => Web3Client.init();

  const playerPreviousGames = getPlayerPreviousGames(playerGames, games);

  return (
    <Games
      games={allGames}
      network={network}
      infoModal={infoModal}
      goToPage={goToPage}
      playerPreviousGames={playerPreviousGames}
      playerActiveGames={activeGames}
      isLoadingGames={isLoadingGames}
      showPreviousPools={showPreviousPools}
      onSetShowPreviousPools={onSetShowPreviousPools}
      onSetShowParentChallengeModal={onSetShowParentChallengeModal}
      onSetShowFutureChallengeModal={onSetShowFutureChallengeModal}
      userAddress={userAddress}
      connectWallet={connectWallet}
      handleUserSurveyModalClick={handleUserSurveyModalClick}
      supportedToken={supportedToken}
    />
  );
});

class Games extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterBy: false,
      filterAttribute: false,
      hideClosed: false,
      showMoreHalofiChallenges: false,
      showCommunityChallenges: false,
      showCompletedChallenges: false,
      selectedChallengeFiter: {},
      shouldResetfilter: false,
      loading: false,
      search: '',
      errorMessage: '',
      games: [],
      playerPreviousGames: [],
      filterTypeSequence: {},
      parentChallenge: {},
      childrenOfParentChallenge: [],
      showFilter: false,
    };
    this.errorMessageRef = React.createRef();
    this.halofiChallenge = React.createRef();
    this.communityChallenge = React.createRef();
    this.completedChallenge = React.createRef();
    this.filterTypeSessionID = 'filter-type-sequence';
    this.SelectedChallengeID = 'selected-challenge-filter';
    this.showFilterID = 'show-filter';
  }

  componentDidMount() {
    const { games, playerPreviousGames } = this.props;
    this.setState({
      games: Object.values(games),
      playerPreviousGames,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoadingGames !== prevProps.isLoadingGames) {
      this.setState({ playerPreviousGames: this.props.playerPreviousGames });
    }
  }

  handleGamesFilters = (filterType, argument) => {
    const { filterTypeSequence } = this.state;
    filterTypeSequence[filterType] = argument;
    this.setState({ filterTypeSequence: { ...filterTypeSequence } });
    SaveOnSession({ [this.filterTypeSessionID]: filterTypeSequence });
  };

  handleChallengeFilterSelection = (filterType, value) => {
    const selectedFilter = this.state.selectedChallengeFiter;
    selectedFilter[filterType] = value;
    this.setState({ selectedChallengeFiter: { ...selectedFilter }, shouldResetfilter: false });
    SaveOnSession({ [this.SelectedChallengeID]: selectedFilter });
  };

  handleClearFilter = () => {
    this.setState({ selectedChallengeFiter: {}, shouldResetfilter: true, filterTypeSequence: {} });
    SaveOnSession({ [this.filterTypeSessionID]: {}, [this.SelectedChallengeID]: {} });
  };

  onChange = (e) => {
    this.setState({ search: e.target.value, errorMessage: '' }, () => {
      const { search } = this.state;
      const { games, playerPreviousGames } = this.props;
      if (search === '') {
        this.setState({ games: Object.values(games), playerPreviousGames });
      }
    });
  };

  handleFilterToggle = () => {
    const prevState = getFromSession(this.showFilterID) ?? this.state.showFilter;
    const showFilter = !prevState;
    this.setState({ showFilter });
    SaveOnSession({ [this.showFilterID]: showFilter });
  };

  handleSearch = () => {
    const getMatchedGames = (selectedGames, query) => {
      return [...selectedGames].filter((game) => {
        const { gameNameShort, gameName } = game;
        return (
          gameNameShort?.toLowerCase().includes(query.toLowerCase()) ||
          gameName?.toLowerCase().includes(query.toLowerCase())
        );
      });
    };

    const { search } = this.state;
    if (!search) {
      this.setState({ errorMessage: 'Enter the name of the game' });
      return;
    }
    this.setState({ loading: true });
    const matchGames = getMatchedGames(Object.values(this.props.games), search);
    const matchPreviousGames = getMatchedGames(this.props.playerPreviousGames, search);

    this.setState({ games: matchGames, playerPreviousGames: matchPreviousGames, loading: false });
  };

  handleOpenLinks = (url) => {
    window.open(url);
  };

  handleShowPreviousPools = (e) => {
    const isChecked = e.target.checked;
    this.props.onSetShowPreviousPools(isChecked);
  };

  handleParentChallenge = (challengeType, parentId, title, description) => {
    const children = Object.values(this.props.games).filter((game) => game.parent === parentId);
    this.setState({
      parentChallenge: { type: challengeType, title, description },
      childrenOfParentChallenge: children,
    });
    this.props.onSetShowParentChallengeModal(true);
  };

  handleShowFutureChallenge = ({
    poolNumber,
    title,
    subTitle,
    description,
    descriptionItems,
    calenderUrl,
    blogUrl,
  }) => {
    this.props.onSetShowFutureChallengeModal({
      show: true,
      data: { poolNumber, title, subTitle, description, descriptionItems, calenderUrl, blogUrl },
    });
  };

  handleOnChallengeClick = ({
    challengeType,
    displayId,
    modalTitle,
    modalDescription,
    isParent,
    futureGame,
    descriptionItems,
    calenderUrl,
    blogUrl,
    description,
    gameNameShort,
  }) => {
    if (futureGame) {
      this.handleShowFutureChallenge({
        poolNumber: displayId,
        title: gameNameShort,
        description,
        descriptionItems,
        calenderUrl,
        blogUrl,
      });
      return;
    }
    if (isParent) {
      this.handleParentChallenge(challengeType, displayId, modalTitle, modalDescription);
      return;
    }
    this.props.goToPage(`/challenges/${displayId}`);
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      isLoadingGames,
      hasBgColor,
      showPreviousPools,
      connectWallet,
      infoModal,
      userAddress,
      supportedToken,
      ...props
    } = this.props;
    const { games, errorMessage, search, loading, playerPreviousGames } = this.state;
    const playerPrevGames = showPreviousPools ? [...playerPreviousGames] : Object.values(games);
    const filterTypeSequence = getFromSession(this.filterTypeSessionID) ?? this.state.filterTypeSequence;
    const selectedChallengeFiter = getFromSession(this.SelectedChallengeID) ?? this.state.selectedChallengeFiter;
    const showFilter = getFromSession(this.showFilterID) ?? this.state.showFilter;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      className
    );

    const searchButtonText = loading ? 'Fetching' : 'Search';
    const showPlayerPreviousGames = !userAddress && showPreviousPools;
    const challenges = handleGameFilterTypeExecution(filterTypeSequence, games);

    const filterGames = (gameData) =>
      this.state.filterBy ? gameData[this.state.filterBy] === this.state.filterAttribute : true;

    const hideClosed = (gameData) => {
      return this.state.hideClosed ? gameData.closes && new Date(gameData.closes).getTime() > Date.now() : true;
    };

    const onlyLaunchingSoon = (gameData) => {
      return this.state.onlyLaunchingSoon ? !!gameData.futureGame : true;
    };

    const printLiveGames = () => {
      return challenges
        .filter((game) => {
          const notEnded = game.ends ? new Date(game.ends).getTime() > Date.now() : true; // assuming games without a start time are future games that have not ended
          return (
            notEnded &&
            !game.isChild &&
            filterGames(game) &&
            hideClosed(game) &&
            onlyLaunchingSoon(game) &&
            !game.isHidden &&
            !game.futureGame
          );
        })
        .sort((a, b) => (b.displayOrder || -1) - (a.displayOrder || -1));
    };

    const printClosedGames = () => {
      return challenges
        .filter(
          (game) =>
            !!game.ends &&
            new Date(game.ends).getTime() <= Date.now() &&
            !game.isChild &&
            !game.isHidden &&
            !game.futureGame
        )
        .sort((a, b) => (new Date(b.ends).getTime() || -1) - (new Date(a.ends).getTime() || -1));
    };

    const liveChallenges = printLiveGames();
    const closeChallenges = printClosedGames();

    const communityFutureChallenge = challenges.filter(
      (game) => game.proposer === GAME_PROPOSER.Community && game.futureGame && !game.isChild && !game.isHidden
    );
    const halofiFutureChallenge = challenges.filter(
      (game) => game.proposer === GAME_PROPOSER.Self && game.futureGame && !game.isChild && !game.isHidden
    );

    const numOfHalofiChallengeToDisplay = 8;
    const halofiChallenges = liveChallenges.filter((game) => {
      const defaultChecker = game.proposer === GAME_PROPOSER.Self && ~~game.currentSegment === 0;
      if (userAddress && this.props.playerActiveGames[userAddress]) {
        return defaultChecker && !this.props.playerActiveGames[userAddress].some((item) => item.gameId === game.id);
      }
      return defaultChecker;
    });
    const sortedHalofiChallenges = [...halofiFutureChallenge, ...halofiChallenges].sort(
      (a, b) => parseInt(b.displayId) < parseInt(a.displayId)
    );
    const [shouldShowMoreHalofiChallenges, toDisplayHalofiChallenges] = getNumberOfGamesToDisplay(
      sortedHalofiChallenges,
      numOfHalofiChallengeToDisplay,
      this.state.showMoreHalofiChallenges
    );

    const numOfCommunityChallengeToDisplay = 4;
    const communityChallenges = liveChallenges.filter((game) => {
      const defaultChecker = game.proposer === GAME_PROPOSER.Community && ~~game.currentSegment === 0;

      if (userAddress && this.props.playerActiveGames[userAddress]) {
        return defaultChecker && !this.props.playerActiveGames[userAddress].some((item) => item.gameId === game.id);
      }

      return defaultChecker;
    });
    const sortedCommunityChallenges = [...communityFutureChallenge, ...communityChallenges].sort(
      (a, b) =>
        new Date(parseInt(b.gameStartsAt) * UNIX_MINUTES_EPOCH_CONVERSION) -
        parseInt(a.gameStartsAt) * UNIX_MINUTES_EPOCH_CONVERSION
    );
    const [shouldShowMoreCommunityChallenges, toDisplayCommunityChallenges] = getNumberOfGamesToDisplay(
      sortedCommunityChallenges,
      numOfCommunityChallengeToDisplay,
      this.state.showCommunityChallenges
    );

    const numOfCompletedChallengeToDisplay = 4;
    const closedToJoiners = liveChallenges
      .filter((game) => ~~game.currentSegment > 0)
      .sort((a, b) => new Date(b.ends) - new Date(a.ends));
    const sortedClosedChallenge = closeChallenges.sort((a, b) => new Date(b.ends) - new Date(a.ends));
    const joinedHalofiChallenges = liveChallenges.filter(
      (game) =>
        userAddress &&
        this.props.playerActiveGames[userAddress] !== undefined &&
        ~~game.currentSegment === 0 &&
        this.props.playerActiveGames[userAddress].some((item) => item.gameId === game.id)
    );

    const filteredClosedToJoiner = handleGameFilterTypeExecution(filterTypeSequence, playerPrevGames).filter(
      (game) => ~~game.currentSegment > 0
    );
    const completedChallenges = showPreviousPools
      ? [...filteredClosedToJoiner, ...joinedHalofiChallenges].sort((a, b) => new Date(b.ends) - new Date(a.ends))
      : [...joinedHalofiChallenges, ...closedToJoiners, ...sortedClosedChallenge];

    const [shouldShowMoreCompletedChallenges, toDisplayCompletedChallenges] = getNumberOfGamesToDisplay(
      completedChallenges,
      numOfCompletedChallengeToDisplay,
      this.state.showCompletedChallenges
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container" id="team">
          <div>
            <h2 className="challenges-h2 mt-0">Crypto Challenges</h2>
            <div className="illustrations">
              <img
                className="eth-illustration hide-mobile-same-dimensions"
                src={EthIllustration}
                alt="ETH challenge illustration"
              />
              <img
                className="btc-illustration hide-mobile-same-dimensions"
                src={BtcIllustration}
                alt="BTC challenge illustration"
              />
            </div>
            <br />
            <ChallengesBar
              handleHalofiChallenge={() => this.halofiChallenge.current.scrollIntoView()}
              handleCommunityChallenge={() => this.communityChallenge.current.scrollIntoView()}
              handleCompletedChallenge={() => this.completedChallenge.current.scrollIntoView()}
            />

            <div className="switch-container">
              <div className="switch-filter-text">
                <span>Search & Filter</span>
                <ToggleSwitch
                  className="switch-primary switch-filter"
                  checked={showFilter}
                  onChange={this.handleFilterToggle}
                />
              </div>
            </div>
            <div className={`getting-started ${!showFilter && 'hide'}`}>
              <div className="search-container">
                <SearchBar
                  errorMessageRef={this.errorMessageRef}
                  placeholder="Search…"
                  onChange={this.onChange}
                  searchButtonText={searchButtonText}
                  search={search}
                  errorMessage={errorMessage}
                  onSearch={this.handleSearch}
                  searchButtonID="share-player-profile"
                  searchButtonType="button-primary-outline"
                  className="search-bar-default"
                />
              </div>

              <div className="search-selection-type">
                <Dropdown
                  className="dropdown-button dropdown-mr-60"
                  label="Network"
                  shouldReset={this.state.shouldResetfilter}
                  handleSelectedValue={(value) => this.handleChallengeFilterSelection(gameFilterTypes.Network, value)}
                  options={[
                    {
                      label: 'Celo',
                      value: NETWORK_TYPE.Celo,
                      action: () => this.handleGamesFilters(gameFilterTypes.Network, NETWORK_TYPE.Celo),
                    },
                    {
                      label: 'Polygon',
                      value: NETWORK_TYPE.Polygon,
                      action: () => this.handleGamesFilters(gameFilterTypes.Network, NETWORK_TYPE.Polygon),
                    },
                    {
                      label: 'Base',
                      value: NETWORK_TYPE.Base,
                      action: () => this.handleGamesFilters(gameFilterTypes.Network, NETWORK_TYPE.Base),
                    },
                    {
                      label: 'Base Goerli',
                      value: NETWORK_TYPE.BaseGoerli,
                      action: () => this.handleGamesFilters(gameFilterTypes.Network, NETWORK_TYPE.BaseGoerli),
                    },
                    {
                      label: 'All',
                      value: 'All',
                      action: () => this.handleGamesFilters(gameFilterTypes.Network, gameFilterTypes.All),
                    },
                  ]}
                />
                <Dropdown
                  className="dropdown-button dropdown-mr-100"
                  label="Type"
                  shouldReset={this.state.shouldResetfilter}
                  handleSelectedValue={(value) =>
                    this.handleChallengeFilterSelection(gameFilterTypes.ChallengeType, value)
                  }
                  options={[
                    {
                      label: 'Saving',
                      value: 'Saving',
                      action: () => this.handleGamesFilters(gameFilterTypes.ChallengeType, MECHANISM_TYPE.Saving),
                    },
                    {
                      label: 'Hold',
                      value: 'Hold',
                      action: () => this.handleGamesFilters(gameFilterTypes.ChallengeType, MECHANISM_TYPE.Hodl),
                    },
                    {
                      label: 'All',
                      value: 'All',
                      action: () => this.handleGamesFilters(gameFilterTypes.ChallengeType, gameFilterTypes.All),
                    },
                  ]}
                />
                <Dropdown
                  className="dropdown-button dropdown-mr-60"
                  label="Duration"
                  shouldReset={this.state.shouldResetfilter}
                  handleSelectedValue={(value) => this.handleChallengeFilterSelection(gameFilterTypes.Duration, value)}
                  options={[
                    {
                      label: 'Short < 1 month',
                      value: 'Short < 1 month',
                      action: () => this.handleGamesFilters(gameFilterTypes.Duration, [0, 0]),
                    },
                    {
                      label: 'Medium  1-3 months',
                      value: 'Medium  1-3 months',
                      action: () => this.handleGamesFilters(gameFilterTypes.Duration, [1, 3]),
                    },
                    {
                      label: 'Long 3-6 months',
                      value: 'Long 3-6 months',
                      action: () => this.handleGamesFilters(gameFilterTypes.Duration, [3, 6]),
                    },
                    {
                      label: 'Extra long  6+ months',
                      value: 'Extra long  6+ months',
                      action: () => this.handleGamesFilters(gameFilterTypes.Duration, [6, 50]),
                    },
                    {
                      label: 'All',
                      value: 'All',
                      action: () => this.handleGamesFilters(gameFilterTypes.Duration, [0, 50]),
                    },
                  ]}
                />
                <Dropdown
                  className="dropdown-button dropdown-mr-60"
                  label="Token"
                  shouldReset={this.state.shouldResetfilter}
                  handleSelectedValue={(value) => this.handleChallengeFilterSelection(gameFilterTypes.Token, value)}
                  options={[
                    {
                      label: 'Stable',
                      value: 'Stable',
                      action: () => this.handleGamesFilters(gameFilterTypes.Token, TOKEN_TYPE.Stable),
                    },
                    {
                      label: 'Volatile',
                      value: 'Volatile',
                      action: () => this.handleGamesFilters(gameFilterTypes.Token, TOKEN_TYPE.Volatile),
                    },
                    {
                      label: 'All',
                      value: 'All',
                      action: () => this.handleGamesFilters(gameFilterTypes.Token, gameFilterTypes.All),
                    },
                  ]}
                />
                <Dropdown
                  className="dropdown-button dropdown-mr-60"
                  label="Token Symbol"
                  shouldReset={this.state.shouldResetfilter}
                  handleSelectedValue={(value) =>
                    this.handleChallengeFilterSelection(gameFilterTypes.TokenSymbol, value)
                  }
                  options={supportedToken.map((token) => {
                    return {
                      label: token.symbol,
                      value: token.symbol,
                      action: () => this.handleGamesFilters(gameFilterTypes.TokenSymbol, token.symbol),
                    };
                  })}
                />
              </div>

              <div className="selection-tags">
                <div className="selected-item-container">
                  {!isEmpty(selectedChallengeFiter) && <p className="mb-0 mr-35 font-size-16 fw-600">Filtered by:</p>}
                  {Object.values(selectedChallengeFiter).map((selectedItem) => (
                    <div className="selected-item">
                      <p>{selectedItem}</p>
                    </div>
                  ))}
                </div>

                {Object.values(selectedChallengeFiter).length > 0 && (
                  <div className="reset-button">
                    <Button
                      onClick={this.handleClearFilter}
                      id="reset-filter"
                      className="button button-wide-mobile button-link"
                    >
                      Reset All
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div ref={this.halofiChallenge} className="halofi-challenges">
              <div className="container">
                <SectionHeader
                  data={{
                    title: `Latest ${brandCaps} challenges`,
                    paragraph:
                      userAddress && toDisplayHalofiChallenges.length === 0
                        ? "You're on fire!🔥 You've joined every HaloFi challenge. Keep an eye out for new ones and keep crushing your current goals."
                        : 'Ready to put your savings and holding skills to the test?',
                  }}
                  className="mt-50 halofi-challenges-section"
                  headerClass="page-title-section"
                  subHeaderClass="page-sub-title-section "
                />
                {/* <Button
                  className="button-primary button-show-user-survey-modal"
                  onClick={this.props.handleUserSurveyModalClick}
                  id="show-user-survey-modal"
                >
                  Not sure where to start?
                </Button> */}
                <UserSurveyModal id="jEeqe2oP" />
                <div className="challenges-container">
                  {toDisplayHalofiChallenges.map((game) => {
                    const {
                      gameNameShort,
                      paymentAmount,
                      mechanismType,
                      depositType,
                      depositToken,
                      name,
                      displayId,
                      isParent,
                      description,
                      descriptionItems,
                      calenderUrl,
                      blogPostUri,
                      futureGame,
                      gameImage,
                    } = game;

                    const payableAmount =
                      depositType === DEPOSIT_TYPE.Fixed
                        ? `${paymentAmount} ${depositToken?.toUpperCase()}`
                        : `${depositToken?.toUpperCase()}`;
                    const modalTitle = 'Which challenge will you take?';
                    const modalDescription = 'Challenges are not exclusive. If you can handle it, you can join both!';

                    return (
                      <PoolCard
                        heading={gameNameShort}
                        subHeading={
                          !isParent
                            ? `${payableAmount} | ${titleCase(mechanismType)} |  ${titleCase(name)}`
                            : 'Choose a Challenge'
                        }
                        poolNumber={displayId}
                        network={name}
                        onClick={() =>
                          this.handleOnChallengeClick({
                            challengeType: typeOfChallenge.Halofi,
                            displayId,
                            modalTitle,
                            modalDescription,
                            isParent,
                            futureGame,
                            descriptionItems,
                            calenderUrl,
                            blogUrl: blogPostUri,
                            description,
                            gameNameShort,
                          })
                        }
                        className="pool-box-container-halofi"
                        challengeType={typeOfChallenge.Halofi}
                        isfutureGame={futureGame}
                        nftImage={gameImage}
                      />
                    );
                  })}
                </div>
                {shouldShowMoreHalofiChallenges && !this.state.showMoreHalofiChallenges && (
                  <Button
                    className="button-primary-outline mb-40"
                    onClick={() => this.setState({ showMoreHalofiChallenges: true })}
                    id="show-more-halofi-challenges"
                  >
                    Show More
                  </Button>
                )}

                {this.state.showMoreHalofiChallenges && (
                  <Button
                    className="button-primary-outline mb-40"
                    onClick={() => this.setState({ showMoreHalofiChallenges: false })}
                    id="show-less-halofi-challenges"
                  >
                    Show Less
                  </Button>
                )}
              </div>
            </div>

            <div ref={this.communityChallenge} className="community-challenges">
              <div className="sub-header-container">
                <div className="section-header section-header-container">
                  <div>
                    <h2 className="mt-0 page-title-section mb-16 page-title-section">Unique challenges</h2>
                    {userAddress && toDisplayCommunityChallenges.length === 0 ? (
                      <p className="m-0 page-sub-title-section page-sub-title-section">
                        Great job joining all unique challenges! Get involved in creating new ones on our{' '}
                        <a href={snapshotURL} target="_blank" rel="noopener noreferrer">
                          Snapshot page
                        </a>
                        .
                      </p>
                    ) : (
                      <>
                        <p className="m-0 page-sub-title-section page-sub-title-section">
                          Be a part of our custom-made savings and holding challenges.
                        </p>
                        <p className="m-0 page-sub-title-section page-sub-title-section">
                          Created just for you, by you. Find out more on our{' '}
                          <a href={snapshotURL} target="_blank" rel="noopener noreferrer">
                            Snapshot page
                          </a>
                          .
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <div className="sub-header-button">
                  <Button
                    onClick={() => this.handleOpenLinks(`${docsURL}/hub/getting-started`)}
                    className="button button-wide-mobile button-link"
                    id="bottom-getting-started-guide"
                  >
                    Getting started guide
                  </Button>
                </div>
              </div>

              <div className="challenges-container">
                {toDisplayCommunityChallenges.map((game) => {
                  const {
                    gameNameShort,
                    paymentAmount,
                    depositToken,
                    depositType,
                    mechanismType,
                    name,
                    displayId,
                    description,
                    descriptionItems,
                    futureGame,
                    calenderUrl,
                    blogPostUri,
                    isParent,
                    gameImage,
                  } = game;
                  const payableAmount =
                    depositType === DEPOSIT_TYPE.Fixed
                      ? `${paymentAmount} ${depositToken?.toUpperCase()}`
                      : `${depositToken?.toUpperCase()}`;
                  const modalTitle = 'Which challenge will you take?';
                  const modalDescription = 'Challenges are not exclusive. If you can handle it, you can join both!';
                  return (
                    <CommunityPoolCard
                      heading={gameNameShort}
                      subHeading={
                        !isParent
                          ? `${payableAmount} | ${titleCase(mechanismType)} |  ${titleCase(name)}`
                          : 'Choose a Challenge'
                      }
                      nftImage={gameImage}
                      poolNumber={displayId}
                      network={name}
                      descriptionItems={descriptionItems ?? []}
                      description={description}
                      onClick={() =>
                        this.handleOnChallengeClick({
                          challengeType: typeOfChallenge.Community,
                          displayId,
                          modalTitle,
                          modalDescription,
                          isParent,
                          futureGame,
                          descriptionItems,
                          calenderUrl,
                          blogUrl: blogPostUri,
                          description,
                          gameNameShort,
                        })
                      }
                      className="button-primary button-xs w-24"
                      isfutureGame={futureGame}
                    />
                  );
                })}
              </div>
              {shouldShowMoreCommunityChallenges && !this.state.showCommunityChallenges && (
                <div className="show-more-container">
                  <Button
                    className="button-primary-outline mb-40"
                    onClick={() => this.setState({ showCommunityChallenges: true })}
                    id="show-more-community-challenges"
                  >
                    Show More
                  </Button>
                </div>
              )}
              {this.state.showCommunityChallenges && (
                <div className="show-more-container">
                  <Button
                    className="button-primary-outline mb-40"
                    onClick={() => this.setState({ showCommunityChallenges: false })}
                    id="show-less-community-challenges"
                  >
                    Show Less
                  </Button>
                </div>
              )}
            </div>

            <div ref={this.completedChallenge} className="completed-challenges">
              <div className="sub-header-container">
                <SectionHeader
                  data={{
                    title: 'Challenges Overview',
                    paragraph:
                      "Explore challenges you've joined, along with completed ones and those not accepting new joiners.",
                  }}
                  className="mt-40"
                  headerClass="page-title-section"
                  subHeaderClass="page-sub-title-section"
                />

                <div className="sub-header-toggle-switch">
                  <div className="toggle-container">
                    <p>all</p>
                    <ToggleSwitch
                      className="switch-primary"
                      checked={showPreviousPools}
                      onChange={this.handleShowPreviousPools}
                    />
                    <p>mine</p>
                  </div>
                </div>
              </div>
              {userAddress && !!isLoadingGames && <CenterLoading />}
              {!showPlayerPreviousGames && !isLoadingGames && (
                <div className="challenges-container mt-20">
                  {toDisplayCompletedChallenges.map((game) => {
                    const {
                      gameNameShort,
                      paymentAmount,
                      name,
                      depositToken,
                      depositType,
                      mechanismType,
                      displayId,
                      gameName,
                      ends,
                      isParent,
                      gameImage,
                    } = game;
                    const modalTitle = 'Which challenge do you want to view?';

                    const payableAmount =
                      depositType === DEPOSIT_TYPE.Fixed
                        ? `${paymentAmount} ${depositToken?.toUpperCase()}`
                        : `${depositToken?.toUpperCase()}`;

                    return (
                      <PoolCard
                        heading={gameNameShort ?? gameName}
                        nftImage={gameImage}
                        subHeading={
                          !isParent
                            ? ` ${payableAmount} | ${titleCase(mechanismType)} |  ${titleCase(name)}`
                            : 'Choose a Challenge'
                        }
                        poolNumber={displayId}
                        network={name}
                        poolName={gameName}
                        subDescription={`${dayjs(new Date()).isBefore(dayjs(ends)) ? 'Ends' : 'Ended'} ${formatDate(
                          ends
                        )}`}
                        className="pool-box-container-completed"
                        challengeType={typeOfChallenge.Completed}
                        onClick={() =>
                          isParent
                            ? this.handleParentChallenge(typeOfChallenge.Completed, displayId, modalTitle, '')
                            : this.props.goToPage(`/challenges/${displayId}`)
                        }
                      />
                    );
                  })}
                </div>
              )}
              {showPlayerPreviousGames && (
                <Button
                  onClick={connectWallet}
                  className="button-primary button mb-20"
                  id="player-previous-games-connect-wallet"
                >
                  Connect Wallet
                </Button>
              )}
              {!showPlayerPreviousGames &&
                shouldShowMoreCompletedChallenges &&
                !isLoadingGames &&
                !this.state.showCompletedChallenges && (
                  <div className="show-more-container">
                    <Button
                      className="button-primary-outline mb-40"
                      onClick={() => this.setState({ showCompletedChallenges: true })}
                      id="show-more-completed-challenges"
                    >
                      Show All
                    </Button>
                  </div>
                )}
              {!showPlayerPreviousGames &&
                this.state.showCompletedChallenges &&
                !isLoadingGames &&
                shouldShowMoreCompletedChallenges && (
                  <div className="show-more-container">
                    <Button
                      className="button-primary-outline mb-40"
                      onClick={() => this.setState({ showCompletedChallenges: false })}
                      id="show-less-completed-challenges"
                    >
                      Show Less
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </div>
        <ParentChallenge
          challengeType={this.state.parentChallengeType?.type}
          headerTitle={this.state.parentChallenge?.title}
          headerDescription={this.state.parentChallenge?.description}
          childPools={this.state.childrenOfParentChallenge}
        />
        <InfoModal
          title={infoModal?.data?.title}
          subTitle={infoModal?.data?.subTitle}
          description={infoModal?.data?.description}
          subDescription="We're putting the finishing touches on this challenge. Stay tuned."
          descriptionItems={infoModal?.data?.descriptionItems}
          buttonType="button button-primary-outline"
          buttonText="Close"
          buttonId="info-modal-close"
          isAnchorHeader
          onClick={() => this.props.onSetShowFutureChallengeModal({ show: false })}
          href={() => infoModal?.data?.blogUrl && window.open(infoModal?.data?.blogUrl)}
        >
          <img src={ComingSoonGIF} alt="Coming Soon" className="info-modal-image" />
          {infoModal?.data?.calenderUrl && (
            <p>
              Don't miss it -{' '}
              <a className="link" href={infoModal?.data?.calenderUrl} target="_blank" rel="noreferrer">
                add to your calendar now
              </a>
            </p>
          )}
          {infoModal?.data?.blogUrl && (
            <p>
              Want to learn more? Read this pool's{' '}
              <a className="link" href={infoModal?.data?.blogUrl} target="_blank" rel="noreferrer">
                blog post!
              </a>
            </p>
          )}
        </InfoModal>
      </section>
    );
  }
}
