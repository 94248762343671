import { useContext } from 'react';
import { AnalyticsProviderContext } from 'providers/AnalyticsProvider';

export const useSendEvent = () => {
  const { sendEvent } = useContext(AnalyticsProviderContext);
  const txTimestamp = Math.floor(Date.now() / 1000);
  const logEvent = (type, networkId, eventData) => {
    sendEvent(type, networkId, txTimestamp, eventData);
  };

  return logEvent;
};
