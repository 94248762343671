import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function SkeletonCard() {
  return (
    <div className="game-card">
      <h5>
        <Skeleton />
      </h5>
      <h6>
        <Skeleton />
      </h6>
      <Skeleton />
    </div>
  );
}

export default SkeletonCard;
