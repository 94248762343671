/* eslint-disable no-await-in-loop */
import React from 'react';
import { request, gql } from 'graphql-request';

export const NftContext = React.createContext();

const GRAPH_API_URL = 'https://api.thegraph.com/subgraphs/name/aavegotchi/';
const aavegotchiReq = async (players, subgraphName = 'aavegotchi-core-matic') => {
  const limit = 1000;
  let skip = 0;
  let responseCount = 0;
  const aavegotchis = [];

  do {
    // Resets counter to avoid infinite loop
    responseCount = 0;

    // Query HAVE TO be ordered because we're using skip, so we need to make sure
    // we always skip the records already returned, and not something random
    const query = gql`
        {
          users(
            where:{id_in: ${`["${players.join('", "')}"]`}},
            skip: ${skip},
            first: ${limit},
            orderBy:id
          ) {
            gotchisOwned(first:1,orderBy:modifiedRarityScore, orderDirection:desc){
              id
              name
              collateral
              withSetsNumericTraits
              owner{
                id
              }
            }
          }
        }
      `;

    const response = await request(`${GRAPH_API_URL}${subgraphName}`, query);

    if (response && response.users) {
      responseCount = response.users.length;
      skip += responseCount;
      aavegotchis.push(...response.users);
    }

    // Keeps querying the graph until all players are returned.
  } while (responseCount === limit);

  // const aavegotchiImages = await aavegotchiImageReq(aavegotchis)

  return { aavegotchis };
};

const aavegotchiImageReq = async (aavegotchiIDs) => {
  const limit = 1000;
  let responseCount = 0;
  const aavegotchiImages = [];

  do {
    // Resets counter to avoid infinite loop
    responseCount = 0;

    // Query HAVE TO be ordered because we're using skip, so we need to make sure
    // we always skip the records already returned, and not something random
    const query = gql`
        {
          aavegotchis(where: {id_in: ${`["${aavegotchiIDs.join('", "')}"]`}},
          orderBy:id) {
            id
            svg
          }
        }
      `;

    const response = await request(`https://api.thegraph.com/subgraphs/name/aavegotchi/aavegotchi-svg`, query);

    if (response && response.aavegotchis) {
      responseCount = response.aavegotchis.length;
      aavegotchiImages.push(...response.aavegotchis);
    }

    // Keeps querying the graph until all players are returned.
  } while (responseCount === limit);

  return { aavegotchiImages };
};

export default function NftProvider({ children }) {
  const getAavegotchiNFTs = async (players) => {
    try {
      const aavegotchis = await aavegotchiReq(players);
      const validAavegotchis = Object.values(aavegotchis)[0].filter((item) => {
        return item.gotchisOwned[0] !== undefined;
      });
      const aavegotchIds = validAavegotchis.map((item) => {
        if (item.gotchisOwned[0]) {
          return item.gotchisOwned[0].id;
        }
        return undefined;
      });
      const aavegotchiImages = await aavegotchiImageReq(aavegotchIds);
      const playerAavegotchiData = {};
      aavegotchiImages.aavegotchiImages.map((item, index) => {
        playerAavegotchiData[validAavegotchis[index].gotchisOwned[0].owner.id] = item.svg;
        return item.svg;
      });
      return playerAavegotchiData;
    } catch (err) {
      console.error(err);
      return undefined;
      // TODO return error to client
    }
  };
  return <NftContext.Provider value={{ getAavegotchiNFTs }}>{children}</NftContext.Provider>;
}
