/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { celoID } from 'utils/networks';
import { status } from 'utils/utilities';

import { DefaultSlippage, DefaultSlippageCelo } from 'views/NewGame/components/Slippage/SlippageConfiguration';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    address: '',
    networkId: 0,
    player: {},
    profileImage: {},
    status: status.unloaded,
    hasBalance: false,
    balance: 0,
    walletChange: false,
    refetch: false,
    configuredSlippage: DefaultSlippage,
  },
  reducers: {
    setUsersAddress: (state, { payload }) => {
      state.address = payload ? String(payload).toLowerCase() : '';
    },
    setNetworkId: (state, { payload }) => {
      state.networkId = payload;

      if (Number(payload) === celoID) {
        state.configuredSlippage = DefaultSlippageCelo;
      } else {
        state.configuredSlippage = DefaultSlippage;
      }
    },
    setUserBalance: (state, { payload }) => {
      state.balance = payload;
    },
    setProfileImage: (state, { payload }) => {
      state.profileImage[payload.userAddress.toLowerCase()] = payload.profileImage;
    },
    setPlayerInfo: (state, { payload }) => {
      state.player = payload;
    },
    updatePlayerInfo: (state, { payload }) => {
      state.player = { ...state.player, ...payload };
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setHasBalance: (state, { payload }) => {
      state.hasBalance = payload;
    },
    setWalletChange: (state, { payload }) => {
      state.walletChange = payload;
    },
    setRefetch: (state, { payload }) => {
      state.refetch = payload;
    },
    resetUser: (state) => {
      state.address = '';
      state.networkId = 0;
      state.player = {};
      state.profileImage = {};
      state.walletChange = false;
      state.refetch = false;
      state.configuredSlippage = DefaultSlippage;
      state.balance = 0;
    },
    setConfiguredSlippage: (state, { payload }) => {
      state.configuredSlippage = Number(payload);
    },
  },
});

export const {
  setUsersAddress,
  setNetworkId,
  setPlayerInfo,
  setProfileImage,
  updatePlayerInfo,
  setStatus,
  setHasBalance,
  resetUser,
  setWalletChange,
  setRefetch,
  setConfiguredSlippage,
  setUserBalance,
} = userSlice.actions;

export default userSlice.reducer;
