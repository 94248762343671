import { useCallback } from 'react';
import { weiToERC20, ethToWeiEthersBN } from 'providers/ethersBN';

const FEE_DECIMALS = 8;

export function useStrategySlippage() {
  const getPoolFee = useCallback(async ({ strategyContract }) => {
    const fee = await strategyContract.getFee();
    return Number(weiToERC20(fee, FEE_DECIMALS));
  }, []);

  const getRemainingBalance = useCallback(async ({ strategyContract }) => {
    const remainingBalance = await strategyContract.getTotalAmount();
    return remainingBalance;
  }, []);

  const convertToLiquidityToken = useCallback(async ({ paymentAmount, strategyContract, decimals }) => {
    const paymentAmountWei = String(ethToWeiEthersBN(paymentAmount, decimals));
    const paymentInLiquidityToken = await strategyContract.getLPTokenAmount(paymentAmountWei);
    return paymentInLiquidityToken;
  }, []);

  return { getPoolFee, convertToLiquidityToken, getRemainingBalance };
}
