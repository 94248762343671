/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import { ContractContext } from 'providers/ContractProvider/ContractProvider';
import { NftContext } from 'providers/NftProvider';
import { useSelector, useDispatch } from 'react-redux';
import { resetErrors, setLoaderGetPlayer, setErrorNotWhitelisted } from 'redux/reducers/feedbacks';
import { setPlayerInfo, setStatus, setRefetch } from 'redux/reducers/user';
import { status } from 'utils/utilities';
import { getConnectedNetworkId, getUserAddress } from 'redux/selectors/user.selector';
import { getGameInfo } from 'redux/selectors/game.selector';
import { sdkJsClient } from 'service/sdk';
import { ERRORS } from 'utils/constants';
import { setNft } from 'redux/reducers/players';

export default function useFetchProfile() {
  const dispatch = useDispatch();
  const ContractClient = useContext(ContractContext);
  const NftClient = useContext(NftContext);
  const contract = useSelector((state) => state.pool.info.contract);
  const hasWhitelist = useSelector((state) => state.pool.info.hasWhitelist);
  const usersAddress = useSelector(getUserAddress);
  const walletChange = useSelector((state) => state.user.walletChange);
  const gameInfo = useSelector(getGameInfo);
  const refetch = useSelector((state) => state.user.refetch);
  const nftProvider = useSelector((state) => state.pool.info.nftProvider);
  const connectedNetworkId = useSelector(getConnectedNetworkId);
  const [fetching, setFetching] = useState(false);

  const getNFT = async (player) => {
    if (nftProvider === 'aavegotchi') {
      const res = await NftClient.getAavegotchiNFTs([player]);
      return res;
    }
    return undefined;
  };

  const checkUserCanJoin = async () => {
    try {
      if (hasWhitelist && usersAddress) {
        const res = await sdkJsClient.getProofs(contract, usersAddress);
        if (!res.proofs?.length) {
          return false;
        }
      }
      return true;
    } catch (err) {
      console.error(err);
      if (err.message === 'User not on whitelist') {
        dispatch(setErrorNotWhitelisted(true));
      }
      return false;
    }
  };

  const fetchProfile = async () => {
    let allowance = 0;

    try {
      let currentPlayer = {};
      let playerNFT = [];

      dispatch(setLoaderGetPlayer(true));

      if (Number(connectedNetworkId) === Number(gameInfo.networkId)) {
        allowance = await ContractClient.getAvailableAllowance(usersAddress.toLowerCase());
      }

      try {
        currentPlayer = await sdkJsClient.getPlayerGameStatus({
          playerAddress: usersAddress.toLowerCase(),
          contractAddress: contract,
        });
      } catch (error) {
        // if user doesn't exist, then we just continue w/ the regular logic.
        // if some other error happens, then we need to rethrow the error.
        if (error?.errorCode !== ERRORS.RECORD_NOT_FOUND) {
          throw error;
        }
      }

      playerNFT = await getNFT(usersAddress.toLowerCase());

      const newPlayerInfo = {
        ...currentPlayer,
        allowance,
        allowanceString: allowance.toString(),
      };
      dispatch(setPlayerInfo(newPlayerInfo));

      if (currentPlayer?.address) {
        dispatch(setStatus(status.registered));
      } else {
        dispatch(setStatus(status.unregistered));
      }

      if (playerNFT) {
        dispatch(setNft(playerNFT));
      }
    } catch (err) {
      dispatch(setStatus(status.unregistered));
      dispatch(
        setPlayerInfo({
          allowance,
          allowanceString: allowance.toString(),
        })
      );
    } finally {
      dispatch(setLoaderGetPlayer(false));
    }
  };

  /**
   * triggered on init
   */
  useEffect(() => {
    (async function () {
      if (!usersAddress.length || !contract || !gameInfo?.networkId || !connectedNetworkId) {
        return;
      }

      if (fetching) {
        return;
      }

      setFetching(true);
      try {
        await fetchProfile();
        await checkUserCanJoin();
      } finally {
        setFetching(false);
      }
    })();
  }, [usersAddress, gameInfo, connectedNetworkId]);

  /**
   * triggered on wallet change / join game / withdraw / early withdraw
   */
  useEffect(() => {
    if (!walletChange && !refetch) {
      return;
    }

    // remove old state for previous user when
    // a new user is detected in the wallet.
    (async function fetchProfileData() {
      if (fetching) {
        return;
      }

      setFetching(true);
      try {
        dispatch(resetErrors());
        await fetchProfile();
        await checkUserCanJoin();
        dispatch(setRefetch(false));
      } finally {
        setFetching(false);
      }
    })();
  }, [walletChange, refetch]);
}
