import React from 'react';
import { useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { getMaxPlayers } from 'redux/selectors/pool.selector';

export default function GameFullWarning() {
  const maxPlayers = useSelector(getMaxPlayers);
  return (
    <>
      <p className="red-text">This game is limited to {maxPlayers} players. Unfortunately, all spots were taken.</p>
      <p>
        Want to join another game? - check out our other <HashLink to="/challenges">pools</HashLink> or join our{' '}
        <span role="img" aria-label="hand">
          👉 {'   '}
        </span>
        <HashLink to="/#emailSection">mailing list.</HashLink>
      </p>
    </>
  );
}
