import React from 'react';
import classNames from 'classnames';

import Loading from 'assets/loading.svg';
import Button from 'components/elements/Button';

import useCloseErrorMsg from '../Modals/hooks/useCloseErrorMsg';

const LoadingButton = (props) => {
  const classes = classNames(props.disabled && 'disabled', props.className);
  const closeErrorMsg = useCloseErrorMsg();

  return (
    <Button
      className={classes}
      tag="a"
      id={props.buttonId}
      onClick={() => {
        if (props.disabled) {
          return;
        }
        closeErrorMsg();
        props.onClick();
      }}
    >
      {props.loading ? (
        <>
          Loading <img src={Loading} alt="loading" className="w-28 mx-1" />
        </>
      ) : (
        props.text
      )}
    </Button>
  );
};

export default LoadingButton;
