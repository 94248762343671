import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import VideoExplainer from 'views/NewGame/components/VideoExplainer/VideoExplainer';
import Footer from 'components/layout/Footer';
import { HashContext } from 'providers/HashProvider';
import Header from '../components/layout/Header';

const LayoutDefault = ({ children }) => {
  const [highlightHeader, setHighlightHeader] = useState(true);
  const [showBanner, setShowBanner] = useState(true);
  const [showTopBanner, setShowTopBanner] = useState(false);
  const closeHighlightHeader = () => {
    setHighlightHeader(false);
  };
  const { pathname } = useLocation();

  // list of displayIds of the pools that should have a custom header message (emergency situations)
  // set it as [] or undefined to disable it.
  const customHeaderPools = [];
  // update pool display ID here to enable specific pool banner
  const poolSpecificHeader = 588;
  const poolId = pathname ? String(pathname).toLowerCase().substring('/challenges/'.length) : undefined;
  // remove "true || " condition when it's time to disable banner for all pools.
  const showEmergencyHeader = poolId && customHeaderPools?.includes(parseInt(poolId));
  const showPoolSpecificHeader = poolId === poolSpecificHeader.toString();
  useEffect(() => {
    setShowBanner(highlightHeader && false); // add "&& false" to hide lower banner
    setShowTopBanner(false);
  }, [highlightHeader]);

  const { darkTheme } = useContext(HashContext);
  const wrapClass = classNames('body-wrap', darkTheme ? 'theme--dark' : 'theme--default');
  const classes = classNames(
    'site-content',
    showBanner ? (showTopBanner ? 'alert-top-two-banner' : 'alert-top-one-banner') : 'top-90',
    showTopBanner && !showBanner && 'top-banner'
  );

  return (
    <div className={wrapClass}>
      <Header
        navPosition="right"
        showBanner={showBanner}
        setShowTopBanner={setShowTopBanner}
        showTopBanner={showTopBanner}
        closeHighlightHeader={closeHighlightHeader}
        showEmergencyHeader={showEmergencyHeader}
        showPoolSpecificHeader={showPoolSpecificHeader}
      />
      <main className={classes}>{children}</main>
      <VideoExplainer />
      <div className={classes}>
        <Footer />
      </div>
    </div>
  );
};

export default LayoutDefault;
