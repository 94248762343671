const levels = [
  {
    level: 15,
    Color: 'Black orange stripes',
    minScore: 1000000.0,
    maxScore: 999999999.99,
    intros: [
      "I'm an absolute superstar 🤩",
      'Living the dream 💫',
      'Nothing can stop me now 🔥',
      'Crushing it every day 🚀',
      "I'm unstoppable 🙌",
      "I'm the GOAT 🐐",
    ],
    emoji: '🖤🍊',
  },
  {
    level: 14,
    Color: 'Black',
    minScore: 750000.0,
    maxScore: 999999.99,
    intros: [
      "I'm killing it 🔥",
      'Making my mark 💪',
      'Rising to the top 🏆',
      'Living my best life 🌟',
      'On a roll 🤑',
    ],
    emoji: '🖤',
  },
  {
    level: 13,
    Color: 'pink /light stripes',
    minScore: 500000.0,
    maxScore: 749999.99,
    intros: [
      'Taking the world by storm 🌪️',
      'Nothing can hold me back 🤯',
      'Reaching new heights 🚁',
      'Pushing myself to the limit ⚡',
      'Slaying every day 🦸‍♀️',
    ],
    emoji: '💗',
  },
  {
    level: 12,
    Color: 'purple',
    minScore: 250000.0,
    maxScore: 499999.99,
    intros: [
      'Living my best life 🌟',
      'Making every day count 🌞',
      'Taking it to the next level 🚀',
      'The sky is the limit 🌤️',
      'Living like a boss 💼',
    ],
    emoji: '💜',
  },
  {
    level: 11,
    Color: 'pink',
    minScore: 150000.0,
    maxScore: 249999.99,
    intros: [
      'Every day is a new adventure 🗺️',
      'Going the extra mile 🏃‍♀️',
      'Creating my own destiny 🌟',
      'Living my best life 🌞',
      'The world is my oyster 🌍',
    ],
    emoji: '💗',
  },
  {
    level: 10,
    Color: 'blue stripes',
    minScore: 100000.0,
    maxScore: 149999.99,
    intros: [
      'Living the dream 💭',
      'Making it happen 💫',
      'The best is yet to come 🌅',
      'I was born ready 🌟',
      "I'm on fire 🔥",
    ],
    emoji: '💙',
  },
  {
    level: 9,
    Color: 'dark blue',
    minScore: 75000.0,
    maxScore: 99999.99,
    intros: [
      'The adventure continues 🚀',
      'Living life to the fullest 🌞',
      'The journey is the reward 🏆',
      'I am the master of my fate 🌟',
      'Rising to the challenge ⛰️',
    ],
    emoji: '🔵',
  },

  {
    level: 8,
    Color: 'purple',
    minScore: 50000.0,
    maxScore: 999999999.99,
    intros: [
      'I was born to do this 💥',
      'My potential is limitless 🌅',
      'The journey to success is exhilarating 🎢',
      "I'm unstoppable and unbreakable 💪",
      "I'm a superstar 🌟",
    ],
    emoji: '🟣',
  },
  {
    level: 7,
    Color: 'red',
    minScore: 25000.0,
    maxScore: 49999.99,
    intros: [
      'Success is mine for the taking 💪',
      'Confidence is my middle name 😎',
      "I'm a force to be reckoned with 🌪️",
      'The fire in me burns bright 🔥',
      'The road to success is paved 🛣️',
    ],
    emoji: '🔴',
  },
  {
    level: 6,
    Color: 'blue',
    minScore: 10000.0,
    maxScore: 24999.99,
    intros: [
      'The path to greatness is clear 🌟',
      'The world is at my feet 🌎',
      'Keep pushing, keep climbing 🧗‍♀️',
      'Success is mine for the taking 💰',
      "I'm the master of my destiny 🗝️",
    ],
    emoji: '🔵',
  },
  {
    level: 5,
    Color: 'green',
    minScore: 5000.0,
    maxScore: 9999.99,
    intros: [
      'Just getting started on greatness 👊',
      "I'm living my best life 🌟",
      'Nothing can hold me back 💪',
      'Going above and beyond 🚀',
      "The sky's the limit ☁️",
    ],
    emoji: '🟢',
  },
  {
    level: 4,
    Color: 'yellow',
    minScore: 2500.0,
    maxScore: 4999.99,
    intros: [
      'Onward and upward 🚀',
      "I'm making waves 🌊",
      "Can't stop, won't stop 🙅‍♀️",
      'The future looks bright ☀️',
      'This is just the beginning 🌅',
    ],
    emoji: '🟡',
  },
  {
    level: 3,
    Color: 'orange',
    minScore: 1000.0,
    maxScore: 2499.99,
    intros: [
      "I'm feeling unstoppable 💪",
      'Making steady progress 📈',
      'Just getting warmed up 🔥',
      "Let's keep it going 🙌",
      "I'm on a roll 🚀",
    ],
    emoji: '🍊',
  },
  {
    level: 2,
    Color: 'grey',
    minScore: 500.0,
    maxScore: 999.99,
    intros: [
      "I'm making progress 📈",
      'Pushing forward 🚶‍♀️',
      'Moving on up 📈',
      'Nothing can stop me 💪',
      'The climb continues 🧗‍♀️',
    ],
    emoji: '🟡',
  },
  {
    level: 1,
    Color: 'no halo',
    minScore: 0.0,
    maxScore: 499.99,
    intros: [
      'The journey begins',
      'This is only day one',
      'The starting line awaits',
      'Time to make things happen',
      "From here, the sky's the limit",
      'The first step towards greatness',
      'Ready, set, go!',
      'Embarking on a new adventure',
      'The world is my oyster',
      'Onwards and upwards',
    ],
    emoji: '⭕️',
  },
  {
    level: 1,
    Color: 'no halo',
    minScore: 54000,
    maxScore: 100000,
    intros: [
      'The journey begins',
      'This is only day one',
      'The starting line awaits',
      'Time to make things happen',
      "From here, the sky's the limit",
      'The first step towards greatness',
      'Ready, set, go!',
      'Embarking on a new adventure',
      'The world is my oyster',
      'Onwards and upwards',
    ],
    emoji: '⭕️',
  },
];

export default levels;
