import { displaySegment, titleCase } from 'utils/utilities';
import { getDepositRoundMeasure } from 'utils/numberFormat';
import { MECHANISM_TYPE, DEPOSIT_TYPE } from 'utils/constants';
import { useSelector } from 'react-redux';
import { getPlayer } from 'redux/selectors/user.selector';

const getRound = (gameInfo, isCompleted, isWaitingSegment) => {
  if (isCompleted) {
    return 'Completed';
  }
  if (isWaitingSegment) {
    return 'Waiting round (final)';
  }
  return `${displaySegment(gameInfo.currentSegment)} / ${displaySegment(~~gameInfo.paymentCount)}`;
};

function getDepositAmount(gameInfo, playerInfo) {
  const { depositType, paymentAmount, stableToken, maxDepositAmount } = gameInfo;
  const { depositAmount: playerDepositAmount, withdrawn, isWinner } = playerInfo;
  const hasWithdrawn = withdrawn && !isWinner;

  if (depositType === DEPOSIT_TYPE.Flexible) {
    if (playerDepositAmount && !hasWithdrawn) {
      return {
        depositAmount: `${playerDepositAmount} ${stableToken?.toUpperCase()}`,
        depositAmountTooltip: 'The selected amount you need to deposit each round to be a winner',
      };
    }

    return {
      depositAmount: `up to ${maxDepositAmount} ${stableToken?.toUpperCase()}`,
      depositAmountTooltip: `The max deposit amount is ${maxDepositAmount} ${stableToken?.toUpperCase()}`,
    };
  }

  return {
    depositAmount: `${paymentAmount} ${stableToken?.toUpperCase()}`,
    depositAmountTooltip: 'The amount you need to deposit each round to be a winner',
  };
}

function getHodlPoolStats({ info, gameInfo, isCompleted, isWaitingSegment, playerInfo }) {
  const { depositAmount, depositAmountTooltip } = getDepositAmount(info, playerInfo);
  const singleDeposit = parseInt(info.paymentCount) === 1;

  return [
    {
      label: `Deposits`,
      confirmLabel: 'Deposits',
      data: singleDeposit ? info.paymentCount : getDepositRoundMeasure(gameInfo).depositAndFrequency,
      frequency: getDepositRoundMeasure(gameInfo).frequency,
      tooltip: singleDeposit
        ? 'Make a single deposit and wait for the challenge to conclude to secure your win'
        : 'The total number of required deposits and the frequency between each deposit',
    },
    {
      label: 'Deposit Amount',
      data: depositAmount,
      confirmLabel: 'Recurring deposits',
      tooltip: depositAmountTooltip,
    },
    {
      label: 'GG Score',
      confirmLabel: 'GG Score',
      // Temporary "hack" to handle games with GGScoreV2 where score depends on deposit time/amount
      data: info.ggScore > 1 ? info.ggScore : 'Dynamic',
      tooltip: 'The maximum GG Score you can earn by completing all deposits',
    },
    {
      label: 'Risk | Strategy',
      confirmLabel: 'Strategy',
      data: `${titleCase(info.strategyProvider)}`,
      rawData: info.riskProfile,
      tooltip: 'The risk and yield strategy associated with this challenge. Click the risk icon to learn more.',
    },
    {
      label: 'Challenge Progress',
      data: getRound(gameInfo, isCompleted, isWaitingSegment),
      confirmLabel: 'Pool Progress',
      tooltip: 'The current round (out of total rounds in the challenge)',
    },
  ];
}

function getSavingsPoolStats({ info, gameInfo, isCompleted, isWaitingSegment, playerInfo }) {
  const { depositAmount, depositAmountTooltip } = getDepositAmount(info, playerInfo);
  return [
    {
      label: `Deposits`,
      confirmLabel: 'Deposits',
      data: getDepositRoundMeasure(gameInfo).depositAndFrequency,
      frequency: getDepositRoundMeasure(gameInfo).frequency,
      tooltip: 'The total number of required deposits and the frequency between each deposit',
    },
    {
      label: 'Deposit Amount',
      data: depositAmount,
      confirmLabel: 'Recurring deposits',
      tooltip: depositAmountTooltip,
    },
    {
      label: 'GG Score',
      confirmLabel: 'GG Score',
      // Temporary "hack" to handle games with GGScoreV2 where score depends on deposit time/amount
      data: info.ggScore > 1 ? info.ggScore : 'Dynamic',
      tooltip: 'The maximum GG Score you can earn by completing all deposits',
    },
    {
      label: 'Risk | Strategy',
      confirmLabel: 'Strategy',
      data: `${titleCase(info.strategyProvider)}`,
      rawData: info.riskProfile,
      tooltip: 'The risk and yield strategy associated with this challenge. Click the risk icon to learn more.',
    },
    {
      label: 'Challenge Progress',
      data: getRound(gameInfo, isCompleted, isWaitingSegment),
      confirmLabel: 'Game status',
      confirmData: displaySegment(gameInfo.paymentAmount),
      tooltip: 'The current round of the challenge, out of the total number of rounds',
    },
  ];
}

export default function useViewableStats() {
  const gameInfo = useSelector((state) => state.game.info);
  const info = useSelector((state) => state.pool.info);
  const isCompleted = useSelector((state) => state.game.stats.isCompleted);
  const isWaitingSegment = useSelector((state) => state.game.stats.isWaitingSegment);
  const playerInfo = useSelector(getPlayer);

  const savingsPoolStats = getSavingsPoolStats({ info, playerInfo, gameInfo, isCompleted, isWaitingSegment });
  const hodlPoolStats = getHodlPoolStats({ info, playerInfo, gameInfo, isCompleted, isWaitingSegment });

  if (info.mechanismType === MECHANISM_TYPE.Hodl) {
    return hodlPoolStats;
  }

  return savingsPoolStats;
}
