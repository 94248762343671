import React from 'react';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import Tooltip from 'components/elements/Tooltip';
import RiskProfileIcon from 'components/elements/RiskProfileIcon';

const ExtraTooltip = ({ content }) => (
  <Tooltip
    placement="bottom"
    content={content}
    displayContent={() => (
      <span className="render-data-icon">
        <i className="bi bi-info" />
      </span>
    )}
  />
);

export default function RenderData({ data, tooltipText, label, measure, riskProfile, extraTooltip }) {
  const classes = classNames('game-state-value mb-6', !extraTooltip && 'd-inline mr-10');

  const displayContent = () => (
    <div>
      <h5 className="game-state-title pb-10">{label}</h5>
      <p className={classes}>
        {data} {measure}
        {extraTooltip && <ExtraTooltip content={extraTooltip} />}
      </p>

      {riskProfile ? (
        <RiskProfileIcon grayscale riskProfile={riskProfile} className="risk-profile-icon" alt="risk-profile-icon" />
      ) : null}
    </div>
  );

  return (
    <div className="game-stat">
      <Container>
        <Tooltip placement="bottom" content={tooltipText} displayContent={displayContent} />
      </Container>
    </div>
  );
}
