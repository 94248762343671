import { useState, useEffect, useCallback } from 'react';
import { sdkJsClient } from 'service/sdk';
import { IMAGE_RESOLUTION } from 'utils/constants';

export async function getPlayerGGScore({ userAddress, leaderboard }) {
  const [playerScoreRequest, profileImage] = await Promise.all([
    sdkJsClient.getPlayerGGScore(userAddress, leaderboard),
    sdkJsClient.getProfileImage({
      playersAddress: [userAddress],
      resolution: IMAGE_RESOLUTION.Low,
    }),
  ]);
  const [playerImg] = profileImage.playersProfileImage;
  return { playerScoreRequest, profileImage: playerImg };
}

export function useGetPlayerScore(userAddress, leaderboard) {
  const [loading, setLoading] = useState(false);
  const [playerScore, setPlayerScore] = useState({});

  const getPlayerScore = useCallback(() => {
    async function fetch() {
      if (!userAddress || !leaderboard) {
        return;
      }
      setLoading(true);
      try {
        const { playerScoreRequest, profileImage } = await getPlayerGGScore({ userAddress, leaderboard });
        setPlayerScore({ ...playerScoreRequest, profileImage });
      } catch (err) {
        //
      } finally {
        setLoading(false);
      }
    }
    fetch();
    // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  }, [userAddress, leaderboard]);

  useEffect(() => {
    getPlayerScore();
  }, [getPlayerScore]);

  return [playerScore, getPlayerScore, loading];
}
