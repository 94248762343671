import React from 'react';
import classNames from 'classnames';
import { levelsLink } from 'utils/brand';
import LevelsGraphic from '../../../../assets/images/levels.svg';

const Levels = ({ positionClass }) => {
  const classes = classNames('levels-graphic hide-mobile', positionClass);
  return (
    <>
      <div className={classes}>
        <img
          src={LevelsGraphic}
          alt="a graphic showing the halo color levels. Vist the info link below for full details."
        />
        <div className="info">
          <a href={levelsLink} target="_blank" className="levels-link" rel="noreferrer">
            info
          </a>
        </div>
      </div>
    </>
  );
};

export default Levels;
