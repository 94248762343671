export const docsURL = 'https://docs.halofi.me/hub';
export const saveAppURL = 'https://save.halofi.me/';
export const brand = 'halofi';
export const brandCaps = 'HaloFi';
export const appSubdomain = 'app';
export const brandDomain = 'halofi.me';
export const snapshotURL = 'https://vote.halofi.me/#/';
export const twitterHandle = '@halofi_me';
export const twitterURL = 'https://twitter.com/halofi_me';
export const blogURL = 'https://medium.com/halofi';
export const githubURL = 'https://github.com/Good-Ghosting';
export const youtubeURL = 'https://www.youtube.com/@halofi_me';
export const contactEmail = 'hello@halofi.me';
export const levelsLink = 'https://docs.halofi.me/hub/halofi-challenges/halofi-heroes-levels-and-gg-score';
export const brandAnnouncement =
  'https://medium.com/goodghosting/say-hey-to-halofi-our-new-brand-e6a7870ca7d2?source=friends_link&sk=e1e85f3b8751b9ab2ebe2546b1d845c7';
export const hashtag = 'BeYourBestFinancialSelf';

export const topAddresses = [
  { address: 'clementfd.eth', raw: false, emoji: '😇' }, // clement pool angel
  { address: '0x540c44FF7B71F2487Cd11aaF53aeC1a85BFE8f7a', raw: true, emoji: '👑' }, // llpo top 100
  { address: '0xBe1D7136dc7da1a6C01Fb8a8732C0a8095380494', raw: true, emoji: '🦸' }, // mobius community hero
];
