import React from 'react';
import { useSelector } from 'react-redux';
import infoAnimation from 'assets/animations/info.gif';
import tick from 'assets/animations/tick.gif';
import {
  getLoaderEarlyWithdraw,
  getErrorTransaction,
  getErrorRefusedSignature,
  getSuccessEarlyWithdraw,
  getShowEarlyWithdrawModal,
  getLoaderGasPrices,
  getErrorTimeout,
} from 'redux/selectors/feedbacks.selector';
import { getPoolInfo, getEarlyWithdrawFee } from 'redux/selectors/pool.selector';
import { getPlayer } from 'redux/selectors/user.selector';
import GasPicker from 'components/sections/GasPicker';
import ExplorerRoute from 'utils/ExplorerRoute';
import Modal from 'components/elements/Modal';
import ModalHeader from 'components/elements/ModalHeader';
import ModalFooter from 'components/elements/ModalFooter';
import { docsURL } from 'utils/brand';
import LoadingButton from '../../common/LoadingButton';
import { TransactionError, TransactionRefusedError, TimeoutError } from '../../common/Errors';
import useEarlyWithdraw from '../hooks/useEarlyWithdraw';
import SlippageSection from '../../Slippage/SlippageSection';

export default () => {
  const earlyWithdrawFee = useSelector(getEarlyWithdrawFee);
  const loaderEarlyWithdraw = useSelector(getLoaderEarlyWithdraw);
  const loaderGasPrices = useSelector(getLoaderGasPrices);
  const errorTransaction = useSelector(getErrorTransaction);
  const errorRefusedSignature = useSelector(getErrorRefusedSignature);
  const timeoutError = useSelector(getErrorTimeout);
  const showEarlyWithdrawModal = useSelector(getShowEarlyWithdrawModal);
  const playerInfo = useSelector(getPlayer);
  const successEarlyWithdrawMap = useSelector(getSuccessEarlyWithdraw);
  const poolInfo = useSelector(getPoolInfo);
  const [earlyWithdraw, closeEarlyWithdrawModal] = useEarlyWithdraw();
  const successEarlyWithdraw = successEarlyWithdrawMap[poolInfo.id];

  return (
    <Modal show={showEarlyWithdrawModal} handleClose={closeEarlyWithdrawModal}>
      {!successEarlyWithdraw && <ModalHeader header="Withdraw deposit early" onClose={closeEarlyWithdrawModal} />}
      {successEarlyWithdraw && (
        <div>
          <ModalHeader onClose={closeEarlyWithdrawModal} />
          <img src={tick} alt="tick animation" />
          <h4>You successfully withdrew your funds! </h4>
          <ExplorerRoute />
        </div>
      )}
      {!successEarlyWithdraw && (
        <>
          <img src={infoAnimation} className="w-120" alt="info animation" />
          <p className="font-size-14 fw-600">
            {' '}
            Early withdraws incur a {earlyWithdrawFee}%{' '}
            <a
              href={`${docsURL}/halofi-challenges/technical-documentation/fees#early-withdrawal-fee`}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              fee
            </a>
            {playerInfo.isAlive ? ', and make you lose the game.' : '.'}
          </p>
          <p className="font-size-16 mb-10 pl-15">
            {' '}
            If you want your full deposit back, withdraw at the end of the game.
          </p>
          <div className="pl-20">
            <GasPicker />
            <SlippageSection />
          </div>
          {errorTransaction && !successEarlyWithdraw && <TransactionError />}
          {errorRefusedSignature && !successEarlyWithdraw && <TransactionRefusedError />}
          {timeoutError && <TimeoutError />}
          <ModalFooter>
            <LoadingButton
              onClick={earlyWithdraw}
              loading={loaderEarlyWithdraw}
              text="Withdraw early anyway"
              disabled={loaderEarlyWithdraw || loaderGasPrices}
              className="button-primary"
              buttonId="confirm-early-withdraw"
            />
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
