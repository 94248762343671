import Image from 'components/elements/Image';
import React, { useEffect } from 'react';

import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { useQuery } from 'Hooks/useQuery';
import squidrouterConfig from 'utils/squidrouter-config.json';
import FrontPage from 'views/Front/FrontPage';
import useScrollToTop from 'Hooks/useScrollToTop';
import useRampConf, { defaultAsset, sharedRampOpts } from './hooks/useRampConf';

export default function Buy() {
  const query = useQuery();
  // 👇️ scroll to top on page load
  useScrollToTop();

  const encodedConfig = encodeURIComponent(JSON.stringify(squidrouterConfig));

  const { usersAddress, swapAmount, asset, userConnectedNetworkId, alertMessage } = useRampConf(query.get('token'));
  useEffect(() => {
    document.getElementById('ramp-container').replaceChildren();
    (function initRamp() {
      return new RampInstantSDK({
        ...sharedRampOpts,
        ...(swapAmount && { swapAmount }),
        ...(usersAddress && { userAddress: usersAddress }),
        defaultAsset: asset || defaultAsset,
        containerNode: document.getElementById('ramp-container'),
      })
        .show()
        .on('WIDGET_CLOSE', () => initRamp());
    })();
  }, [userConnectedNetworkId, usersAddress]);

  return (
    <div id="buy">
      <div className="container mb-5">
        <div className="d-flex">
          <div className="d-flex flex-column text-start">
            <h1 className="mt-6">Get crypto</h1>
            <p className="mb-10">
              Unlock the world of crypto with ease. Transfer your assets effortlessly across 10+ blockchains and leading
              exchanges exchanges like never before! Buy, sell, bridge, and swap securely to shape your financial
              future.
            </p>
          </div>
          <Image
            className="buy-image"
            src={require('assets/images/get-crypto.png')}
            alt="Ramp cards"
            style={{ width: '15%', height: 'auto' }}
          />
        </div>
        <div className="front">
          <FrontPage />
        </div>
        <div className="d-flex widgets-wrapper flex-column flex-md-row align-items-center align-items-md-start">
          <div className="d-flex flex-column text-start">
            <h3>Bridge and Swap</h3>
            <p>
              Easily and securely bridge and swap your assets across multiple blockchains with Squid. Embrace simplicity
              with no technical expertise required.
            </p>
            <iframe
              title="squid_widget"
              width="360"
              height="740"
              data-hj-allow-iframe="true"
              src={`https://widget.squidrouter.com/iframe?config=${encodedConfig}`}
            />
            <p className="mb-30">
              <span className="fw-600">Side note: </span>
              <span className="fst-italic">
                The widget only displays when allowing this page to use third-party cookies from{' '}
                <a href="https://widget.squidrouter.com/" target="_blank" rel="noreferrer">
                  squidrouter
                </a>
                . If you can’t see the widget above, check these{' '}
                <a
                  href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies-for-a-specific-site"
                  target="_blank"
                  rel="noreferrer"
                >
                  instructions
                </a>{' '}
                on how to enable cookies on your browser.
              </span>
            </p>
          </div>
          <div className="d-flex flex-column text-start">
            <h3 className="text-left">Buy and Sell</h3>
            <p>
              Buy and sell crypto in minutes! Use your favorite payment method like Apple Pay, Google Pay, Revolut,
              credit card or bank transfer. Cash out effortlessly.
            </p>
            {alertMessage && <p style={{ color: 'red' }}>{alertMessage}</p>}
            <div id="ramp-container" />
          </div>
        </div>
      </div>
    </div>
  );
}
