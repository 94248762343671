/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState: {
    customInput: false,
    revokeApproval: false,
    // loaders
    loaderApproveDeposit: false,
    loaderEarlyWithdraw: false,
    loaderGetPlayer: false,
    loaderInitGame: true,
    loaderJoinGame: false,
    loaderMakeDeposit: false,
    loaderUpdatingGame: false,
    loaderWithdraw: false,
    loaderGasPrices: false,
    loaderDashboardProfile: false,
    // errors
    errorGeneral: false,
    errorNotWhitelisted: false,
    errorRedeem: false,
    errorDepositIntoExternalPool: false,
    errorTransaction: false,
    errorTransactionMessage: '',
    errorRefusedSignature: false,
    errorTimeout: false,
    // success
    successApproveDeposit: false,
    successJoinGame: {},
    successEarlyWithdraw: {},
    successMakeDeposit: {},
    successWithdraw: {},
    successRedeem: false,
    successDepositIntoExternalPool: false,
    // modals
    showConfirmModal: false,
    showCustomiseAvatarModal: false,
    showCustomiseAvatarThankYouModal: false,
    showDepositModal: false,
    showEarlyWithdrawModal: false,
    showWithdrawModal: false,
    showDisclaimerModal: false,
    showParentChallengeModal: false,
    showMintNFTModal: false,
    showVideoModal: false,
    showUserSurveyModal: false,
    showInfoModal: {},
  },
  reducers: {
    setCustomInput: (state, { payload }) => {
      state.customInput = payload;
    },
    setRevokeApproval: (state, { payload }) => {
      state.revokeApproval = payload;
    },
    setLoaderInitGame: (state) => {
      state.loaderInitGame = false;
    },
    setLoaderApproveDeposit: (state, { payload }) => {
      state.loaderApproveDeposit = payload;
    },
    setLoaderJoinGame: (state, { payload }) => {
      state.loaderJoinGame = payload;
    },
    setLoaderWithdraw: (state, { payload }) => {
      state.loaderWithdraw = payload;
    },
    setLoaderEarlyWithdraw: (state, { payload }) => {
      state.loaderEarlyWithdraw = payload;
    },
    setLoaderUpdatingGame: (state, { payload }) => {
      state.loaderUpdatingGame = payload;
    },
    setLoaderMakeDeposit: (state, { payload }) => {
      state.loaderMakeDeposit = payload;
    },
    setLoaderDashboardProfile: (state, { payload }) => {
      state.loaderDashboardProfile = payload;
    },
    setLoaderGasPrices: (state, { payload }) => {
      state.loaderGasPrices = payload;
    },
    setLoaderGetPlayer: (state, { payload }) => {
      state.loaderGetPlayer = payload;
    },
    setErrorGeneral: (state, { payload }) => {
      state.errorGeneral = payload;
    },
    setErrorNotWhitelisted: (state, { payload }) => {
      state.errorNotWhitelisted = payload;
    },
    setErrorRedeem: (state, { payload }) => {
      state.errorRedeem = payload;
    },
    setErrorDepositIntoExternalPool: (state, { payload }) => {
      state.errorDepositIntoExternalPool = payload;
    },
    setErrorTransaction: (state, { payload }) => {
      state.errorTransaction = payload;
      if (payload === false) {
        state.errorTransactionMessage = '';
      }
    },
    setErrorTransactionMessage: (state, { payload }) => {
      state.errorTransactionMessage = payload;
    },
    setErrorRefusedSignature: (state, { payload }) => {
      state.errorRefusedSignature = payload;
    },
    setErrorTimeout: (state, { payload }) => {
      state.errorTimeout = payload;
    },
    setSuccessApproveDeposit: (state, { payload }) => {
      state.successApproveDeposit = payload;
    },
    setSuccessJoinGame: (state, { payload }) => {
      const { success, id } = payload;
      state.successJoinGame[id] = success;
    },
    setSuccessEarlyWithdraw: (state, { payload }) => {
      const { success, id } = payload;
      state.successEarlyWithdraw[id] = success;
    },
    setSuccessMakeDeposit: (state, { payload }) => {
      const { success, id } = payload;
      state.successMakeDeposit[id] = success;
    },
    setSuccessWithdraw: (state, { payload }) => {
      const { success, id } = payload;
      state.successWithdraw[id] = success;
    },
    setSuccessRedeem: (state, { payload }) => {
      state.successRedeem = payload;
    },
    setSuccessDepositIntoExternalPool: (state, { payload }) => {
      state.successDepositIntoExternalPool = payload;
    },
    setShowConfirmModal: (state, { payload }) => {
      state.showConfirmModal = payload;
    },
    setShowCustomiseAvatarModal: (state, { payload }) => {
      state.showCustomiseAvatarModal = payload;
    },
    setShowDisclaimerModal: (state, { payload }) => {
      state.showDisclaimerModal = payload;
    },
    setShowCustomiseAvatarThankYouModal: (state, { payload }) => {
      state.showCustomiseAvatarThankYouModal = payload;
    },
    setShowDepsitModal: (state, { payload }) => {
      state.showDepositModal = payload;
    },
    setShowEarlyWithdrawModal: (state, { payload }) => {
      state.showEarlyWithdrawModal = payload;
    },
    setShowParentChallengeModal: (state, { payload }) => {
      state.showParentChallengeModal = payload;
    },
    setShowMintNFTModal: (state, { payload }) => {
      state.showMintNFTModal = payload;
    },
    setShowInfoModal: (state, { payload }) => {
      state.showInfoModal = payload;
    },
    setShowWithdrawModal: (state, { payload }) => {
      state.showWithdrawModal = payload;
    },
    setShowVideoModal: (state, { payload }) => {
      state.showVideoModal = payload;
    },
    setShowUserSurveyModal: (state, { payload }) => {
      state.showUserSurveyModal = payload;
    },
    resetCustomInput: (state) => {
      state.customInput = false;
    },
    resetErrors: (state) => {
      state.errorGeneral = false;
      state.errorNotWhitelisted = false;
      state.errorRedeem = false;
      state.errorDepositIntoExternalPool = false;
      state.errorTransaction = false;
      state.errorRefusedSignature = false;
      state.errorTimeout = false;
    },
    resetSuccess: (state) => {
      state.successApproveDeposit = false;
      state.successJoinGame = {};
      state.successEarlyWithdraw = {};
      state.successMakeDeposit = {};
      state.successWithdraw = {};
      state.successRedeem = false;
      state.successDepositIntoExternalPool = false;
    },
    resetFeedbacks: (state) => {
      state.errorGeneral = false;
      state.loaderInitGame = true;
      state.loaderApproveDeposit = false;
      state.loaderJoinGame = false;
      state.loaderWithdraw = false;
      state.loaderMakeDeposit = false;
      state.loaderGasPrices = false;
      state.loaderDashboardProfile = false;
      state.loaderEarlyWithdraw = false;
      state.loaderUpdatingGame = false;
      state.loaderGetPlayer = false;
      state.errorNotWhitelisted = false;
      state.errorRedeem = false;
      state.errorDepositIntoExternalPool = false;
      state.errorTransaction = false;
      state.errorRefusedSignature = false;
      state.errorTimeout = false;
      state.successApproveDeposit = false;
      state.successJoinGame = {};
      state.successEarlyWithdraw = {};
      state.successMakeDeposit = {};
      state.successWithdraw = {};
      state.successRedeem = false;
      state.successDepositIntoExternalPool = false;
      state.showConfirmModal = false;
      state.showCustomiseAvatarModal = false;
      state.showCustomiseAvatarThankYouModal = false;
      state.showDepositModal = false;
      state.showEarlyWithdrawModal = false;
      state.showParentChallengeModal = false;
      state.showMintNFTModal = false;
      state.showWithdrawModal = false;
      state.showDisclaimerModal = false;
      state.showVideoModal = false;
      state.showUserSurveyModal = false;
    },
  },
});

export default feedbacksSlice.reducer;
export const {
  setCustomInput,
  setRevokeApproval,
  setLoaderInitGame,
  setLoaderApproveDeposit,
  setLoaderJoinGame,
  setLoaderWithdraw,
  setLoaderEarlyWithdraw,
  setLoaderUpdatingGame,
  setLoaderGetPlayer,
  setLoaderMakeDeposit,
  setLoaderGasPrices,
  setLoaderDashboardProfile,
  setErrorGeneral,
  setErrorNotWhitelisted,
  setErrorRedeem,
  setErrorDepositIntoExternalPool,
  setErrorTransaction,
  setErrorTransactionMessage,
  setErrorRefusedSignature,
  setErrorTimeout,
  setSuccessApproveDeposit,
  setSuccessJoinGame,
  setSuccessEarlyWithdraw,
  setSuccessMakeDeposit,
  setSuccessWithdraw,
  setSuccessRedeem,
  setSuccessDepositIntoExternalPool,
  setShowConfirmModal,
  setShowCustomiseAvatarModal,
  setShowDisclaimerModal,
  setShowCustomiseAvatarThankYouModal,
  setShowDepsitModal,
  setShowEarlyWithdrawModal,
  setShowParentChallengeModal,
  setShowMintNFTModal,
  setShowInfoModal,
  setShowWithdrawModal,
  setShowVideoModal,
  setShowUserSurveyModal,
  resetCustomInput,
  resetErrors,
  resetSuccess,
  resetFeedbacks,
} = feedbacksSlice.actions;
