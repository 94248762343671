/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { networkData, celoID } from 'utils/networks';
import { setUsersAddress, setNetworkId, resetUser } from 'redux/reducers/user';
import { setIsUsingValora } from 'redux/reducers/wallet';
import { Contract } from 'ethers';

import { getConnectedNetworkId } from 'redux/selectors/user.selector';

import { useAccount, useNetwork, useSwitchNetwork, useDisconnect, useConnect } from 'wagmi';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { AnalyticsProviderContext } from './AnalyticsProvider';
import { useEthersSigner } from './EthersProvider';

const WalletConnectConnector = 'walletconnect';
const MetamaskConnector = 'metamask';
const InjectedConnector = 'injected';

export const Web3Context = React.createContext();

export default function Web3Provider({ children }) {
  const dispatch = useDispatch();
  const info = useSelector((state) => state.pool.info);
  const connectedNetworkId = useSelector(getConnectedNetworkId);
  const { connectWallet, changeChain } = useContext(AnalyticsProviderContext);

  const [isNetworkChanging, setIsNetworkChanging] = useState(false);

  const { chain } = useNetwork({});
  const chainId = chain?.id;
  const signer = useEthersSigner();
  const { address: userAddress, connector } = useAccount();
  const { connectAsync } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { openConnectModal } = useConnectModal();
  const { switchNetworkAsync } = useSwitchNetwork({
    onSettled: () => {
      setIsNetworkChanging(false);
    },
  });

  const connectorId = connector?.id?.toLowerCase();
  const providerUrl = networkData[chainId]?.rpcURL;

  const signerIsReady = !!signer && !isNetworkChanging;
  const isNetworkUpdatedOnStore = Number(connectedNetworkId) === Number(chainId);

  useEffect(() => {
    if (userAddress && signerIsReady && isNetworkUpdatedOnStore) {
      connectWallet(userAddress.toString(), chainId);
      dispatch(setUsersAddress(userAddress.toString()));
    }
  }, [userAddress, signerIsReady, isNetworkUpdatedOnStore, dispatch]);

  useEffect(() => {
    const updateNetworkId = async () => {
      try {
        if (chainId && signerIsReady) {
          const { chainId: underlyingProviderNetwork } = await signer.provider.getNetwork();

          if (Number(chainId) === Number(underlyingProviderNetwork)) {
            dispatch(setNetworkId(chainId));
          }
        }
      } catch (e) {
        console.error('Wallet Provider is connected to the wrong chain, re-connecting');
        await disconnectAsync();
        await connectAsync({ connector, chainId: Number(chainId) });
      }
    };
    updateNetworkId();
  }, [chainId, signerIsReady, dispatch]);

  useEffect(() => {
    const isValora = connectorId && connectorId === WalletConnectConnector && Number(chainId) === Number(celoID);

    dispatch(setIsUsingValora(isValora));
  }, [dispatch, connectorId, chainId]);

  const logOut = useCallback(async () => {
    await disconnectAsync();
    dispatch(resetUser());
    localStorage.clear();
    sessionStorage.clear();
  }, [dispatch, disconnectAsync]);

  const init = async () => {
    openConnectModal();
  };

  const changeNetwork = useCallback(
    async ({ networkId = info.networkId }) => {
      const startSwitchNetwork = async () => {
        if (switchNetworkAsync) {
          await switchNetworkAsync(Number(networkId));
        }
      };

      const startSwitchNetworkMetamask = async () => {
        // https://github.com/rainbow-me/rainbowkit/issues/686
        // Sometimes rainbow modal open o disconnect
        await disconnectAsync();
        await connectAsync({ connector, chainId: Number(networkId) });
      };

      /*
        Wagmi/Metamask Bug
        https://github.com/wagmi-dev/wagmi/issues/180
        https://github.com/wagmi-dev/wagmi/issues/287

        Workaround : When using metamask disconnect and connect again when switching network
      */

      if (connectorId === MetamaskConnector || connectorId === InjectedConnector) {
        try {
          await startSwitchNetworkMetamask();
          changeChain(chainId);
          return;
        } catch (e) {
          console.error(e);
        }
      }

      setIsNetworkChanging(true);
      await startSwitchNetwork();
      changeChain(chainId);
    },
    [info, connectorId, connector, disconnectAsync, connectAsync, switchNetworkAsync]
  );

  const createContract = useCallback(
    ({ address, abi }) => {
      if (signer) {
        const contract = new Contract(address, abi, signer);
        return contract;
      }

      throw new Error('Tried to create new contract instance but signer is not defined');
    },
    [signer]
  );

  const canChangeNetwork = !!switchNetworkAsync;

  return (
    <Web3Context.Provider
      value={{
        providerUrl,
        provider: signer?.provider,
        signer,
        init,
        logOut,
        changeNetwork,
        canChangeNetwork,
        createContract,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
}
