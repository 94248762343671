import React, { useContext } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HashContext } from 'providers/HashProvider';

export default ({ content, placement, displayContent }) => {
  const { darkTheme } = useContext(HashContext);
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 500 }}
      overlay={
        <Tooltip id="button-tooltip-39" className={`${darkTheme ? 'theme--dark' : 'theme--default'}`}>
          {content}
        </Tooltip>
      }
    >
      {displayContent()}
    </OverlayTrigger>
  );
};
