import React from 'react';
import { Chrono } from 'react-chrono';
import goodluckImg from 'assets/images/good-luck.png';
import Image from 'components/elements/Image';
import useTimeline from './hooks/useTimeline';

export default function Timeline() {
  const { addToCalendar, items, currentSegment, segmentCount } = useTimeline();

  let currentIndex;

  if (currentSegment < segmentCount) {
    currentIndex = currentSegment;
  } else {
    currentIndex = segmentCount;
  }

  return (
    <div className="schedule tabs-content">
      <h3 className="show-mobile-only mt-custom-5 text-start">Timeline</h3>
      {addToCalendar && (
        <div>
          {' '}
          <a href={addToCalendar} target="_blank" id="add-to-calendar" rel="noopener noreferrer">
            Add to Calendar{' '}
          </a>
        </div>
      )}
      <div className="container">
        <Chrono
          items={items}
          allowDynamicUpdate
          mode="VERTICAL_ALTERNATING"
          hideControls
          cardHeight={80}
          activeItemIndex={parseInt(currentIndex)}
          theme={{
            primary: '#95a1bc',
            secondary: '#fff',
            titleColor: '#2B2B52',
          }}
        >
          <div className="chrono-icons">
            {items.map((_, index) => (
              <div className={`icon ${parseInt(currentIndex) === index ? 'active' : ''}`} key={`icn-${index}`} />
            ))}
          </div>
        </Chrono>
        <p className="mt-10 text-uppercase cardo-sans-serif font-size-12 fw-400 text-center">
          After the waiting period
        </p>
        <span>
          <p className="text-center">Get back your initial deposit and collect your winnings!</p>
        </span>

        <p className="text-center pb-20">
          <Image src={goodluckImg} height={250} width={150} />
          <span role="img" aria-label="money-emoji" className="font-size-40 line-height-40" />
        </p>
      </div>
    </div>
  );
}
