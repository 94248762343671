import { createSelector } from '@reduxjs/toolkit';
import { polygonID, celoID, baseID, baseGoerliID } from 'utils/networks';
import { getNetworkId } from './game.selector';

export const getUser = (state) => state.user;

export const getPlayer = createSelector(getUser, (user) => user.player);
export const getUserAddress = createSelector(getUser, (user) => user.address);
export const getUserStatus = createSelector(getUser, (user) => user.status);
export const getProfileImages = createSelector(getUser, (user) => user.profileImage);
export const getMostRecentSegmentPaid = createSelector(getPlayer, (player) => player.mostRecentSegmentPaid);

export const getPlayerStatus = createSelector(getUser, (user) => user.status);
export const getPlayerAddress = createSelector(getUser, (user) => user?.player?.address || '');
export const getIsPlayer = createSelector(
  getUser,
  getPlayer,
  (user, player) => !!(user?.address && user.address === String(player?.address || '').toLowerCase())
);
export const getConnectedNetworkId = createSelector(getUser, (user) => user.networkId);
export const getIsConnectedCelo = createSelector(getUser, (user) => user.networkId === celoID);
export const getIsConnectedPolygon = createSelector(getUser, (user) => user.networkId === polygonID);
export const getIsConnectedBase = createSelector(getUser, (user) => user.networkId === baseID);
export const getIsConnectedBaseGoerli = createSelector(getUser, (user) => user.networkId === baseGoerliID);

export const getConfiguredSlippage = createSelector(getUser, (user) => user.configuredSlippage);
export const getUserBalance = createSelector(getUser, (user) => user.balance);

export const isHighSlippageError = createSelector(getConfiguredSlippage, (slippage) => slippage >= 5);
export const isZeroSlippageError = createSelector(getConfiguredSlippage, (slippage) => slippage === 0);

export const isLowSlippageError = createSelector(
  isZeroSlippageError,
  getConfiguredSlippage,
  (isZero, slippage) => !isZero && slippage < 0.5
);

export const isUserOnCorrectNetwork = createSelector(
  [getNetworkId, getConnectedNetworkId],
  (gameNetwork, userNetwork) => parseInt(gameNetwork) === parseInt(userNetwork)
);
