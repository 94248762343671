/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'components/elements/Tooltip';
import { truncateAndformat } from 'utils/numberFormat';
import { ABIVersions } from 'utils/constants';
import polygonImg from 'assets/images/game/polygon-label.svg';
import celoImg from 'assets/images/game/celo-label.svg';
import curveImg from 'assets/images/curve-logo.png';
import RewardsIllustration from 'assets/images/extra_rewards.svg';
import Sponsor from './Sponsor';

const ExtraSection = () => {
  const gameInfo = useSelector((state) => state.game.info);
  const incentiveToken = useSelector((state) => state.game.info.incentiveToken);
  const rewardToken = useSelector((state) => state.game.info.rewardToken);
  const sponsors = useSelector((state) => state.pool.info.sponsors);
  const isV2Game = ABIVersions.v20x.includes(gameInfo.contractVersion);
  let rewardTitle = null;

  if (isV2Game) {
    if (gameInfo.rewards && gameInfo.rewards.length > 0) {
      rewardTitle = 'Bonus rewards';
    }
  } else if (incentiveToken || rewardToken) {
    rewardTitle = 'Bonus rewards';
  }

  return (
    <div>
      <div className="rewards pl-15">
        {rewardTitle && (
          <h5 className="mt-0 mb-20 text-start">
            Bonus rewards <img className="rewards-illustration" src={RewardsIllustration} alt="sparkles illustration" />
          </h5>
        )}
        <section className="token">
          {isV2Game ? <RewardsV2 /> : <Rewards />}
          <Incentives />
        </section>
        {gameInfo.gameImage && (
          <section className="nft-area">
            <strong>NFT</strong>
            <img src={gameInfo.gameImage} alt="nft" />
            <p>All successful savers will be minted this NFT</p>
          </section>
        )}
        <section className="sponsor">
          {sponsors && sponsors.length > 0 && (
            <>
              <h3 className="text-start m-0">Sponsors</h3>
              <Sponsor sponsors={sponsors} />
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default ExtraSection;

const Incentives = () => {
  const incentiveToken = useSelector((state) => state.game.info.incentiveToken);
  const img = incentiveToken === 'celo' ? celoImg : polygonImg;
  const incentives = useSelector((state) => state.game.stats.incentives);
  const incentivesConverted = useSelector((state) => state.game.stats.incentivesConverted);
  const conversionCurrency = useSelector((state) => state.game.stats.conversionCurrency);

  if (!incentiveToken) {
    return null;
  }

  return (
    <div className="token-item">
      <img src={img} alt="token" />
      <p className="mb-0 ml-6 text-start">
        <span>
          <Tooltip
            placement="bottom"
            content={`≈${truncateAndformat(incentivesConverted, 2)} ${conversionCurrency} at the current market rate`}
            displayContent={() => (
              <div>
                {' '}
                {truncateAndformat(incentives, 2)} <i className="bi bi-info" />
              </div>
            )}
          />
        </span>
        <span className="blue-3-text">{incentiveToken.toUpperCase()}</span>
      </p>
    </div>
  );
};

const RewardToken = ({ img, rewardToken, rewards, rewardsConverted, conversionCurrency }) => {
  return (
    <div className="token-item">
      {img && <img src={img} alt="" />}
      <div>
        <p className="mb-0 ml-6 text-start">
          <span>
            <Tooltip
              placement="bottom"
              content={`≈${truncateAndformat(rewardsConverted, 2)} ${conversionCurrency} at the current market rate`}
              displayContent={() => (
                <div>
                  {truncateAndformat(rewards, 2)} <i className="bi bi-info" />
                </div>
              )}
            />
          </span>
          <span className="blue-3-text">{rewardToken.toUpperCase()}</span>
        </p>
      </div>
    </div>
  );
};

const RewardsImgMap = {
  celo: celoImg,
  wmatic: polygonImg,
  matic: polygonImg,
  crv: curveImg,
};

const Rewards = () => {
  const rewardToken = useSelector((state) => state.game.info.rewardToken);
  const secondaryRewardToken = useSelector((state) => state.game.info?.secondaryRewardToken);

  const img = RewardsImgMap[rewardToken];
  const secondaryImg = RewardsImgMap[secondaryRewardToken];

  const rewards = useSelector((state) => state.game.stats.rewards);
  const secondaryRewards = useSelector((state) => state.game.stats?.secondaryRewards);

  const rewardsConverted = useSelector((state) => state.game.stats.rewardsConverted);
  const secondaryRewardsConverted = useSelector((state) => state.game.stats?.secondaryRewardsConverted);

  const conversionCurrency = useSelector((state) => state.game.stats.conversionCurrency);

  if (!rewardToken) {
    return null;
  }

  return (
    <>
      <RewardToken
        img={img}
        rewardToken={rewardToken}
        rewards={rewards}
        rewardsConverted={rewardsConverted}
        conversionCurrency={conversionCurrency}
      />

      {secondaryRewardToken && (
        <RewardToken
          img={secondaryImg}
          rewardToken={secondaryRewardToken}
          rewards={secondaryRewards}
          rewardsConverted={secondaryRewardsConverted}
          conversionCurrency={conversionCurrency}
        />
      )}
    </>
  );
};

const RewardsV2 = () => {
  const gameStats = useSelector((state) => state.game.stats);
  const { rewardsBalances, conversionCurrency } = gameStats;

  return (
    <>
      {rewardsBalances.map((reward) => (
        <RewardToken
          img={RewardsImgMap[reward.tokenId]}
          rewardToken={reward.tokenId}
          rewards={reward.balance}
          rewardsConverted={reward.convertedBalance}
          conversionCurrency={conversionCurrency}
        />
      ))}
    </>
  );
};
