import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { Web3Context } from 'providers/Web3Provider';
import { status } from 'utils/utilities';
import { getPlayer, getUserStatus, getUserAddress, getConnectedNetworkId } from 'redux/selectors/user.selector';
import { getLoaderInitGame, getLoaderGetPlayer, getErrorNotWhitelisted } from 'redux/selectors/feedbacks.selector';
import {
  getGameInfo,
  getFirstSegment,
  getIsGameFull,
  getIsCapped,
  getGameStarted,
} from 'redux/selectors/game.selector';
import Loading from 'components/elements/Loading';
import Button from 'components/elements/Button';
import Join from './components/Join';
import PlayerProfile from './components/PlayerProfile';
import Deadline from '../../../../assets/images/deadline.png';

import GameFullWarning from './components/GameFullWarning';

export default function LeftColumn(props) {
  const Web3Client = useContext(Web3Context);
  const loaderInitGame = useSelector(getLoaderInitGame);
  const loaderGetPlayer = useSelector(getLoaderGetPlayer);
  const errorNotWhitelisted = useSelector(getErrorNotWhitelisted);
  const gameAlreadyStarted = useSelector(getGameStarted);
  const gameInfo = useSelector(getGameInfo);
  const player = useSelector(getPlayer);
  const userAddress = useSelector(getUserAddress);
  const userStatus = useSelector(getUserStatus);
  const isGameFull = useSelector(getIsGameFull || 0);
  const isCapped = useSelector(getIsCapped);
  const isFirstSegment = useSelector(getFirstSegment);
  const history = useHistory();

  const registeredPlayer = userAddress && userStatus === status.registered;
  const unRegisteredPlayer = userAddress && userStatus !== status.registered;
  const userNetworkId = useSelector(getConnectedNetworkId);
  const isWrongNetwork = ~~userNetworkId !== ~~gameInfo.networkId;
  const hasWithdrawn = player.withdrawn && !player.isWinner;

  const { canChangeNetwork } = Web3Client;

  const DashBoardBtn = ({ buttonId }) => (
    <Button className="button-link" onClick={() => history.push('/dashboard')} id={buttonId}>
      Dashboard
    </Button>
  );

  /**
   * user cannot connect because the game is already started
   */

  if (gameAlreadyStarted) {
    return (
      <div
        className={`left-content ${props.mobileHide && 'hide-mobile'} min-height-tmd-400 justify-content-end mid ${
          props.mediumAndUnder && 'hidemedium'
        }  ${props.extraClasses}`}
      >
        <img alt="clock illustration" className="deadline-img" src={Deadline} />
        <p className="fw-400 light-text">
          {' '}
          Sorry, it looks like you missed the chance to join this challenge. Keep an eye out for new ones!
        </p>
        <div className="mt-0">
          <DashBoardBtn buttonId="game-already-started" />
        </div>
      </div>
    );
  }

  if (loaderGetPlayer || loaderInitGame || (registeredPlayer && !userAddress)) {
    return <Loading classes="m-60 loading" />;
  }

  /**
   * user wallet is on a different network
   */
  if (~~userNetworkId && isWrongNetwork) {
    return (
      <div
        className={`left-content ${props.mobileHide && 'hide-mobile'} min-height-tmd-300 justify-content-end  ${
          props.extraClasses
        }`}
      >
        {canChangeNetwork && Web3Client.changeNetwork ? (
          <>
            <Button
              className="button-primary"
              wideMobile
              onClick={Web3Client.changeNetwork}
              id="left-column-change-network"
            >
              Change Network
            </Button>
            <div className="or">or</div>
          </>
        ) : (
          <>
            <div className="fw-400 light-text">
              {' '}
              Oops, it looks like switching networks automatically isn't supported by your wallet. Try switching
              networks from within your wallet instead.
            </div>
            <br />
          </>
        )}
        {Web3Client.logOut ? (
          <Button
            tag="a"
            className="button-primary-outline align-self-start mx-auto"
            wideMobile
            onClick={Web3Client.logOut}
            id="left-column-log-out"
          >
            Logout
          </Button>
        ) : null}
      </div>
    );
  }

  if ((unRegisteredPlayer && !errorNotWhitelisted) || (hasWithdrawn && gameInfo.currentSegment === '0')) {
    return (
      <div
        className={`left-content ${props.mobileHide && 'hide-mobile'}  min-height-tmd-400 justify-content-end ${
          props.extraClasses
        }`}
      >
        <p>Start saving now</p>
        <Join />
        <DashBoardBtn id="join-dashboard" />
      </div>
    );
  }

  if ((registeredPlayer || hasWithdrawn) && Object.prototype.hasOwnProperty.call(player, 'withdrawn')) {
    return (
      <div className={`left-content min-height-tmd-300 up ${props.extraClasses}`}>
        <PlayerProfile />
        <DashBoardBtn id="dashboard-current-seg0-registered-player" />
      </div>
    );
  }

  return (
    <div className={`left-content ${props.mobileHide && 'hide-mobile'} min-height-tmd-300 up ${props.extraClasses}`}>
      <div className="has-top-divider mb-30" />
      <p className="ps-1 mb-60 fw-400 hide-in-countdown">Connect your wallet for the complete experience</p>
      <div className="connect-wallet">
        <Button
          tag="a"
          className="button-primary button button-connect"
          wideMobile
          onClick={Web3Client.init}
          id="left-column-connect-wallet"
        >
          Connect to Join
        </Button>
      </div>
      {isCapped && isGameFull && isFirstSegment && <GameFullWarning />}
    </div>
  );
}
