import React, { useState } from 'react';
import Badge from 'components/elements/Badge';
import Button from 'components/elements/Button';
import SkeletonCard from './SkeletonCard';
import { getGameInfo } from '../../helpers/getGameInfo';

export default function PreviousGames({ games, previousGames, isLoading }) {
  const hasPreviousGames = previousGames.length > 0;
  const numOfGamesToDisplay = 20;
  const [showMore, setShowMore] = useState(false);
  const shouldDisplayShowMore = previousGames.length > numOfGamesToDisplay;
  const toDisplayGames = showMore ? previousGames : previousGames.slice(0, numOfGamesToDisplay);

  if (isLoading) {
    return (
      <section className="games-section">
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </section>
    );
  }

  return (
    <div className="previous-games-container">
      {!hasPreviousGames && (
        <p className="mt-15 w-75 mx-auto">
          Looks like you're missing out on all the fun! Complete challenges to learn new habits, earn rewards and build
          up your wealth.
        </p>
      )}
      {hasPreviousGames && (
        <div className="previous-completed-games-title-container">
          <div className="form-badge-status form-badge-status-active previous-completed-game-indicator" />
          <p className="previous-completed-games-title">Won</p>
        </div>
      )}
      <div className="previous-games-badges">
        {toDisplayGames.map((game) => {
          const { gameName, gameNameShort, gameNumber } = getGameInfo(game.gameId, games);

          return (
            <Badge
              className="previous-games-badges-item"
              label={gameNameShort ?? gameName}
              type="secondary"
              custom={game.isWinner ? 'active' : 'not-active'}
              link={`/#/challenges/${gameNumber}`}
            />
          );
        })}
      </div>
      {shouldDisplayShowMore && !showMore && (
        <div className="previous-games-button">
          <Button
            className="button button-primary-outline"
            onClick={() => setShowMore(true)}
            id="games-section-show-more"
          >
            Show all
          </Button>
        </div>
      )}

      {showMore && (
        <div className="previous-games-button">
          <Button
            className="button button-primary-outline"
            onClick={() => setShowMore(false)}
            id="games-section-show-less"
          >
            Show less
          </Button>
        </div>
      )}
    </div>
  );
}
