import { BigNumber, utils } from 'ethers';
import { truncateAndformat } from 'utils/numberFormat';

export const toEthersBN = (value) => BigNumber.from(value);

export const ethToWeiEthersBN = (n, decimals) => {
  const value = truncateAndformat(n, decimals);

  return toEthersBN(utils.parseUnits(String(value), decimals ?? 'ether'));
};

export const gweiToWeiEthersBN = (n) => toEthersBN(utils.parseUnits(n.toString(), 'gwei'));

export const weiToERC20 = (wei, decimals) => {
  const weiBN = toEthersBN(wei?.toString());
  return utils.formatUnits(weiBN, decimals);
};
