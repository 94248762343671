import { Accordion } from 'react-bootstrap';
import Alert from 'components/elements/Alert';
import Button from 'components/elements/Button';
import LinkNewTab from 'components/elements/LinkNewTab';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { titleCase, getGameVersionType } from 'utils/utilities';

import {
  getGameInfo,
  getDepositToken,
  getDepositTokenAddress,
  getRewardToken,
  getRewardTokenAddress,
  getSecondaryRewardToken,
  getSecondaryRewardTokenAddress,
  getIncentiveToken,
  getIncentiveTokenAddress,
} from 'redux/selectors/game.selector';

import { getPoolInfo } from 'redux/selectors/pool.selector';

const TokenRow = ({ tokenName, tokenAddress, baseExplorerURL, setErrorMsg }) => {
  const handleAddToken = async () => {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts', params: [] });
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenName.toUpperCase(),
            decimals: 18,
          },
        },
      });
    } catch (error) {
      setErrorMsg('Oops! There was an error while adding the token, please try later or contact our support!');
      console.error('Oops! There was an error while adding the token, please try later or contact our support!');
      console.error(error);
    }
  };

  return (
    <div className="token-row mb-10">
      <p>
        <b>{tokenName?.toUpperCase()}</b>:{' '}
        <LinkNewTab link={`${baseExplorerURL}address/${tokenAddress}`}>
          <span>{tokenAddress}</span>
        </LinkNewTab>
      </p>
      <Button className="button-sm" color="primary" onClick={() => handleAddToken()} id="add-token-to-wallet">
        Add to wallet
      </Button>
    </div>
  );
};

export default function Admin() {
  const poolInfo = useSelector(getPoolInfo);
  const gameInfo = useSelector(getGameInfo);

  const depositToken = useSelector(getDepositToken);
  const depositTokenAddress = useSelector(getDepositTokenAddress);
  const rewardToken = useSelector(getRewardToken);
  const rewardTokenAddress = useSelector(getRewardTokenAddress);
  const secondaryRewardToken = useSelector(getSecondaryRewardToken);
  const secondaryRewardTokenAddress = useSelector(getSecondaryRewardTokenAddress);
  const incentiveToken = useSelector(getIncentiveToken);
  const incentiveTokenAddress = useSelector(getIncentiveTokenAddress);
  const isV2Game = getGameVersionType(gameInfo);

  const network = poolInfo.display;
  const [errorMsg, setErrorMsg] = useState('');

  const tokens = () => {
    const t = [];

    if (depositToken) {
      t.push({ tokenName: depositToken, tokenAddress: depositTokenAddress });
    }
    if (incentiveToken) {
      t.push({ tokenName: incentiveToken, tokenAddress: incentiveTokenAddress });
    }
    if (rewardToken) {
      t.push({ tokenName: rewardToken, tokenAddress: rewardTokenAddress });
    }
    if (secondaryRewardToken) {
      t.push({ tokenName: secondaryRewardToken, tokenAddress: secondaryRewardTokenAddress });
    }
    return t;
  };

  const tokensV2 = () => {
    const t = [];
    const { rewards } = gameInfo;

    if (depositToken) {
      t.push({ tokenName: depositToken, tokenAddress: depositTokenAddress });
    }

    rewards.map((reward) => t.push({ tokenName: reward.tokenId, tokenAddress: reward.address }));
    return t;
  };

  const gameTokens = isV2Game ? tokensV2() : tokens();

  return (
    <>
      <Alert show={!!errorMsg} type="error-msg" text={errorMsg} />
      <div className="admin-text p-10">
        <p>
          {' '}
          This info panel is shown for transparency reasons only.
          <br />
          <i>Don't worry if you don't know what it all means.</i>
        </p>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span>Backend</span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Pool smart contract address:{' '}
                <a
                  href={`${poolInfo.baseExplorerURL}address/${poolInfo.contract}`}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <span> {poolInfo.contract}</span>
                </a>
              </p>
              {isV2Game && poolInfo.strategyController && (
                <p>
                  Strategy smart contract address:{' '}
                  <a
                    href={`${poolInfo.baseExplorerURL}address/${poolInfo.strategyController}`}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <span> {poolInfo.strategyController}</span>
                  </a>
                </p>
              )}
              {poolInfo.isUsingTheGraph && (
                <p>
                  The Graph URL:{' '}
                  <a
                    href={`https://thegraph.com/explorer/subgraph/good-ghosting/${poolInfo.subgraphName}`}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <span>{`https://thegraph.com/explorer/subgraph/good-ghosting/${poolInfo.subgraphName}`}</span>
                  </a>
                </p>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <span>Tokens</span>
            </Accordion.Header>
            <Accordion.Body>
              {gameTokens.map((item) => (
                <React.Fragment key={item.tokenAddress}>
                  <TokenRow {...item} setErrorMsg={setErrorMsg} baseExplorerURL={poolInfo.baseExplorerURL} />
                </React.Fragment>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span>Pool settings</span>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Interest platform: <b>{titleCase(poolInfo.strategyProvider)}</b>
                {/* [NOTE] Will need to be refactored later when we start supporting more than one underlying platform per network */}
              </p>
              <p>
                Early withdrawal fee: <b>{gameInfo.earlyWithdrawalFee}%</b>
              </p>
              <p>
                Performance fee: <b>{gameInfo.performanceFee}%</b>
              </p>
              <p>
                Blockchain: <b>{network}</b>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          {!!poolInfo.whitelist && (
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span>Whitelist</span>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Access to this game is restricted to all addresses on the{' '}
                  <LinkNewTab link={poolInfo.whitelist}>whitelist</LinkNewTab>.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>
      </div>
    </>
  );
}
