import { useState, useCallback } from 'react';

const defaultDecimals = 18;

export function useFetchDecimals({ getDepositTokenContract }) {
  const [depositTokenDecimals, setDepositTokenDecimals] = useState(defaultDecimals);
  const [hasFetchedDecimals, setHasFetchedDecimals] = useState(false);

  const getDepositTokenDecimals = useCallback(async () => {
    if (hasFetchedDecimals) {
      return depositTokenDecimals;
    }

    const depositTokenContract = getDepositTokenContract();

    if (!depositTokenContract) {
      return defaultDecimals;
    }

    try {
      const decimals = await depositTokenContract.decimals();

      setDepositTokenDecimals(decimals);
      setHasFetchedDecimals(true);

      return decimals;
    } catch (err) {
      console.error('getDepositTokenDecimals error:', err);
      return defaultDecimals;
    }
  }, [getDepositTokenContract, hasFetchedDecimals, depositTokenDecimals]);

  return getDepositTokenDecimals;
}
