import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import Tooltip from 'components/elements/Tooltip';
import { Link, useHistory } from 'react-router-dom';
import gemFull from 'assets/images/game/gem-full.png';
import Image from 'components/elements/Image';

import { displayEnsNameOrAddress, displaySegment, status, getGameProgressStats } from 'utils/utilities';
import { MECHANISM_TYPE } from 'utils/constants';
import { truncateAndformat, truncateAndFormatCoin } from 'utils/numberFormat';
import { setShowEarlyWithdrawModal, setShowWithdrawModal, setLoaderGasPrices } from 'redux/reducers/feedbacks';
import { getGameInfo, getNextSegmentEnd, getGameStats } from 'redux/selectors/game.selector';
import { IdentityContext } from 'providers/IdentityProvider';
import ProgessBar from 'components/elements/ProgessBar';
import Button from 'components/elements/Button';
import useGetProfileImage from 'Hooks/useGetProfileImage';
import ProfitAndLossSummary from './ProfitAndLossSummary';
import MakeDepositButton from './MakeDepositButton';

export function PlayerProfileImg({ playerAddress, classes }) {
  const history = useHistory();
  const [playerImage] = useGetProfileImage(playerAddress);
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  return <img className={classes} src={playerImage} alt="profile-img" onClick={() => history.push('/dashboard')} />;
}

function getPlayerProgressStats(gameInfo, nextSegmentEnd, playerInfo) {
  const { mostRecentSegmentPaid, isWinner, isAlive } = playerInfo;
  const mostRecentRoundPaid = mostRecentSegmentPaid !== undefined ? parseInt(mostRecentSegmentPaid) + 1 : 0;
  const playerSegmentProgress = (mostRecentRoundPaid / gameInfo.totalSegmentCount) * 100;

  const { gameEndsAt } = gameInfo;

  const { currentProgress } = getGameProgressStats(gameInfo, nextSegmentEnd, gameEndsAt);

  if (!isWinner) {
    return { playerSegmentProgress: parseInt(playerSegmentProgress) };
  }

  if (isAlive) {
    return { playerSegmentProgress: parseInt(currentProgress) };
  }

  return { playerSegmentProgress: parseInt(playerSegmentProgress) };
}

export default () => {
  const dispatch = useDispatch();
  const { identityName } = useContext(IdentityContext);
  const gameInfo = useSelector(getGameInfo);
  const gameStats = useSelector(getGameStats);
  const isCompleted = useSelector((state) => state.game.stats.isCompleted);
  const isWaitingSegment = useSelector((state) => state.game.stats.isWaitingSegment);
  const { isLastPayableSegment } = gameStats;
  const playerInfo = useSelector((state) => state.user.player);
  const nftData = useSelector((state) => state.players.nft);
  const hasWithdrawn = playerInfo.withdrawn && !playerInfo.isWinner;
  const nextSegmentEnd = useSelector(getNextSegmentEnd);
  const hasPaid = parseInt(playerInfo.mostRecentSegmentPaid) === parseInt(gameInfo.currentSegment);

  const depositMade = displaySegment(playerInfo.mostRecentSegmentPaid) || 0;
  const depositTotal = displaySegment(gameInfo.paymentCount) - 1 || 0;
  const FullGems = Array.from(Array(Math.abs(depositMade)).keys());
  const EmptyGems = Array.from(Array(Math.abs(depositTotal - depositMade)).keys());
  const gameMechanismType = gameInfo.mechanismType ? gameInfo.mechanismType : MECHANISM_TYPE.Saving;
  const { playerSegmentProgress } = getPlayerProgressStats(gameInfo, nextSegmentEnd, playerInfo);

  const totalDepositedContent = () => (
    <Row>
      <Col xs={5}>
        <strong className="fw-400">Total Deposited</strong>
      </Col>
      <Col xs={7}>
        <span className="fw-400">
          {truncateAndFormatCoin(playerInfo.paidAmount, gameInfo.depositToken.toLowerCase())}
        </span>{' '}
        <i className="bi bi-info" />
      </Col>
    </Row>
  );

  const maxGainsContent = () => (
    <Row>
      <Col xs={5}>
        <strong className="fw-400">Max Gains</strong>
      </Col>
      <Col xs={7}>
        <span className="fw-400">≈${truncateAndformat(playerInfo.maxEarningsConverted, 2)}</span>{' '}
        <i className="bi bi-info" />
      </Col>
    </Row>
  );

  return (
    <div>
      {nftData && nftData[playerInfo.address] ? (
        <div>
          <Image
            className="avatar"
            src={URL.createObjectURL(
              new Blob([nftData[playerInfo.address?.toLowerCase()]], {
                type: 'image/svg+xml',
              })
            )}
          />
        </div>
      ) : (
        <PlayerProfileImg playerAddress={playerInfo.address} classes="avatar" />
      )}

      <Link to="/dashboard" className="light-text">
        {playerInfo.threeBoxName ? playerInfo.threeBoxName : displayEnsNameOrAddress(identityName)}
      </Link>
      <div className="deposit">
        <span>Deposits Made</span>
        {gameMechanismType === MECHANISM_TYPE.Saving && (
          <div className="gems">
            {FullGems.map((index) => (
              <img src={gemFull} key={`full-${index}`} alt="" />
            ))}
            {EmptyGems.map((index) => (
              <img className="gem-empty" src={gemFull} key={`emoty-${index}`} alt="" />
            ))}
          </div>
        )}

        {gameMechanismType === MECHANISM_TYPE.Hodl && <ProgessBar currentProgressInSegment={playerSegmentProgress} />}
      </div>

      <div className="user-info light-text">
        <Row>
          <Col xs={5}>
            <strong className="fw-400">Status</strong>
          </Col>
          <Col xs={7}>
            <span className="fw-400">
              {hasWithdrawn ? 'Withdrawn' : playerInfo.isAlive ? (isCompleted ? 'Won' : 'Winning') : 'Lost'}
            </span>
          </Col>
        </Row>
        <Tooltip
          placement="bottom"
          content={`The ${gameInfo.depositToken.toUpperCase()} amount deposited by the player`}
          displayContent={totalDepositedContent}
        />

        {playerInfo.isAlive && !playerInfo.withdrawAmount && !playerInfo.withdrawn && !isCompleted && (
          <Tooltip
            placement="bottom"
            content="What you gain in the unlikely event that all other players drop out from saving."
            displayContent={maxGainsContent}
          />
        )}

        <ProfitAndLossSummary />
      </div>

      {/* Has not paid this round */}
      <MakeDepositButton />

      {/* has paid this round */}
      {playerInfo.isAlive && hasPaid && !isLastPayableSegment ? (
        <p className="pt-16 font-size-16 mb-10 fw-600">Current deposit made. Keep it going next round!</p>
      ) : null}

      {/* a user has made all deposits and is not waiting round */}

      {hasPaid && isLastPayableSegment && (
        <>
          <p className="pt-16 font-size-16 mb-0 fw-600">All deposits completed! 😇</p>
          <p className="pb-16 m-0 font-size-16">One more waiting round left</p>
        </>
      )}

      {isWaitingSegment && !isCompleted && (
        <p className="pt-16 font-size-15 mb-10 fw-600">
          Almost there 👀 <br />
          Withdraw your winnings soon!
        </p>
      )}

      {/** withdraw at the end */}
      <WithdrawButton />

      {/* Can withdraw early */}
      {!hasWithdrawn && !isCompleted ? (
        <WithdrawEarlyButton
          earlyWithdraw={() => {
            dispatch(setShowEarlyWithdrawModal(true));
            dispatch(setLoaderGasPrices(true));
          }}
        />
      ) : null}
    </div>
  );
};

const WithdrawEarlyButton = (props) => {
  return (
    <Button id="early-withdraw" onClick={props.earlyWithdraw} className="button-primary-outline mb-3">
      Early Withdraw
    </Button>
  );
};

const WithdrawButton = () => {
  const dispatch = useDispatch();
  const withdrawn = useSelector((state) => state.user.player.withdrawn);
  const isCompleted = useSelector((state) => state.game.stats.isCompleted);
  const loaderWithdraw = useSelector((state) => state.feedbacks.loaderWithdraw);
  const playerStatus = useSelector((state) => state.user.status);

  if (!isCompleted || playerStatus !== status.registered) {
    return null;
  }

  if (withdrawn) {
    return <p className="font-size-16 mb-10 mt-15 fw-600">Your funds were already withdrawn</p>;
  }

  return (
    <Button
      id="withdraw"
      loading={loaderWithdraw}
      className="mb-3 mt-16 button-primary--extend"
      onClick={() => {
        dispatch(setShowWithdrawModal(true));
        dispatch(setLoaderGasPrices(true));
      }}
    >
      Withdraw
    </Button>
  );
};
