import { useEffect } from 'react';
import { sdkJsClient } from 'service/sdk';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileImages } from 'redux/selectors/user.selector';
import { setProfileImage } from 'redux/reducers/user';
import { IMAGE_RESOLUTION } from 'utils/constants';

export default function useGetProfileImage(userAddress) {
  const dispatch = useDispatch();
  const playerImages = useSelector(getProfileImages);
  useEffect(() => {
    const fetchProfileImage = async () => {
      if (!playerImages[userAddress.toLowerCase()]) {
        const { playersProfileImage } = await sdkJsClient.getProfileImage({
          playersAddress: [userAddress],
          resolution: IMAGE_RESOLUTION.Low,
        });
        const [playerProfileImage] = playersProfileImage;
        dispatch(setProfileImage({ userAddress, profileImage: playerProfileImage }));
      }
    };
    fetchProfileImage();
  }, [userAddress, dispatch, playerImages]);

  return [playerImages[userAddress.toLowerCase()]];
}
