import { createSelector } from '@reduxjs/toolkit';
import { FetchActionStatus } from 'redux/reduxConstants';

const getDashboard = (state) => state.dashboard;

export const getLoadingPlayerGames = createSelector(
  getDashboard,
  (dashboard) => dashboard.loadingPlayerGames === FetchActionStatus.Pending
);
export const getPlayerGames = createSelector(getDashboard, (dashboard) => {
  const playerGames = Object.values(dashboard.playerGames);
  return playerGames[0] ?? [];
});

export const getIsDashboardWithdrawing = createSelector(getDashboard, (dashboard) => dashboard.isDashboardWithdrawing);

const isActiveGame = (game) => !game.isGameCompleted && !game.withdrawn;
export const getActiveGames = createSelector(getDashboard, (dashboard) => {
  const playerID = Object.keys(dashboard.playerGames);
  const playerAddress = playerID[0];

  const playerActiveGames = dashboard.playerGames[playerAddress]?.filter(isActiveGame);
  const activeGames = {};
  activeGames[playerAddress] = playerActiveGames;
  return activeGames;
});

const isPendingWithdrawn = (game) => game.isGameCompleted && !game.withdrawn;
export const getPendingWithdrawnGames = createSelector(getPlayerGames, (games) => games.filter(isPendingWithdrawn));

const isPreviousGame = (game) => game.withdrawn;
export const getPreviousGames = createSelector(getPlayerGames, (games) => games.filter(isPreviousGame));

const isWinner = (game) => game.isWinner;
export const getPendingWithdrawnWonGames = createSelector(getPendingWithdrawnGames, (games) => games.filter(isWinner));

const isNotWinner = (game) => !isWinner(game);
export const getPendingWithdrawnLostGames = createSelector(getPendingWithdrawnGames, (games) =>
  games.filter(isNotWinner)
);

export const getTotalNumberOfGames = createSelector(getPlayerGames, (games) => games.length);
export const getNumberOfActiveGames = createSelector(getActiveGames, (games) => {
  const keys = Object.keys(games);
  // games = { 'playerAddress': [] }
  return keys?.length > 0 ? games[keys[0]]?.length : 0;
});

export const getGGScore = createSelector(getDashboard, (dashboard) => dashboard.playerGGScore);
export const getloadingGGScore = createSelector(getDashboard, (dashboard) => dashboard.loadingPlayerGGScore);
export const getExternalLink = createSelector(getDashboard, (dashboard) => dashboard.externalLink);
export const getIdentityName = createSelector(getDashboard, (dashboard) => dashboard.identityNames);
export const getShowPreviousPools = createSelector(getDashboard, (dashboard) => dashboard.showPreviousPools);
