/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-string-refs */

import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, Switch, HashRouter, useLocation } from 'react-router-dom';

import Analytics from 'react-router-ga';

import { resetFeedbacks } from 'redux/reducers/feedbacks';
import { setGameInfo } from 'redux/reducers/game';
import { setPlayerInfo } from 'redux/reducers/user';
import { setIsCelo, setPool } from 'redux/reducers/pool';
import { resetPlayersReducer } from 'redux/reducers/players';

import Buy from 'views/Buy/components/Buy';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import { ValoraModal } from './Models/wallet/ValoraModal';

// Views
import Terms from './views/Terms';
import GamesOverview from './views/GamesOverview';
import Leaderboard from './views/Leaderboard/components/Leaderboard';

import MovedURL from './components/sections/MovedURL';
import NewGame from './views/NewGame/NewGame';
import PlayerDashboard from './views/PlayerDashboard/PlayerDashboard';

const App = () => {
  const scrollRevealRef = useRef();
  useEffect(() => {
    document.body.classList.add('is-loaded');
    scrollRevealRef.current.init();
  }, []);

  return (
    <ScrollReveal
      ref={scrollRevealRef}
      children={() => (
        <HashRouter>
          <ResetStoreOnRouteChange />
          <ValoraModal />
          <Analytics id="G-HH43L982PZ">
            <Switch>
              <AppRoute exact path={['/challenges', '/pools']} component={GamesOverview} />
              <AppRoute exact path="/beta" component={MovedURL} />
              <AppRoute exact path="/dashboard/:playerAddress" component={PlayerDashboard} />
              <AppRoute exact path={['/', '/dashboard']} component={PlayerDashboard} />
              <AppRoute exact path="/challenges/:id" component={NewGame} />
              <AppRoute exact path="/pools/:id" component={NewGame} />
              <AppRoute exact path="/leaderboard/" component={Leaderboard} />
              <AppRoute exact path="/buy/" component={Buy} />
              <AppRoute
                exact
                path="/litepaper"
                component={() => {
                  window.location.href =
                    'https://docs.google.com/document/d/1iDszb9HGIC8brG77Qxl1LFj26sGkHMMA30wnpG1cLP8/edit?usp=sharing';
                }}
              />
              <AppRoute exact path="/terms" component={Terms} />
            </Switch>
          </Analytics>
        </HashRouter>
      )}
    />
  );
};

export default withRouter((props) => <App {...props} />);

function ResetStoreOnRouteChange() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(resetFeedbacks());
    dispatch(setPool({}));
    dispatch(setIsCelo(false));
    dispatch(setGameInfo({}));
    dispatch(setPlayerInfo({}));
    dispatch(resetPlayersReducer());
  }, [location, dispatch]);

  return null;
}
