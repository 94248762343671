import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowVideoModal } from 'redux/reducers/feedbacks';
import Video from '../../../../assets/images/video-1.svg';

const VideoButton = () => {
  const dispatch = useDispatch();
  const onVideoModal = () => {
    dispatch(setShowVideoModal({ show: true, backButton: false }));
  };

  return (
    <div className="video-explainer" onClick={() => onVideoModal()}>
      <img src={Video} alt="play explaiiner video" />
    </div>
  );
};

export default VideoButton;
