import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  className: '',
};

const ToggleSwitch = ({ className, checked, onChange, ...props }) => {
  const classes = classNames('switch', className);

  return (
    <label className={classes} {...props}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="slider round" />
    </label>
  );
};

ToggleSwitch.propTypes = propTypes;
ToggleSwitch.defaultProps = defaultProps;

export default ToggleSwitch;
