import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Image from 'components/elements/Image';
import { Row, Col } from 'react-bootstrap';
import Tooltip from 'components/elements/Tooltip';
import classNames from 'classnames';
import { statusEmoji } from 'Hooks/players';
import ReactHtmlParser from 'react-html-parser';
import InfiniteScroll from 'components/elements/InfiniteScroll';

const RenderPlayers = ({ getPlayers }) => {
  const players = useSelector((state) => state.players.list, shallowEqual);
  const nftData = useSelector((state) => state.players.nft);
  const playersCount = useSelector((state) => state.game.stats.playersCount);

  return (
    <InfiniteScroll
      nextFunction={getPlayers}
      hasMore={~~playersCount > players.length}
      loadingText="Loading Players..."
    >
      <Row>
        {players.length &&
          players.map((player, key) => {
            const statusBadgeClass = classNames('status-badge position-absolute', player.color);
            const displayContent = () => (
              <div className="nft-data d-inline-block overflow-hidden">
                {ReactHtmlParser(nftData[player.address?.toLowerCase()])}
              </div>
            );
            return (
              <Col className="mb-8" key={key} xs={4} sm={3} md={2}>
                <div className="badge-image m-auto position-relative">
                  {nftData && nftData[player.address] ? (
                    <Tooltip
                      placement="bottom"
                      content={`Status: ${player.playerStatus} Address: ${player.address}`}
                      displayContent={displayContent}
                    />
                  ) : (
                    <PlayerImage i={key} player={player} />
                  )}

                  <span className={statusBadgeClass}>{statusEmoji[player.playerStatus]}</span>
                </div>
              </Col>
            );
          })}
      </Row>
    </InfiniteScroll>
  );
};

export default RenderPlayers;

export const PlayerImage = (props) => {
  const gotoPlayerDashboard = () => {
    const { address } = props.player;
    window.open(`/#/dashboard/${address}`);
  };

  const displayContent = () => (
    <Image
      onClick={gotoPlayerDashboard}
      src={props.player.threeBoxAvatar ? props.player.threeBoxAvatar : props.player.profileImage}
    />
  );

  return (
    <Tooltip
      placement="bottom"
      content={` Status: ${props.player.playerStatus}    Address: ${props.player.address}   `}
      displayContent={displayContent}
    />
  );
};
