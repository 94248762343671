import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../elements/Button';

export default () => (
  <div className="d-flex flex-column align-items-center justify-content-center h-custom-100">
    <h4>The location of this game has changed</h4>
    <Link to="/challenges">
      <Button color="primary" id="moved-url-to-challenges">
        Go to challenges
      </Button>
    </Link>
  </div>
);
