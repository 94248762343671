import RiskProfileLowStat from 'assets/images/risk/RiskProfileLow.svg';
import RiskProfileMediumStat from 'assets/images/risk/RiskProfileMedium.svg';
import RiskProfileHighStat from 'assets/images/risk/RiskProfileHigh.svg';
import RiskProfileLowColored from 'assets/images/risk/RiskProfileLowColored.png';
import RiskProfileHighColored from 'assets/images/risk/RiskProfileHighColored.png';
import RiskProfileMediumColored from 'assets/images/risk/RiskProfileMediumColored.png';

import { docsURL } from './brand';

export const RiskProfiles = {
  LowRisk: 'low',
  MediumRisk: 'medium',
  HighRisk: 'high',
};

export const RiskProfilesLabels = {
  [RiskProfiles.LowRisk]: 'Low Risk',
  [RiskProfiles.MediumRisk]: 'Medium Risk',
  [RiskProfiles.HighRisk]: 'High Risk',
};

export const RiskProfilesLinks = {
  [RiskProfiles.LowRisk]: `${docsURL}/halofi-challenges/risks#low-risk`,
  [RiskProfiles.MediumRisk]: `${docsURL}/halofi-challenges/risks#medium-risk`,
  [RiskProfiles.HighRisk]: `${docsURL}/halofi-challenges/risks#high-risk`,
};

export const RiskProfilesGrayIconMap = {
  [RiskProfiles.LowRisk]: RiskProfileLowStat,
  [RiskProfiles.MediumRisk]: RiskProfileMediumStat,
  [RiskProfiles.HighRisk]: RiskProfileHighStat,
};

export const RiskProfilesIconMap = {
  [RiskProfiles.LowRisk]: RiskProfileLowColored,
  [RiskProfiles.MediumRisk]: RiskProfileMediumColored,
  [RiskProfiles.HighRisk]: RiskProfileHighColored,
};
