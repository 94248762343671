/* eslint-disable react/no-unknown-property */
import React, { useRef, useCallback, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { Web3Context } from 'providers/Web3Provider';
import SearchBar from 'components/elements/SearchBar';
import Tabs from 'components/elements/TabGroup';
import Image from 'components/elements/Image';
import Loading from 'components/elements/Loading';

import { setLoaderGetPlayer } from 'redux/reducers/feedbacks';
import { getUserAddress } from 'redux/selectors/user.selector';
import { getLoaderGetPlayer } from 'redux/selectors/feedbacks.selector';
import { LEADERBOARD_TYPES } from 'utils/constants';
import { displayAddress, mappedToObject } from 'utils/utilities';
import { TwitterShareButton } from 'react-share';
import { AddressResolverProviderContext } from 'providers/AddressResolverProvider';
import { appSubdomain, brandCaps, brandDomain, levelsLink, twitterHandle, hashtag } from 'utils/brand';
import { SCORE_TWEETS } from 'utils/twitterTexts';
import InfiniteScroll from 'components/elements/InfiniteScroll';
import useScrollToTop from 'Hooks/useScrollToTop';
import leaderBoardHeaderImg from '../../../assets/images/leaderboard-image-header.svg';
import useGetPlayersGGScore from '../hooks/useGetPlayersGGScore';
import { useGetPlayerScore, getPlayerGGScore } from '../hooks/useGetPlayerGGScore';
import twitterShareIcon from '../../../assets/images/twitter-share-icon.svg';

function PlayerScoreTable({ playersGGScores, connectWallet, userAddress, connectedUserHasGGScore, cta }) {
  const history = useHistory();
  const firstIndex = 0;

  const loaderGetPlayer = useSelector(getLoaderGetPlayer);

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Rank</th>
            <th className="pd-r-150">Player</th>
            <th>Score</th>
            <th>Games won</th>
            {/*  eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
          </tr>
        </thead>
        <tbody>
          {!userAddress && (
            <tr className="table-tr-button">
              <td colSpan="5">
                <div onClick={connectWallet}>
                  Connect your wallet to check your score <i className="bi bi-arrow-right" />
                </div>
              </td>
            </tr>
          )}
          {userAddress && !connectedUserHasGGScore && (
            <tr className="table-tr-button">
              <td colSpan="5">
                <div onClick={() => history.push('/challenges')}>{cta}</div>
              </td>
            </tr>
          )}
          {loaderGetPlayer && (
            <tr>
              <td colSpan="5">
                <Loading classes="m-10 loading" height={35} width={35} />
              </td>
            </tr>
          )}
          {!loaderGetPlayer &&
            [...playersGGScores].map((score, i) => {
              const ArrayOfTweets = SCORE_TWEETS(score.ggScore);
              const tweetToShare = ArrayOfTweets[Math.floor(Math.random() * ArrayOfTweets.length)];

              const walletConnected =
                firstIndex === i && score.playerAddress && score.playerAddress === userAddress
                  ? 'table-tr-wallet-connected'
                  : '';
              return (
                <tr className={walletConnected} key={score.playerAddress}>
                  <td>
                    <span>{score.ranking}</span>
                  </td>
                  <td>
                    <div onClick={() => window.open(`/#/dashboard/${score.playerAddress}`)} className="profile-image">
                      <div>
                        <Image src={score.profileImage} />
                      </div>
                      <span>{displayAddress(score.playerAddress)}</span>
                    </div>
                  </td>
                  <td>
                    <span>{score.ggScore}</span>
                  </td>
                  <td>
                    <span>{score.completedGames}</span>
                  </td>
                  <td>
                    {walletConnected && (
                      <div className="twitter-share-icon">
                        <TwitterShareButton
                          title={`${tweetToShare}\n\n${twitterHandle}`}
                          url={`https://${appSubdomain}.${brandDomain}/#/leaderboard?utm_source=twitter&utm_medium=social&utm_campaign=leaderboard&utm_content=leaderboard\n\n`}
                          hashtags={[hashtag, 'HaloFiLeaderboard']}
                          related={[twitterHandle]}
                          className="mb-1"
                          id="leaderboard-share-icon-button"
                        >
                          <div className="twitter-icon">
                            <Image src={twitterShareIcon} />
                          </div>
                        </TwitterShareButton>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

function Leaderboard(props) {
  const addressResolverProvider = useContext(AddressResolverProviderContext);
  const Web3Client = useContext(Web3Context);
  const dispatch = useDispatch();
  // 👇️ scroll to top on page load
  useScrollToTop();

  const userAddress = useSelector(getUserAddress);
  const [selectedTab, setSelectedTab] = useState(null);

  const [connectedUserGGScore] = useGetPlayerScore(userAddress, selectedTab?.value);
  const connectedUserHasGGScore = connectedUserGGScore?.ggScore > 0;

  const [lastFiveChallengeIndex] = useState(0);
  const [governanceV2Index] = useState(0);
  const [allTimeIndex] = useState(0);

  const [searchQuery, setSearchQuery] = useState('');
  const searchInputRef = useRef({});
  const [errorMessage, setErrorMessage] = useState('');

  const [lastFiveChallengesPlayerScores, getFiveChallengesScores, totalFiveChallengesScores] = useGetPlayersGGScore(
    LEADERBOARD_TYPES.LastFiveChallenges,
    lastFiveChallengeIndex,
    !!searchQuery
  );
  const [governanceV2PlayerScores, getGovernanceV2Scores, totalGovernanceV2Scores] = useGetPlayersGGScore(
    LEADERBOARD_TYPES.GovernanceV2,
    governanceV2Index,
    !!searchQuery
  );
  const [allTimePlayerScores, getAllTimeScores, totalAllTimeScores] = useGetPlayersGGScore(
    LEADERBOARD_TYPES.AllTime,
    allTimeIndex,
    !!searchQuery
  );

  const [allTimeScores, setAllTimeScorese] = useState([]);
  const [governanceV2Scores, setGovernanceV2Scores] = useState([]);
  const [lastFiveChallengesScores, setLastFiveChallengesScores] = useState([]);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
    setErrorMessage('');
  };

  const onReset = () => {
    setSearchQuery('');
    searchInputRef.current.focus();
  };

  const connectWallet = () => Web3Client.init();

  const setPlayerScoresProvider = (playerScores, leaderboardType) => {
    switch (leaderboardType) {
      case LEADERBOARD_TYPES.LastFiveChallenges:
        setLastFiveChallengesScores(playerScores);
        break;
      case LEADERBOARD_TYPES.GovernanceV2:
        setGovernanceV2Scores(playerScores);
        break;
      case LEADERBOARD_TYPES.AllTime:
        setAllTimeScorese(playerScores);
        break;
      default:
      // Do nothing
    }
  };

  const searchPlayerGGScore = async () => {
    if (!searchQuery) {
      setErrorMessage('Enter a player address');
      return;
    }
    dispatch(setLoaderGetPlayer(true));
    try {
      const resolvedAddress = await addressResolverProvider.resolveAddress(searchQuery);
      if (resolvedAddress instanceof Error) {
        setErrorMessage('Could not resolve player address');
        return;
      }
      const { playerScoreRequest, profileImage } = await getPlayerGGScore({
        userAddress: resolvedAddress.userAddress,
        leaderboard: selectedTab.value,
      });
      setPlayerScoresProvider([{ ...playerScoreRequest, profileImage }], selectedTab.value);
    } catch (err) {
      //
    } finally {
      dispatch(setLoaderGetPlayer(false));
    }
  };

  const getConnectedGGScore = useCallback(() => {
    return connectedUserHasGGScore ? [connectedUserGGScore] : [];
  }, [connectedUserGGScore, connectedUserHasGGScore]);

  useEffect(() => {
    if (!searchQuery) {
      const userGGScore = getConnectedGGScore();
      const allTimeScoresAsObject = mappedToObject(allTimePlayerScores, 'playerAddress');
      const userGGScoreAsObject = mappedToObject(userGGScore, 'playerAddress');

      setAllTimeScorese(Object.values({ ...userGGScoreAsObject, ...allTimeScoresAsObject }));
    }
  }, [allTimePlayerScores, searchQuery, getConnectedGGScore]);

  useEffect(() => {
    if (!searchQuery) {
      const userGGScore = getConnectedGGScore();
      const governanceV2ScoresAsObject = mappedToObject(governanceV2PlayerScores, 'playerAddress');
      const userGGScoreAsObject = mappedToObject(userGGScore, 'playerAddress');

      setGovernanceV2Scores(Object.values({ ...userGGScoreAsObject, ...governanceV2ScoresAsObject }));
    }
  }, [governanceV2PlayerScores, searchQuery, getConnectedGGScore]);

  useEffect(() => {
    if (!searchQuery) {
      const userGGScore = getConnectedGGScore();
      const lastFiveChallengesSccoresAsObject = mappedToObject(lastFiveChallengesPlayerScores, 'playerAddress');
      const userGGScoreAsObject = mappedToObject(userGGScore, 'playerAddress');

      setLastFiveChallengesScores(Object.values({ ...userGGScoreAsObject, ...lastFiveChallengesSccoresAsObject }));
    }
  }, [lastFiveChallengesPlayerScores, searchQuery, getConnectedGGScore]);

  const { className, topOuterDivider, bottomOuterDivider } = props;

  const outerClasses = classNames(
    'team section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className="leaderboard-container">
          <div className="header">
            <div className="description">
              <h4 className="mt-0">Leaderboard</h4>
              <p>
                {`Are you crushing it as in ${brandCaps}? Track your progress and see where you rank. Your `}
                <a href={`${levelsLink}`} target="_blank" rel="noreferrer">
                  GG score
                </a>{' '}
                is updated at the end of every challenge.
              </p>
            </div>
            <div className="image-container">
              <Image src={leaderBoardHeaderImg} height={250} width={300} />
            </div>
          </div>
          <div className="leaderboard-content">
            <div className="header-content">
              <div className="tab-container">
                <Tabs tabListItemClassName="tab-list-item" tabContent="tab-content">
                  <div
                    label="All Time"
                    value={LEADERBOARD_TYPES.AllTime}
                    onClick={() => null}
                    onSelectedTab={(tab) => setSelectedTab(tab)}
                  >
                    <p className="tab-content-header">
                      The best of the best! Ranking the top GG scores across all past challenges.
                    </p>

                    <InfiniteScroll
                      nextFunction={getAllTimeScores}
                      loadingText="Loading Scores..."
                      hasMore={~~totalAllTimeScores > allTimeScores.length}
                      shouldScroll={selectedTab?.value === LEADERBOARD_TYPES.AllTime && !searchQuery}
                    >
                      <PlayerScoreTable
                        playersGGScores={allTimeScores}
                        connectWallet={connectWallet}
                        userAddress={userAddress}
                        connectedUserHasGGScore={connectedUserHasGGScore}
                        cta="Earn your spot by completing your first challenge!"
                      />
                    </InfiniteScroll>
                  </div>
                  <div
                    label="Most Recent"
                    value={LEADERBOARD_TYPES.LastFiveChallenges}
                    onClick={() => null}
                    onSelectedTab={(tab) => setSelectedTab(tab)}
                  >
                    <p className="tab-content-header">The top performers from the last five completed challenges.</p>
                    <InfiniteScroll
                      hasMore={~~totalFiveChallengesScores > lastFiveChallengesScores.length}
                      loadingText="Loading Scores..."
                      nextFunction={getFiveChallengesScores}
                      shouldScroll={selectedTab?.value === LEADERBOARD_TYPES.LastFiveChallenges && !searchQuery}
                    >
                      <PlayerScoreTable
                        playersGGScores={lastFiveChallengesScores}
                        userAddress={userAddress}
                        connectWallet={connectWallet}
                        connectedUserHasGGScore={connectedUserHasGGScore}
                        cta="Earn your spot by completing a recent challenge!"
                      />
                    </InfiniteScroll>
                  </div>
                  <div
                    label="Governance V2"
                    value={LEADERBOARD_TYPES.GovernanceV2}
                    onClick={() => null}
                    onSelectedTab={(tab) => setSelectedTab(tab)}
                  >
                    <p className="tab-content-header">
                      See who's leading the pack in GG Scores for our Governance V2 Challenges.
                    </p>
                    <InfiniteScroll
                      hasMore={~~totalGovernanceV2Scores > governanceV2Scores.length}
                      loadingText="Loading Scores..."
                      nextFunction={getGovernanceV2Scores}
                      shouldScroll={selectedTab?.value === LEADERBOARD_TYPES.GovernanceV2 && !searchQuery}
                    >
                      <PlayerScoreTable
                        playersGGScores={governanceV2Scores}
                        userAddress={userAddress}
                        connectWallet={connectWallet}
                        connectedUserHasGGScore={connectedUserHasGGScore}
                        cta="Earn your spot by completing a Governance v2 Challenge!"
                      />
                    </InfiniteScroll>
                  </div>
                </Tabs>
              </div>
              <SearchBar
                errorMessageRef={searchInputRef}
                placeholder="Search for a player"
                onChange={onChange}
                search={searchQuery}
                onReset={onReset}
                errorMessage={errorMessage}
                onSearch={searchPlayerGGScore}
                type="search-bar-withIcon"
                className="search-bar-withIcon search-container"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Leaderboard);
