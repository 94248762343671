import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUserAddress } from 'redux/selectors/user.selector';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import Image from 'components/elements/Image';
import Button from 'components/elements/Button';
import { unaccomplishedBadges } from 'utils/playerBadges';
import { hashtag, brandDomain, twitterHandle } from 'utils/brand';
import { BADGES_TEXT } from 'utils/twitterTexts';
import { pickRandomItem } from 'utils/utilities';
import SkeletonCard from '../GamesSection/SkeletonCard';

const BadgeGroupType = {
  GameWon: 'game-won',
  GgScore: 'gg-score',
  EventChampion: 'event-champion',
};

async function getPlayerBadges(playerBadges) {
  const maxUnachievedBadgeToDisplay = 4;
  const maxNumberOfBadges = playerBadges.length + maxUnachievedBadgeToDisplay;

  const badges = [
    ...playerBadges.map((badge) => {
      return {
        icon: badge.imageUrl,
        twitterLink: true,
        badgeType: badge.group,
        threshold: badge.id,
        mintable: badge.mintable,
        mintableUrl: badge.mintableUrl,
      };
    }),
  ];

  const eventChampion = badges.filter((badge) => badge.group === BadgeGroupType.EventChampion);
  const [, eventChampionEmptyBadge] = unaccomplishedBadges;
  if (eventChampion.length === 0) {
    badges.push({
      icon: eventChampionEmptyBadge,
      twitterLink: false,
    });
  }

  const sizeOfBadges = badges.length > 0 ? badges.length - 1 : 0;
  const unachievedBadgeLength = maxNumberOfBadges - sizeOfBadges;
  const totalUnachievedBadgeToDisplay = sizeOfBadges + unachievedBadgeLength;
  const totalBadgeToDisplay = sizeOfBadges + maxUnachievedBadgeToDisplay;
  const unachievedBadgeToDisplay =
    totalBadgeToDisplay > maxNumberOfBadges ? totalUnachievedBadgeToDisplay : totalBadgeToDisplay;

  const [emptyBadge] = unaccomplishedBadges;
  for (let i = sizeOfBadges; i < unachievedBadgeToDisplay; i += 1) {
    badges.push({
      icon: emptyBadge,
      twitterLink: false,
    });
  }
  return badges;
}

const GamesBadge = ({ icon, twitterLink, tweeterUri, openBadge, isProfileOwner, badgeType, mintableUrl, mintable }) => (
  <div className="player-badges-section player-Badges-item">
    <Image className="player-badges-icons" width="120" height="100" src={icon} onClick={openBadge} />
    {twitterLink && isProfileOwner && (
      <TwitterShareButton
        title={pickRandomItem(BADGES_TEXT)}
        url={`\n\n${tweeterUri}?utm_source=twitter&utm_medium=social&utm_campaign=badge&utm_content=${badgeType}\n`}
        hashtags={[hashtag, 'HaloFiBadges']}
        related={[twitterHandle]}
        className="mb-1"
        id="share-badge-from-dashboard"
      >
        <TwitterIcon size={24} round />
      </TwitterShareButton>
    )}
    {mintable && isProfileOwner && (
      <Button onClick={() => window.open(mintableUrl)} className="button-link" id={`button-mint-badge-${badgeType}`}>
        Mint now
      </Button>
    )}
  </div>
);

function getBadgeService({ badgeId, playerAddress, badgeType }) {
  const badgeServiceUri = `https://badges.${brandDomain}/?badgeId=${badgeId}&playerAddress=${playerAddress}&badgeType=${badgeType}&theme=default`;
  return badgeServiceUri;
}

function openBadgeService({ badgeId, playerAddress, badgeType }) {
  const badgeUri = getBadgeService({ badgeId, playerAddress, badgeType });
  window.open(badgeUri);
}

export default function PlayerGamesBadge({ isLoading, playerBadges }) {
  const [badges, setBadges] = useState([]);
  const params = useParams();
  const userAddress = useSelector(getUserAddress);
  const playerAddress = params?.playerAddress || userAddress;
  const isProfileOwner = playerAddress === userAddress;

  const getGameBadges = useCallback(async () => {
    return getPlayerBadges(playerBadges);
  }, [playerBadges]);

  useEffect(() => {
    async function fetchData() {
      const playerGameBadges = await getGameBadges();
      setBadges(playerGameBadges.filter((badge) => badge.mintable === true));
    }
    fetchData();
  }, [getGameBadges]);

  if (isLoading || playerBadges === undefined) {
    return (
      <section className="games-section">
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </section>
    );
  }

  return (
    <div className="player-dashboard-badges">
      {badges.map((badge) => {
        const { threshold, badgeType, twitterLink, icon, mintable, mintableUrl } = badge;
        let badgeId;
        let tweeterUri;
        if (badgeType === BadgeGroupType.GameWon || badgeType === BadgeGroupType.GgScore) {
          badgeId = `${threshold}-${badgeType}`;
          tweeterUri = getBadgeService({ badgeId, playerAddress, badgeType });
        } else {
          badgeId = threshold;
          tweeterUri = getBadgeService({ badgeId, playerAddress, badgeType });
        }
        if (!twitterLink) {
          return <GamesBadge icon={icon} twitterLink={twitterLink} tweeterUri={tweeterUri} />;
        }
        return (
          <GamesBadge
            icon={icon}
            twitterLink={twitterLink}
            isProfileOwner={isProfileOwner}
            tweeterUri={tweeterUri}
            mintableUrl={mintableUrl}
            mintable={mintable}
            openBadge={() =>
              openBadgeService({
                badgeId,
                playerAddress,
                badgeType,
              })
            }
            badgeType={badgeType}
          />
        );
      })}
    </div>
  );
}
