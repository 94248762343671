// export const calendarInvites = [
//   "https://calendar.google.com/event?action=TEMPLATE&tmeid=NTZpY3NxaWR2OWIxOGpuZTNsZ2o4MDlpb3FfMjAyMTA2MTlUMDQwMDAwWiBjX2tsYWU4dGVlcmtocjRqaG82NjQxOGVjY3NjQGc&tmsrc=c_klae8teerkhr4jho66418eccsc%40group.calendar.google.com&scp=ALL",
//   "https://calendar.google.com/event?action=TEMPLATE&tmeid=NTZpY3NxaWR2OWIxOGpuZTNsZ2o4MDlpb3FfMjAyMTA2MTlUMDQwMDAwWiBjX2tsYWU4dGVlcmtocjRqaG82NjQxOGVjY3NjQGc&tmsrc=c_klae8teerkhr4jho66418eccsc%40group.calendar.google.com&scp=ALL",
//   "https://calendar.google.com/event?action=TEMPLATE&tmeid=NTZpY3NxaWR2OWIxOGpuZTNsZ2o4MDlpb3FfMjAyMTA2MTlUMDQwMDAwWiBjX2tsYWU4dGVlcmtocjRqaG82NjQxOGVjY3NjQGc&tmsrc=c_klae8teerkhr4jho66418eccsc%40group.calendar.google.com&scp=ALL",
//   "https://calendar.google.com/event?action=TEMPLATE&tmeid=NTZpY3NxaWR2OWIxOGpuZTNsZ2o4MDlpb3FfMjAyMTA2MTlUMDQwMDAwWiBjX2tsYWU4dGVlcmtocjRqaG82NjQxOGVjY3NjQGc&tmsrc=c_klae8teerkhr4jho66418eccsc%40group.calendar.google.com&scp=ALL",
//   "https://calendar.google.com/event?action=TEMPLATE&tmeid=NTZpY3NxaWR2OWIxOGpuZTNsZ2o4MDlpb3FfMjAyMTA2MTlUMDQwMDAwWiBjX2tsYWU4dGVlcmtocjRqaG82NjQxOGVjY3NjQGc&tmsrc=c_klae8teerkhr4jho66418eccsc%40group.calendar.google.com&scp=ALL",
// ];

export const calendarInvites = [
  'https://calendar.google.com/event?action=TEMPLATE&tmeid=MTlwaWd1cmNpcTMzZ3Y5MzY4bGgwOGl2Y2UgY19rbGFlOHRlZXJraHI0amhvNjY0MThlY2NzY0Bn&tmsrc=c_klae8teerkhr4jho66418eccsc%40group.calendar.google.com',
  'https://calendar.google.com/event?action=TEMPLATE&tmeid=MnFiZHI2dG1vb2VuNmhhbzl2dWx2amk5YzhfMjAyMTA2MjRUMDUwMDAwWiBjX2tsYWU4dGVlcmtocjRqaG82NjQxOGVjY3NjQGc&tmsrc=c_klae8teerkhr4jho66418eccsc%40group.calendar.google.com&scp=ALL',
  'https://calendar.google.com/event?action=TEMPLATE&tmeid=NTUwcmoya2FjcGJoYnZmMjVnc3F1Zm5nazMgY19rbGFlOHRlZXJraHI0amhvNjY0MThlY2NzY0Bn&tmsrc=c_klae8teerkhr4jho66418eccsc%40group.calendar.google.com',
];

export const cashOutInvite =
  'https://calendar.google.com/event?action=TEMPLATE&tmeid=N3JpYzRjcXMyMThwbzJtbW1wb3JwbXVpdTggY19rbGFlOHRlZXJraHI0amhvNjY0MThlY2NzY0Bn&tmsrc=c_klae8teerkhr4jho66418eccsc%40group.calendar.google.com';

export const isGameComplete = (gameInfo) => parseInt(gameInfo.currentSegment) > parseInt(gameInfo.lastSegment); // technically this is true when all payable rounds have been paid

export const isPayableSegmentsDone = (gameInfo) => parseInt(gameInfo.currentSegment) >= parseInt(gameInfo.lastSegment);
