import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMaxPlayers, getPoolInfo } from 'redux/selectors/pool.selector';
import { setShowConfirmModal, setLoaderGasPrices } from 'redux/reducers/feedbacks';
import Loading from 'assets/loading-dark.svg';
import Button from 'components/elements/Button';
import GameFullWarning from './GameFullWarning';

const Join = () => {
  const dispatch = useDispatch();
  // for capped games, checks if limit number of players was reached
  const maxPlayers = useSelector(getMaxPlayers);
  const poolInfo = useSelector(getPoolInfo);
  const { isCapped } = poolInfo;
  const successJoinGameMap = useSelector((state) => state.feedbacks.successJoinGame);
  const loaderJoinGame = useSelector((state) => state.feedbacks.loaderJoinGame);
  const availableSpots = useSelector((state) => state.game.stats.availableSpots || 0);
  const max = isCapped ? ~~maxPlayers || -1 : -1;
  const canJoin = isCapped ? ~~availableSpots > 0 && ~~availableSpots <= max : true;
  const successJoinGame = successJoinGameMap[poolInfo.id];

  const onDepositModal = () => {
    dispatch(setShowConfirmModal({ show: true, backButton: false }));
  };

  return (
    <div className="join">
      <div>
        {!successJoinGame && isCapped && !canJoin && <GameFullWarning />}
        {!loaderJoinGame && !successJoinGame && canJoin && (
          <>
            <Button
              id="join-game"
              className="button-primary"
              onClick={() => {
                onDepositModal();
                dispatch(setLoaderGasPrices(true));
              }}
            >
              Join Challenge
            </Button>
            {isCapped ? <p>{`Available spots: ${availableSpots}`}</p> : null}
          </>
        )}
        {loaderJoinGame && (
          <div>
            <p>We are processing your transactions. Keep an eye on your wallet</p>
            <img src={Loading} alt="Loading" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Join;
