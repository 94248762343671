import React from 'react';

export default function ModalFooter(props) {
  return (
    <>
      <div className="has-top-divider mt-20" />
      <div className={`modal-footer-gg mt-20${props.className ? ` ${props.className}` : ''}`}>{props.children}</div>
    </>
  );
}
