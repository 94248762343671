import { utils } from 'ethers';
import axios from 'axios';
import BigNumber from 'bignumber.js';

import { ZERO_ADDRESS, ABIVersions } from './constants';
import { networksIcon } from './networks';

export function getNetworkIcon(network) {
  return networksIcon[network];
}

export function getGameVersionType(gameInfo) {
  const { contractVersion } = gameInfo;

  return ABIVersions.v20x.includes(contractVersion);
}

export async function getSheets(sheetUrl) {
  const sheet = await axios.get(sheetUrl);
  return sheet.data.values;
}

export function getGameProgressStats(gameInfo) {
  const { nextSegmentEnd, lastSegmentEnd } = gameInfo;
  const gameStarted = gameInfo.gameStartsAt * 1000;
  const roundingError = 6;

  const nextSegment = getDateDiff(nextSegmentEnd, gameStarted, 'minutes');
  const gameEnd = getDateDiff(lastSegmentEnd, gameStarted, 'minutes');

  const now = getCurrentTimestamp();
  const currentProgressInSegment = getDateDiff(now, gameStarted, 'minutes');

  const segmentProgress = (nextSegment / gameEnd) * 100;
  let currentProgress = (currentProgressInSegment / gameEnd) * 100;
  const segmentProgressDiff = segmentProgress - currentProgress;

  if (segmentProgressDiff <= roundingError) {
    currentProgress -= roundingError;
  }

  return { currentProgress: Math.ceil(currentProgress), segmentProgress };
}

export function getDateDiff(endDate, startDate, measure = 'days') {
  const dateDiff = new Date(endDate).getTime() - new Date(startDate).getTime();

  if (measure === 'months') {
    //  To calculate the no. of months between two dates
    return parseInt(dateDiff / (1000 * 3600 * 24 * 30));
  }

  if (measure === 'days') {
    //  To calculate the no. of days between two dates
    return parseInt(dateDiff / (1000 * 3600 * 24));
  }

  if (measure === 'hours') {
    //  To calculate the no. of hours between two dates
    return parseInt(dateDiff / (1000 * 3600));
  }

  if (measure === 'minutes') {
    //  To calculate the no. of minutes between two dates
    return parseInt(dateDiff / (1000 * 60));
  }

  throw new Error('Not Implemented');
}

export function getCurrentTimestamp() {
  return Math.round(Date.now());
}

export function validateAmount(amount) {
  const validAmount = /^\d*(\.\d+)?$/;

  if (amount.match(validAmount)) {
    return true;
  }

  return false;
}

export const status = {
  unloaded: 'unloaded',
  registered: 'registered',
  unregistered: 'unregistered',
};

export function toMonthName(monthNumber) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return months[monthNumber - 1];
}

export function mappedToObject(games, key) {
  return games.reduce((prevGame, game) => {
    const prevGames = { ...prevGame };
    prevGames[game[key]] = game;
    return prevGames;
  }, {});
}

export function formatDate(date) {
  const derivedDate = new Date(date).toISOString();
  const [year, month, day] = derivedDate.substring(0, 10).split('-');

  const derivedMonth = toMonthName(month);
  const derivedYear = year.substring(2, 4);

  return `${day} ${derivedMonth} ${derivedYear}`;
}

export const isNotEmptyObj = (myObject) => !!Object.keys(myObject).length;

export const waitlist = 'https://uy4iqxavuee.typeform.com/to/Z3AHK8NV';
export const brandColors = {
  darkBlue: '#5680e9',
  lightBlue: '#84ceeb',
  mediumBlue: '#5ab9ea',
  lilac: '#c1c8e4',
  purple: '#8860d0',
};

export const BN = BigNumber;

export const timeToCloseModal = 7000;

export const discordLink = 'https://discord.gg/6VT8hn8jzE';

export const communityInterestForm = 'https://uy4iqxavuee.typeform.com/to/ksiZyELu';

export const displayAddress = (address) =>
  address ? `${address.slice(0, 4)}...${address.slice(-4)}`.toUpperCase() : '';

export const displayEnsNameOrAddress = (address) => {
  if (!address) {
    return '';
  }
  if (!utils.isAddress(address)) {
    return address;
  }
  return displayAddress(address);
};

export const displayAddressLong = (address) => (address ? `${address.slice(0, 8)}...${address.slice(-8)}` : '');

export const gqlErrors = {
  players: 'gql players request failed',
  game: 'gql game request failed',
};

export const gameNumber = 0;

/**
 * Multiplies two big numbers (bn.js) in wei format.
 * This helper divides the multiplication result by 10**decimals
 * to provide the correct result of the multiplication in wei format
 * considering the decimals provided.
 */

// current segment counts from 0 and is a string
export const displaySegment = (raw) => parseInt(raw) + 1;

export const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

function isString(x) {
  return Object.prototype.toString.call(x) === '[object String]';
}

export function titleCase(str) {
  return str?.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
}

const isNegative = (x) => Math.sign(x) === -1;

/** Returns the decimal precision of a number
 * @param {Number|String|BN(bn.js)} x a valid number of type
 * @returns {Number} the number of decimal places
 * @link Adapted from https://stackoverflow.com/a/27865285
 */
export function getPrecision(x) {
  let number = x;

  if (BN.isBigNumber(x)) {
    number = x.toNumber();
  } else if (isString(x)) {
    number = parseFloat(x);
  }

  if (!Number.isFinite(number) || Number.isNaN(number)) {
    return 0;
  }
  const value = +number;
  let e = 1;
  let p = 0;
  while (Math.round(value * e) / e !== value) {
    e *= 10;
    p += 1;
  }
  return p;
}

/** Truncates a number to a given precision
 * @param {Number|String|BN(bn.js)} value a valid number
 * @param {Number} precision the number of decimal places (precision)
 * @returns {Number|String|BN(bn.js)} the value truncated to the specified permission,
 * in the same format used for the {value} input
 */
export function truncate(value, precision = 0) {
  let number = value;
  let type = 'number';

  if (BN.isBigNumber(value)) {
    number = value.toNumber();
    type = 'bn';
  } else if (isString(value)) {
    number = parseFloat(number);
    type = 'string';
  }

  if (Number.isNaN(number)) {
    return NaN;
  }

  // Handles values with less decimals than the specified precision.
  // Example: a value of 0.00013 returns 0.00012999 if precision is 8.
  if (precision >= getPrecision(number)) {
    return number;
  }

  const multi = 10 ** precision;
  let result;

  if (isNegative(number)) {
    result = Math.ceil(number * multi) / multi;
  } else {
    result = Math.floor(number * multi) / multi;
  }

  if (type === 'bn') {
    return new BigNumber(result);
  }
  if (type === 'string') {
    return result.toString();
  }

  return result;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isCeloNetwork(id) {
  const name = String(id).toLowerCase();
  return name.includes('celo') || ['alfajores', 'baklava'].includes(name);
}

// TODO: this is an unused function, can we remove it?
export function getRoundPaidAndNoPaymentNextRound(currentSegment, lastPayableSegment, playerInfo) {
  return (
    currentSegment === lastPayableSegment &&
    parseInt(playerInfo.mostRecentSegmentPaid) === currentSegment &&
    parseInt(playerInfo.mostRecentSegmentPaid) &&
    lastPayableSegment
  );
}

export function trimWords(words, offset, maxLength) {
  if (words?.length >= maxLength) {
    return { text: `${words.substr(0, offset)}...`, trimmed: true };
  }
  return { text: words, trimmed: false };
}

export function findPlayerAndOverrideWithdrawn(players, address, withdrawn) {
  const index = players.findIndex((pl) => pl.address?.toLowerCase() === address || pl.addr?.toLowerCase() === address);
  if (index > -1) {
    return players.map((e, i) => {
      if (i === index) {
        return [...players[index], withdrawn];
      }
      return e;
    });
  }
  return players;
}

export function getUniquePlayersInArray([first, ...rest]) {
  if (!first) {
    return [];
  }

  const newArray = [first];

  rest.forEach((player) => {
    if (Object.prototype.hasOwnProperty.call(player, 'address')) {
      if (!newArray.find((elem) => elem.address?.toLowerCase() === player.address?.toLowerCase())) {
        newArray.push(player);
      } else {
        const indx = newArray.findIndex((p) => p.address.toLowerCase() === player.address.toLowerCase());
        newArray[indx] = player;
      }
    } else if (Object.prototype.hasOwnProperty.call(player, 'addr')) {
      if (!newArray.find((elem) => elem.addr?.toLowerCase() === player.addr?.toLowerCase())) {
        newArray.push(player);
      } else {
        const indx = newArray.findIndex((p) => p.addr?.toLowerCase() === player.addr?.toLowerCase());
        newArray[indx] = player;
      }
    }
  });

  return newArray;
}

/**
 * check if the user address is valid
 */
export const isAddressConsistent = (address) => {
  if (!address || !address.length) {
    return false;
  }

  if (address.includes(ZERO_ADDRESS)) {
    return false;
  }

  return true;
};

/**
 * Parse boolean env variables
 */

export const parseBoolEnvVar = (env) => {
  return env === 'true';
};

export function pickRandomItem(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

const PlayerStatus = {
  Winning: 'Winning',
  Withdrawn: 'Withdrawn',
  Waiting: 'Waiting',
  Won: 'Won',
  Lost: 'Lost',
};

export function getStatusLabel(game) {
  const { withdrawn, isGameCompleted, isWinner, isWaiting } = game;
  if (withdrawn && !isGameCompleted) {
    return PlayerStatus.Withdrawn;
  }
  if (isWinner && !isGameCompleted) {
    return PlayerStatus.Winning;
  }
  if (isWaiting && !isGameCompleted) {
    return PlayerStatus.Waiting;
  }
  if (isWinner && isGameCompleted) {
    return PlayerStatus.Won;
  }
  return PlayerStatus.Lost;
}
