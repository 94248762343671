import React from 'react';
import { useSelector } from 'react-redux';
import { getGameInfo } from 'redux/selectors/game.selector';
import { getStableToken } from 'redux/selectors/pool.selector';
import { getUserBalance } from 'redux/selectors/user.selector';

import { bigNumberFrom } from 'utils/bignumber';
import { format } from 'utils/numberFormat';

export default function BuyCryptoMsg({ depositAmount }) {
  const stableToken = useSelector(getStableToken);
  const userBalance = useSelector(getUserBalance);
  const { paymentAmount } = useSelector(getGameInfo);
  const paymentAmountBN = bigNumberFrom(depositAmount ?? paymentAmount);
  const userBalanceBN = bigNumberFrom(userBalance);
  const margin = format(paymentAmountBN.minus(userBalanceBN), stableToken);

  const alertMessage = `
    Your ${String(stableToken).toUpperCase()} balance is too low by ${margin}. Click 'Get ${String(
    stableToken
  ).toUpperCase()}' below to top up.
    `;
  return <>{margin > 0 && <p className="code red-text">{alertMessage}</p>}</>;
}
