import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  invalidMsg: PropTypes.string,
};

const defaultProps = {
  value: undefined,
  label: '',
  placeholder: '',
  type: '',
  disabled: false,
  isInvalid: false,
  invalidMsg: '',
};

const InputGroupWrap = ({ value, label, type, placeholder, disabled, isInvalid, invalidMsg, ...props }) => {
  return (
    <InputGroup className="mb-3 input-group-wrap" {...props}>
      <FormControl
        value={value}
        placeholder={placeholder}
        type={type}
        isInvalid={isInvalid}
        disabled={disabled}
        aria-label={placeholder}
        aria-describedby="basic-addon2"
      />
      <InputGroup.Text id="basic-addon2">{label}</InputGroup.Text>
      {isInvalid && <FormControl.Feedback type="invalid">{invalidMsg}</FormControl.Feedback>}
    </InputGroup>
  );
};

InputGroupWrap.propTypes = propTypes;
InputGroupWrap.defaultProps = defaultProps;

export default InputGroupWrap;
