import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.number.isRequired,
};

const HighestProgressValue = 100;

function setProgress(progressBar, value) {
  progressBar.forEach((path) => {
    const length = path.getTotalLength();
    const to = length * ((100 - value) / 100);
    path.getBoundingClientRect();

    // eslint-disable-next-line no-param-reassign
    path.style.strokeDashoffset = Math.max(0, to);
  });
}

function RoundProgressBar({ value, label }) {
  useEffect(() => {
    const outerProgressBar = document.querySelectorAll('svg[data-value] .outer-progress-bar');
    setProgress(outerProgressBar, value);
    const innerProgressBar = document.querySelectorAll('svg[data-value] .inner-progress-bar');
    setProgress(innerProgressBar, 100);
  }, [value]);

  const xWidth = Number(label) > 9 ? '41%' : '45%';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="55" width="55" viewBox="0 0 200 200" data-value="40">
      <path
        className={`progess-bar-circular ${value === HighestProgressValue && 'progess-bar-completed'}`}
        stroke="#ccc"
        d=" M 100, 100
                 m -75, 0
                 a 75,75 0 1,0 150,0
                 a 75,75 0 1,0 -150,0"
        fill="none"
      />
      <text x={xWidth} y="50%" dy=".3em" className="description-svg">
        {label}
      </text>
      <path
        className="inner-progress-bar"
        d="M41 149.5a77 77 0 1 1 117.93 0"
        fill="none"
        strokeDasharray="350"
        strokeDashoffset="350"
      />
      <path
        className="outer-progress-bar"
        stroke="#6882CF"
        d="M41 149.5a77 77 0 1 1 117.93 0"
        fill="none"
        strokeDasharray="350"
        strokeDashoffset="350"
      />
    </svg>
  );
}

RoundProgressBar.propTypes = propTypes;
export default RoundProgressBar;
