import React, { useContext } from 'react';
import { utils, ethers } from 'ethers';
import { EnsContext } from 'providers/EnsProvider';
import { UnstoppableDomainContext } from 'providers/UnstoppableDomainProvider';
import { ZERO_ADDRESS, ENS_ADDRESS, ENS_REGISTRY, UNSTOPPABLE_DOMAIN_REGISTRY, REGISTRY } from 'utils/constants';

export const AddressResolverProviderContext = React.createContext();

export default function AddressResolverProvider({ children }) {
  const EnsClient = useContext(EnsContext);
  const UnstoppableDomainClient = useContext(UnstoppableDomainContext);

  const getEnsName = async (address) => {
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CELO_RPC);
    const getEnsNameResolver = Object.keys(ENS_REGISTRY).map((tld) => {
      if (tld !== 'nom') {
        return EnsClient.getEnsName(address, provider, ENS_ADDRESS);
      }
      return EnsClient.getEnsName(address, provider, ENS_ADDRESS, tld);
    });
    const ensNameResolver = await Promise.all(getEnsNameResolver);
    const ensNames = ensNameResolver.filter((ens) => !utils.isAddress(ens));
    if (ensNames.length > 0) {
      const [ensName] = ensNames;
      return ensName;
    }
    return address;
  };

  const searchENS = async (searchParam, tld) => {
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CELO_RPC);
    const userAddress = await EnsClient.getAddress(provider, ENS_ADDRESS, searchParam, tld);
    if (userAddress.indexOf(ZERO_ADDRESS) !== -1) {
      return { fetched: false, userAddress: '', userName: '' };
    }
    return { fetched: true, userAddress, userName: searchParam };
  };

  const searchUnstoppableDomain = async (searchParam) => {
    const userAddress = await UnstoppableDomainClient.getAddress(searchParam).catch((err) => err);
    const isAddress = utils.isAddress(userAddress);
    if (isAddress) {
      return { fetched: true, userAddress, userName: searchParam };
    }
    return { fetched: false, userAddress: '', userName: '' };
  };

  const searchRegistry = (searchParam, tld) => {
    const registry = Object.assign(ENS_REGISTRY, UNSTOPPABLE_DOMAIN_REGISTRY);
    const registryFound = registry[tld];
    if (registryFound === REGISTRY.ensDomain) {
      return searchENS(searchParam, tld);
    }
    if (registryFound === REGISTRY.unstoppableDomain) {
      return searchUnstoppableDomain(searchParam);
    }
    return new Error('Not Implemented');
  };

  const searchAllRegistry = async (searchParam) => {
    const getEnsDomainResolver = Object.keys(ENS_REGISTRY).map((tld) => {
      const playerName = `${searchParam}.${tld}`;
      return searchENS(playerName, tld);
    });
    const ensDomainResolver = await Promise.all(getEnsDomainResolver);
    const ensDomains = ensDomainResolver.filter((ens) => !!ens.fetched);

    if (ensDomains.length > 0) {
      const [ensDomain] = ensDomains;
      const { userAddress, userName } = ensDomain;
      return { fetched: true, userAddress, userName };
    }

    const getUnstoppableDomainResolver = Object.keys(UNSTOPPABLE_DOMAIN_REGISTRY).map((tld) => {
      const playerName = `${searchParam}.${tld}`;
      return searchUnstoppableDomain(playerName, tld);
    });

    const unstoppableDomainResolver = await Promise.all(getUnstoppableDomainResolver);
    const unstoppableDomains = unstoppableDomainResolver.filter((unstoppableDomain) => !!unstoppableDomain.fetched);

    if (unstoppableDomains.length > 0) {
      const [unstoppableDomain] = unstoppableDomains;
      const { userAddress, userName } = unstoppableDomain;
      return { fetched: true, userAddress, userName };
    }
    return { fetched: false, userAddress: '', userName: '' };
  };

  const resolveAddress = async (address) => {
    if (!address) {
      return new Error('AddressResolverProvider: Address not provider');
    }
    const addressInLowerCase = address.toLowerCase();
    const isAddress = utils.isAddress(addressInLowerCase);

    if (isAddress) {
      const ensName = await getEnsName(addressInLowerCase);
      return { userAddress: addressInLowerCase, userName: ensName };
    }
    const [name, tld] = addressInLowerCase.split('.');
    if (!tld) {
      const { userAddress, userName } = await searchAllRegistry(name);
      return { userAddress, userName };
    }
    const response = await searchRegistry(addressInLowerCase, tld);
    if (response instanceof Error) {
      return new Error(`AddressResolverProvider: ${response}`);
    }
    const { fetched, userAddress, userName } = response;

    if (!fetched) {
      return new Error('AddressResolverProvider: Could not resolve address');
    }
    return { userAddress, userName };
  };

  return (
    <AddressResolverProviderContext.Provider value={{ resolveAddress }}>
      {children}
    </AddressResolverProviderContext.Provider>
  );
}
