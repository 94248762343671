import { createSelector } from '@reduxjs/toolkit';

const getFeedbacks = (state) => state.feedbacks;

export const getCustomInput = createSelector(getFeedbacks, (feedbacks) => feedbacks.customInput);
export const getRevokeApproval = createSelector(getFeedbacks, (feedbacks) => feedbacks.revokeApproval);
export const getErrorNotWhitelisted = createSelector(getFeedbacks, (feedbacks) => feedbacks.errorNotWhitelisted);
export const getLoaderGetPlayer = createSelector(getFeedbacks, (feedbacks) => feedbacks.loaderGetPlayer);
export const getLoaderDashboardProfile = createSelector(getFeedbacks, (feedbacks) => feedbacks.loaderDashboardProfile);
export const getLoaderInitGame = createSelector(getFeedbacks, (feedbacks) => feedbacks.loaderInitGame);
export const getLoaderEarlyWithdraw = createSelector(getFeedbacks, (feedbacks) => feedbacks.loaderEarlyWithdraw);
export const getLoaderWithdraw = createSelector(getFeedbacks, (feedbacks) => feedbacks.loaderWithdraw);
export const getLoaderMakeDeposit = createSelector(getFeedbacks, (feedbacks) => feedbacks.loaderMakeDeposit);
export const getLoaderApproveDeposit = createSelector(getFeedbacks, (feedbacks) => feedbacks.loaderApproveDeposit);
export const getLoaderGasPrices = createSelector(getFeedbacks, (feedbacks) => feedbacks.loaderGasPrices);
export const getErrorTransaction = createSelector(getFeedbacks, (feedbacks) => feedbacks.errorTransaction);
export const getErrorRefusedSignature = createSelector(getFeedbacks, (feedbacks) => feedbacks.errorRefusedSignature);
export const getErrorTimeout = createSelector(getFeedbacks, (feedbacks) => feedbacks.errorTimeout);
export const getShowEarlyWithdrawModal = createSelector(getFeedbacks, (feedbacks) => feedbacks.showEarlyWithdrawModal);
export const getShowWithdrawModal = createSelector(getFeedbacks, (feedbacks) => feedbacks.showWithdrawModal);
export const getShowDepositModal = createSelector(getFeedbacks, (feedbacks) => feedbacks.showDepositModal);
export const getSuccessEarlyWithdraw = createSelector(getFeedbacks, (feedbacks) => feedbacks.successEarlyWithdraw);
export const getSuccessWithdraw = createSelector(getFeedbacks, (feedbacks) => feedbacks.successWithdraw);
export const getSuccessJoinGame = createSelector(getFeedbacks, (feedbacks) => feedbacks.successJoinGame);
export const getSuccessApproveDeposit = createSelector(getFeedbacks, (feedbacks) => feedbacks.successApproveDeposit);
export const getShowConfirmModal = createSelector(getFeedbacks, (feedbacks) => feedbacks.showConfirmModal);
export const getShowVideoModal = createSelector(getFeedbacks, (feedbacks) => feedbacks.showVideoModal);
export const getShowUserSurveyModal = createSelector(getFeedbacks, (feedbacks) => feedbacks.showUserSurveyModal);
export const getShowParentChallengeModal = createSelector(
  getFeedbacks,
  (feedbacks) => feedbacks.showParentChallengeModal
);
export const getShowMintNFTModal = createSelector(getFeedbacks, (feedbacks) => feedbacks.showMintNFTModal);
export const getShowInfoModal = createSelector(getFeedbacks, (feedbacks) => feedbacks.showInfoModal);
export const getShowCustomiseAvatarModal = createSelector(
  getFeedbacks,
  (feedbacks) => feedbacks.showCustomiseAvatarModal
);
export const getShowDisclaimerModal = createSelector(getFeedbacks, (feedbacks) => feedbacks.showDisclaimerModal);
export const getShowCustomiseAvatarThankYouModal = createSelector(
  getFeedbacks,
  (feedbacks) => feedbacks.showCustomiseAvatarThankYouModal
);
export const getLoaderJoinGame = createSelector(getFeedbacks, (feedbacks) => feedbacks.loaderJoinGame);
export const getLoaderUpdatingGame = createSelector(getFeedbacks, (feedbacks) => feedbacks.loaderUpdatingGame);
export const getErrorTransactionMessage = createSelector(
  getFeedbacks,
  (feedbacks) => feedbacks.errorTransactionMessage
);
