import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePlayersStats } from 'redux/reducers/players';
import {
  setLoaderEarlyWithdraw,
  setSuccessEarlyWithdraw,
  setShowEarlyWithdrawModal,
  setErrorTransaction,
  setErrorTransactionMessage,
  setErrorRefusedSignature,
  resetErrors,
} from 'redux/reducers/feedbacks';
import { getPoolInfo } from 'redux/selectors/pool.selector';
import { setRefetch as setRefetchPlayer } from 'redux/reducers/user';
import { getWinnersCount, getDropOutsCount } from 'redux/selectors/players.selector';
import { getNetworkId } from 'redux/selectors/game.selector';
import { getUserAddress } from 'redux/selectors/user.selector';
import { GasContext } from 'providers/GasProvider';

import useWriteContract from 'providers/ContractProvider/useWriteContract';

import { useSendEvent } from './useSendEvent';

export default function useEarlyWithdraw() {
  const dispatch = useDispatch();
  const { reset } = useContext(GasContext);
  const WriteContract = useWriteContract();
  const winners = useSelector(getWinnersCount);
  const dropouts = useSelector(getDropOutsCount);
  const networkId = useSelector(getNetworkId);
  const usersAddress = useSelector(getUserAddress);
  const poolInfo = useSelector(getPoolInfo);

  const logEvent = useSendEvent();

  const earlyWithdraw = async () => {
    dispatch(setLoaderEarlyWithdraw(true));

    try {
      await WriteContract.earlyWithdraw();
      dispatch(setSuccessEarlyWithdraw({ success: true, id: poolInfo.contract }));
      dispatch(setRefetchPlayer(true));
      dispatch(updatePlayersStats({ key: 'winners', value: ~~winners - 1 }));
      dispatch(updatePlayersStats({ key: 'dropouts', value: ~~dropouts + 1 }));
    } catch (err) {
      console.error(err);
      if (err?.message?.includes('User denied')) {
        dispatch(setErrorRefusedSignature(true));
      } else {
        dispatch(setErrorTransaction(true));
        if (err?.message.includes('max fee per gas less than block base fee')) {
          dispatch(
            setErrorTransactionMessage(
              'Minimum Network fee is higher than the Transaction Speed set. Increase the Transaction Speed and try again'
            )
          );
        } else {
          dispatch(setErrorTransactionMessage(err?.message));
        }
        logEvent('EARLY_WITHDRAW_TRANSACTION_ERROR', networkId, {
          errMsg: err?.message,
          usersAddress,
          navigator: window.navigator.userAgent,
        });
      }
    } finally {
      dispatch(setLoaderEarlyWithdraw(false));
    }
  };

  const closeEarlyWithdrawModal = () => {
    dispatch(setShowEarlyWithdrawModal(false));
    dispatch(resetErrors());
    dispatch(setSuccessEarlyWithdraw({ success: false, id: poolInfo.contract }));
    reset();
  };

  return [earlyWithdraw, closeEarlyWithdrawModal];
}
