/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { format, truncateAndformat, truncateAndFormatCoin } from 'utils/numberFormat';

import { getGameInfo, getGameStats } from 'redux/selectors/game.selector';
import { isEmpty } from 'lodash';
import Loading from 'components/elements/Loading';
import { RiskProfilesGrayIconMap, RiskProfilesLinks } from 'utils/RiskProfiles';
import Tooltip from 'components/elements/Tooltip';
import useViewableStats from './hooks/useViewableStats';
import ExtraSection from './ExtraSection';
import RenderStat from './RenderStat';
import RenderData from './RenderData';
import { docsURL } from '../../../../utils/brand';

const NegativeValueTooltip = () => {
  return (
    <p>
      Check our{' '}
      <a
        href={`${docsURL}/halofi-challenges/technical-documentation/slippage-and-impermanent-loss`}
        target="_blank"
        rel="noreferrer"
      >
        Risk Disclaimers
      </a>{' '}
      to learn more why a pool may have negative earning
    </p>
  );
};

export default function GameStats() {
  const gameInfo = useSelector(getGameInfo);
  const gameStats = useSelector(getGameStats);
  const [deposit, amount, network, riskAndStrategy, currentRound] = useViewableStats();
  const { totalEarningsConverted, apy } = gameStats;
  const { stableToken } = useMemo(() => {
    if (isEmpty(gameInfo)) {
      return { stableToken: '', isWeth: false };
    }
    const _stableToken = gameInfo.depositToken;
    const _isWeth = _stableToken?.toLowerCase() === 'weth';
    return { stableToken: _stableToken, isWeth: _isWeth };
  }, [gameStats, gameInfo]);

  if (isEmpty(gameInfo) || isEmpty(gameStats)) {
    return <Loading />;
  }

  return (
    <section>
      <div>
        <div>
          <h3 className="d-sm-none animate__fadeIn mt-custom-5">Game Stats</h3>
          <div className="game-stats">
            <Row>
              <Col className="col-sm">
                <RenderStat title={deposit.label} value={deposit.data} tooltip={deposit.tooltip} />
              </Col>
              <Col className="col-sm">
                <RenderStat title={amount.label} value={amount.data} tooltip={amount.tooltip} />
              </Col>

              <Col className="col-sm">
                <RenderStat title={network.label} value={network.data} tooltip={network.tooltip} />
              </Col>
              <Col className="col-sm">
                <RenderStat
                  title={
                    <>
                      {riskAndStrategy.label}
                      <span className="risk-info">
                        <Tooltip
                          content={riskAndStrategy.tooltip}
                          placement="bottom"
                          displayContent={() => <i className="bi bi-info" />}
                        />
                      </span>
                    </>
                  }
                  value={
                    <span className="strategy">
                      <a href={RiskProfilesLinks[riskAndStrategy.rawData]} rel="noreferrer" target="_blank">
                        <img src={RiskProfilesGrayIconMap[riskAndStrategy.rawData]} alt="icon" />
                      </a>
                      <span className="line">|</span>
                      <p className="m-0">{riskAndStrategy.data}</p>
                    </span>
                  }
                />
              </Col>
            </Row>
            <Row className="game-stats-second">
              <Col className="col-sm">
                <RenderData
                  label="Yield (APY)"
                  data={truncateAndformat(apy, 2)}
                  measure="%"
                  tooltipText="The Annual Percentage Yield (APY) earned by all winners in this challenge, calculated in USD. It is a variable rate that includes lending interest, any token rewards and sponsorship, fees from drop-outs and gains or losses due to impermanent loss and performance fees."
                  extraTooltip={parseFloat(gameStats.apy) < 0 && <NegativeValueTooltip />}
                />
              </Col>
              <Col className="col-sm">
                <RenderData
                  label="Total Earnings"
                  data={
                    gameInfo.adminAlert // NOTE the call public admin function can be deleted when he no longer support batch games.
                      ? 'Call public admin function.'
                      : `≈ ${truncateAndFormatCoin(totalEarningsConverted, 'usd')}`
                  }
                  measure={!gameInfo.adminAlert && 'USD'}
                  tooltipText="The total earnings of the challenge thus far. This amount will be shared among all winning participants after deducting performance fees."
                  extraTooltip={parseFloat(gameStats.totalEarningsConverted) < 0 && <NegativeValueTooltip />}
                />
              </Col>

              <Col className="col-sm">
                <RenderData
                  label="Total Deposited"
                  data={format(gameStats.principal, stableToken)}
                  measure={String(stableToken).toUpperCase()}
                  tooltipText="The total amount deposited into the challenge by participants"
                />
              </Col>
              <Col className="col-sm">
                <RenderData label={currentRound.label} data={currentRound.data} tooltipText={currentRound.tooltip} />
              </Col>
            </Row>
            <ExtraSection />
          </div>
        </div>
      </div>
    </section>
  );
}
