import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from './Tooltip';

const propTypes = {
  className: PropTypes.string,
  propertyClassName: PropTypes.string,
  segmentProgress: PropTypes.number,
  currentProgressInSegment: PropTypes.number,
  gameStartsOn: PropTypes.string,
  gameEndsAt: PropTypes.string,
  stacked: PropTypes.boolean,
  nextSegmentEnd: PropTypes.string,
  segmentProgressDescription: PropTypes.string,
};

const defaultProps = {
  className: '',
  propertyClassName: '',
  segmentProgress: 0,
  currentProgressInSegment: 0,
  gameStartsOn: '',
  gameEndsAt: '',
  stacked: false,
  nextSegmentEnd: '',
  segmentProgressDescription: '',
};

const getSegmentProgress = ({ progressValue }) => {
  return {
    width: `${progressValue}%`,
  };
};

const ProgressBar = ({ className, thumb, tooltip, ...props }) => {
  const classes = classNames(className);
  const showTooltip = !!tooltip;

  const displayContent = () => (
    <div className={classes} {...props}>
      <div className="progress-value" />
    </div>
  );

  if (showTooltip) {
    return <Tooltip placement="right-start" content={tooltip} displayContent={displayContent} />;
  }

  return (
    <div className={classes} {...props}>
      <div className="progress-value" />
      {thumb && <div className="progress-thumb" />}
    </div>
  );
};

const ProgressBarWrap = ({
  className,
  propertyClassName,
  segmentProgress,
  currentProgressInSegment,
  gameStartsOn,
  stacked,
  nextSegmentEnd,
  segmentProgressDescription,
  ...props
}) => {
  const classes = classNames(gameStartsOn && 'progress-bar-wrap', className);
  const propertyClasses = classNames('progress', propertyClassName);

  const nextSegmentEndTime = nextSegmentEnd && new Date(nextSegmentEnd).toString();
  const segmentDescription = `${segmentProgressDescription} ${nextSegmentEndTime}`;

  const currentSegmentProgress = getSegmentProgress({
    progressValue: parseInt(segmentProgress),
  });

  const progressInSegment = getSegmentProgress({
    progressValue: parseInt(currentProgressInSegment),
  });

  return (
    <div className={classes} {...props}>
      <div className={propertyClasses}>
        {stacked && (
          <div>
            {' '}
            <div className="progress-starting-round-event progress-text">Launched</div>
            <div className="progress-ending-round-event progress-text">Withdraw</div>
          </div>
        )}
        {stacked && (
          <ProgressBar
            className="progress-light-primary progress-done"
            style={{ ...currentSegmentProgress }}
            tooltip={segmentDescription}
          />
        )}
        <ProgressBar className="progress-dark-primary progress-done" style={{ ...progressInSegment }} />
      </div>
    </div>
  );
};

ProgressBarWrap.propTypes = propTypes;
ProgressBarWrap.defaultProps = defaultProps;

export default ProgressBarWrap;
