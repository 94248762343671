import { useContext } from 'react';
import { ContractContext } from 'providers/ContractProvider/ContractProvider';
import { GasContext } from 'providers/GasProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSuccessApproveDeposit,
  setLoaderApproveDeposit,
  setErrorTransaction,
  setErrorRefusedSignature,
  setErrorTransactionMessage,
} from 'redux/reducers/feedbacks';
import { updatePlayerInfo } from 'redux/reducers/user';
import { getNetworkId } from 'redux/selectors/game.selector';

import { useSendEvent } from './useSendEvent';

export default function useApproveDeposit() {
  const dispatch = useDispatch();
  const ContractClient = useContext(ContractContext);
  const { show, defaultChoice, setUserChoice, setCustomFee, setBtnName, setPrevBtn, setShow } = useContext(GasContext);
  const usersAddress = useSelector((state) => state.user.address);
  const networkId = useSelector(getNetworkId);

  const logEvent = useSendEvent();

  const approveDeposit = async (amount) => {
    dispatch(setLoaderApproveDeposit(true));
    try {
      const allowance = await ContractClient.approveToSpendToken(usersAddress, amount);

      dispatch(setSuccessApproveDeposit(true));
      dispatch(updatePlayerInfo({ allowance: parseFloat(allowance), allowanceString: allowance.toString() }));
    } catch (err) {
      console.error(err);
      if (err?.message?.includes('User denied')) {
        dispatch(setErrorRefusedSignature(true));
      } else {
        dispatch(setErrorTransaction(true));
        if (err?.message.includes('max fee per gas less than block base fee')) {
          dispatch(
            setErrorTransactionMessage(
              'Minimum Network fee is higher than the Transaction Speed set. Increase the Transaction Speed and try again'
            )
          );
        } else {
          dispatch(setErrorTransactionMessage(err?.message));
        }
        logEvent('APPROVE_TRANSACTION_ERROR', networkId, {
          errMsg: err?.message,
          usersAddress,
          navigator: window.navigator.userAgent,
        });
      }
      dispatch(setSuccessApproveDeposit(false));
    } finally {
      dispatch(setLoaderApproveDeposit(false));
      setUserChoice(defaultChoice);
      setCustomFee(0);
      setBtnName('low');
      setPrevBtn('low');
      if (show) {
        setShow(false);
      }
    }
  };

  return [approveDeposit];
}
