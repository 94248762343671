import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setShowVideoModal, resetErrors } from 'redux/reducers/feedbacks';
import { GasContext } from 'providers/GasProvider';
import Modal from 'components/elements/Modal';
import ModalHeader from 'components/elements/ModalHeader';
import Button from 'components/elements/Button';
import ModalFooter from 'components/elements/ModalFooter';
import { docsURL } from '../../../../../utils/brand';

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default () => {
  const dispatch = useDispatch();
  const { reset } = useContext(GasContext);
  const showVideoModal = useSelector((state) => state.feedbacks.showVideoModal);

  const closeVideoModal = () => {
    dispatch(setShowVideoModal(false));
    dispatch(resetErrors());
    reset();
  };

  return (
    <div className="video-modal">
      <Modal handleClose={closeVideoModal} show={showVideoModal}>
        <ModalHeader header="How we help you save" onClose={closeVideoModal} />
        <YoutubeEmbed embedId="cA-gsJrQXRU" />
        <br />
        <p className="video-subheading">
          more info in our <a href={docsURL}> docs</a>
        </p>

        <ModalFooter>
          <Button className="button-primary-outline mr-15" onClick={closeVideoModal} id="video-explainer-close">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
