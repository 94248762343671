import React from 'react';
import Loading from './Loading';

export default function CenterLoading({ text }) {
  return (
    <div className="section center-content">
      <Loading classes="m-30" />
      <span>{text}</span>
    </div>
  );
}
