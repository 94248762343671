/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Button from 'components/elements/Button';
import Image from 'components/elements/Image';
import { HOW_IT_WORKS, DEPOSIT_TYPE, LEADERBOARD_TYPES } from 'utils/constants';
import RoundProgressBar from 'components/elements/RoundProgressBar';
import imgWhereToStart from 'assets/how-it-works/where-to-start.svg';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import imgAvatar from 'assets/how-it-works/avatar.svg';
import imgIllustrationCelebrationFirst from 'assets/how-it-works/illustration-celebration-1.svg';
import imgIllustrationCelebrationSecond from 'assets/how-it-works/illustration-celebration-2.svg';
import imgBadgesIllustration from 'assets/how-it-works/badges-illustration.png';
import PoolCard from 'components/elements/PoolCard';
import { titleCase, trimWords, displayAddress } from 'utils/utilities';
import { getDepositRoundMeasure, truncateAndformat } from 'utils/numberFormat';
import imgArrow from 'assets/how-it-works/arrow.svg';
import imgNFTMintPolygon from 'assets/how-it-works/nft-mint-polygon.svg';
import imgNFTMintCelo from 'assets/how-it-works/nft-mint-celo.svg';
import { PlayerProfileImg } from 'views/NewGame/components/LeftColumn/components/PlayerProfile';
import ProgessBar from 'components/elements/ProgessBar';
import Tabs from 'components/elements/TabGroup';
import { useSelector, useDispatch } from 'react-redux';
import { Web3Context } from 'providers/Web3Provider';
import {
  getTotalNumberOfGames,
  getNumberOfActiveGames,
  getPlayerGames,
  getGGScore,
} from 'redux/selectors/dashboard.selector';
import { getShowMintNFTModal } from 'redux/selectors/feedbacks.selector';
import { setShowMintNFTModal, setShowUserSurveyModal } from 'redux/reducers/feedbacks';
import classNames from 'classnames';
import { GameTypes } from 'views/PlayerDashboard/helpers/dashboardConstants';
import { sdkJsClient } from 'service/sdk';
import { appSubdomain, brandDomain, twitterHandle, hashtag, docsURL } from 'utils/brand';
import levels from 'utils/levels';
import Modal from 'components/elements/Modal';
import ModalHeader from 'components/elements/ModalHeader';
import UserSurveyModal from 'views/NewGame/components/Modals/UserSurveyModal/UserSurveyModal';
import PlayerGamesBadges from '../PlayerGamesBadges/PlayerGamesBadges';
import ChallengeCard from '../GamesSection/ChallengeCard';
import GamesSection from '../GamesSection/GamesSection';
import PreviousGames from '../GamesSection/PreviousGames';

function formatDate(date) {
  if (!date) {
    return '';
  }
  return new Date(date).toISOString().replace(/T.*/, '').split('-').reverse().join('.');
}

function findLevel(levelsArr, ggScoreNum) {
  const userslevel = levelsArr.find((level) => ggScoreNum >= level.minScore && ggScoreNum <= level.maxScore);
  return userslevel;
}

const StepButtonType = {
  Close: 'Close',
  Next: 'Next',
};

function MintNFT() {
  const dispatch = useDispatch();
  const showModal = useSelector(getShowMintNFTModal);

  const closeModal = () => {
    dispatch(setShowMintNFTModal(false));
  };

  const openLink = (url) => {
    window.open(url);
  };

  return (
    <Modal show={showModal} handleClose={closeModal}>
      <ModalHeader onClose={closeModal} />
      <div className="mint-nft-container">
        <div className="header-section">
          <h4>Mint your NFT on..</h4>
        </div>
        <div className="network-icons-container">
          <div className="network-icons">
            <div className="network-icon">
              <Image className="celo-icon" src={imgNFTMintCelo} alt="Celo" />
            </div>
            <div className="network-icon">
              <Image className="polygon-icon" src={imgNFTMintPolygon} alt="Polygon" />
            </div>
          </div>
        </div>
        <div className="description-container">
          <p>You will need a wallet which connects either Celo or Matic.</p>
        </div>
        <div className="mint-button-container">
          <Button
            className="button button-primary button-sm mr-80"
            onClick={() =>
              openLink(
                'https://bafybeic54udbggbhtn7nfjrrj7elrim4shfped7c3cco7neset7fdwpwmu.gateway.ipfscdn.io/erc1155.html?contract=0xA553FeDB4EEc005C0480172199B0B24307FFd0Ae&chain=%7B%22name%22%3A%22Celo+Mainnet%22%2C%22chain%22%3A%22CELO%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fcelo.rpc.thirdweb.com%2F5a9bc94b87f7cbbbfbbc234bf1e07f0adf5f3cf3012c9f26f9fc9820d64df93a%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22CELO%22%2C%22symbol%22%3A%22CELO%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22celo%22%2C%22chainId%22%3A42220%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22celo%22%7D&tokenId=4&primaryColor=purple'
              )
            }
          >
            Celo
          </Button>
          <Button
            className="button button-primary button-sm"
            onClick={() =>
              openLink(
                'https://bafybeic54udbggbhtn7nfjrrj7elrim4shfped7c3cco7neset7fdwpwmu.gateway.ipfscdn.io/erc1155.html?contract=0x750181AC14FA17df6A62A9B5602C7B541a04E726&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fpolygon.rpc.thirdweb.com%2F5a9bc94b87f7cbbbfbbc234bf1e07f0adf5f3cf3012c9f26f9fc9820d64df93a%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22MATIC%22%2C%22symbol%22%3A%22MATIC%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%7D&tokenId=9&primaryColor=purple'
              )
            }
          >
            Matic
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function HowItWorks({ steps, onHide }) {
  const [step, setStep] = useState(0);
  const [buttonText, setButtonText] = useState(StepButtonType.Next);
  const [fadeKey, setFadeKey] = useState(1);

  const onNextStep = () => {
    const numberOfSteps = steps.length;
    if (numberOfSteps === 0) {
      return;
    }
    if (step === numberOfSteps - 1) {
      onHide();
      return;
    }
    if (step + 1 === numberOfSteps - 1) {
      setButtonText(StepButtonType.Close);
    }
    const nextStep = step + 1;
    setStep(nextStep);
    setFadeKey(new Date().getTime());
  };

  const setNextStep = (index) => {
    const numberOfSteps = steps.length;
    if (index === numberOfSteps - 1) {
      setButtonText(StepButtonType.Close);
    } else {
      setButtonText(StepButtonType.Next);
    }
    setStep(index);
    setFadeKey(new Date().getTime());
  };

  return (
    <div className="main">
      <div className="steps-container">
        <button type="button" className="btn-close button-close-icon" aria-label="Close" onClick={onHide} />
        <div className="slide-indicator-container">
          {steps.map((_, i) => {
            const selected = step === i ? 'selected' : '';
            return <span className={`slide-indicator ${selected}`} key={i} onClick={() => setNextStep(i)} />;
          })}
        </div>
        <div className="how-it-work-container">
          {steps.map((element, i) => {
            const { title, description } = element;
            const selected = step === i ? 'selected' : '';
            return (
              <div className={`how-it-work ${selected}`}>
                <div className="title-container" onClick={() => setNextStep(i)}>
                  <span>{i + 1}.</span>
                  <h4>{title}</h4>
                </div>
                <p>{description}</p>
                <div className="button-container">
                  <Button className="button button-primary button-sm" onClick={onNextStep}>
                    {buttonText}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="image-container">
          <Image
            className="fade-in"
            src={steps[step]?.imageUri}
            key={fadeKey}
            alt="How-it-works"
            width={550}
            height={400}
          />
          <div className="button-container-mobile">
            <Button className="button button-primary button-sm" onClick={onNextStep}>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

function AvailableGames({ liveGames, className, isWalletConnected }) {
  const history = useHistory();
  const classes = classNames(!isWalletConnected && 'mb-40', 'available-challenges-section', className);

  return (
    <div className={classes}>
      <div className="available-challenges-container">
        {liveGames.slice(0, 4).map((game) => {
          const {
            gameNameShort,
            paymentAmount,
            mechanismType,
            depositType,
            depositToken,
            name,
            displayId,
            isParent,
            futureGame,
            gameImage,
            apy,
          } = game;

          const payableAmount =
            depositType === DEPOSIT_TYPE.Fixed
              ? `${paymentAmount} ${depositToken?.toUpperCase()}`
              : `${depositToken?.toUpperCase()}`;
          return (
            <PoolCard
              heading={gameNameShort}
              subHeading={
                !isParent
                  ? `${payableAmount} | ${titleCase(mechanismType)} |  ${titleCase(name)}`
                  : 'Choose a Challenge'
              }
              poolNumber={displayId}
              network={name}
              onClick={() => history.push(`/challenges/${displayId}`)}
              className="pool-box-container-dashboard"
              challengeType="dashboard"
              isfutureGame={futureGame}
              nftImage={gameImage}
              extraDescription={`${truncateAndformat(apy, 2)}% APY`}
            />
          );
        })}
      </div>
      {isWalletConnected && (
        <Button
          className="button button button-wide-mobile button-link button-container"
          onClick={() => history.push('/challenges')}
        >
          view more challenges
        </Button>
      )}
    </div>
  );
}

function ConnectWallet() {
  const Web3Client = useContext(Web3Context);

  const connectWallet = () => Web3Client.init();

  return (
    <div className="connect-wallet-container">
      <div className="left-container">
        <div className="header-section">
          <h4>Are you ready for a Savings Challenge?</h4>
        </div>
        <div className="connect-wallet-button">
          <Button className="button button-primary" onClick={connectWallet}>
            Get started
          </Button>
        </div>
        <p>
          Want to look around? Explore the <Link to="/challenges">Challenges</Link> or{' '}
          <Link to="/leaderboard">Leaderboard</Link>
        </p>
      </div>
      <div className="right-container">
        <Image className="image" src={imgBadgesIllustration} alt="How-it-works" width={550} height={400} />
      </div>
    </div>
  );
}

function NewJoinerProfile({ liveGames, isWalletConnected, openJoinableGames }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [hide, setHide] = useState('');
  const [challenge] = liveGames;
  let depositRoundMeasure = {};
  let sponsorLogo = null;

  if (challenge) {
    depositRoundMeasure = getDepositRoundMeasure(challenge);
    const { sponsors } = challenge;
    const [sponsor] = sponsors;
    sponsorLogo = sponsor?.logo;
  }
  const gameName = trimWords(challenge?.gameNameShort, 11, 14);
  const allJoinableGames = openJoinableGames.length;

  const onHide = () => {
    setHide('hide-how-it-works');
  };

  const handleUserSurveyModalClick = () => {
    dispatch(setShowUserSurveyModal(true));
  };

  return (
    <div className="new-joiner-dashboard-profile">
      {!isWalletConnected && <ConnectWallet />}
      <div className={`how-it-work ${hide}`}>
        <div className="content">
          <h4 className="title">How it Works</h4>
          <HowItWorks steps={HOW_IT_WORKS} onHide={onHide} />
        </div>
      </div>
      <div className="join-challenge-container">
        <div className="header">
          <h4>Join a DeFi Challenge</h4>
        </div>
        <div className="content">
          <div className="challenge-card-container">
            <div className="card-container-section">
              <p className="limited-time">Limited time only!</p>
              <ChallengeCard
                gameShortName={gameName.text}
                gameName={challenge?.gameNameShort}
                gameDescription={`${challenge?.baseToken} | ${titleCase(challenge?.mechanismType)} | ${titleCase(
                  challenge?.name
                )}`}
                gameEndDate={formatDate(challenge?.ends)}
                frequencyOfDeposit={depositRoundMeasure.frequency?.toLowerCase()}
                buttonLabel="Join"
                apy={truncateAndformat(challenge?.apy, 2)}
                network={challenge?.name}
                gameImage={sponsorLogo}
                onClick={() => history.push(`/challenges/${challenge?.displayId}`)}
              />
              <div className="arrow">
                <Image className="image" src={imgArrow} alt="How-it-works" width={180} height={99} />
              </div>
            </div>
          </div>
          <AvailableGames
            liveGames={liveGames}
            className="available-challenges-section-mobile"
            isWalletConnected={isWalletConnected}
          />
          <div className="where-to-start-container">
            <div className="gamification-challenges-container">
              <p>How many challenges can you join?</p>
              <div className="gamification">
                <RoundProgressBar value={0} label={allJoinableGames} />
              </div>
            </div>
            <div className="where-to-start">
              <div className="description description-mobile">
                <p>Not sure where to start?</p>
              </div>
              <div className="image-container">
                <Image className="image" src={imgWhereToStart} alt="How-it-works" width={118} height={161} />
              </div>
              <Button className="button button-primary-outline button-mobile" onClick={handleUserSurveyModalClick}>
                help me{' '}
              </Button>
              <div className="description">
                <p>Not sure where to start?</p>
                <Button className="button button-primary-outline" onClick={handleUserSurveyModalClick}>
                  help me{' '}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AvailableGames liveGames={liveGames} isWalletConnected={isWalletConnected} />
      {isWalletConnected && (
        <div className="footer-content">
          <div className="nft-avatar-container">
            <div className="header">
              <h4>NFTs</h4>
              <p>
                Earn NFTs by completing key milestones.{' '}
                <a target="_blank" href={`${docsURL}/hub/getting-started/discover-your-nfts`} rel="noreferrer">
                  Read more in our docs.
                </a>
              </p>
            </div>
            <div className="avatar">
              <div className="avatar-image">
                <Image className="image" src={imgAvatar} alt="How-it-works" width={61} height={61} />
              </div>
              <p>
                Earn your avatar by joining a <Link to="/challenges">challenge</Link>
              </p>
            </div>
          </div>
          <div className="nft-avatar-container-mobile">
            <div className="avatar-container">
              <div className="gamification-challenges-container">
                <div className="gamification">
                  <RoundProgressBar value={0} label={allJoinableGames} />
                </div>
                <p>How many challenges can you join?</p>
              </div>
              <div className="avatar">
                <div className="avatar-image">
                  <Image className="image" src={imgAvatar} alt="How-it-works" width={61} height={61} />
                </div>
                <p>
                  Earn your avatar by joining a <Link to="/challenges">challenge</Link>
                </p>
              </div>
            </div>
            <div className="header">
              <h4>NFTs</h4>
              <p>
                Earn NFTs by completing key milestones.{' '}
                <a target="_blank" href={`${docsURL}/hub/getting-started/discover-your-nfts`} rel="noreferrer">
                  Read more in our docs.
                </a>
              </p>
            </div>
          </div>
          <div className="video-container">
            <div className="video">
              <YoutubeEmbed embedId="cA-gsJrQXRU" />
            </div>
          </div>
        </div>
      )}
      <UserSurveyModal id="jEeqe2oP" />
    </div>
  );
}

function ProfileCard({ playerAddress, totalNumberOfGames, numberOfActiveGames, gamesWon, ggScore, isProfileOwner }) {
  const ggScoreProgressInPercentage = (Number(ggScore?.ggScore) / Number(ggScore?.nextGGScore)) * 100;

  function getRandomNumber(n) {
    return Math.floor(Math.random() * (n + 1));
  }

  const level = findLevel(levels, Number(ggScore?.ggScore));
  const randomNum = getRandomNumber(level?.intros.length - 1);
  let twitterText = null;

  if (level) {
    twitterText = isProfileOwner
      ? `${level?.intros[randomNum]}. Here's my progress on ${twitterHandle}\n\n🏆 GG Score: ${ggScore?.ggScore} \n💪 Total Challenges: ${totalNumberOfGames}\n🔝 I'm Level: ${level.level} 🚀\n\nMy Profile:`
      : `Wow ${displayAddress(playerAddress)} is seriously killing it on @halofi\n\n🏆 GG Score: ${
          ggScore?.ggScore
        } \n💪 Total Challenges: ${totalNumberOfGames}\n🔝 I'm Level: ${level.level} 🚀\n\nProfile:`;
  }

  return (
    <div className="profile-card">
      <div className="profile-image-container">
        <div className="level">
          <p>Level</p>
          <span>{ggScore?.level}</span>
        </div>
        <div className="profile-image">
          <PlayerProfileImg playerAddress={playerAddress.toLowerCase()} classes="avatar-dashboard" />
          <div className="twitter">
            <TwitterShareButton
              title={twitterText}
              hashtags={[hashtag, 'halofiprofile']} // #halofiprofile is a hashtag only used here. We can use to track how many of these tweets are sent.
              related={[twitterHandle]}
              url={`https://${appSubdomain}.${brandDomain}/#/dashboard/${playerAddress}?utm_source=twitter&utm_medium=social&utm_campaign=share_profile&utm_content=profile`}
              id="share-profile-from-dashboard"
            >
              <TwitterIcon size={36} round />
            </TwitterShareButton>
          </div>
        </div>
        <div className="gg-score">
          <p>GG Score</p>
          <span>{ggScore?.ggScore}</span>
        </div>
      </div>
      <div className="social-container">
        <span>Level up</span>
        <span>{ggScore?.nextGGScore}</span>
      </div>
      <ProgessBar currentProgressInSegment={ggScoreProgressInPercentage} className="activity-progress" />
      <div className="game-activity">
        <div className="activity">
          <span>Challenges</span>
          <span>{totalNumberOfGames}</span>
        </div>
        <div className="activity">
          <span>Actives</span>
          <span>{numberOfActiveGames}</span>
        </div>
        <div className="activity">
          <span>Won</span>
          <span>{gamesWon}</span>
        </div>
      </div>
    </div>
  );
}

function PlayerProfileCard({ playerAddress, playerJoinableGames }) {
  const history = useHistory();
  const [playerRanks, setPlayerRanks] = useState([]);

  const getPlayerAllTypesRanks = useCallback(async () => {
    const getPlayerRanks = Object.values(LEADERBOARD_TYPES).map((type) => {
      return sdkJsClient.getPlayerGGScore(playerAddress, type);
    });
    const playerTypesScores = await Promise.all(getPlayerRanks);
    setPlayerRanks(playerTypesScores);
  }, [playerAddress]);

  useEffect(() => {
    getPlayerAllTypesRanks();
  }, [getPlayerAllTypesRanks]);

  const [, v2Governance, allTime] = playerRanks;
  return (
    <div className="player-profile-container">
      <div className="rankings">
        <div className="header-section">
          <h4>rankings</h4>
        </div>
        <div className="rank-container">
          <div className="rank">
            <p>{allTime?.ranking}</p>
            <span>all time</span>
          </div>
          <div className="rank">
            <p>{v2Governance?.ranking}</p>
            <span>v2 governance</span>
          </div>
          <div className="rank">
            <p>{playerJoinableGames}</p>
            <span>challenges joined this month</span>
          </div>
        </div>
      </div>
      <div className="button-container">
        <Button onClick={() => history.push('/leaderboard')} className="button button-wide-mobile button-link">
          view leaderboards
        </Button>
      </div>
    </div>
  );
}

function UserChallengeCompletedCard({ playerJoinableGames, allJoinableGames, progressMadeInPercentage }) {
  const dispatch = useDispatch();

  const openNFTMintModal = () => {
    dispatch(setShowMintNFTModal(true));
  };
  return (
    <div className="user-challenge-completed-container">
      <div className="challenge">
        <div className="header-section">
          <h4>All challenges joined!</h4>
        </div>
        <div className="illustration-container">
          <img src={imgIllustrationCelebrationFirst} width="223" height="127" alt="How-it-works" />
          <img src={imgIllustrationCelebrationSecond} width="223" height="127" alt="How-it-works" />
        </div>
        <div className="more-details">
          <div className="gamification-container">
            <h4>Congrats</h4>
            <div className="gamification-section">
              <p>
                joined {playerJoinableGames} out of {allJoinableGames} joined challenges this month.
              </p>
              <div className="gamification">
                <RoundProgressBar value={progressMadeInPercentage} label={playerJoinableGames} />
              </div>
            </div>
          </div>
          <div className="nft-container">
            <p>you've earned a special NFT?</p>
            <Button className="button button-primary" onClick={openNFTMintModal}>
              Mint
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function UserChallengeCard({
  challenge,
  gameName,
  progressMadeInPercentage,
  playerJoinableGames,
  allJoinableGames,
  depositRoundMeasure,
  sponsorLogo,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleUserSurveyModalClick = () => {
    dispatch(setShowUserSurveyModal(true));
  };

  return (
    <div className="user-challenge-container">
      <div className="challenge">
        <div className="header">
          <h4>Your Next Challenge</h4>
          <p>
            Start with our top pick, or{' '}
            <Link to="/challenges" className="light-text">
              explore all challenges
            </Link>
          </p>
        </div>
        <div className="main-challenge">
          <ChallengeCard
            gameShortName={gameName.text}
            gameName={challenge?.gameNameShort}
            gameDescription={`${challenge?.baseToken} | ${titleCase(challenge?.mechanismType)} | ${titleCase(
              challenge?.name
            )}`}
            gameEndDate={formatDate(challenge?.ends)}
            frequencyOfDeposit={depositRoundMeasure.frequency?.toLowerCase()}
            buttonLabel="Join"
            apy={truncateAndformat(challenge?.apy, 2)}
            network={challenge?.name}
            gameImage={sponsorLogo}
            onClick={() => history.push(`/challenges/${challenge?.displayId}`)}
          />
        </div>
        <div className="more-details">
          <div className="gamification-container">
            <p>
              Join all available challenges for a surprise. You're at {playerJoinableGames} of {allJoinableGames} 👀
            </p>
            <div className="gamification">
              <RoundProgressBar value={progressMadeInPercentage} label={playerJoinableGames} />
            </div>
          </div>
          <div className="help-container">
            <p>Need help choosing the next challenge?</p>
            <Button className="button button-primary-outline" onClick={handleUserSurveyModalClick}>
              Help me
            </Button>
          </div>
        </div>
      </div>
      <UserSurveyModal id="jEeqe2oP" />
    </div>
  );
}

function UserProfile({
  liveGames,
  playerAddress,
  openJoinableGames,
  playerOpenGames,
  previousGames,
  playerBadges,
  isLoadingGames,
  playerActiveGames,
  showDisplayLoading,
  games,
  isProfileOwner,
}) {
  const [challenge] = liveGames;
  const totalNumberOfGames = useSelector(getTotalNumberOfGames);
  const numberOfActiveGames = useSelector(getNumberOfActiveGames);
  const playerGames = useSelector(getPlayerGames);
  const gamesWon = playerGames.filter((game) => game.isGameCompleted && game.isWinner).length;
  const ggScore = useSelector(getGGScore);
  let depositRoundMeasure = {};
  let sponsorLogo = null;

  if (challenge) {
    depositRoundMeasure = getDepositRoundMeasure(challenge);
    const { sponsors } = challenge;
    const [sponsor] = sponsors;
    sponsorLogo = sponsor?.logo;
  }
  const gameName = trimWords(challenge?.gameNameShort, 11, 14);
  const allJoinableGames = openJoinableGames.length;
  const playerJoinableGames = playerOpenGames.length;
  const progressMadeInPercentage = (playerJoinableGames / allJoinableGames) * 100;

  return (
    <div className="user-dashboard-profile">
      <div className="challenge-profile-container">
        {isProfileOwner && playerJoinableGames >= allJoinableGames && (
          <UserChallengeCompletedCard
            playerJoinableGames={playerJoinableGames}
            allJoinableGames={allJoinableGames}
            progressMadeInPercentage={progressMadeInPercentage}
          />
        )}
        {isProfileOwner && playerJoinableGames < allJoinableGames && (
          <UserChallengeCard
            challenge={challenge}
            gameName={gameName}
            progressMadeInPercentage={progressMadeInPercentage}
            playerJoinableGames={playerJoinableGames}
            allJoinableGames={allJoinableGames}
            depositRoundMeasure={depositRoundMeasure}
            sponsorLogo={sponsorLogo}
          />
        )}
        {!isProfileOwner && (
          <PlayerProfileCard playerAddress={playerAddress} playerJoinableGames={playerJoinableGames} />
        )}
        <ProfileCard
          playerAddress={playerAddress}
          totalNumberOfGames={totalNumberOfGames}
          numberOfActiveGames={numberOfActiveGames}
          gamesWon={gamesWon}
          ggScore={ggScore}
          isProfileOwner={isProfileOwner}
        />
      </div>
      <div className="tab-container">
        <Tabs tabListItemClassName="tab-list-item" tabContent="tab-content tab-content-dashboard">
          <div label="Challenges" value="" onClick={() => null} onSelectedTab={() => null}>
            <GamesSection
              isLoading={showDisplayLoading}
              gamesInSection={playerActiveGames}
              type={GameTypes.ActiveGame}
              games={games}
              playerAddress={playerAddress}
            />
          </div>
          <div label="NFTs" value="" onClick={() => null} onSelectedTab={() => null}>
            <PlayerGamesBadges games={previousGames} isLoading={isLoadingGames} playerBadges={playerBadges} />
          </div>
          <div label="Previous Challenges" value="" onClick={() => null} onSelectedTab={() => null}>
            <PreviousGames previousGames={previousGames} games={games} isLoading={isLoadingGames} />
          </div>
        </Tabs>
      </div>
    </div>
  );
}

function PlayerDashboardProfile({
  liveGames,
  isNewJoiner,
  games,
  playerActiveGames,
  playerAddress,
  previousGames,
  playerBadges,
  isLoadingGames,
  showDisplayLoading,
  openJoinableGames,
  playerOpenGames,
  isProfileOwner,
  isWalletConnected,
}) {
  if (!isWalletConnected && !playerAddress) {
    return (
      <NewJoinerProfile
        liveGames={liveGames}
        isLoadingGames={isLoadingGames}
        isWalletConnected={isWalletConnected}
        openJoinableGames={openJoinableGames}
      />
    );
  }
  return (
    <>
      {isNewJoiner && (
        <NewJoinerProfile
          liveGames={liveGames}
          isLoadingGames={isLoadingGames}
          isWalletConnected={isWalletConnected}
          openJoinableGames={openJoinableGames}
        />
      )}
      {!isNewJoiner && (
        <UserProfile
          openJoinableGames={openJoinableGames}
          playerOpenGames={playerOpenGames}
          liveGames={liveGames}
          playerAddress={playerAddress}
          previousGames={previousGames}
          playerBadges={playerBadges}
          isLoadingGames={isLoadingGames}
          playerActiveGames={playerActiveGames}
          showDisplayLoading={showDisplayLoading}
          games={games}
          isProfileOwner={isProfileOwner}
        />
      )}
      <MintNFT />
    </>
  );
}

export default PlayerDashboardProfile;
