import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserAddress } from 'redux/selectors/user.selector';

import classNames from 'classnames';
import Button from 'components/elements/Button';

import CommunityPoolCard from 'components/elements/CommunityPoolCard';
import { DEPOSIT_TYPE } from 'utils/constants';
import {
  titleCase,
  mappedToObject,
  getStatusLabel,
  getDateDiff,
  getCurrentTimestamp,
  getGameProgressStats,
} from 'utils/utilities';
import { getAllSegmentDateData, getSegmentGameInfo } from 'redux/service/gameInfoDTO';
import SkeletonCard from './SkeletonCard';
import GameWithdraw from '../GameWithdrawSection/GameWithdrawItem';

const numOfGamesToDisplay = 8;

function checkDeposits(game) {
  return !!game.isWaiting && !game.isWinner && !game.withdrawn && !game.isGameCompleted;
}

function checkWonWithdrawalDue(game) {
  return game.isGameCompleted && game.isWinner;
}

function checkLostWithdrawalDue(game) {
  return game.isGameCompleted && !game.withdrawn && !game.isWinner;
}

function normaliseToDepositWithdrawalAndGames(gamesInSection) {
  const depositDueGames = [...gamesInSection].filter((game) => checkDeposits(game));
  const wonWithdrawalDueGames = [...gamesInSection].filter((game) => checkWonWithdrawalDue(game));
  const lostWithdrawalDueGames = [...gamesInSection].filter((game) => checkLostWithdrawalDue(game));

  const otherGames = [...gamesInSection].filter(
    (game) => !checkDeposits(game) && !checkWonWithdrawalDue(game) && !checkLostWithdrawalDue(game)
  );
  const sortedOtherGames = [...otherGames].sort((a, b) => parseInt(a.daysDiff) - parseInt(b.daysDiff));

  return [...depositDueGames, ...wonWithdrawalDueGames, ...lostWithdrawalDueGames, ...sortedOtherGames];
}

const PlayerGameActionType = {
  Deposit: 'Deposit in',
  Withdraw: 'Withdraw in',
};

function getRemainingDays(remainingDays) {
  if (~~remainingDays < 1) {
    return 'soon';
  }
  return `${remainingDays} days`;
}

function getPlayerNextGameAction(gameInfo) {
  const currentSegment = ~~gameInfo.currentSegment;
  const totalSegmentCount = ~~gameInfo.totalSegmentCount;
  const mostRecentSegmentPaid = ~~gameInfo.mostRecentSegmentPaid;
  const paymentCount = ~~gameInfo.paymentCount - 1;

  const lastSegment = totalSegmentCount - 1;
  const now = getCurrentTimestamp();
  const segmentGameInfo = getSegmentGameInfo(gameInfo);
  const { currentProgress } = getGameProgressStats(segmentGameInfo);

  if (mostRecentSegmentPaid >= currentSegment && currentSegment < paymentCount) {
    const nextRound = getAllSegmentDateData(mostRecentSegmentPaid + 1, segmentGameInfo);

    const daysDiff = getDateDiff(nextRound, now);
    const remainingDays = getRemainingDays(daysDiff);

    return { playerAction: PlayerGameActionType.Deposit, remainingDays, currentProgress, daysDiff };
  }

  if (mostRecentSegmentPaid >= currentSegment && currentSegment === paymentCount) {
    const nextRound = getAllSegmentDateData(lastSegment + 1, segmentGameInfo);

    const daysDiff = getDateDiff(nextRound, now);
    const remainingDays = getRemainingDays(daysDiff);

    return { playerAction: PlayerGameActionType.Withdraw, remainingDays, currentProgress, daysDiff };
  }

  if (currentSegment - mostRecentSegmentPaid > 1 && !gameInfo.isGameCompleted) {
    const safeWithdrawalRound = getAllSegmentDateData(lastSegment + 1, segmentGameInfo);

    const daysDiff = getDateDiff(safeWithdrawalRound, now);
    const remainingDays = getRemainingDays(daysDiff);

    return { playerAction: PlayerGameActionType.Withdraw, remainingDays, currentProgress, daysDiff };
  }

  if (mostRecentSegmentPaid + 1 === currentSegment && currentSegment === lastSegment) {
    const withdrawalRound = getAllSegmentDateData(lastSegment + 1, segmentGameInfo);

    const daysDiff = getDateDiff(withdrawalRound, now);
    const remainingDays = getRemainingDays(daysDiff);

    return { playerAction: PlayerGameActionType.Withdraw, remainingDays, currentProgress, daysDiff };
  }
  const depositRound = getAllSegmentDateData(currentSegment, segmentGameInfo);

  const daysDiff = getDateDiff(depositRound, now);
  const remainingDays = getRemainingDays(daysDiff);

  return { playerAction: PlayerGameActionType.Deposit, remainingDays, currentProgress, daysDiff };
}

async function getPlayerActiveGames({ activeGames, games }) {
  if (activeGames.length === 0) {
    return [];
  }
  const mappedActiveGames = mappedToObject(activeGames, 'gameId');
  const playerActiveGames = Object.values(games).filter((game) => {
    return mappedActiveGames[game.contract] !== undefined;
  });

  return playerActiveGames.map((game) => {
    return {
      ...mappedActiveGames[game.contract],
      ...game,
      paymentAmount:
        game.depositType === DEPOSIT_TYPE.Flexible
          ? mappedActiveGames[game.contract]?.depositAmount
          : game?.paymentAmount,
      ...getPlayerNextGameAction({ ...mappedActiveGames[game.contract], ...game }),
    };
  });
}

function GamesSection({ isLoading, gamesInSection, type, games, playerAddress }) {
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const [playerGames, setPlayerGames] = useState([]);
  const userAddress = useSelector(getUserAddress);

  const shouldDisplayShowMore = gamesInSection.length > numOfGamesToDisplay;
  const toDisplayGames = showMore ? playerGames : playerGames.slice(0, numOfGamesToDisplay);

  const isUserProfile = userAddress === playerAddress;

  const getActiveGames = useCallback(async () => {
    const playerActiveGames = await getPlayerActiveGames({ activeGames: gamesInSection, games });
    const depositwithdrawalAndOtherGames = normaliseToDepositWithdrawalAndGames(playerActiveGames);

    setPlayerGames(depositwithdrawalAndOtherGames);
    // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  }, [playerAddress, JSON.stringify(gamesInSection)]);

  useEffect(() => {
    getActiveGames();
  }, [getActiveGames]);

  if (isLoading || Object.keys(games).length === 0) {
    return (
      <section className="games-section-dashboard-container">
        <section className={classNames('games-section', type)}>
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </section>
      </section>
    );
  }

  if (playerGames.length === 0) {
    return (
      <p className="mt-40 mb-70">
        Why not take on a <Link to="/challenges">challenge</Link> and earn some crypto while you're at it?
      </p>
    );
  }

  return (
    <section className="games-section-dashboard-container">
      <div className={classNames('games-section', type)}>
        {toDisplayGames.map((game) => {
          const {
            gameName,
            gameNameShort,
            paymentAmount,
            depositToken,
            gameNumber,
            mechanismType,
            name,
            gameImage,
            currentProgress,
            playerAction,
            remainingDays,
          } = game;
          const shouldDisplayIndicator = !!game.isWaiting && !game.isWinner && !game.withdrawn && isUserProfile;
          if (checkWonWithdrawalDue(game) || checkLostWithdrawalDue(game)) {
            return <GameWithdraw gameInfo={game} isProfileOwner={isUserProfile} progressBarValue={currentProgress} />;
          }

          return (
            <CommunityPoolCard
              heading={gameNameShort ?? gameName}
              nftImage={gameImage}
              subHeading={`${paymentAmount} ${depositToken && depositToken.toUpperCase()} | ${titleCase(
                mechanismType
              )} |  ${titleCase(name)}`}
              poolNumber={gameNumber}
              network={name}
              buttonText={shouldDisplayIndicator && 'Deposit Now'}
              buttonId="deposit-now-pool-card"
              status={shouldDisplayIndicator ? 'Make your Deposit' : getStatusLabel(game)}
              onClick={() => history.push(`/challenges/${gameNumber}`)}
              className="button-tertiary"
              showGameProgress
              progressBarValue={currentProgress}
              playerNextRoundActionText={!shouldDisplayIndicator && playerAction}
              playerNextRoundActionValue={remainingDays}
            />
          );
        })}
      </div>

      {shouldDisplayShowMore && !showMore && (
        <Button
          className="button button-primary-outline"
          onClick={() => setShowMore(true)}
          id="games-section-show-more"
        >
          Show all
        </Button>
      )}

      {showMore && (
        <Button
          className="button button-primary-outline"
          onClick={() => setShowMore(false)}
          id="games-section-show-less"
        >
          Show less
        </Button>
      )}
    </section>
  );
}

export default React.memo(GamesSection);
