import React from 'react';
import Loading from 'components/elements/Loading';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import { hasPaidThisSegment as calcHasPaidThisSegment } from 'Hooks/players';
import { Col, Container, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderUpdatingGame } from 'redux/reducers/feedbacks';
import {
  getGameInfo,
  getIsGameFull,
  getIsCompleted,
  getCurrentSegment,
  getPaymentCount,
  getLastSegment,
  getFirstSegment,
  getNextSegmentEnd,
  getLastSegmentEnd,
  getGameStarted,
} from 'redux/selectors/game.selector';
import { getUserStatus, getPlayer } from 'redux/selectors/user.selector';
import { getLoaderUpdatingGame } from 'redux/selectors/feedbacks.selector';
import { status, getGameProgressStats } from 'utils/utilities';
import ProgessBar from 'components/elements/ProgessBar';
import Badge from 'components/elements/Badge';
import LeftColumn from '../LeftColumn';
// import { Web3Context } from 'providers/Web3Provider';
// import Join from '../LeftColumn/components/Join';
// import MakeDepositButton from '../LeftColumn/components/MakeDepositButton';

/* function CallToActionButton() {
  const Web3Client = useContext(Web3Context);
  const userAddress = useSelector(getUserAddress);
  const userStatus = useSelector(getUserStatus);
  const unRegisteredPlayer = userAddress && userStatus !== status.registered;
  const errorNotWhitelisted = useSelector(getErrorNotWhitelisted);
  const gameAlreadyStarted = useSelector(getGameStarted);
  const loaderGetPlayer = useSelector(getLoaderGetPlayer);
  const userNetworkId = useSelector(getConnectedNetworkId);
  const player = useSelector(getPlayer);
  const hasWithdrawn = player.withdrawn && !player.isWinner;
  const gameInfo = useSelector(getGameInfo);
  const info = useSelector(getPoolInfo);
  const isWrongNetwork = ~~userNetworkId && ~~userNetworkId !== ~~info.networkId;
  const registeredPlayer = userAddress && userStatus === status.registered;

  const connect = () => Web3Client.init();

  if (!userAddress) {
    return (
      <Button 
      onClick={connect} 
      className="button-primary"
      id="countdown-connect-wallet"
      >
        Connect Wallet
      </Button>
    );
  }

  if (isWrongNetwork) {
    return null;
  }

  if (loaderGetPlayer) {
    return null;
  }

  if (unRegisteredPlayer && !errorNotWhitelisted && !gameAlreadyStarted) {
    return <Join />;
  }

  if ((registeredPlayer || hasWithdrawn) && Object.prototype.hasOwnProperty.call(player, 'withdrawn')) {
    if (hasWithdrawn && gameInfo.currentSegment === '0') {
      return <Join />;
    }
  }

  return <MakeDepositButton />;
} */

dayjs.extend(timezone);
dayjs.extend(relativeTime);

const Time = ({ label, hand }) => (
  <div>
    <p className="p-label">{label}</p>
    <p className="p-clock">{hand}</p>
  </div>
);

export default function CountDown() {
  const dispatch = useDispatch();
  const gameInfo = useSelector(getGameInfo);
  const isCompleted = useSelector(getIsCompleted);
  const currentSegment = useSelector(getCurrentSegment);
  const paymentCount = useSelector(getPaymentCount);
  const lastSegment = useSelector(getLastSegment);
  const isFirstSegment = useSelector(getFirstSegment);
  const nextSegmentEnd = useSelector(getNextSegmentEnd);
  const lastSegmentEnd = useSelector(getLastSegmentEnd);
  const userStatus = useSelector(getUserStatus);
  const playerInfo = useSelector(getPlayer);
  const loaderUpdatingGame = useSelector(getLoaderUpdatingGame);
  const hasPaidThisSegment = calcHasPaidThisSegment(playerInfo, gameInfo);
  const isGameFull = useSelector(getIsGameFull);
  const gameAlreadyStarted = useSelector(getGameStarted);
  const isGameLoaded = Object.keys(gameInfo).length > 0 && lastSegmentEnd !== undefined;
  const joinStatus = gameAlreadyStarted
    ? { text: 'closed to join', type: 'primary' }
    : { text: 'open to join', type: 'primary' };

  if (!nextSegmentEnd || isCompleted) {
    return null;
  }

  let currentSegmentProgress = 0;
  let currentProgressInSegment = 0;
  let gameStartTime = null;
  let gameEndTime = null;

  if (isGameLoaded) {
    const { currentProgress, segmentProgress } = getGameProgressStats({ ...gameInfo, nextSegmentEnd, lastSegmentEnd });

    gameStartTime = gameInfo.gameStartsOn;
    gameEndTime = lastSegmentEnd;

    currentSegmentProgress = segmentProgress;
    currentProgressInSegment = currentProgress;
  }

  let text;

  if (isFirstSegment) {
    text =
      userStatus === status.registered || isGameFull
        ? 'Next deposit opens on'
        : 'The clock is ticking - join the challenge today!';
  } else if (~~currentSegment === ~~paymentCount - 1) {
    text = 'Waiting round starts on:';
  } else if (~~currentSegment === ~~lastSegment) {
    text = "Time's almost up! The challenge ends at:";
  } else {
    text = !hasPaidThisSegment
      ? 'Dont forget! This deposit is due by:'
      : 'Get ready for the next round! The deposit window opens on:';
  }

  if (loaderUpdatingGame) {
    return (
      <Container className="next-action p-30">
        <Row>
          <Col>
            <Loading />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="next-action">
      <div className="dynamic-text-container">
        <p className="dynamic-text mb-0 mt-10"> {text}</p>
        {gameAlreadyStarted && <Badge label={joinStatus.text} type={joinStatus.type} />}
      </div>
      <section className="countdown-section">
        <div className="info-container">
          {isGameLoaded && (
            <ProgessBar
              segmentProgress={currentSegmentProgress}
              currentProgressInSegment={currentProgressInSegment}
              gameStartsOn={gameStartTime}
              gameEndsAt={gameEndTime}
              nextSegmentEnd={nextSegmentEnd}
              segmentProgressDescription={text}
              stacked
            />
          )}

          {/*
          COMMENTED OUT CALL TO ACTION
          <div className="call-to-action">
          <CallToActionButton />
        </div> */}
        </div>
        <div className="clock-container">
          <div className="clock">
            <Countdown
              date={new Date(nextSegmentEnd)}
              onComplete={() => {
                dispatch(setLoaderUpdatingGame(true));
              }}
              renderer={({ days, hours, minutes, seconds }) => {
                return (
                  <>
                    <Time label="Days" hand={days} />
                    <span className="separator">:</span>
                    <Time label="Hours" hand={hours} />
                    <span className="separator">:</span>
                    <Time label="Minutes" hand={minutes} />
                    <span className="separator">:</span>
                    <Time label="Seconds" hand={seconds} />
                  </>
                );
              }}
            />
          </div>
        </div>
      </section>
      <div className="left-content-display">
        <LeftColumn extraClasses="mobile-CTA" />
      </div>
    </Container>
  );
}
