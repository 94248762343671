import React from 'react';
import { useSelector } from 'react-redux';
import { getIsPlayer } from 'redux/selectors/user.selector';
import { isAddressConsistent, discordLink } from 'utils/utilities';
import { getPoolInfo } from 'redux/selectors/pool.selector';
import Alert from 'components/elements/Alert';

export const AlertJoinGame = () => {
  const successJoinGame = useSelector((state) => state.feedbacks.successJoinGame);
  const poolInfo = useSelector(getPoolInfo);

  return <Alert show={successJoinGame[poolInfo.id]} text="Success - you have joined the Challenge! 🥳" />;
};

export const AlertGameStarted = () => {
  const gameAlreadyStarted = useSelector((state) => state.game.derived.gameAlreadyStarted);

  return (
    <Alert
      show={gameAlreadyStarted}
      type="error-msg"
      text="Oops, deposits are closed. Check our Challenges page to not miss the next one!"
    />
  );
};

export const AlertGeneralError = () => {
  const generalError = useSelector((state) => state.feedbacks.errorGeneral);

  return (
    <Alert
      show={generalError}
      type="error-msg"
      text="Whoops, something went wrong 😕. Give your browser a quick refresh and try again."
    />
  );
};

export const AlertWithdrawOrGhost = () => {
  const usersAddress = useSelector((state) => state.user.address);
  const withdrawn = useSelector((state) => state.user.player.withdrawn);
  const isAlive = useSelector((state) => state.user.player.isAlive);
  const currentSegment = useSelector((state) => state.game.info.currentSegment);
  const totalSegmentCount = useSelector((state) => state.game.info.totalSegmentCount);
  const isFirstSegment = useSelector((state) => state.game.derived.isFirstSegment);
  const isWithdrawnOrGhost = withdrawn || (isAddressConsistent(usersAddress) && isAlive !== undefined && !isAlive);
  const isPayableSegment = ~~currentSegment <= totalSegmentCount - 1;
  const isPlayer = useSelector(getIsPlayer);

  return (
    <Alert
      show={isPlayer && isWithdrawnOrGhost && isPayableSegment && !isFirstSegment}
      type="warning-msg"
      text="Sorry, you missed a deposit or already withdrew. Keep checking our Challenges page for new opportunities!"
    />
  );
};

export const AlertErrorRedeem = () => {
  const errorRedeem = useSelector((state) => state.feedbacks.errorRedeem);
  const errorDepositIntoExternalPool = useSelector((state) => state.feedbacks.errorDepositIntoExternalPool);

  return (
    <Alert
      show={errorRedeem || errorDepositIntoExternalPool}
      type="error-msg"
      text="Oops, an error occurred. Please try again."
    />
  );
};

export const AlertSuccessRedeem = () => {
  const successRedeem = useSelector((state) => state.feedbacks.successRedeem);

  return (
    <Alert show={successRedeem} text="Funds allocated successfully. Individual players can now withdraw their funds." />
  );
};

export const AlertSuccessDeposit = () => {
  const successMakeDeposit = useSelector((state) => state.feedbacks.successMakeDeposit);
  const poolInfo = useSelector(getPoolInfo);

  return (
    <Alert
      show={successMakeDeposit[poolInfo.id]}
      text="Congrats, your deposit is complete! 🥳 Keep up the good work!"
    />
  );
};

export const AlertSuccessExternalPool = () => {
  const successDepositIntoExternalPool = useSelector((state) => state.feedbacks.successDepositIntoExternalPool);

  return <Alert show={successDepositIntoExternalPool} text="Deposit into external protocol is complete" />;
};

export const AlertNotWhitelisted = () => {
  const errorNotWhitelisted = useSelector((state) => state.feedbacks.errorNotWhitelisted);
  return (
    <Alert type="warning-msg" show={errorNotWhitelisted}>
      <span>
        Unfortunately your address isn't whitelisted for this game{' '}
        <span role="img" aria-label="sad">
          😢
        </span>
        <br />
        Keep an eye on our{' '}
        <a href={discordLink} target="_blank" rel="noopener noreferrer nofollow">
          Discord
        </a>{' '}
        to get whitelisted for future games!
      </span>{' '}
    </Alert>
  );
};

export const Alerts = () => {
  return (
    <>
      <AlertJoinGame />
      <AlertGameStarted />
      <AlertGeneralError />
      <AlertWithdrawOrGhost />
      <AlertErrorRedeem />
      <AlertSuccessRedeem />
      <AlertSuccessDeposit />
      <AlertSuccessExternalPool />
      <AlertNotWhitelisted />
    </>
  );
};
