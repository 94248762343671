import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { setRevokeApproval } from 'redux/reducers/feedbacks';
import {
  getRevokeApproval,
  getLoaderGasPrices,
  getErrorTransaction,
  getErrorRefusedSignature,
  getErrorTimeout,
} from 'redux/selectors/feedbacks.selector';
import { getStableToken } from 'redux/selectors/pool.selector';
import { isZeroSlippageError } from 'redux/selectors/user.selector';
import { bigNumberFrom } from 'utils/bignumber';

import GasPicker from 'components/sections/GasPicker';
import Button from 'components/elements/Button';
import FlexibleDepositTab from './FlexibleDepositTab';

import { ModalTab, TabHeading } from '../ModalTab';
import LoadingButton from '../../common/LoadingButton';
import { TransactionError, TransactionRefusedError, TimeoutError } from '../../common/Errors';
import SlippageSection from '../../Slippage/SlippageSection';
import useApproveDeposit from '../hooks/useApproveDeposit';
import BuyCryptoMsg from './BuyCryptoMsg';
import useCloseErrorMsg from '../hooks/useCloseErrorMsg';

const PaymentCrypto = ({ action, loaderAction }) => {
  const [approveDeposit] = useApproveDeposit();
  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useDispatch();

  const loaderApproveDeposit = useSelector((state) => state.feedbacks.loaderApproveDeposit);
  const refetch = useSelector((state) => state.user.refetch);
  const currentSegment = useSelector((state) => state.game.info.currentSegment);
  const allowance = useSelector((state) => state.user.player?.allowance);
  const stableToken = useSelector(getStableToken);
  const paymentAmount = useSelector((state) => state.game.info.paymentAmount);
  const totalSegmentCount = useSelector((state) => state.game.info.totalSegmentCount);
  const [userAgreement, setUserAgreement] = useState(false);
  const [underReview, setUnderReview] = useState(true);
  const hasBalance = useSelector((state) => state.user.hasBalance);
  const isZeroSlippage = useSelector(isZeroSlippageError);
  const revokeApproval = useSelector(getRevokeApproval);
  const loaderGasPrices = useSelector(getLoaderGasPrices);
  const closeErrorMsg = useCloseErrorMsg();

  const allowanceBN = bigNumberFrom(allowance?.toString());
  const paymentAmountBN = bigNumberFrom(paymentAmount);
  const totalGameAmount = paymentAmountBN.multipliedBy(bigNumberFrom(~~totalSegmentCount - 1 - ~~currentSegment));
  let gameAllowanceNeeded = bigNumberFrom(totalGameAmount).minus(allowanceBN);
  if (gameAllowanceNeeded.isLessThan(paymentAmountBN)) {
    gameAllowanceNeeded = paymentAmountBN;
  }
  const gameApprovalRequired = bigNumberFrom(gameAllowanceNeeded).isGreaterThanOrEqualTo(paymentAmountBN);
  const segmentApprovalRequired = paymentAmountBN.isGreaterThan(allowanceBN);
  const zeroPaymentAmount = (parseFloat(paymentAmount) === 0 || !userAgreement) && parseFloat(currentSegment) === 0;

  useEffect(() => {
    const res =
      (zeroPaymentAmount && '0') ||
      (segmentApprovalRequired && gameApprovalRequired && (!underReview || parseFloat(currentSegment) > 0) && 1) ||
      ((!segmentApprovalRequired || !gameApprovalRequired) && (!underReview || parseFloat(currentSegment) > 0) && 2);

    setActiveTab(Number(res));
    if (parseFloat(currentSegment) > 0 && !underReview) {
      setUnderReview(true);
    }
  }, [segmentApprovalRequired, gameApprovalRequired, underReview]);

  const handleSelect = (index) => {
    setActiveTab(index);
    closeErrorMsg();
    if (index === 0 && !underReview) {
      setUnderReview(true);
    }
  };

  return (
    <>
      <Tabs selectedTabClassName="active" selectedIndex={activeTab} onSelect={(index) => handleSelect(index)}>
        <TabList className="modal-tab-list mb-0">
          <ModalTab>
            <TabHeading num={1} title="Review" />
          </ModalTab>
          <ModalTab disabled={zeroPaymentAmount}>
            <TabHeading num={2} title="Approve" />
          </ModalTab>
          <ModalTab disabled={zeroPaymentAmount}>
            <TabHeading num={3} title="Confirm" />
          </ModalTab>
        </TabList>
        <TabPanel>
          <FlexibleDepositTab
            userAgreement={userAgreement}
            updateUserAgreement={setUserAgreement}
            updateReview={setUnderReview}
          />
        </TabPanel>
        <TabPanel>
          {!gameApprovalRequired ||
            (!segmentApprovalRequired && (
              <>
                <GasPicker />
                <PaymentAction
                  tooltipText="You already approved the amount required for this deposit. If preferred, you can revoke it."
                  description={`Approved amount: ${allowance?.toString()} ${stableToken}. If preferred, you can revoke it.`}
                  buttonLabel="Revoke Approval"
                  buttonId="revoke-approval"
                  isLoading={loaderApproveDeposit || refetch}
                  isDisabled={loaderApproveDeposit || refetch || loaderGasPrices}
                  buttonOnClick={() => approveDeposit('0')}
                />
              </>
            ))}
          {gameApprovalRequired && segmentApprovalRequired && (
            <>
              <p className="note">
                Note: This approval is needed before depositing your tokens in the final step. It does NOT set up
                automatic payments. You still need to make the actual deposit(s).
              </p>
              {(gameApprovalRequired || segmentApprovalRequired) && !hasBalance && <BuyCryptoMsg />}
              <div className="gas-container">
                <div className="content-column-left">
                  <p className="mb-0">Depositing</p>
                </div>
                <div className="content-column-right">
                  <p className="mb-0">
                    <a
                      href="https://docs.halofi.me/hub/halofi-challenges/faq#how-much-do-i-need-to-deposit"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`${paymentAmount || 0} ${String(stableToken).toUpperCase()}`}
                    </a>
                  </p>
                </div>
              </div>
              <GasPicker />
              <PaymentAction
                buttonLabel="Approve"
                buttonId="approve"
                isLoading={loaderApproveDeposit || refetch}
                isDisabled={loaderApproveDeposit || refetch || loaderGasPrices}
                buttonOnClick={() => {
                  approveDeposit(gameAllowanceNeeded);
                  if (revokeApproval) {
                    dispatch(setRevokeApproval(false));
                  }
                }}
                hasBalance={hasBalance}
                styles="mt-3"
              />
            </>
          )}
        </TabPanel>
        <TabPanel>
          {segmentApprovalRequired && (
            <p className="fw-600 font-size-10"> Please approve the required amount before depositing.</p>
          )}
          {!segmentApprovalRequired && (
            <>
              <p className="note">
                Note: This does NOT set up automatic payments. You still need to make the actual deposit(s).
              </p>
              {!segmentApprovalRequired && !hasBalance && <BuyCryptoMsg />}
              <GasPicker />
              <PaymentAction
                tooltipText={`A deposit of ${paymentAmount} ${stableToken} is required to proceed`}
                buttonLabel="Confirm"
                isLoading={loaderAction || refetch}
                isDisabled={loaderAction || isZeroSlippage || loaderAction || loaderGasPrices}
                buttonOnClick={action}
                buttonId="deposit"
                isDepositTransaction
                hasBalance={hasBalance}
              />
            </>
          )}
        </TabPanel>
      </Tabs>
    </>
  );
};

const PaymentAction = ({
  description,
  buttonLabel,
  buttonOnClick,
  buttonId,
  isLoading,
  isDisabled,
  styles,
  isDepositTransaction,
  hasBalance,
}) => {
  const history = useHistory();
  const stableToken = useSelector(getStableToken);
  const depositToken = stableToken && stableToken.toUpperCase();
  const errorTransaction = useSelector(getErrorTransaction);
  const errorRefusedSignature = useSelector(getErrorRefusedSignature);
  const timeoutError = useSelector(getErrorTimeout);

  const classes = classNames('payment-action', styles);
  const buttonClassName = classNames('button', 'button-primary', !hasBalance && 'button-primary-outline');
  const buyBtnClass = classNames('mb-10 mr-10', hasBalance ? 'button-light' : 'button-primary');
  return (
    <div className={classes}>
      {isDepositTransaction && <SlippageSection />}
      {description && <p className="mb-12 fw-600 font-size-10">{description}</p>}
      {errorTransaction && <TransactionError />}
      {errorRefusedSignature && <TransactionRefusedError />}
      {timeoutError && <TimeoutError />}
      <div>
        <Button
          onClick={() => history.push(`/buy${depositToken ? `?token=${depositToken}` : ''}`)}
          className={buyBtnClass}
          id="buy-crypto-button-review-tab"
        >
          Get {String(depositToken)}
        </Button>
        <LoadingButton
          text={buttonLabel}
          onClick={buttonOnClick}
          loading={isLoading}
          buttonId={buttonId}
          disabled={isDisabled}
          className={buttonClassName}
        />
      </div>
    </div>
  );
};

export { PaymentCrypto };
