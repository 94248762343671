import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSuccessWithdraw,
  setLoaderWithdraw,
  setShowWithdrawModal,
  setErrorTransaction,
  setErrorRefusedSignature,
  setErrorTransactionMessage,
} from 'redux/reducers/feedbacks';
import { updatePlayerInfo } from 'redux/reducers/user';
import { getUserAddress } from 'redux/selectors/user.selector';
import useWriteContract from 'providers/ContractProvider/useWriteContract';

import { HashContext } from 'providers/HashProvider';
import { GasContext } from 'providers/GasProvider';
import { getGameVersionType } from 'utils/utilities';

import { useSendEvent } from '../components/Modals/hooks/useSendEvent';

export default function useWithdraw(gameInfo) {
  const dispatch = useDispatch();
  const WriteContract = useWriteContract();
  const {
    setWithdrawPlayerInfo,
    withdrawGameInfo: { displayId },
    setWithdrawnGames,
  } = useContext(HashContext);

  const { reset } = useContext(GasContext);

  const usersAddress = useSelector(getUserAddress);

  const logEvent = useSendEvent();

  const withdraw = async () => {
    dispatch(setLoaderWithdraw(true));

    try {
      const withdrawTx = await WriteContract.withdraw();
      const isV2Game = getGameVersionType(gameInfo);

      let withdrawnInboundTokensAmount = null;
      let withdrawRewardTokensAmount = null;
      let withdrawIncentiveTokensAmount = null;
      let withdrawnAmount = null;

      if (isV2Game) {
        withdrawnInboundTokensAmount = withdrawTx?.events?.WithdrawInboundTokens?.returnValues?.amount;
        withdrawRewardTokensAmount = withdrawTx?.events?.WithdrawRewardTokens?.returnValues?.amount;
        withdrawIncentiveTokensAmount = withdrawTx?.events?.WithdrawIncentiveToken?.returnValues?.amount;
      } else {
        withdrawnAmount = withdrawTx?.events?.Withdrawal?.returnValues?.amount;
      }

      dispatch(
        updatePlayerInfo({
          withdrawn: true,
          withdrawnAmount,
          withdrawnInboundTokensAmount,
          withdrawRewardTokensAmount,
          withdrawIncentiveTokensAmount,
          isV2Game,
        })
      );
      setWithdrawPlayerInfo((prev) => ({
        ...prev,
        withdrawn: true,
        withdrawnAmount,
        withdrawnInboundTokensAmount,
        withdrawRewardTokensAmount,
        withdrawIncentiveTokensAmount,
        isV2Game,
      }));
      dispatch(setLoaderWithdraw(false));
      dispatch(setSuccessWithdraw({ success: true, id: gameInfo.id }));
      dispatch(setShowWithdrawModal(true));

      setWithdrawnGames((prev) => [...prev, displayId]);

      reset();

      return true;
    } catch (err) {
      console.error(err);
      dispatch(setSuccessWithdraw({ success: false, id: gameInfo.id }));

      if (err.code === 4001) {
        dispatch(setErrorRefusedSignature(true));
      } else {
        dispatch(setErrorTransaction(true));
        if (err?.message.includes('max fee per gas less than block base fee')) {
          dispatch(
            setErrorTransactionMessage(
              'Minimum Network fee is higher than the Transaction Speed set. Increase the Transaction Speed and try again'
            )
          );
        } else {
          dispatch(setErrorTransactionMessage(err?.message));
        }
        logEvent('WITHDRAW_TRANSACTION_ERROR', gameInfo.networkId, {
          errMsg: err?.message,
          usersAddress,
          navigator: window.navigator.userAgent,
        });
      }

      dispatch(setShowWithdrawModal(true));

      return false;
    } finally {
      dispatch(setLoaderWithdraw(false));
    }
  };

  return [withdraw];
}
