/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { getCurrentSegment } from 'redux/selectors/game.selector';
import { getAddToCalendar } from 'redux/selectors/pool.selector';
import { getMostRecentSegmentPaid, getPlayer } from 'redux/selectors/user.selector';

function _getTimelineTitle(
  segment,
  numberOfRounds,
  mostRecentRoundPaid,
  mostRecentSegmentPaid,
  currentSegment,
  player
) {
  if (segment.round === numberOfRounds) {
    return 'Waiting Round';
  }

  if (segment.round === numberOfRounds + 1) {
    return 'Withdraw';
  }

  if (segment.round === 1) {
    return `Join Round ${
      mostRecentRoundPaid && player.allowanceString ? (mostRecentRoundPaid >= segment.round ? '✔️' : '❌') : ''
    }`;
  }

  if (mostRecentRoundPaid) {
    const lastPart =
      mostRecentRoundPaid >= segment.round ? '✔️' : currentSegment - parseInt(mostRecentSegmentPaid) > 1 ? '❌' : '';
    return `Deposit ${segment.round} ${lastPart}`;
  }

  return `Deposit ${segment.round}`;
}

function _getTimelineCardTitle(segment, numberOfRounds) {
  const isGameEnd = segment.round === numberOfRounds + 1;

  if (segment.round === 1) {
    return 'Game launched';
  }

  if (isGameEnd) {
    return 'Waiting round ends';
  }

  if (segment.round === 2) {
    return 'Join deadline';
  }

  return `Deposit deadline ${segment.round - 1}`;
}

export default function useTimeline() {
  const addToCalendar = useSelector(getAddToCalendar);
  const allSegmentTimes = useSelector((state) => state.game.derived.allSegmentTimes || []);
  const gameInfo = useSelector((state) => state.game.info);
  const { totalSegmentCount } = gameInfo;
  const mostRecentSegmentPaid = useSelector(getMostRecentSegmentPaid);
  const currentSegment = useSelector(getCurrentSegment);
  const player = useSelector(getPlayer);
  const user = useSelector((state) => state.user);

  const segmentCount = ~~totalSegmentCount;
  const mostRecentRoundPaid = useMemo(
    () => parseInt(mostRecentSegmentPaid !== undefined && player.isAlive ? mostRecentSegmentPaid : -2) + 1,
    [mostRecentSegmentPaid, player]
  );

  const items = useMemo(
    () =>
      allSegmentTimes.map((segment, index) => ({
        title: _getTimelineTitle(
          segment,
          segmentCount,
          mostRecentRoundPaid,
          mostRecentSegmentPaid,
          currentSegment,
          player
        ),
        cardTitle: _getTimelineCardTitle(segment, segmentCount),
        cardSubtitle: segment.dateData.format('HH:mm ddd D MMM z'),
        active: index > 0,
      })),
    [allSegmentTimes, user]
  );

  return { items, addToCalendar, currentSegment, segmentCount };
}
